import { Pipe, PipeTransform } from '@angular/core';

/**
 * Example:
 * 1000 | value
 * returns:
 * 1 000
 */
@Pipe({
  name: 'thousandspipe',
  pure: true
})
export class ThousandsPipe implements PipeTransform {
  transform(value: number | string | null = null, roundTo: number | null = null) {
    if (!value || value == '' || value == null) {
      return '0';
    }

    if (typeof value === 'string') {
      value = parseInt(value, 10);
    }

    let str = '';

    if (roundTo == null) {
      str = Number(value).toFixed();
    }
    else {
      str = Number(value).toFixed(roundTo);
    }

    if (!(str.length > 3)) {
      return str;
    }

    let mass = str.split('');
    str = mass.reverse().join('');
    str = str.replace(/(\d{3})/g, '$1 ');
    mass = str.split('');
    str = mass.reverse().join('').trim();

    return str;
  }
}
