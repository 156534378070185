export class ArticleHistoryModel {
    id!: string | null;
    articleId!: string | null;
    userName!: string;
    recordContent!: string;
    recordedDate!: string;

    constructor(data?: any) {
        if (data) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    this[key as keyof ArticleHistoryModel] = <never>data[key];
                }
            }
        }
    }
}