import { Component, Input, Output, EventEmitter, SimpleChange, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { FileContract } from 'src/app/contracts/file';
import { trigger, transition, animate, style } from '@angular/animations';
import { MessageService } from '../../services/message.service';
import { FileViewData } from 'src/app/contracts/file-view-data';




@Component({
  selector: 'app-file-list',
  templateUrl: './app-file-list.component.html',
  styleUrls: ['./app-file-list.component.scss'],
  animations: [
    trigger('showElement', [
      transition(':enter', [
        style({ opacity: '0', }),
        animate('.5s linear', style({ opacity: '1' }))
      ]),
      transition(':leave', [
        style({ opacity: '1', }),
        animate('.2s linear', style({ opacity: '0' }))
      ])
    ]),
  ]
})
export class AppFileListComponent implements OnChanges, OnInit, OnDestroy {
  /**
   * Отоброжаемые имена файлов для пользователей
   * (Требуется если они должны отличаться от пользовательских)
   * @type {string[]}
   */
  @Input() filesFakeNames: string[];
  @Output() filesFakeNamesChange = new EventEmitter<string[]>();
  /**
   * Файлы для отображения
   * @type {FileViewData[]}
   */
  @Input() files: FileViewData[];
  @Output() filesChange = new EventEmitter<FileViewData[]>();
  /**
   * Флаг для блокировки кнопки удаления
   * @type {boolean}
   */
  @Input() disabled: boolean;
  /**
   * Флаг для возмоности скачивания файлов
   * @type {boolean}
   */
  @Input() downloadEnable: boolean;
  /**
   * Флаг для возможности удаления файлов из списка
   * @type {boolean}
   */
  @Input() deleteEnable: boolean;
  /**
   * Флаг для отображения мок данных
   * @type {boolean}
   */
  @Input() showMockFile: boolean;
  /**
   * Событие удаления элемента из списка
   * Передает индекс удаленного элемента из листа
   * Нужен для связной работы с загрзчиком файлов(для чистки очереди)
   * @type {EventEmitter<any>}
   */
  @Output() deleteEvent: EventEmitter<number>;


  constructor(
    private messageService: MessageService,
  ) {
    this.filesFakeNames = [];
    this.files = [];
    this.showMockFile = false;
    this.disabled = false;
    /**По умолчанию скачивание запрещено */
    this.downloadEnable = false;
    /**По умолчанию удаление разрешено */
    this.deleteEnable = true;
    this.deleteEvent = new EventEmitter<number>();
  }

  /**
   * Отслеживаются изменения в компоненте
   * @param {object} changes Изменения входных полей
   *
   * @returns {void}
   */
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
    if (changes['filesFakeNames'] || changes['files']) {
      if (this.checkArray(this.filesFakeNames) && this.checkArray(this.files)) {
        /** Устанавиваем фейковые имена для файлов, если они пришли */
        this.files.forEach((file, index) => {
          if (file) {
            file.fileFakeName = this.filesFakeNames[index] ?
              this.filesFakeNames[index]
              : file.fileFakeName;
          }
        });
        this.filesChange.emit(this.files);
        return;
      }

      if (!this.checkArray(this.filesFakeNames) && this.checkArray(this.files)) {
        this.filesFakeNames = [];
        /** Устанавиваем реальные имена для файлов в фейковые, если фэйковые не пришли */
        this.filesFakeNames.forEach((name, index) => {
          if (this.files[index]) {
            name = this.files[index].fileFakeName ?
              this.files[index].fileFakeName
                : this.files[index].fileName ?
                  this.files[index].fileName
              : 'No name';
          }
        });
        this.filesFakeNamesChange.emit(this.filesFakeNames);
        return;
      }
    }
  }

  /**
   * Инициализация компонента
   * @returns {void}
   */
  ngOnInit():void {
    if (this.showMockFile) {
      this.files = [
        new FileViewData({
          id: '1',
          fileName: 'file.jpg',
          fileFakeName: 'my_file.jpg'
        })
      ];
      this.filesChange.emit(this.files);
    }
  }

  /**
   * Уничтожение компонента
   * @returns {void}
   */
  ngOnDestroy():void {
    this.filesFakeNames = [];
    this.filesFakeNamesChange.emit(this.filesFakeNames);
    this.files = [];
    this.filesChange.emit(this.files);
  }

  /**
   * Метод удаления файла из списка
   * @param {FileContract} file Файл
   * @param {number} index Индекс файла в списке
   *
   * @returns {void}
   */
  onDelete(file: FileViewData, index: number): void {
    if (this.deleteEnable && !this.disabled && file) {
      /**Имя файла без расшерения */
      // const id = file.id ? file.id.split('.').shift() : null;
      if (!file.id) {
        this.messageService.error('Id файла undefined');
        return;
      }
      this.disabled = true;
      this.deleteItem(index);
      this.disabled = false;
      this.deleteEvent.emit(index);
    }
  }

  /**
   * Метод скачивания файла
   * @param {FileContract} file Файл
   *
   * @returns {void}
   */
  onDownload(file: FileViewData): void {
    if (this.downloadEnable && !this.disabled && file) {
      // TODO
    }
    console.log(file);
  }

  /**
   * Метод удаления элемента из списка
   * @param {number} index Индекс файла в списке
   *
   * @returns {void}
   */
  private deleteItem(index: number): void {
    if (this.checkArray(this.files)) {
      this.files.splice(index, 1);
      this.filesChange.emit(this.files);
    }
  }

  /**
   * Метод проверяет наличие элементов в массиве - его содержание
   * @param {any[]} array Массив для проверки
   *
   * @returns {boolean} результат - удовлетворяет ли массив условию
   */
  private checkArray(array: any[]): boolean {
    return (array && array.length) ? true : false;
  }
}
