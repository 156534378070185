import { Injectable } from '@angular/core';
import { ApiEndpoints, ApiService, AuthorizationService, BaseApiService } from '../../api';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscriber, throwError, Subject, forkJoin } from 'rxjs';
import { FileContract } from 'src/app/contracts';
import * as _ from 'underscore';
import { finalize } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FileService {
    /**
     * Событие завершения загрузки файла
     * @type {Subject<boolean>}
     */
    uploadComplete: Subject<boolean>;
    /**
     * Событие ошибки загрузки файла
     * @type {Subject<boolean>}
     */
    uploadCompleteWithError: Subject<boolean>;

    constructor(private http: HttpClient, private baseApiService: BaseApiService, private apiService: ApiService, private authService: AuthorizationService) {
        this.uploadComplete = new Subject<boolean>();
        this.uploadCompleteWithError = new Subject<boolean>();
    }

    // /**
    //  * Метод отправки файла на сервер
    //  * @param {File} file Файл
    //  *
    //  * @returns {Observable<FileContract>}
    //  */
    // uploadFile(file: File, userUrl?: string, data?: any): Observable<any> {

    //   const url = userUrl ? userUrl : ApiEndpoints.files().listUrl();
    //   const options = this.getDefaultOptions();
    //   const formData = new FormData();
    //   formData.append('file', file);
    //   formData.append('fileName', file.name);

    //   if (data) {
    //     for (const d in data) {
    //       if (data.hasOwnProperty(d)) {
    //         formData.append(`${d}`, data[d]);
    //       }
    //     }
    //   }

    //   this.baseApiService.loadInProgress = true;

    //   const ctx = this;

    //   return new Observable<any>((sub: Subscriber<any>) => {
    //     ctx.http.post(url, formData, options)
    //       .do(() => { this.baseApiService.loadInProgress = false; })
    //       .catch(error => this.handleError(error, this))
    //       .subscribe(res => {
    //         sub.next(res['_body']);
    //         sub.complete();
    //       }, error => {
    //         this.baseApiService.loadInProgress = false;
    //         sub.error(JSON.parse(error['_body']));
    //         sub.complete();
    //       });
    //   });
    // }

    // /**
    //  * Метод обновления файла на сервере
    //  * @param {string} id Идентификатор Файла
    //  * @param {File} file Файл
    //  *
    //  * @returns {Observable<string>}
    //  */
    // updateFile(id: string, file: File): Observable<string> {

    //   const url = ApiEndpoints.files().itemUrl(id);
    //   const options = this.getDefaultOptions();
    //   const formData = new FormData();
    //   formData.append('file', file);
    //   formData.append('fileName', file.name);
    //   this.baseApiService.loadInProgress = true;

    //   const ctx = this;

    //   return new Observable<string>((s: Subscriber<string>) => {
    //     ctx.http.put(url, formData, options)
    //       .do(() => { this.baseApiService.loadInProgress = false; })
    //       .catch(error => this.handleError(error, this))
    //       .subscribe(res => {
    //         s.next(res['_body']);
    //         s.complete();
    //       }, error => {
    //         this.baseApiService.loadInProgress = false;
    //         s.error(JSON.parse(error['_body']));
    //         s.complete();
    //       });
    //   });
    // }

    // /**
    //  * Метод удаления файла
    //  * @param {string} id Идентификатор файла
    //  *
    //  * @returns {Observable<void>}
    //  */
    // removeFile(id: string): Observable<void> {
    //   const ctx = this;
    //   const url = ApiEndpoints.files().itemUrl(id);
    //   const options = this.getDefaultOptions();
    //   this.baseApiService.loadInProgress = true;
    //   return new Observable((sub: Subscriber<void>) => {
    //     ctx.http.delete(url, options)
    //     .do(() => { this.baseApiService.loadInProgress = false; })
    //     .catch(error => this.handleError(error, this))
    //     .subscribe(res => {
    //       sub.next(null);
    //       sub.complete();
    //     }, error => {
    //       this.baseApiService.loadInProgress = false;
    //       sub.error(error['_body']);
    //       sub.complete();
    //     });
    //   });
    // }

    /**
     * Метод скачивания файла
     * @param {string} url Адрес
     *
     * @returns {Observable<any>}
     */
    downloadFile(url: string): Observable<any> {
        const downloading = true;
        const options = AuthorizationService.createOptions(null, 'blob');
        this.baseApiService.loadInProgress = true;

        const ctx = this;

        return new Observable<any>((sub: Subscriber<any>) => {
            ctx.http
                .get(url, options)
                .pipe(
                    // takeUntil(this.unsubscribe),
                    finalize(() => (this.baseApiService.loadInProgress = false))
                )
                // .catch(error => this.handleError(error, this))
                .subscribe(
                    (data: any) => { // Blob
                        const blob = new Blob([data], { type: data.type });
                        sub.next(blob);
                        sub.complete();
                    },
                    (error: any) => {
                        this.baseApiService.loadInProgress = false;
                        sub.error(error);
                        sub.complete();
                    }
                );
        });
    }

    /**
     * Метод отправки нескольких файлов на сервер параллельными запросами
     * @param {File[]} files Файлы
     *
     * @returns {Observable<FileContract[]>}
     */
    uploadQueueOfFiles(files: File[], url?: string, data?: any): Observable<FileContract[]> {
        const _url = url ? url : ApiEndpoints.files().listUrl();
        this.baseApiService.loadInProgress = true;

        const ctx = this;
        return new Observable(sub => {
            const requests = _.map(files, file => {
                const options = AuthorizationService.createOptions(null, 'blob');
                options.headers = { Authorization: AuthorizationService.getAuthorizationHeader() };
                const formData = new FormData();
                formData.append('file', file);
                formData.append('fileName', file.name);

                if (data) {
                    for (const d in data) {
                        if (data.hasOwnProperty(d)) {
                            formData.append(`${d}`, data[d]);
                        }
                    }
                }

                return ctx.apiService.post(_url, formData);
            });

            /** Если файлов нет */
            if (requests.length === 0) {
                this.baseApiService.loadInProgress = false;
                sub.next([]);
                sub.complete();
                return;
            }

            /** Параллельные запросы */
            forkJoin(requests)
                .pipe(
                    // takeUntil(this.unsubscribe),
                    finalize(() => (this.baseApiService.loadInProgress = false))
                )
                // .catch(error => this.handleError(error, this))
                .subscribe(
                    res => {
                        sub.next(_.map(res, r => new FileContract()));
                        sub.complete();
                    },
                    error => {
                        this.baseApiService.loadInProgress = false;
                        sub.error(JSON.parse(error['_body']));
                        sub.complete();
                    }
                );
        });
    }

    // /**
    //  * Метод удаления нескольких файлов одновременно
    //  * @param {string[]} fileIds Идентификаторы файлов
    //  *
    //  * @returns {Observable<void>}
    //  */
    // removeQueueOfFiles(fileIds: string[]): Observable<void> {
    //   this.baseApiService.loadInProgress = true;

    //   const ctx = this;
    //   return new Observable(sub => {
    //     const requests = _.map(fileIds, id => {

    //       const url = ApiEndpoints.files().itemUrl(id);
    //       const options = this.getDefaultOptions();

    //       return ctx.http.delete(url, options);
    //     });

    //     /** Если идентификаторов нет */
    //     if (requests.length === 0) {
    //       this.baseApiService.loadInProgress = false;
    //       sub.next(null);
    //       sub.complete();
    //       return;
    //     }

    //     /** Параллельные запросы на удаление */
    //     forkJoin(requests)
    //       .do(() => { this.baseApiService.loadInProgress = false; })
    //       .catch(error => this.handleError(error, this))
    //       .subscribe(() => {
    //         this.baseApiService.loadInProgress = false;
    //         sub.next();
    //         sub.complete();
    //       }, error => {
    //         this.baseApiService.loadInProgress = false;
    //         sub.next(error);
    //         sub.complete();
    //       });
    //   });
    // }

    /**
     * Метод отлова ошибок
     * @param {Response} error Ошибка с сервера
     * @param {FileService} ctx Контекст
     *
     * @returns {Observable<never>}
     */
    handleError(error: Response, ctx: FileService): Observable<never> {
        // this.baseApiService.loadInProgress = false;

        if (error.status == 401) {
            // Unauthorized
            this.authService.logout();
        }

        if (error.status == 403) {
            // Forbidden
            this.authService.logout();
        }

        return throwError(error);
    }

    // /**
    //  * Получение options для запросов
    //  * @param {boolean} downloading Флаг скачивания, для добасления responseType
    //  *
    //  * @returns {RequestOptions} объект настроек для http запроса
    //  */
    // getDefaultOptions(downloading?: boolean): RequestOptions {
    //     const headers = new Headers({
    //         'Access-Control-Allow-Origin': '*',
    //         Authorization: AuthorizationService.getAuthorizationHeader()
    //     });
    //     if (downloading) {
    //         const responseType = ResponseContentType.Blob;
    //         return new RequestOptions({ headers: headers, responseType: responseType });
    //     }
    //     return new RequestOptions({ headers: headers });
    // }
}
