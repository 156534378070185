 <div *ngIf="settings?.filterVisible" class="app-filters" [class.compact]="isFilterCompact" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">

  <div *ngIf="settings?.filterBySearch">
    <mat-form-field class="mat-form-field__search-field">
      <div matPrefix disableRipple matTooltip="Press Enter to search" matTooltipPosition="after">
        <img height="18px" src="./../../../../assets/icons/search.svg" alt="">
      </div>
      <input (keyup.enter)="onSearch(search)" matInput type="search" class="" placeholder="Search on page" name="search"
        [(ngModel)]="search" [disabled]="disabled()" autocomplete="off">
      <div *ngIf="isSearchDirty" (click)="onResetSearchFilter()" matSuffix disableRipple>
        <img width="14px" src="./../../../../assets/icons/search.svg" alt="">
      </div>
    </mat-form-field>
  </div>

  <div *ngIf="settings?.applyBtnVisible || settings?.resetBtnVisible" fxLayout="column">
    <div class="filter-header">
      <span>FILTERS</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px">

        <button type="button" mat-flat-button class="btn-sm btn-normal"
          (click)="onApplyFilter()" style="min-width: unset; width: 50%;"
          [disabled]="applyBtnDisabled()">Apply</button>

        <button type="button" mat-flat-button class="btn-sm btn-normal"
          (click)="onResetFilter()" style="min-width: unset; width: 50%;"
          [disabled]="resetBtnDisabled()">Reset</button>

    </div>
  </div>

  <div *ngIf="isVisibleSomeFilter() && !settings?.applyBtnVisible && !settings?.resetBtnVisible" fxLayout="column">
    <div class="filter-header" fxLayout="row" fxLayoutAlign="space-between center">
      <span>FILTERS</span>
      <span *ngIf="!settings?.applyBtnVisible" class="reset-filters-btn" matTooltip="Reset all filters" matTooltipPosition="after" (click)="onResetAllFilters()">RESET</span>
    </div>
  </div>

  <div fxLayout="column" fxLayoutGap="20">
    <!-- DECISION RULES FILTER -->
    <div *ngIf="settings?.filterByDecisionRules" class="filters" fxLayout="row" fxLayoutAlign="start center">

      <app-multiselect
        [disabled]="disabled() || decisionRulesDisabled || disabledFromParent"
        [placeholder]="'Decision Rules'"

        [items]="decisionRules"
        [selectedItems]="selectedDecisionRules"

        [isMultipleModeEnable]="true"
        [isSearchEnable]="true"
        [panelStyleClass]="'app-filters__multiselect-panel'"
        [componentStyleClass]="'app-filters__multiselect'"

        (selectedValue)="onSelectDecisionRules($event)"
        (selectedItemsChange)="updateSelectedItems($event, 'selectedDecisionRules')">
      </app-multiselect>

      <div fxHide fxShow.xs fxShow.sm fxShow.md class="divider"></div>

    </div>

    <!-- RULES FILTER -->
    <div *ngIf="settings?.filterByRulesMatch" class="filters" fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field appearance="outline"  class="app-multiselect__multiselect" fxFlex="100" [matMenuTriggerFor]="rulesMatch">
        <input matInput placeholder="Rules Match"
          [disabled]="disabled() || rulesMatchDisabled || disabledFromParent"
          [ngModel]="getRulesMatchInput()" #rulesMatchInput readonly/>
        <!-- Текст при множественном выборе -->
        <span *ngIf="selectedRulesMatch.length > 1" class="app-multiselect__multiple app-multiselect__multiple--show">(+{{ selectedRulesMatch.length - 1 }})</span>
        <!-- Иконка удаления -->
        <div matSuffix class="app-multiselect__icon-clear" [class.app-multiselect__icon-clear--show]="selectedRulesMatch.length > 0 && !rulesMatchFilterIsOpen()" (click)="onClearRulesMatch($event)"></div>
        <!-- Иконка стрелочка -->
        <div matSuffix class="app-multiselect__icon-arrow" [class.app-multiselect__icon-arrow--up]="rulesMatchFilterIsOpen()" [class.app-multiselect__icon-arrow--hide]="selectedRulesMatch.length > 0 && !rulesMatchFilterIsOpen()"></div>
      </mat-form-field>
      <mat-menu class="rules-match-menu" #rulesMatch="matMenu">
        <div class="rules-match-list" fxLayout="column" fxLayoutGap="5">
          <div *ngFor="let ruleMatch of decisionRules" class="rules-match-item" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5">
            <span>{{ ruleMatch.name }}</span>
            <mat-button-toggle-group class="rules-match-toggle-group" name="fontStyle" aria-label="Font Style" [value]="getRuleMatchValue(ruleMatch)">
              <mat-button-toggle (click)="onChangeRulesMatch(ruleMatch, 'Yes', $event)" value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle (click)="onChangeRulesMatch(ruleMatch, 'No', $event)" value="No">No</mat-button-toggle>
              <mat-button-toggle (click)="onChangeRulesMatch(ruleMatch, 'Empty', $event)" value="Empty">Empty</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>

  <!-- CONFIDENCE FILTER -->
  <div *ngIf="settings?.filterByConfidence" class="filters" fxLayout="column" fxLayoutAlign="start " fxLayoutGap="10">
    <span>Confidence</span>
    <div class="filters" fxLayout="row" fxLayoutAlign="start center" fxFlex="100" fxLayoutGap="10">
      <mat-form-field appearance="outline"  class="app-multiselect__multiselect" fxFlex="100px">
        <input matInput placeholder="From" type="number"
          [disabled]="disabled() || confidenceDisabled || disabledFromParent"
          (blur)="onChangeConfidence()"
          [(ngModel)]="confidenceFrom" #confidenceFromInput/>
        <!-- Иконка удаления -->
        <div matSuffix class="app-multiselect__icon-clear" [class.app-multiselect__icon-clear--show]="confidenceFrom" (click)="confidenceFrom = null;"></div>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="app-multiselect__multiselect" fxFlex="100px">
        <input matInput placeholder="To" type="number"
          [disabled]="disabled() || confidenceDisabled || disabledFromParent"
          (blur)="onChangeConfidence()"
          [(ngModel)]="confidenceTo" #confidenceToInput/>
        <!-- Иконка удаления -->
        <div matSuffix class="app-multiselect__icon-clear" [class.app-multiselect__icon-clear--show]="confidenceTo" (click)="confidenceTo = null;"></div>
      </mat-form-field>
    </div>
  </div>

</div>
