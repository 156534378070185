import { ArticleGroupModel } from "../../article-groups/shared/article-group.model";
import { Model } from "../../shared/interfaces";
import { ArticleAttribute } from "./article-attribute";
import { CompetitorModel } from "../../competitors/shared/competitor.model";
import { TagModel } from "src/app/tags/shared/tag.model";

export type ListerPlatformTypes = 'EbayLister' | 'AmazonLister';

// Это на самом деле Lister как оказалось
export class ArticleModel implements Model {
    id!: string | number | null; // listerId для базы (ранее был articleId)
    productId!: string;
    listerId!: string; // listerId для ETS
    dbListerId!: string;
    articleId!: string; // добавлен чтобы сделать id уникальным
    articleLink!: string;
    image!: string;
    imageUri!: string;
    ebayUri!: string;
    articleEbayId!: string;
    articleEbayUri!: string;
    articleImageUri!: string;
    articleAccount!: string;
    competitivePrice!: number;
    maxCompetitorPrice!: number;
    minCompetitorPrice!: number;
    bestCompetitorPrice!: number;
    title!: string;
    price!: number;
    deliveryPrice!: number;
    totalPrice!: number;
    articleGroup!: string | null;
    articleGroupName!: string | null;
    active!: boolean;
    excluded!: boolean;
    excludedByGroup!: boolean;
    totalActualCompetitors!: number;
    totalCompetitorsSold!: number;
    totalCompetitorsSale!: number;
    attributes!: ArticleAttribute[];
    source!: string;
    platformId!: string;

    createdDate!: number;
    updatedDate!: number;
    updatedByUserId!: string;
    updatedByUserName!: string;

    visibilityRank!: number;
    marketplaceRank!: number;

    sold!: number;
    sale!: number;
    sellerAccount!: string;
    soldFor7Days!: number;
    saleIncomeFor7Days!: number;
    totalCompetitorsSoldFor7Days!: number;
    totalCompetitorsSoldFor30Days!: number;
    totalCompetitorsSaleIncomeFor7Days!: number;
    totalCompetitorsSaleIncomeFor30Days!: number;
    isCompetitionDiscoveryInProgress!: boolean;

    articleGroupId!: string | null;
    account!: string;

    priceDifference!: number;
    priceDifferencePercent!: number;

    // For UI
    isShownImage: boolean | undefined = false;
    competitors!: CompetitorModel[];
    isShownCompetitors: boolean = false;

    tags!: TagModel[];
    subArticles!: ArticleModel[];
    strategyId!: string | null;
    strategyName!: string | null;
    isStrategyCompetitivePriceRangeEnabled!: boolean | null;
    strategyFromCompetitivePrice!: number | null;
    strategyToCompetitivePrice!: number | null;
    bidPercentage!: number | null;

    calculatedPrice!: number | null;
    recommendedSalePrice!: number | null;
    minCalculatedPrice!: number | null;
    maxCalculatedPrice!: number | null;
    erpPrice!: number | null;

    boostEnabled!: boolean | null;
    boostStartedAt!: number | null;
    boostEndsAt!: number | null;
    listerPlatformType!: ListerPlatformTypes | null;
    listingDate!: string;

    constructor(data?: any) {
        if (data) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    this[key as keyof ArticleModel] = <never>data[key];
                }
            }
        }
    }
}