<div class="fx fx-column filter-wrapper">
    <div class="fx fx-column filter__header">
        <div class="fx fx-row fx-jc-space-between fx-gap-24">
            <div class="fx fx-row">
                <app-search fxFlex="389px"
                [searchByEnter]="false"
                    [borderRadiusRightDisable]="true"
                    [search]="search"
                    [placeholder]="pageText.searchPlaceholder"
                    [tagRecognitionModeEnable]="false"
                    (searchChanged)="onSearch($event)">
                </app-search>
                <div style="width:254px;">
                    <button (click)="onApplyFilter()" type="button"
                        mat-flat-button color="primary" disableRipple style="border-radius: 0 4px 4px 0;width:100%;">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                            <svg class="search-icon btn-svg-icon-16">
                                <use xlink:href="assets/icons/search.svg#searchIcon"/>
                            </svg>
                            <span>{{pageText.searchBtnPlaceholder}}</span>
                        </div>
                    </button>
                </div>
            </div>
            <div class="buttons-container" fxLayout="row">
                <button type="button" class="button" fxLayoutAlign="center center"
                    style="min-width:79px;" fxLayoutGap="12" disableRipple (click)="onClose()">
                    <span>{{pageText.closeBtnPlaceholder}}</span>
                </button>
            </div>
        </div>

        <div class="fx fx-column fx-jc-center filter__seletced-filters">
            @if (selectedFields && selectedFields.length) {
                <app-filter-selected-fields
                    [selectedFields]="selectedFields"
                    (fieldClicked)="onResetSelectedField($event)"
                    (resetButtonClicked)="onResetAllSelectedFields()">
                </app-filter-selected-fields>
            }
            @else {
                <span class="filter__no-filters">{{pageText.noFilters}}</span>
            }
        </div>
    </div>

    <div class="fx fx-column filter__panel">
        <div fxLayout="column" fxFlex="auto" fxLayoutGap="16px">
            <div #platformFiltersOptions fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                <h1 class="filter-pannel__title">{{pageText.platformFiltersPlaceholder}}</h1>
            </div>
            <div fxLayout="column" fxLayoutGap="16px">
                <div fxLayout="row">
                    <span class="filter-pannel__label">{{pageText.productPrice}}</span>
                </div>
                <div fxLayout="row" fxLayoutGap="16px">
                    <app-number-input fxFlex="50" [label]="'From, €'" [value]="priceFrom" [min]="0"
                        [max]="1000000" [regex]="priceRegex"
                        (valueChange)="onChangePriceFrom($event)"></app-number-input>

                    <app-number-input fxFlex="50" [label]="'To, €'" [value]="priceTo" [min]="0" [max]="1000000"
                        [regex]="priceRegex" (valueChange)="onChangePriceTo($event)"></app-number-input>
                </div>
            </div>
            <div fxLayout="column" fxLayoutGap="16px">
                <div fxLayout="row">
                    <span class="filter-pannel__label">{{pageText.entityIsOneCar}}</span>
                </div>
                <div fxLayout="row" fxLayoutGap="16px">
                    <app-multiselect fxFlex="100" [isSearchEnable]="true"
                        [generalTextIsSearchPlaceholder]="pageText.hsnTsnPlaceholder" [isLocalList]="false"
                        [isClearIconVisible]="true" [items]="cars" [selectedItems]="selectedCars"
                        [isSearchEnable]="true" [displayName]="'productName'" [uniqueField]="'productName'"
                        [placeholder]="pageText.generalTextIsSelectCar" [panelStyleClass]="'app-filter__multiselect-panel'"
                        [componentStyleClass]="'app-filter__multiselect hide-field-hint'" [disabled]="!isComponentInitialized"
                        (selectedValue)="onSelectCars($event)"
                        (selectedItemsChange)="onUpdateSelectedItems($event, 'selectedCars')"
                        (searchChange)="onCarSearchChange($event)">
                    </app-multiselect>
                </div>
            </div>
        </div>
    </div>
</div>