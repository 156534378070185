import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IHeaderSettings } from '../../interfaces';



@Injectable({
  providedIn: 'root'
})
export class AppHeaderService {

  public searchEvent = new Subject<string | null>();

  public setSettings: BehaviorSubject<Partial<IHeaderSettings>> = new BehaviorSubject({

    isEnable: false,
    // header: '',
    // subHeader: '',

  } as Partial<IHeaderSettings>);

  public setDefaultSettings(): Partial<IHeaderSettings> {
    return {

      isEnable: false,
      // header: '',
      // subHeader: '',

    } as Partial<IHeaderSettings>;
  }
}
