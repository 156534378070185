<ng-container>
  <div fxLayout="row" style="max-height: 44px;">
    <div class="btn-stroked sort-icon-wrapper border-right-radius-none"
        [class.asc]="orderDesc == false"
        [class.desc]="orderDesc == true"
        [class.disabled]="disabled"
        (click)="onOrderDesc(orderDesc)">
        <svg class="sort-icon-desc btn-svg-icon-16">
            <use xlink:href="assets/icons/sort-desc.svg#sortDestId"/>
        </svg>
        <svg class="sort-icon-asc btn-svg-icon-16">
            <use xlink:href="assets/icons/sort-asc.svg#sortAscId"/>
        </svg>
    </div>

    <app-multiselect #sortSelect style="min-width: 80px;max-width: calc(100% - 45px);"
        [disabled]="!sortFields || !sortFields.length || disabled"
        [isSearchEnable]="true"
        [items]="sortFields"
        [selectedItems]="selectedField"
        [displayName]="'value'"
        [uniqueField]="'key'"
        [placeholder]="'Select sort'"
        [panelStyleClass]="'app-sort__multiselect-panel'"
        [componentStyleClass]="'app-sort__multiselect hide-field-hint'"
        (selectedValue)="onOrderBy($event)">
    </app-multiselect>
  </div>
</ng-container>
