import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthorizationService, LocalStorage } from 'src/app/api';
import { defaultRoute, routeMap } from 'src/app/route-map';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {

    /**
     * For subscription on the route
     */
    private _routeSub: Subscription | null;

    /**
     * The page of user
     */
    private _userPage!: string | null;

    /**
     * Getter for private _userPage
     */
    get userPage(): string | null {
        return this._userPage;
    }

    /**
     * Setter for private _userPage
     */
    set userPage(page: string | null) {
        this._userPage = page;
    }

    constructor(
        private authService: AuthorizationService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this._routeSub = new Subscription();
        this.userPage = null;
    }

    public isLoginPage(page: string | null): boolean {
        return page === `/${routeMap.signIn}` || page?.includes(`/${routeMap.signIn}?existingEmail=`) ? true : false;
    }

    public isCreateUserPage(page: string | null): boolean {
        return page === `/${routeMap.signUp}`;
    }

    public isConfirmAccountPage(page: string | null): boolean {
        return page === `/${routeMap.confirmAccount}` || page?.includes(`/${routeMap.confirmAccount}?code=`) ? true : false;
    }

    /**
     * Component initialization method
     */
    public ngOnInit(): void {
        this._routeSub = this.route.queryParams
            .subscribe((queryParams: Params) => {
                this.userPage = queryParams['return_to'];
                if (this.userPage) {
                    this.authService.redirectUrl = this.userPage;
                }
                const token = AuthorizationService.getToken();
                const temporaryToken = AuthorizationService.getTemporaryTokenOfNotEmailVerifiedUser();
                if (this.isConfirmAccountPage(this.userPage) && token == null && temporaryToken == null) {
                    const email = this.userPage?.match(/(?<=&email=).*(?=&expiresOn=)/)?.[0] || null;
                    email && LocalStorage.setTemporaryTokenOfNotEmailVerifiedUser(email);
                }
                this.checkProfile();
            });
    }

    /**
     * The method for destroying the component
     */
    public ngOnDestroy(): void {
        if (this._routeSub) {
            this._routeSub.unsubscribe();
            this._routeSub = null;
        }
    }

    /**
     * Profile verification method followed by redirection.
     */
    private checkProfile(): void {
        this.authService.appInit()
            .subscribe((userAuthorized: boolean) => {
                if (userAuthorized) {
                    const token = AuthorizationService.getToken();
                    const temporaryToken = AuthorizationService.getTemporaryTokenOfNotEmailVerifiedUser();
                    if (token != null) {
                        this.userPage && !this.isLoginPage(this.userPage) &&
                            !this.isCreateUserPage(this.userPage) && !this.isConfirmAccountPage(this.userPage) ?
                            this.router.navigateByUrl(this.userPage)
                            : this.router.navigate([`/${defaultRoute}`]);
                    }
                    else if (temporaryToken != null) {
                        this.authService.redirectUrl = '';
                        this.userPage && this.isConfirmAccountPage(this.userPage) ?
                            this.router.navigateByUrl(this.userPage)
                            : this.router.navigate([`/${routeMap.confirmAccount}`]);
                    }
                }
                else if (this.userPage && (this.isCreateUserPage(this.userPage) || this.isLoginPage(this.userPage))) {
                    this.router.navigateByUrl(this.userPage);
                }
                else {
                    this.authService.logout();
                }
            }, (error: any) => {
                this.authService.logout();
            });
    }
}
