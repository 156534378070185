<div class="app-paginator" fxLayoutAlign="space-between center">

  <!-- Блок с количеством элементов на странице -->
  <div class="app-paginator__options" fxLayoutAlign="start center" fxLayoutGap="12px">
    <!-- <span *ngIf="false" fxHide.lt-md fxShow>{{ pageText.pageSizeTitle }}</span> -->
    <div class="app-paginator__size" #pageSizeSelector fxLayoutAlign="space-between center" fxLayoutGap="4px" (click)="onOpenOverlayWithAvailablePageSizes()">
        <span class="assign-color">{{ pageSize }}</span>
        <div class="app-paginator__size-arrow app-paginator__size-arrow--down" [class.arrow-transform]="overlayRef && overlayRef.hasAttached()"></div>
    </div>
  </div>

  <div *ngIf="pageCountCalculation" class="app-paginator__navigation app-paginator__navigation-skeleton skeleton-block"></div>

  <!-- Навигация -->
  <div *ngIf="!pageCountCalculation" class="app-paginator__navigation" fxLayoutAlign="start center" fxLayoutGap="10px">

    <!-- Стрелка навигации "назад" -->
    <div class="app-paginator__arrow app-paginator__arrow--left" fxLayoutAlign="center center" [class.disabled]="pageIndex <= 0" (click)="onNavigateBefore()"></div>

    <!-- Блок со страницами -->
    <div class="app-paginator__pages" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">

      <!-- Первая страница. присутствует всегда -->
      <div class="app-paginator__page" fxLayout="row" fxLayoutAlign="center center" [class.active]="(pageIndex === 0)" (click)="onSelectPage(0)">
        <span class="assign-color">{{ firstPage }}</span>
      </div>

      <!-- Принцип: либо троеточие либо вторая страница -->

      <!-- Вторая страница. Скрывается, если показано троеточие и наоборот -->
      <div *ngIf="isShowSecondPage" class="app-paginator__page" fxLayout="row" fxLayoutAlign="center center" [class.active]="(pageIndex === 1)" (click)="onSelectPage(1)">
        <span class="assign-color">{{ secondPage }}</span>
      </div>
      <!-- Троеточие. Скрывается, если показана вторая страница и наоборот. Непоказывается вообще, если страниц < 8 -->
      <div *ngIf="isShowFirstThreeDots" class="app-paginator__dots" fxLayout="row" fxLayoutAlign="center center">
        <span class="assign-color">. . .</span>
      </div>

      <!-- Предыдущая страница -->
      <div *ngIf="availablePages >= 3" class="app-paginator__page" fxLayout="row" fxLayoutAlign="center center" [class.active]="(pageIndex === (previousPage-1))" (click)="onSelectPage(previousPage-1)">
        <span class="assign-color">{{  previousPage }}</span>
      </div>
      <!-- Средняя страница -->
      <div *ngIf="availablePages >= 4" class="app-paginator__page" fxLayout="row" fxLayoutAlign="center center" [class.active]="(pageIndex === (middlePage-1))" (click)="onSelectPage(middlePage-1)">
        <span class="assign-color">{{ middlePage }}</span>
      </div>
      <!-- Следующая страница -->
      <div *ngIf="availablePages >= 5" class="app-paginator__page" fxLayout="row" fxLayoutAlign="center center" [class.active]="(pageIndex === (nextPage-1))" (click)="onSelectPage(nextPage-1)">
        <span class="assign-color">{{ nextPage }}</span>
      </div>

      <!-- Принцип: либо троеточие либо предпоследняя страница -->

      <!-- Троеточие. Скрывается, если показана предпоследняя страница и наоборот. Непоказывается вообще, если страниц < 8 -->
      <div *ngIf="isShowLastThreeDots" class="app-paginator__dots" fxLayout="row" fxLayoutAlign="center center">
        <span class="assign-color">. . .</span>
      </div>
      <!-- Предпоследняя страница. Скрывается, если показано троеточие и наоборот. Непоказывается вообще, если страниц < 6 -->
      <div *ngIf="isShowLastButOnePage" class="app-paginator__page" fxLayout="row" fxLayoutAlign="center center" [class.active]="availablePages > 6 ? (pageIndex === (availablePages - 2)) : (pageIndex === (availablePages - 1))" (click)="onSelectPage(availablePages > 6 ? availablePages - 2 : availablePages - 1)">
        <span class="assign-color">{{ lastButOnePage }}</span>
      </div>

      <!-- Последняя страница. Непоказывается вообще, если availablePages < 7 -->
      <div *ngIf="isShowLastPage" class="app-paginator__page" fxLayout="row" fxLayoutAlign="center center" [class.active]="(pageIndex === (availablePages - 1))" (click)="onSelectPage((availablePages - 1))">
        <span class="assign-color">{{ lastPage }}</span>
      </div>
    </div>

    <!-- Стрелка навигации "вперед" -->
    <div class="app-paginator__arrow app-paginator__arrow--right" fxLayoutAlign="center center" [class.disabled]="(availablePages - pageIndex <= 1)" (click)="onNavigateNext()"></div>

  </div>
</div>
