import { Model } from "../shared/interfaces";
import { ArticleModel } from "../articles/shared/article.model";
import { CompetitorModel } from "../competitors/shared/competitor.model";

export class CompetitorOriginalPair implements Model {
  id!: string | number | null;
  original!: ArticleModel;
  competitor!: CompetitorModel;
  skipped!: boolean;

  constructor(data?: any) {
    this.id = data?.id;
    this.original = data?.original;
    this.competitor = data?.competitor;
  }
}
