<div [id]="textareaWrapperName" class="textarea-autocomplete">
    <mat-form-field appearance="outline" class="textarea-autocomplete-field mat-textarea {{styleClass}}" fxFlex="100"
        [class.textarea-autocomplete-field__empty]="!isValid">
        <mat-label *ngIf="label">{{label}}</mat-label>
        <textarea matInput type="text" [id]="textareaElementName" [placeholder]="placeholder" [disabled]="disabled" [maxlength]="maxLength"
            [required]="required" [(ngModel)]="displayText" [pattern]="fieldPattern" (ngModelChange)="onDisplayTextChange($event)"
            cdkTextareaAutosize #autosize="cdkTextareaAutosize" [cdkAutosizeMinRows]="cdkAutosizeMinRows"
            [cdkAutosizeMaxRows]="cdkAutosizeMaxRows" (keyup.backspace)="onDisplayTextChange(displayText)">
            <!-- (keydown)="onCheckCaretPosition($event)" (click)="onSetCursorPosition($event)" (blur)="onResetCursorPosition()" -->
        </textarea>
        <mat-hint *ngIf="displayHintMessage" align="start">{{displayHintMessage}}</mat-hint>
        <mat-hint *ngIf="enableTextLengthHintMessage" align="end">{{textareaElement?.value.length}}/{{maxLength}}</mat-hint>
        <mat-error *ngIf="!isValid">{{displayErrorMessage}}</mat-error>
    </mat-form-field>
</div>