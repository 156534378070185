export class CarModel {
    hsn!: string;
    tsn!: string;
    productName!: string;
    model!: string;
    type!: string;
    make!: string;
    year!: string;
    platform!: string;
    engine!: string;

    constructor(data?: any) {
        if (data) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    this[key as keyof CarModel] = data[key];
                }
            }
        }
    }
}
