import { Component, EventEmitter, HostListener, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { ItemList, SimpleDataFilter } from 'src/app/contracts';
import { ListItem } from 'src/app/shared/contracts';
import { AppText } from 'src/app/text/app-text';
import * as _ from 'underscore';
import { FilterSelectedField } from '../app-filter-selected-fields/filter-selected-field';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CarModel } from 'src/app/shared/contracts/car';
import { ApiEndpoints, ApiService } from 'src/app/api';
import { catchError } from 'rxjs/operators';
import { decodeCarData, encodeCarData } from 'src/app/shared/utils/util-functions';

@Component({
    selector: 'app-filter-dialog',
    templateUrl: './app-filter-dialog.component.html',
    styleUrls: ['./app-filter-dialog.component.scss']
})
export class AppFilterDialogComponent implements OnInit {
    /**
     * Измененные параметры, которые еще не отправлены в родителю
     * @type {SimpleDataFilter}
     */
    private changedFilterParams!: SimpleDataFilter;

    search: string = '';

    carSearch: string = '';

    /**
     * Список машин
     * @type {CarModel[]}
     */
    cars!: CarModel[];

    /**
     * Выбраанная машина
     * @type {CarModel}
     */
    parsedCar!: CarModel | null;

    /**
     * Список выбранных машин
     * @type {CarModel[]}
     */
    selectedCars!: CarModel[];

    public carsChange: Subject<CarModel[]> = new Subject<CarModel[]>();

    /**
     * Значение цены "от"
     * @type {number}
     */
    public priceFrom!: number | null;

    /**
     * Значение цены "до"
     * @type {number}
     */
    public priceTo!: number | null;

    public hsn!: string | null;
    public tsn!: string | null;

    public isComponentInitialized: boolean = false;

    /**
     * Регулярное выражение для цен
     * @type {number}
     */
    public priceRegex: RegExp = /^(([1-9]{1})([0-9]{1,6})?((\.|\,)[0-9]{1,2})?)$/;

    /**
     * Все выбранные поля
     */
    selectedFields!: Array<FilterSelectedField>;

    /**
     * Регулярное выражение для HSN
     * @type {number}
     */
    public hsnRegex: RegExp = /^[a-zA-Z0-9]{4}$/;

    /**
     * Регулярное выражение для TSN
     * @type {number}
     */
    public tsnRegex: RegExp = /^[a-zA-Z0-9]{3}$/;

    /**
     * Регулярное выражение для HSN-TSN
     * @type {number}
     */
    public hsnTsnRegex: RegExp = /^[a-zA-Z0-9]{4}(?:\-|\s|\/)[a-zA-Z0-9]{3}$/;

    /**
     * Параметры для изменения
     * @type {SimpleDataFilter}
     */
    filterParams!: SimpleDataFilter;

    pageText = {
        searchPlaceholder: 'Search competitors on Platform',
        searchBtnPlaceholder: 'Find & add competitors',
        cancelBtnPlaceholder: AppText.cancelAction,
        closeBtnPlaceholder: AppText.closeAction,
        noFilters: AppText.generalTextIsNoFilters,
        productPrice: AppText.propertyPlaceholderProductPrice,
        platformFiltersPlaceholder: AppText.generalTextIsFilters,
        entityIsOneCar: AppText.entityIsOneCar,
        generalTextIsSelectCar: AppText.generalTextIsSelectCar,
        generalTextIsHsn: AppText.generalTextIsHsn,
        generalTextIsTsn: AppText.generalTextIsTsn,
        hsnTsnPlaceholder: `${AppText.generalTextIsHsn}-${AppText.generalTextIsTsn} (0000-000)`,
        yes: AppText.yesAction,
        no: AppText.noAction,
    };

    /**
     * Событие о том, что параметры фильтра изменились
     * @type {EventEmitter<SimpleDataFilter>}
     */
    @Output() selectedFieldsChanged!: EventEmitter<Array<FilterSelectedField>>;

    /**
     * Событие о том, что значение поиска в селектах было изменено
     * @type {EventEmitter<boolean>}
     */
    @Output() searchChange!: EventEmitter<{ search: string | null; arrayName: string }>;

    constructor(
        private dialogRef: MatDialogRef<AppFilterDialogComponent>,
        // private messageService: MessageService,
        // private baseApiService: BaseApiService,
        // private http: HttpClient,
        private apiService: ApiService,
        @Inject(MAT_DIALOG_DATA) private data: { filterParams: SimpleDataFilter }
    ) {
        this.filterParams = this.data?.filterParams || new SimpleDataFilter({});
        this.changedFilterParams = new SimpleDataFilter({});

        this.search = this.filterParams.competitorsSearch ? decodeURIComponent(this.filterParams.competitorsSearch) : '';
        this.priceFrom = this.filterParams.priceFrom || null;
        this.priceTo = this.filterParams.priceTo || null;
        this.parsedCar = decodeCarData(this.filterParams.carData || '');
        this.hsn = this.parsedCar?.hsn || '';
        this.tsn = this.parsedCar?.tsn || '';
        this.cars = [];
        this.selectedCars = [];
        this.getCars('', '');
        // this.filterPanelClosed = new EventEmitter<{ changes: boolean; params: SimpleDataFilter }>();
        this.selectedFieldsChanged = new EventEmitter<Array<FilterSelectedField>>();
        this.searchChange = new EventEmitter<{ search: string | null; arrayName: string }>();
    }

    @HostListener('document:keyup.escape', ['$event'])
    public onKeydownHandler(event: KeyboardEvent): void {
        event?.stopPropagation();
        this.onClose();
    }

    @ViewChild('hsnInput', { static: false }) hsnInput?: any;
    @ViewChild('tsnInput', { static: false }) tsnInput?: any;

    /**
     * Инициализация компонента
     */
    public ngOnInit(): void {
        // Запоминаем для дальнейшего обнаружения изменений
        this.changedFilterParams = _.clone(this.filterParams);
        this.setPriceFrom(this.filterParams);
        this.setPriceTo(this.filterParams);
        this.updateSelects();
        this.updateSelectedFields(true);
    }

    onSearch(str: string): void {
        this.search = str;
        this.changedFilterParams.competitorsSearch = str ? encodeURIComponent(str) : '';
    }

    /**
     * Применение фильтра по общей кнопке apply
     */
    public onApplyFilter(): void {
        if (!this.search && !this.priceFrom && !this.priceTo && !this.selectedCars?.length) {
            this.onClose();
        }
        else {
            this.onCloseWithChanges();
        }
    }

    onCancel(): void {
        // TODO
    }

    /**
     * Сброс конкретного тега по клику на крестик
     * (крестик в самом теге находится)
     * @param {number} index Индекс выбранного элемента
     */
    public onResetSelectedField(index: number): void {
        this.onResetSelectItemBySelectedFieldIndex(this.selectedFields[index]);
    }

    /**
     * Сброс всех тегов - кликнули на кнопку сброса всех тегов
     * (крестик рядом с счетчиком)
     */
    public onResetAllSelectedFields(): void {
        this.selectedCars = [];
        this.changedFilterParams.carData = null;
        this.priceFrom = null;
        this.priceTo = null;
        this.changedFilterParams.priceFrom = null;
        this.changedFilterParams.priceTo = null;
        this.selectedFields = [];
    }

    /**
     * Сброс селектов
     * @param {boolean} closeFilter Флаг для закрытия фильтра
     */
    public onResetSelects(closeFilter: boolean = false): void {
        this.selectedCars = [];
        this.changedFilterParams.carData = null;
        if (closeFilter) {
            this.onCloseWithChanges();
        }
    }

    /**
     * Сброс конкретного тега по клику на крестик
     * (крестик в самом теге находится)
     * @param {FilterSelectedField} selectedField Выбранный элемент в тегах
     */
    public onResetSelectItemBySelectedFieldIndex(selectedField: FilterSelectedField): void {
        if (!selectedField) {
            return;
        }

        let arrayKeys = ['selectedCars'];

        if (arrayKeys.includes(selectedField.collectionKey)) {
            let selectedCollection: [] = this[selectedField.collectionKey as keyof AppFilterDialogComponent];
            let foundItem = _.find(selectedCollection, (item: any) => {
                return selectedField.selectedItemUniqueFieldValue == item[selectedField.selectedItemUniqueFieldKey];
            });
            if (!foundItem) {
                this.changedFilterParams.carData = null;
                this.updateSelectedFields();
                // this.onApplyFilter();
                return;
            }
            /** Поиск в массиве */
            const index = selectedCollection.indexOf(foundItem);
            if (index > -1) {
                try {
                    this[selectedField.collectionKey as keyof AppFilterDialogComponent].splice(index, 1);
                    this.changedFilterParams.carData = null;
                    // this.changedFilterParams.carData = this.getKeysStringFromArray(this.selectedCars, selectedField.selectedItemUniqueFieldKey) || null;
                    this.updateSelectedFields();
                    // this.onApplyFilter();
                    return;
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            if (selectedField.selectedItemUniqueFieldKey == 'priceFrom') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterDialogComponent] = <never>null;
                this.changedFilterParams.priceFrom = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterDialogComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'priceTo') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterDialogComponent] = <never>null;
                this.changedFilterParams.priceTo = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterDialogComponent];
            }
            this.updateSelectedFields();
        }
    }

    /**
     * Сброс фильтра по общей кнопке reset
     */
    public onCloseWithChanges(): void {
        this.updateSelectedFields(true);
        this.dialogRef.close({ changes: true, params: this.changedFilterParams });
    }

    /**
     * Сброс фильтра по общей кнопке reset
     */
    public onClose(): void {
        this.dialogRef.close({ changes: false, params: this.filterParams });
    }

    /**
     * Метод обновляет списки выбранных элементов
     * @param {string} selectedItems Выбранные элементы
     * (если селект не с мультивыбором, то в массиве всегда один элемент)
     * @param {any[]} arrayName Имя массива выбранных элементов
     *
     * @returns {void}
     */
    public onUpdateSelectedItems(selectedItems: any[], arrayName: string): void {
        selectedItems = selectedItems ? selectedItems : [];
        this[arrayName as keyof AppFilterDialogComponent] = <never>selectedItems;
    }

    public onCarSearchChange(search: string | null): void {
        let isValid = search?.match(this.hsnTsnRegex) ? true : false;
        if (!isValid) { return; }
        this.carSearch = search ? search.replace(/(\-|\s|\/)/, '-') : '';
        this.hsn = this.carSearch ? this.carSearch.split('-')?.[0] : '';
        this.tsn = this.carSearch ? this.carSearch.split('-')?.[1] : '';
        if (this.hsn && this.tsn) {
            this.getCars(this.hsn, this.tsn);
        }
    }

    /**
     * Изменение ключей машин
     * @param {CarModel} item Выбранная машина
     */
    public onSelectCars(item: CarModel): void {
        this.changedFilterParams.carData = null;
        if (item) {
            if (!this.selectedCars?.length) {
                return;
            }
            this.changedFilterParams.carData = encodeCarData(this.selectedCars[0]);
        }
        // this.updateSelectedFields();
    }

    /**
     * Изменение значений цен
     * @param {any} value введеное значение
     */
    public onChangePriceFrom(value: any) {
        this.priceFrom = value;

        this.changePriceFrom();
    }

    public onChangePriceTo(value: any) {
        this.priceTo = value;

        this.changePriceTo();
    }

    /**
     * Изменения в параметрах - Price
     */
    private changePriceFrom(): void {
        this.changedFilterParams.priceFrom = this.priceFrom || null;
    }
    private changePriceTo(): void {
        this.changedFilterParams.priceTo = this.priceTo || null;
    }

    /**
     * Обновления всех выбранных полей
     */
    private updateSelectedFields(emitEvent: boolean = false): void {
        this.selectedFields = this.changeSelectedFields(this.selectedFields);
        if (emitEvent) {
            this.selectedFieldsChanged.next(this.selectedFields);
        }
    }

    /**
     * Собирает из разных коллекций - одну selectedFields
     * @param {Array<FilterSelectedField>} selectedFields коллекция выбранных элементов со всех селектов
     * @returns {Array<FilterSelectedField>} Возвращает весь список полей
     */
    private changeSelectedFields(selectedFields: Array<FilterSelectedField>): Array<FilterSelectedField> {
        selectedFields = [];

        this.selectedCars.forEach((item: CarModel) => {
            selectedFields.push(this.createField('selectedCars', AppText.entityIsOneCar, 'productName', <never>item.productName, <never>item.productName));
        });
        if (this.priceFrom) {
            selectedFields.push(this.createField('priceFrom', AppText.generalTextIsPriceFrom, 'priceFrom', 'priceFrom', <never>this.priceFrom));
        }
        if (this.priceTo) {
            selectedFields.push(this.createField('priceTo', AppText.generalTextIsPriceTo, 'priceTo', 'priceTo', <never>this.priceTo));
        }
        return selectedFields;
    }

    /**
     * Установка элементов как выбранных по ключам(идентификаторам)
     * @param {string} parsedCar Распарсенная тачка из параметра carData (запроса по получению id - нет)
     */
    private setSelectedCars(parsedCar: CarModel | null): void {
        if (!this.cars?.length || !parsedCar?.productName) {
            return;
        }

        const foundItem = _.find(this.cars, (item: CarModel) => {
            return parsedCar.productName === String(item.productName);
        });
        if (foundItem && (!this.selectedCars?.length || this.selectedCars[0].productName === parsedCar.productName)) {
            this.selectedCars = [];
            this.selectedCars.push(foundItem);
        }
    }

    /**
     * Обновление всех селектов
     */
    private updateSelects(): void {
        this.setSelectedCars(this.parsedCar);
        this.updateSelectedFields(true);
    }

    /**
     * Установка Price исходя из фильтра
     * @param params Параметры фильтра
     */
    private setPriceFrom(params: SimpleDataFilter): void {
        this.priceFrom = params?.priceFrom ? params?.priceFrom : null;
    }

    private setPriceTo(params: SimpleDataFilter): void {
        this.priceTo = params?.priceTo ? params?.priceTo : null;
    }

    /**
     * Получает строку из ключей, разделенные сепаратором (`,` по умолчанию)
     * @param {any[]} array Выбранные элементы
     * @param {string} key Ключ который нужно вписывать в строку
     * @param {string} separator Разделитель ключей в строке
     * @returns {string} Строка ключей через запятыми
     */
    private getKeysStringFromArray(array: any[], key: string = '', separator: string = ','): string | null {
        let str = '';
        if (!array?.length) {
            return str;
        }
        if (typeof array === 'string') {
            str = str + array;
            return str;
        }

        array.forEach((item: any, index: number) => {
            // Для массива с ключами
            if (key && item[key]) {
                str = index === array.length - 1 ? str + item[key] : str + item[key] + separator;
            }
            // Для массива строк, например
            else if (!key && item) {
                str = index === array.length - 1 ? str + item : str + item + separator;
            }
        });
        return str ? str : null;
    }

    /**
     * Создание модели поля применного фильтра
     * @param arrayKey общий ключ коллекции фильров
     * @param arrayTitle отображаемый заголовок примененного фильтра
     * @param uniqueFieldKey уникальный ключ поля для фильтрации
     * @param uniqueFieldValue уникальное значение поля для фильтрации
     * @param value отображаемое значение примененного фильтра
     * @returns
     */
    private createField(arrayKey: string, arrayTitle: string, uniqueFieldKey: string, uniqueFieldValue: string, value: string): FilterSelectedField {
        let field = new FilterSelectedField({});
        field.collectionKey = arrayKey;
        field.collectionTitle = arrayTitle;
        field.selectedItemUniqueFieldKey = uniqueFieldKey;
        field.selectedItemUniqueFieldValue = uniqueFieldValue;
        field.selectedItemValue = value;
        return field;
    }

    private getCars(hsn: string, tsn: string): void {
        if (!hsn || !tsn) {
            this.cars = [];
            this.selectedCars = [];
            if (this.parsedCar?.productName) {
                this.cars.push(this.parsedCar);
                this.selectedCars.push(this.cars[0]);
            }
            this.isComponentInitialized = true;
            return;
        }

        const url = ApiEndpoints.cars().listUrl({ hsn: hsn, tsn: tsn });
        const ctx = this;
        this.apiService.get<ItemList<CarModel>>(url)
            .pipe(catchError((error: any) => throwError(error)))
            .subscribe((res: ItemList<CarModel>) => {
                if (res?.data) {
                    ctx.cars = res.data || [];
                    ctx.setSelectedCars(ctx.parsedCar);
                }
                this.isComponentInitialized = true;
            }, (error: any) => {
                ctx.cars = [];
                ctx.selectedCars = [];
                if (ctx.parsedCar?.productName) {
                    ctx.cars.push(ctx.parsedCar);
                    ctx.selectedCars.push(ctx.cars[0]);
                }
                ctx.isComponentInitialized = true;
            });
    }
}
