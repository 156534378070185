import { ApiEndpointBase } from './base-api-endpoint';
import { environment } from '../../environments/environment';
import { IApiEndpoint } from './interfaces';
import { routeMap } from '../route-map';

export class ApiEndpoints {

    /**
     * Base api url for environment
     */
    public static get BaseApiUrl(): string {
        return environment.apiUrl;
    }

    /**
     * An Endpoint
     */
    public static token(): string {
        return ApiEndpoints.BaseApiUrl + '/connect/token';
    }

    /**
     * An Endpoint
     */
    public static profile() {
        // return ApiEndpoints.BaseApiUrl + '/profile';
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + '/profile');
    }

    public static signup() {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + '/signup');
    }

    public static forgotPassword() {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + '/profile/forgot-password');
    }

    public static resetPassword() {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + '/profile/reset-password');
    }

    public static sendAccountConfirmationMail() {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + '/profile/send-account-confirmation-mail');
    }

    public static confirmAccount() {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + '/profile/confirm-account');
    }

    /**
     * Эндпоинт файлов
     * @type {ApiEndpoint}
     */
    static files(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + '/files');
    }

    /**
     * An Endpoint
     * @param id An id of an item
     * @returns IApiEndpoint
     */
    static competitors(id?: string): IApiEndpoint {
        return id != null ?
            new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.competitor}/${id}`)
            : new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.competitors}`);
    }

    /**
     * Эндпоинт атрибутов конкурента
     * @type {ApiEndpoint}
     */
    static competitorAttributes(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.competitors}/attributes`);
    }

    /**
     * Эндпоинт competitors Upload
     * @type {ApiEndpoint}
     */
    static competitorsUpload(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.competitors}/upload`);
    }

    /**
     * Эндпоинт competitors Upload
     * @type {ApiEndpoint}
     */
    static articlesUpload(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.articles}/upload`);
    }

    /**
     * Эндпоинт requests
     * @type {ApiEndpoint}
     */
    static requests(id?: string | null): IApiEndpoint {
        return id != null ?
            new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.requests}/${id}`)
            : new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.requests}`);
    }

    /**
     * Эндпоинт requestsSearch
     * @type {ApiEndpoint}
     */
    static requestsSearch(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.requests}/search`);
    }

    /**
     * Эндпоинт cars
     * @type {ApiEndpoint}
     */
    static cars(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/cars`);
    }

    /**
     * Эндпоинт атрибутов конкурента
     * @type {ApiEndpoint}
     */
    static carsCompare(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/cars/compare`);
    }

    /**
     * An Endpoint
     * @param id An id of an item
     * @returns IApiEndpoint
     */
    static articles(id?: string | null): IApiEndpoint {
        return id != null ?
            new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.articles}/${id}`)
            : new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.articles}`);
    }

    static articlesCount(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.articles}/count`);
    }

    /**
     * An Endpoint
     * @param id An id of an item
     * @returns IApiEndpoint
     */
    static listers(id?: string | null): IApiEndpoint {
        return id != null ?
            new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/listers/${id}`)
            : new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/listers`);
    }

    /**
     * Эндпоинт атрибутов артикля
     * @type {ApiEndpoint}
     */
    static articleAttributes(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.articles}/attributes`);
    }

    /**
     * An Endpoint
     * @param id An id of an item
     * @returns IApiEndpoint
     */
    static articleGroups(id?: string | null): IApiEndpoint {
        return id != null ?
            new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.articleGroups}/${id}`)
            : new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.articleGroups}`);
    }

    /**
     * An Endpoint
     * @param id An id of an item
     * @returns IApiEndpoint
     */
    static settings(id?: string): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.articleGroups}/${id}/${routeMap.settings}`);
    }

    /**
     * An Endpoint
     * @param id An id of an item
     * @returns IApiEndpoint
     */
    static analysis(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.articleGroups}/${routeMap.statistics}`);
    }

    /**
     * Эндпоинт articleGroups Upload
     * @type {ApiEndpoint}
     */
    static articleGroupsUpload(id?: string | number | null): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.articleGroups}/upload/${id}`);
    }

    /**
     * Эндпоинт articleGroupRecognition - запуск пересбора конкурентов для группы
     * @type {ApiEndpoint}
     */
    static articleGroupRecognition(id?: string | number | null): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.articleGroups}/recognition/${id}`);
    }

    /**
     * Эндпоинт articleGroupRecognition - запуск поиска потенциальных конкурентов для группы
     * @type {ApiEndpoint}
     */
    static articleGroupDiscovery(id?: string | number | null): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.articleGroups}/discovery/${id}`);
    }

    /**
     * An Endpoint
     * @param id An id of an item
     * @returns IApiEndpoint
     */
    static rulesConfiguration(id: string): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.rulesConfiguration}/${id}`);
    }

    /**
     * Эндпоинт rules - список всех правил в системе
     * @param id Id правила - информация по определенному правилу
     * @returns IApiEndpoint
     */
    static rules(id?: string): IApiEndpoint {
        return id != null ?
            new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.rules}/${id}`)
            : new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.rules}`);
    }

    /**
     * Эндпоинт rules - список всех правил в системе
     * @param id Id правила - информация по определенному правилу
     * @returns IApiEndpoint
     */
    static tagAssignmentRules(id?: string): IApiEndpoint {
        return id != null ?
            new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.tagAssignmentRules}/${id}`)
            : new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.tagAssignmentRules}`);
    }

    /**
     * Эндпоинт tags - список всех тегов в системе
     * @param id Id правила - информация по определенному правилу
     * @returns IApiEndpoint
     */
    static tags(id?: string): IApiEndpoint {
        return id != null ?
            new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.tags}/${id}`)
            : new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.tags}`);
    }

    /**
     * Эндпоинт tags - список всех тегов в системе
     * @param id Id правила - информация по определенному правилу
     * @returns IApiEndpoint
     */
    static attributes(id?: string): IApiEndpoint {
        return id != null ?
            new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/attributes/${id}`)
            : new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/attributes`);
    }

    static categories(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/categories`);
    }

    /**
     * Эндпоинт tags - список всех тегов в системе
     * @param id Id правила - информация по определенному правилу
     * @returns IApiEndpoint
     */
    static articleTags(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.articles}/${routeMap.tags}`);
    }

    /**
     * An Endpoint
     * @returns IApiEndpoint
     */
    static rulesStatistics(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.rules}/${routeMap.statistics}`);
    }

    /**
     * Эндпоинт generalRules - список всех кастомных правил в системе
     * @param id Id правила - информация по определенному правилу
     * @returns IApiEndpoint
     */
    static generalRules(id?: string): IApiEndpoint {
        return id != null ?
            new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.rules}/general/${id}`)
            : new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.rules}/general`);
    }

    /**
     * Эндпоинт всех дефолтных "multiplierDetection" правил в системе
     * @param id Id правила - информация по определенному правилу
     * @returns IApiEndpoint
     */
    static defaultRulesCalledMultiplierDetectionRules(id?: string): IApiEndpoint {
        return id != null ?
            new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.rules}/multiplier-detection/${id}`)
            : new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.rules}/multiplier-detection`);
    }

    /**
     * Эндпоинт всех дефолтных "manufacturerTypeMatch" правил в системе
     * @param id Id правила - информация по определенному правилу
     * @returns IApiEndpoint
     */
    static defaultRulesCalledManufacturerTypeMatchRules(id?: string): IApiEndpoint {
        return id != null ?
            new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.rules}/manufacturer-type-match/${id}`)
            : new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.rules}/manufacturer-type-match`);
    }

    /**
     * Эндпоинт всех дефолтных "UnitCountMatchRule" правил в системе
     * @param id Id правила - информация по определенному правилу
     * @returns IApiEndpoint
     */
    static defaultRulesCalledUnitCountMatchRules(id?: string): IApiEndpoint {
        return id != null ?
            new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.rules}/unit-count-match/${id}`)
            : new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.rules}/unit-count-match`);
    }

    /**
     * Эндпоинт articleGroupRules - список всех правил в группе артиклей
     * @param id Id группы артиклей
     * @returns IApiEndpoint
     */
    static articleGroupRules(id: string): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.articleGroups}/${id}/${routeMap.rules}`);
    }

    /**
     * An Endpoint
     * @param id An id of an item
     * @returns IApiEndpoint
     */
    static matchingResults(id: string): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.matchingResults}/${id}`);
    }

    /**
     * An Endpoint
     * @param id An id of an item
     * @returns IApiEndpoint
     */
    static users(id?: string): IApiEndpoint {
        return id != null ?
            new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.user}/${id}`)
            : new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.users}`);
    }

    /**
     * An Endpoint
     * @param id An id of an item
     * @returns IApiEndpoint
     */
    static articlesData(id?: string): IApiEndpoint {
        return id != null ?
            new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.articles}/${id}`)
            : new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.articles}`);
    }

    /**
     * An Endpoint
     * @param id An id of an item
     * @returns IApiEndpoint
     */
    static exportArticles(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.articles}/export`);
    }

    /**
     * An Endpoint
     * @param id An id of an item
     * @returns IApiEndpoint
     */
    static exportCompetitors(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.competitors}/export`);
    }

    /**
     * Эндпоинт multiplierDetectionRules - список всех дефолтных "multiplierDetection" правил в системе
     * @param id Id правила - информация по определенному правилу
     * @returns IApiEndpoint
     */
    static searchRequestTemplates(id?: string): IApiEndpoint {
        return id != null ?
            new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.searchRequestTemplates}/${id}`)
            : new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.searchRequestTemplates}`);
    }

    /**
     * An Endpoint
     * @param id An id of an item
     * @returns IApiEndpoint
     */
    static strategies(id?: string | null): IApiEndpoint {
        return id != null ?
            new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.strategies}/${id}`)
            : new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.strategies}`);
    }

    static platformAccounts(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/${routeMap.platformAccounts}`);
    }

    static ebayAuthorization(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/platform-accounts/ebay-authorization`);
    }

    static platformAccountSynchronization(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/platform-accounts/synchronization`);
    }

    static addEbayAccount(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/platform-accounts/add-ebay-account`);
    }

    /**
     * An Endpoint
     */
    public static ftpFeedSettings() {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + '/ftp-feed-settings');
    }

    /**
     * An Endpoint
     */
    public static ftpTestConnection() {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + '/ftp-feed-settings/test-connection');
    }

    public static ftpDownload() {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + '/ftp-feed-settings/download-example-file');
    }

    static articleHistory(): IApiEndpoint {
        return new ApiEndpointBase(ApiEndpoints.BaseApiUrl + `/journal`);
    }
}