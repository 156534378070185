import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppHeaderService } from 'src/app/shared/components/app-header/app-header.service';
import { AppMenuService } from 'src/app/shared/components/app-menu/app-menu.service';
import { SignUpService } from './sign-up.service';
import { AppText } from '../text/app-text';
import { Router } from '@angular/router';
import { emailPattern, passwordPattern } from '../shared/patterns-config';
import { UserProfile } from '../contracts';
import { ErrorResponse } from '../api';
import { MessageService } from '../shared/services';
import { routeMap } from '../route-map';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./../sign-in/sign-in.component.scss', './sign-up.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
    public pageText = {
        // errorMessageTitle: AppText.errorMessageTitle,
        propertyPlaceholderFirstName: AppText.propertyPlaceholderFirstName,
        propertyPlaceholderLastName: AppText.propertyPlaceholderLastName,
        propertyPlaceholderUserEmail: AppText.propertyPlaceholderUserEmail,
        propertyPlaceholderUserPassword: AppText.propertyPlaceholderUserPassword,
        propertyPlaceholderCompanyName: AppText.propertyPlaceholderCompanyName,
        propertyPlaceholderUserPasswordStrength: AppText.propertyPlaceholderUserPasswordStrength,
        minLengthTooltip: AppText.hintMessageIsLengthOfPassword,
        patternPasswordTooltip: AppText.hintMessageIsPatternOfPassword,
        passwordNotStrongEnoughTooltip: AppText.hintMessageIsPasswordNotStrongEnough
    };

    public detailsForm!: FormGroup;

    public isPasswordHidden: boolean = true;

    emailPattern = emailPattern;

    existingEmails: Array<string> = [];

    get errorMessage(): string | null {
        return this.service?.errorMessage || '';
    }

    get disabled(): boolean {
        return this.service?.authorizationInProgress || false;
    }

    get userEmail(): string {
        return this.detailsForm?.get('userEmail')?.value || '';
    }

    get passwordStrength(): number {
        return this.detailsForm?.get('password')?.value || 0;
    }

    constructor(
        private formBuilder: FormBuilder,
        private service: SignUpService,
        private menuService: AppMenuService,
        private headerService: AppHeaderService,
        private messageService: MessageService,
        private router: Router
    ) {
        this.menuService.setSettings.next({
            isEnable: false
        });
        this.headerService.setSettings.next({
            isEnable: false
        });
    }

    public isUserExist(email: string): boolean {
        return email ? this.existingEmails?.indexOf(email) != -1 : false;
    }

    public isEmailExistsError(error: ErrorResponse): boolean {
        return error?.message.includes('User with email') && error?.message.includes('already exists');
    }

    public ngOnInit(): void {
        this.detailsForm = this.formBuilder.group({
            firstName: [{ value: '', disabled: this.disabled }, [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(30)
            ]],
            lastName: [{ value: '', disabled: this.disabled }, [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(30)
            ]],
            userEmail: [{ value: '', disabled: this.disabled }, [
                Validators.required,
                Validators.minLength(5),
                Validators.maxLength(128),
                Validators.pattern(this.emailPattern)
            ]],
            companyName: [{ value: '', disabled: this.disabled }, [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(50)
            ]],
            password: [{ value: '', disabled: this.disabled }, [
                Validators.required,
                Validators.pattern(passwordPattern),
                Validators.minLength(8),
                Validators.maxLength(32)
            ]]
        });
    }

    public ngOnDestroy(): void {
        this.service.cancelRequest();
    }

    public onSignUp(form: FormGroup): void {
        if (!form.valid || this.disabled) {
            return;
        }
        const data = {
            firstName: form.value.firstName,
            secondName: form.value.lastName,
            companyName: form.value.companyName,
            email: form.value.userEmail,
            password: form.value.password
        };
        this.service.signup(data).subscribe((res: UserProfile) => {
            this.service.login(data.email, data.password);
        }, (error: ErrorResponse) => {
            if (this.isEmailExistsError(error)) {
                if (this.existingEmails?.indexOf(form.value.userEmail) === -1) {
                    this.existingEmails.push(form.value.userEmail);
                }
                return;
            }
            this.messageService.error(error?.message);
        });
    }

    public onShowPassword(clickEvent?: Event): void {
        clickEvent && clickEvent.stopPropagation();
        this.isPasswordHidden = !this.isPasswordHidden;
    }

    public getControlError(controlName: string, errorValidator: string): boolean {
        return this.detailsForm?.controls?.[controlName]?.hasError(errorValidator) ? true : false;
    }

    public goToLogin(): Promise<boolean> {
        return this.router.navigate([`/${routeMap.signIn}`], { queryParams: { existingEmail: this.userEmail } });
    }
}