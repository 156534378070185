import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from 'src/app/api';
import { UserProfile } from 'src/app/contracts';
import { accessMap, routeMap } from 'src/app/route-map';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard {

    constructor(
        private router: Router,
        private authService: AuthorizationService,
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (this.authService.initialized && this.authService.userProfile?.role) {
            const userProfile = new UserProfile(this.authService.userProfile);
            const userAccessMap = accessMap.find(map => map.role === userProfile.role);

            if (!userAccessMap?.accessMap) { // неизвестная роль
                this.authService.logout();
                return false;
            }
            const isAccessDenied: boolean = this.isDenied(route?.routeConfig?.path, userAccessMap?.accessMap);
            if (isAccessDenied) {
                this.router.navigate([`/${userAccessMap?.defaultRoute}`]);
                return false;
            }
            else if (route?.routeConfig?.path === routeMap.signIn && AuthorizationService.getToken() != null) {
                this.router.navigate([routeMap.notFound]);
                return false;
            }
        }
        return true;
    }

    /**
     * проверяет, запрещен ли роут для текущей роли пользователя
     * @param path путь роута
     * @param role роль пользовтеля
     */
    private isDenied(path: string | undefined, userAccessMap: string[] | undefined): boolean {
        if (!userAccessMap?.length || !path) {
            return true;
        }
        return userAccessMap.indexOf(path) === -1;
    }
}
