import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppHeaderService } from 'src/app/shared/components/app-header/app-header.service';
import { AppMenuService } from 'src/app/shared/components/app-menu/app-menu.service';
import { SignInService } from './sign-in.service';
import { AppText } from '../text/app-text';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
    /**
     * Форма
     * @type {FormGroup}
     */
    public loginForm!: FormGroup;

    /**
     * Флаг смены типа поля пароля
     * @type {boolean}
     */
    isTypePassword: boolean;

    existingEmail: string = '';

    get errorMessage(): string | null {
        return this.service?.errorMessage || '';
    }

    get disabled(): boolean {
        return this.service?.authorizationInProgress || false;
    }

    /**
     * Имя контрола пятой группы
     * @type {AbstractControl}
     */
    get email(): AbstractControl | null {
        return this.loginForm?.get('email') || null;
    }

    /**
     * Имя контрола пятой группы
     * @type {AbstractControl}
     */
    get password(): AbstractControl | null {
        return this.loginForm?.get('password') || null;
    }

    pageText = {
        errorMessageTitle: AppText.errorMessageTitle
    }

    constructor(
        private formBuilder: FormBuilder,
        private service: SignInService,
        private menuService: AppMenuService,
        private headerService: AppHeaderService,
        private route: ActivatedRoute
    ) {
        this.menuService.setSettings.next({
            isEnable: false
        });
        this.headerService.setSettings.next({
            isEnable: false
        });
        this.isTypePassword = true;
    }

    public ngOnInit(): void {
        this.route.queryParams.subscribe((queryParams: Params) => {
            this.existingEmail = queryParams['existingEmail'] || '';
        })
            .unsubscribe();
        this.loginForm = this.formBuilder.group({
            /** email */
            email: [{ value: this.existingEmail, disabled: this.disabled }, [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(128)
            ]],
            /** password */
            password: [{ value: '', disabled: this.disabled }, [
                Validators.required,
                Validators.minLength(6),
                Validators.maxLength(32)
            ]]
        });
    }

    /**
     * The authorization method
     * @param form The login form
     */
    public onLogin(form: FormGroup): void {
        if (!this.disabled && form.valid) {
            this.service.login(form.value.email, form.value.password);
        }
    }

    /**
     * Метод отображает пароль в поле - меняет его тип
     * @param {Event} clickEvent Событие для прекращения дальнейшей передачи текущего события
     *
     * @returns {void}
     */
    public onShowPassword(clickEvent?: Event): void {
        /** Прекращение дальнейшей передачи текущего события */
        clickEvent && clickEvent.stopPropagation();
        this.isTypePassword = !this.isTypePassword;
    }
}