import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';




@Injectable({
    providedIn: 'root'
})
export class AppPaginatorOverlayService {
    /**
     * Событие выбора элемента в overlay
     * @type {Subject<number>}
     */
    selectedPageSizeChange: Subject<number>;

    constructor() {
        this.selectedPageSizeChange = new Subject<number>();
    }
}
