<div class="filter-pannel" [class.opened]="panelStateOpen" fxLayout="column" fxLayoutAlign="space-between stretch">
    <ng-scrollbar class="filter-scroll virtual-scroll">
        <div class="filter-pannel__body-wrapper" fxLayout="row" fxFlex="auto" fxLayoutGap="25px">
            <div *ngIf="filterSettings?.sidePanel" class="filter-pannel__options-wrapper" fxLayout="column"
                fxLayoutGap="12px">
                <button *ngIf="filterSettings?.articleFilters" class="btn-stroked active" type="button" mat-button
                    disableRipple (click)="onScrollToArticle()">
                    <div class="close-icon" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                        <svg class="btn-svg-icon-24" style="min-width: 24px;transform: scale(0.6);">
                            <use xlink:href="assets/icons/article-filter-title.svg#articleId" />
                        </svg>
                    </div>
                </button>
                <button *ngIf="filterSettings?.competitorFilters" class="btn-stroked" type="button" mat-button
                    disableRipple (click)="onScrollToCompetitor()">
                    <div class="close-icon" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                        <svg class="btn-svg-icon-24" style="min-width: 24px;transform: scale(0.6);">
                            <use xlink:href="assets/icons/competitor-filter-title.svg#competitorId" />
                        </svg>
                    </div>
                </button>
            </div>

            <div class="filter-pannel__filtes-wrapper" fxLayout="column" fxFlex="auto" fxLayoutGap="24px">

                <div *ngIf="filterSettings?.articleFilters" fxLayout="column" fxFlex="auto" fxLayoutGap="24px">
                    <div #articlesOptions fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                        <svg class="btn-svg-icon-24">
                            <use xlink:href="assets/icons/article-filter-title.svg#articleId" />
                        </svg>
                        <h1 class="filter-pannel__title">{{pageText.articles}}</h1>
                    </div>

                    <div *ngIf="filterSettings?.articleGroupFilter" fxLayout="column" fxLayoutGap="16px">
                        <div fxLayout="row">
                            <span class="filter-pannel__label">{{pageText.articleGroups}}</span>
                        </div>

                        <div fxLayout="column" fxLayoutGap="12px">
                            <app-multiselect [disabled]="!articleGroups || !articleGroups.length"
                                [isSearchEnable]="true" [items]="articleGroups" [selectedItems]="selectedArticleGroups"
                                [isMultipleModeEnable]="true" [displayName]="'value'"
                                [uniqueField]="'key'" [placeholder]="pageText.articleGroups"
                                [panelStyleClass]="'app-filter__multiselect-panel'"
                                [componentStyleClass]="'app-filter__multiselect hide-field-hint'"
                                (selectedValue)="onSelectArticleGroups($event)"
                                (selectedItemsChange)="onUpdateSelectedItems($event, 'selectedArticleGroups')">
                            </app-multiselect>
                        </div>
                    </div>

                    <div *ngIf="filterSettings?.strategiesFilter" fxLayout="column" fxLayoutGap="16px">
                        <div fxLayout="row">
                            <span class="filter-pannel__label">{{pageText.strategies}}</span>
                        </div>

                        <div fxLayout="column" fxLayoutGap="12px">
                            <app-multiselect [disabled]="!strategies || !strategies.length"
                                [isSearchEnable]="true" [items]="strategies" [selectedItems]="selectedStrategies"
                                [isMultipleModeEnable]="true" [displayName]="'value'"
                                [uniqueField]="'key'" [placeholder]="pageText.strategies"
                                [panelStyleClass]="'app-filter__multiselect-panel'"
                                [componentStyleClass]="'app-filter__multiselect hide-field-hint'"
                                (selectedValue)="onSelectStrategies($event)"
                                (selectedItemsChange)="onUpdateSelectedItems($event, 'selectedStrategies')">
                            </app-multiselect>
                        </div>
                    </div>

                    <div *ngIf="filterSettings?.articleDisplayOptions" fxLayout="column" fxLayoutGap="16px">
                        <div fxLayout="column">
                            <div fxLayout="row">
                                <h3 class="filter-pannel__subtitle">{{pageText.displayOptions}}</h3>
                            </div>
                            <div fxLayout="row">
                                <span class="filter-pannel__label">{{pageText.showAction}}</span>
                            </div>
                        </div>
                        <div *ngIf="filterSettings?.articlesWithoutCompetitorsOption" fxLayout="column"
                            fxLayoutGap="12px">
                            <mat-checkbox disableRipple
                                [(ngModel)]="articlesWithoutCompetitors"
                                (change)="onChangeCompetitors($event.checked ? true : null, 'articlesWithoutCompetitors')"
                                i18n="@@general.text.articles.without.competitors">
                                Articles without competitors
                            </mat-checkbox>
                        </div>
                        <div *ngIf="filterSettings?.manuallyCreatedSearchesFilter" fxLayout="column" fxLayoutGap="12px">
                            <div fxLayout="row">
                                <span class="filter-pannel__label" i18n="@@general.text.is.manually.created.search.requests">
                                    Manually created search requests</span>
                            </div>
                            <mat-radio-group fxLayout="column" fxLayoutGap="12px"
                                aria-labelledby="example-radio-group-label" class="example-radio-group"
                                [ngModel]="manuallyCreatedSearchRequests" (ngModelChange)="onChangeManuallyCreatedSearchRequests($event)">
                                <mat-radio-button class="example-radio-button"
                                    *ngFor="let item of pageText.manuallyCreatedSearchRequestsFilterOptions" [value]="item.value">
                                    {{ item.key }}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <div *ngIf="filterSettings?.articlesExcludedOption" fxLayout="column" fxLayoutGap="12px">
                            <div fxLayout="row">
                                <span class="filter-pannel__label">{{pageText.generalTextIsArticlesOnRepricing}}</span>
                            </div>
                            <mat-radio-group fxLayout="column" fxLayoutGap="12px"
                                aria-labelledby="example-radio-group-label" class="example-radio-group"
                                [ngModel]="articlesExcluded" (ngModelChange)="onChangeArticlesExcluded($event)">
                                <mat-radio-button class="example-radio-button"
                                    *ngFor="let item of pageText.excludedFilterOptions" [value]="item.value">
                                    {{ item.key }}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div *ngIf="filterSettings?.articlesBoostedFilter" fxLayout="column" fxLayoutGap="12px">
                            <div fxLayout="row">
                                <span class="filter-pannel__label">{{pageText.articlesBoostedLabel}}</span>
                            </div>
                            <mat-radio-group fxLayout="column" fxLayoutGap="12px"
                                aria-labelledby="example-radio-group-label" class="example-radio-group"
                                [ngModel]="articlesBoosted" (ngModelChange)="onChangeArticlesBoosted($event)">
                                <mat-radio-button class="example-radio-button"
                                    *ngFor="let item of pageText.boostedFilterOptions" [value]="item.value">
                                    {{ item.key }}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div *ngIf="filterSettings?.articlesPlatformFilter" fxLayout="column" fxLayoutGap="12px">
                            <div fxLayout="row">
                                <span class="filter-pannel__label">{{pageText.articlesPlatformLabel}}</span>
                            </div>
                            <mat-radio-group fxLayout="column" fxLayoutGap="12px"
                                aria-labelledby="example-radio-group-label" class="example-radio-group"
                                [ngModel]="listerPlatformType" (ngModelChange)="onChangeArticlesPlatform($event)">
                                <mat-radio-button class="example-radio-button"
                                    *ngFor="let item of pageText.platformFilterOptions" [value]="item.value">
                                    {{ item.key }}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>


                    <div *ngIf="filterSettings?.priceDifferenceFilters" fxLayout="column" fxLayoutGap="16px">
                        <div fxLayout="row">
                            <span class="filter-pannel__label">{{pageText.priceDifference}}</span>
                        </div>

                        <div fxLayout="row" fxLayoutGap="16px">
                            <mat-form-field appearance="outline" 
                                class="mat-form-number hide-field-hint mat-form-field-suffix-padding">
                                <input type="text" matInput placeholder="From 0.00" autocomplete="off" maxlength="11"
                                    #priceFromInput [ngModel]="fromPrice"
                                    (keydown.arrowup)="onUpPriceDifference(fromPrice, 'fromPrice', 'priceFromInput')"
                                    (keydown.arrowdown)="onDownPriceDifference(fromPrice, 'fromPrice', 'priceFromInput')"
                                    (ngModelChange)="onChangePriceDifference($event, 'fromPrice', 'priceFromInput')"
                                    (blur)="onCheckPriceDifference('fromPrice', 'toPrice', 'priceFromInput', 'priceToInput')" />
                                <div matSuffix disableRipple
                                    (click)="onUpPriceDifference(fromPrice, 'fromPrice', 'priceFromInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/plus-icon.svg#plusIcon" />
                                        </svg>
                                    </div>
                                </div>
                                <div matSuffix disableRipple
                                    (click)="onDownPriceDifference(fromPrice, 'fromPrice', 'priceFromInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/minus-icon.svg#minusIcon" />
                                        </svg>
                                    </div>
                                </div>
                            </mat-form-field>
                            <mat-form-field appearance="outline" 
                                class="mat-form-number hide-field-hint mat-form-field-suffix-padding">
                                <input type="text" matInput placeholder="To 9 999 999.99" autocomplete="off" maxlength="11"
                                    #priceToInput [ngModel]="toPrice"
                                    (keydown.arrowup)="onUpPriceDifference(toPrice, 'toPrice', 'priceToInput')"
                                    (keydown.arrowdown)="onDownPriceDifference(toPrice, 'toPrice', 'priceToInput')"
                                    (ngModelChange)="onChangePriceDifference($event, 'toPrice', 'priceToInput')"
                                    (blur)="onCheckPriceDifference('fromPrice', 'toPrice', 'priceFromInput', 'priceToInput')" />
                                <div matSuffix disableRipple
                                    (click)="onUpPriceDifference(toPrice, 'toPrice', 'priceToInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/plus-icon.svg#plusIcon" />
                                        </svg>
                                    </div>
                                </div>
                                <div matSuffix disableRipple
                                    (click)="onDownPriceDifference(toPrice, 'toPrice', 'priceToInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/minus-icon.svg#minusIcon" />
                                        </svg>
                                    </div>
                                </div>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row">
                            <span class="filter-pannel__label">{{pageText.priceDifferencePercent}}</span>
                        </div>

                        <div fxLayout="row" fxLayoutGap="16px">
                            <mat-form-field appearance="outline" 
                                class="mat-form-number hide-field-hint mat-form-field-suffix-padding">
                                <input type="text" matInput placeholder="From -9 999 999.99" autocomplete="off" maxlength="11"
                                    #priceDifferencePercentFromInput [ngModel]="fromPriceDifferencePercent"
                                    (keydown.arrowup)="onUpPriceDifference(fromPriceDifferencePercent, 'fromPriceDifferencePercent', 'priceDifferencePercentFromInput')"
                                    (keydown.arrowdown)="onDownPriceDifference(fromPriceDifferencePercent, 'fromPriceDifferencePercent', 'priceDifferencePercentFromInput', true)"
                                    (ngModelChange)="onChangePriceDifference($event, 'fromPriceDifferencePercent', 'priceDifferencePercentFromInput', true)"
                                    (blur)="onCheckPriceDifference('fromPriceDifferencePercent', 'toPriceDifferencePercent', 'priceDifferencePercentFromInput', 'priceDifferencePercentToInput', true)" />
                                <div matSuffix disableRipple
                                    (click)="onUpPriceDifference(fromPriceDifferencePercent, 'fromPriceDifferencePercent', 'priceDifferencePercentFromInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/plus-icon.svg#plusIcon" />
                                        </svg>
                                    </div>
                                </div>
                                <div matSuffix disableRipple
                                    (click)="onDownPriceDifference(fromPriceDifferencePercent, 'fromPriceDifferencePercent', 'priceDifferencePercentFromInput',true)" onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/minus-icon.svg#minusIcon" />
                                        </svg>
                                    </div>
                                </div>
                            </mat-form-field>
                            <mat-form-field appearance="outline" 
                                class="mat-form-number hide-field-hint mat-form-field-suffix-padding">
                                <input type="text" matInput placeholder="To 9 999 999.99" autocomplete="off" maxlength="11"
                                    #priceDifferencePercentToInput [ngModel]="toPriceDifferencePercent"
                                    (keydown.arrowup)="onUpPriceDifference(toPriceDifferencePercent, 'toPriceDifferencePercent', 'priceDifferencePercentToInput')"
                                    (keydown.arrowdown)="onDownPriceDifference(toPriceDifferencePercent, 'toPriceDifferencePercent', 'priceDifferencePercentToInput', true)"
                                    (ngModelChange)="onChangePriceDifference($event, 'toPriceDifferencePercent', 'priceDifferencePercentToInput', true)"
                                    (blur)="onCheckPriceDifference('fromPriceDifferencePercent', 'toPriceDifferencePercent', 'priceDifferencePercentFromInput', 'priceDifferencePercentToInput', true)" />
                                <div matSuffix disableRipple
                                    (click)="onUpPriceDifference(toPriceDifferencePercent, 'toPriceDifferencePercent', 'priceDifferencePercentToInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/plus-icon.svg#plusIcon" />
                                        </svg>
                                    </div>
                                </div>
                                <div matSuffix disableRipple
                                    (click)="onDownPriceDifference(toPriceDifferencePercent, 'toPriceDifferencePercent', 'priceDifferencePercentToInput', true)"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/minus-icon.svg#minusIcon" />
                                        </svg>
                                    </div>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div *ngIf="filterSettings?.competitorFilters" fxLayout="column" fxFlex="auto" fxLayoutGap="24px">
                    <div #competitorsOptions fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                        <svg class="btn-svg-icon-24">
                            <use xlink:href="assets/icons/competitor-filter-title.svg#competitorId" />
                        </svg>
                        <h1 class="filter-pannel__title">{{pageText.competitors}}</h1>
                    </div>

                    <div *ngIf="filterSettings?.ruleFilters" fxLayout="column" fxLayoutGap="16px">
                        <div fxLayout="column" fxLayoutGap="8px">
                            <h3 class="filter-pannel__subtitle">{{pageText.rules}}</h3>
                            <span class="filter-pannel__hint" i18n="@@general.text.filter.by.rule.matching">Filter potential competitors and competitors by rule matching</span>
                        </div>
                        <div fxLayout="column" fxLayoutGap="16px">
                            <div *ngIf="filterSettings?.validationMatchesRulesCompetitorsFilter" fxLayout="column" fxLayoutGap="12px">
                                <div fxLayout="row">
                                    <span class="filter-pannel__label">{{pageText.generalTextIsValidationMatchesRules}}</span>
                                </div>
                                <mat-radio-group fxLayout="column" fxLayoutGap="12px"
                                    aria-labelledby="example-radio-group-label" class="example-radio-group"
                                    [ngModel]="validationMatchesRules"
                                    (ngModelChange)="onChangeCompetitors($event, 'validationMatchesRules')">
                                    <mat-radio-button class="example-radio-button"
                                        *ngFor="let item of pageText.validationMatchesRulesFilterOptions" [value]="item.value">
                                        {{ item.key }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <app-multiselect *ngIf="filterSettings?.decisionRuleFilter"
                                [disabled]="!decisionRules || !decisionRules.length" [isSearchEnable]="true"
                                [items]="decisionRules" [selectedItems]="selectedDecisionRules"
                                [isMultipleModeEnable]="true" [isSearchEnable]="true" [isSelectAllOptionVisible]="true"
                                [displayName]="'value'" [uniqueField]="'key'" [placeholder]="'Decision Rules'"
                                [panelStyleClass]="'app-filter__multiselect-panel'"
                                [componentStyleClass]="'app-filter__multiselect hide-field-hint'"
                                (selectedValue)="onSelectDecisionRules($event)"
                                (selectedItemsChange)="onUpdateSelectedItems($event, 'selectedDecisionRules')"
                                (selectedAllChange)="onSelectAllDecisionRules($event)">
                            </app-multiselect>

                            <app-multiselect *ngIf="filterSettings?.rulesMatchFilter"
                                [disabled]="!matchRules || !matchRules.length" [isSearchEnable]="true"
                                [items]="matchRules" [selectedItems]="selectedMatchRules" [isMultipleModeEnable]="true"
                                [isSearchEnable]="true" [isSelectAllOptionVisible]="true" [displayName]="'value'"
                                [uniqueField]="'key'" [placeholder]="'Rules Match'"
                                [panelStyleClass]="'app-filter__multiselect-panel'"
                                [componentStyleClass]="'app-filter__multiselect hide-field-hint'"
                                (selectedValue)="onSelectMatchRules($event)"
                                (selectedItemsChange)="onUpdateSelectedItems($event, 'selectedMatchRules')"
                                (selectedAllChange)="onSelectAllMatchRules($event)">
                            </app-multiselect>
                        </div>
                    </div>
                    <div *ngIf="filterSettings?.priceDifferenceCompetitorFilters" fxLayout="column" fxLayoutGap="16px">
                        <div fxLayout="row">
                            <span class="filter-pannel__label">{{pageText.priceDifference}}</span>
                        </div>

                        <div fxLayout="row" fxLayoutGap="16px">
                            <mat-form-field appearance="outline" 
                                class="mat-form-number hide-field-hint mat-form-field-suffix-padding">
                                <input type="text" matInput placeholder="From 0.00" autocomplete="off" maxlength="11"
                                    #fromCompetitorPriceInput [ngModel]="fromCompetitorPrice"
                                    (keydown.arrowup)="onUpPriceDifference(fromCompetitorPrice, 'fromCompetitorPrice', 'fromCompetitorPriceInput')"
                                    (keydown.arrowdown)="onDownPriceDifference(fromCompetitorPrice, 'fromCompetitorPrice', 'fromCompetitorPriceInput')"
                                    (ngModelChange)="onChangePriceDifference($event, 'fromCompetitorPrice', 'fromCompetitorPriceInput')"
                                    (blur)="onCheckPriceDifference('fromCompetitorPrice', 'toCompetitorPrice', 'fromCompetitorPriceInput', 'toCompetitorPriceInput')" />
                                <div matSuffix disableRipple
                                    (click)="onUpPriceDifference(fromCompetitorPrice, 'fromCompetitorPrice', 'fromCompetitorPriceInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/plus-icon.svg#plusIcon" />
                                        </svg>
                                    </div>
                                </div>
                                <div matSuffix disableRipple
                                    (click)="onDownPriceDifference(fromCompetitorPrice, 'fromCompetitorPrice', 'fromCompetitorPriceInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/minus-icon.svg#minusIcon" />
                                        </svg>
                                    </div>
                                </div>
                            </mat-form-field>
                            <mat-form-field appearance="outline" 
                                class="mat-form-number hide-field-hint mat-form-field-suffix-padding">
                                <input type="text" matInput placeholder="To 9 999 999.99" autocomplete="off" maxlength="11"
                                    #toCompetitorPriceInput [ngModel]="toCompetitorPrice"
                                    (keydown.arrowup)="onUpPriceDifference(toCompetitorPrice, 'toCompetitorPrice', 'toCompetitorPriceInput')"
                                    (keydown.arrowdown)="onDownPriceDifference(toCompetitorPrice, 'toCompetitorPrice', 'toCompetitorPriceInput')"
                                    (ngModelChange)="onChangePriceDifference($event, 'toCompetitorPrice', 'toCompetitorPriceInput')"
                                    (blur)="onCheckPriceDifference('fromCompetitorPrice', 'toCompetitorPrice', 'fromCompetitorPriceInput', 'toCompetitorPriceInput')" />
                                <div matSuffix disableRipple
                                    (click)="onUpPriceDifference(toCompetitorPrice, 'toCompetitorPrice', 'toCompetitorPriceInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/plus-icon.svg#plusIcon" />
                                        </svg>
                                    </div>
                                </div>
                                <div matSuffix disableRipple
                                    (click)="onDownPriceDifference(toCompetitorPrice, 'toCompetitorPrice', 'toCompetitorPriceInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/minus-icon.svg#minusIcon" />
                                        </svg>
                                    </div>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="filterSettings?.confidenceFilters" fxLayout="column" fxLayoutGap="16px">
                        <div fxLayout="row">
                            <span class="filter-pannel__label">{{pageText.confidence}}</span>
                        </div>

                        <div fxLayout="row" fxLayoutGap="16px">
                            <mat-form-field *ngIf="filterSettings?.fromConfidenceFilter" appearance="outline"
                                
                                class="mat-form-number hide-field-hint mat-form-field-suffix-padding">
                                <input type="text" matInput placeholder="From 0.00" autocomplete="off"
                                    #confidenceFromInput [ngModel]="confidenceFrom"
                                    (keydown.arrowup)="onUpConfidence(confidenceFrom, 'confidenceFrom', 'confidenceFromInput')"
                                    (keydown.arrowdown)="onDownConfidence(confidenceFrom, 'confidenceFrom', 'confidenceFromInput')"
                                    (ngModelChange)="onChangeConfidence($event, 'confidenceFrom', 'confidenceFromInput')"
                                    (blur)="onCheckConfidence()" />
                                <div matSuffix disableRipple
                                    (click)="onUpConfidence(confidenceFrom, 'confidenceFrom', 'confidenceFromInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/plus-icon.svg#plusIcon" />
                                        </svg>
                                    </div>
                                </div>
                                <div matSuffix disableRipple
                                    (click)="onDownConfidence(confidenceFrom, 'confidenceFrom', 'confidenceFromInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/minus-icon.svg#minusIcon" />
                                        </svg>
                                    </div>
                                </div>
                            </mat-form-field>
                            <mat-form-field *ngIf="filterSettings?.toConfidenceFilter" appearance="outline"
                                
                                class="mat-form-number hide-field-hint mat-form-field-suffix-padding">
                                <input type="text" matInput placeholder="To 0.00" autocomplete="off" #confidenceToInput
                                    [ngModel]="confidenceTo"
                                    (keydown.arrowup)="onUpConfidence(confidenceTo, 'confidenceTo', 'confidenceToInput')"
                                    (keydown.arrowdown)="onDownConfidence(confidenceTo, 'confidenceTo', 'confidenceToInput')"
                                    (ngModelChange)="onChangeConfidence($event, 'confidenceTo', 'confidenceToInput')"
                                    (blur)="onCheckConfidence()" />
                                <div matSuffix disableRipple
                                    (click)="onUpConfidence(confidenceTo, 'confidenceTo', 'confidenceToInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/plus-icon.svg#plusIcon" />
                                        </svg>
                                    </div>
                                </div>
                                <div matSuffix disableRipple
                                    (click)="onDownConfidence(confidenceTo, 'confidenceTo', 'confidenceToInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/minus-icon.svg#minusIcon" />
                                        </svg>
                                    </div>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>

                    <div *ngIf="filterSettings?.competitorDisplayOptions" fxLayout="column" fxLayoutGap="16px">
                        <div fxLayout="column">
                            <div fxLayout="row">
                                <h3 class="filter-pannel__subtitle">{{pageText.displayOptions}}</h3>
                            </div>
                            <!--div fxLayout="row">
                                <span class="filter-pannel__label">Show</span>
                            </div-->
                        </div>
                        <div fxLayout="column" fxLayoutGap="12px">
                            <mat-checkbox *ngIf="filterSettings?.competitorsOption" disableRipple
                                [(ngModel)]="potentialCompetitors"
                                (change)="onChangeCompetitors($event.checked ? true : null, 'potentialCompetitors')">
                                {{pageText.showPotentialCompetitors}}
                            </mat-checkbox>
                            <mat-checkbox *ngIf="filterSettings?.competitorsOption" disableRipple
                                [(ngModel)]="inactiveCompetitors"
                                (change)="onChangeCompetitors($event.checked ? true : null, 'inactiveCompetitors')">
                                {{pageText.tooltipMessageShowOutOfStockCompetitors}}
                            </mat-checkbox>
                            <mat-checkbox *ngIf="filterSettings?.competitorsOption" disableRipple
                                [(ngModel)]="competitorsWithoutSales"
                                (change)="onChangeCompetitors($event.checked ? true : null, 'competitorsWithoutSales')">
                                {{pageText.showCompetitorsWithoutSales}}
                            </mat-checkbox>
                            <mat-checkbox *ngIf="filterSettings?.competitorsOption" disableRipple
                                [(ngModel)]="competitorsWithLongShipping"
                                (change)="onChangeCompetitors($event.checked ? true : null, 'competitorsWithLongShipping')">
                                {{pageText.showCompetitorsWithLongShippingTimes}}
                            </mat-checkbox>
                        </div>
                        <div fxLayout="column" fxLayoutGap="16px">
                            <div *ngIf="filterSettings?.markedAsCompetitorsFilter" fxLayout="column" fxLayoutGap="12px">
                                <div fxLayout="row">
                                    <span class="filter-pannel__label">{{pageText.competition}}</span>
                                </div>
                                <mat-radio-group fxLayout="column" fxLayoutGap="12px"
                                    aria-labelledby="example-radio-group-label" class="example-radio-group"
                                    [ngModel]="isCompetitor"
                                    (ngModelChange)="onChangeCompetitors($event, 'isCompetitor')">
                                    <mat-radio-button class="example-radio-button"
                                        *ngFor="let item of pageText.competitorFilterOptions" [value]="item.value">
                                        {{ item.key }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div *ngIf="filterSettings?.validationCompetitorsFilter" fxLayout="column" fxLayoutGap="12px">
                                <div fxLayout="row">
                                    <span class="filter-pannel__label">{{pageText.validation}}</span>
                                </div>
                                <mat-radio-group fxLayout="column" fxLayoutGap="12px"
                                    aria-labelledby="example-radio-group-label" class="example-radio-group"
                                    [ngModel]="validated" (ngModelChange)="onChangeCompetitors($event, 'validated')">
                                    <mat-radio-button class="example-radio-button"
                                        *ngFor="let item of pageText.validationFilterOptions" [value]="item.value">
                                        {{ item.key }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div *ngIf="filterSettings?.variationCompetitorsFilter" fxLayout="column"
                                fxLayoutGap="12px">
                                <div fxLayout="row">
                                    <span class="filter-pannel__label">{{pageText.variation}}</span>
                                </div>
                                <mat-radio-group fxLayout="column" fxLayoutGap="12px"
                                    aria-labelledby="example-radio-group-label" class="example-radio-group"
                                    [ngModel]="markedAsVariant"
                                    (ngModelChange)="onChangeCompetitors($event, 'markedAsVariant')">
                                    <mat-radio-button class="example-radio-button"
                                        *ngFor="let item of pageText.variationFilterOptions" [value]="item.value">
                                        {{ item.key }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="filterSettings?.rankFilters" fxLayout="column" fxLayoutGap="16px">
                        <div fxLayout="row">
                            <span class="filter-pannel__label">{{pageText.marketplaceRank}}</span>
                        </div>

                        <div fxLayout="row" fxLayoutGap="16px">
                            <mat-form-field appearance="outline" 
                                class="mat-form-number hide-field-hint mat-form-field-suffix-padding">
                                <input type="text" matInput placeholder="From 0" autocomplete="off" maxlength="5"
                                    #rankFromInput [ngModel]="fromMarketplaceRank"
                                    (keydown.arrowup)="onUpRank(fromMarketplaceRank, 'fromMarketplaceRank', 'rankFromInput')"
                                    (keydown.arrowdown)="onDownRank(fromMarketplaceRank, 'fromMarketplaceRank', 'rankFromInput')"
                                    (ngModelChange)="onChangeRank($event, 'fromMarketplaceRank', 'rankFromInput')"
                                    (blur)="onCheckRank('fromMarketplaceRank', 'toMarketplaceRank', 'rankFromInput', 'rankToInput')" />
                                <div matSuffix disableRipple
                                    (click)="onUpRank(fromMarketplaceRank, 'fromMarketplaceRank', 'rankFromInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/plus-icon.svg#plusIcon" />
                                        </svg>
                                    </div>
                                </div>
                                <div matSuffix disableRipple
                                    (click)="onDownRank(fromMarketplaceRank, 'fromMarketplaceRank', 'rankFromInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/minus-icon.svg#minusIcon" />
                                        </svg>
                                    </div>
                                </div>
                            </mat-form-field>
                            <mat-form-field appearance="outline" 
                                class="mat-form-number hide-field-hint mat-form-field-suffix-padding">
                                <input type="text" matInput placeholder="To 100" autocomplete="off" maxlength="5"
                                    #rankToInput [ngModel]="toMarketplaceRank"
                                    (keydown.arrowup)="onUpRank(toMarketplaceRank, 'toMarketplaceRank', 'rankToInput')"
                                    (keydown.arrowdown)="onDownRank(toMarketplaceRank, 'toMarketplaceRank', 'rankToInput')"
                                    (ngModelChange)="onChangeRank($event, 'toMarketplaceRank', 'rankToInput')"
                                    (blur)="onCheckRank('fromMarketplaceRank', 'toMarketplaceRank', 'rankFromInput', 'rankToInput')" />
                                <div matSuffix disableRipple
                                    (click)="onUpRank(toMarketplaceRank, 'toMarketplaceRank', 'rankToInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/plus-icon.svg#plusIcon" />
                                        </svg>
                                    </div>
                                </div>
                                <div matSuffix disableRipple
                                    (click)="onDownRank(toMarketplaceRank, 'toMarketplaceRank', 'rankToInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/minus-icon.svg#minusIcon" />
                                        </svg>
                                    </div>
                                </div>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row">
                            <span class="filter-pannel__label">{{pageText.visibilityRank}}</span>
                        </div>

                        <div fxLayout="row" fxLayoutGap="16px">
                            <mat-form-field appearance="outline" 
                                class="mat-form-number hide-field-hint mat-form-field-suffix-padding">
                                <input type="text" matInput placeholder="From 0" autocomplete="off" maxlength="5"
                                    #visibilityRankFromInput [ngModel]="fromVisibilityRank"
                                    (keydown.arrowup)="onUpRank(fromVisibilityRank, 'fromVisibilityRank', 'visibilityRankFromInput')"
                                    (keydown.arrowdown)="onDownRank(fromVisibilityRank, 'fromVisibilityRank', 'visibilityRankFromInput')"
                                    (ngModelChange)="onChangeRank($event, 'fromVisibilityRank', 'visibilityRankFromInput')"
                                    (blur)="onCheckRank('fromVisibilityRank', 'toVisibilityRank', 'visibilityRankFromInput', 'visibilityRankToInput')" />
                                <div matSuffix disableRipple
                                    (click)="onUpRank(fromVisibilityRank, 'fromVisibilityRank', 'visibilityRankFromInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/plus-icon.svg#plusIcon" />
                                        </svg>
                                    </div>
                                </div>
                                <div matSuffix disableRipple
                                    (click)="onDownRank(fromVisibilityRank, 'fromVisibilityRank', 'visibilityRankFromInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/minus-icon.svg#minusIcon" />
                                        </svg>
                                    </div>
                                </div>
                            </mat-form-field>
                            <mat-form-field appearance="outline" 
                                class="mat-form-number hide-field-hint mat-form-field-suffix-padding">
                                <input type="text" matInput placeholder="To 100" autocomplete="off" maxlength="5"
                                    #visibilityRankToInput [ngModel]="toVisibilityRank"
                                    (keydown.arrowup)="onUpRank(toVisibilityRank, 'toVisibilityRank', 'visibilityRankToInput')"
                                    (keydown.arrowdown)="onDownRank(toVisibilityRank, 'toVisibilityRank', 'visibilityRankToInput')"
                                    (ngModelChange)="onChangeRank($event, 'toVisibilityRank', 'visibilityRankToInput')"
                                    (blur)="onCheckRank('fromVisibilityRank', 'toVisibilityRank', 'visibilityRankFromInput', 'visibilityRankToInput')" />
                                <div matSuffix disableRipple
                                    (click)="onUpRank(toVisibilityRank, 'toVisibilityRank', 'visibilityRankToInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/plus-icon.svg#plusIcon" />
                                        </svg>
                                    </div>
                                </div>
                                <div matSuffix disableRipple
                                    (click)="onDownRank(toVisibilityRank, 'toVisibilityRank', 'visibilityRankToInput')"
                                    onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
                                    <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
                                        <svg class="btn-svg-icon-16">
                                            <use xlink:href="assets/icons/minus-icon.svg#minusIcon" />
                                        </svg>
                                    </div>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div *ngIf="filterSettings?.platformFilters" fxLayout="column" fxFlex="auto" fxLayoutGap="16px">
                    <div #platformFiltersOptions fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                        <h1 class="filter-pannel__title">{{pageText.platformFiltersPlaceholder}}</h1>
                    </div>

                    <div *ngIf="filterSettings?.priceFilters" fxLayout="column" fxLayoutGap="16px">
                        <div fxLayout="row">
                            <span class="filter-pannel__label">{{pageText.productPrice}}</span>
                        </div>

                        <div fxLayout="row" fxLayoutGap="16px">
                            <app-number-input fxFlex="50" [label]="'From, €'" [value]="priceFrom" [min]="0"
                                [max]="1000000" [regex]="priceRegex"
                                (valueChange)="onChangePriceFrom($event)"></app-number-input>

                            <app-number-input fxFlex="50" [label]="'To, €'" [value]="priceTo" [min]="0" [max]="1000000"
                                [regex]="priceRegex" (valueChange)="onChangePriceTo($event)"></app-number-input>

                        </div>
                    </div>

                    <div *ngIf="filterSettings?.hsnTsnFilters" fxLayout="column" fxLayoutGap="16px">
                        <div fxLayout="row">
                            <span class="filter-pannel__label">{{pageText.generalTextIsHsn}}/{{pageText.generalTextIsTsn}}</span>
                        </div>

                        <div fxLayout="row" fxLayoutGap="16px">
                            <mat-form-field class="form-field" [class.form-field__empty]="!hsn" appearance="outline"
                                 fxFlex="50">
                                <mat-label>{{pageText.generalTextIsHsn}}</mat-label>
                                <input #hsnInput matInput placeholder="0000" [ngModel]="hsn" minlength="4"
                                    (ngModelChange)="onChangeHsn($event)" />
                            </mat-form-field>

                            <mat-form-field class="form-field" [class.form-field__empty]="tsn" appearance="outline"
                                 fxFlex="50">
                                <mat-label>{{pageText.generalTextIsTsn}}</mat-label>
                                <input #tsnInput matInput placeholder="000" [ngModel]="tsn" minlength="3"
                                    (ngModelChange)="onChangeTsn($event)" />
                            </mat-form-field>

                        </div>
                    </div>

                    <div *ngIf="filterSettings?.carsFilter" fxLayout="column" fxLayoutGap="16px">
                        <div fxLayout="row">
                            <span class="filter-pannel__label">{{pageText.entityIsOneCar}}</span>
                        </div>
                        <div fxLayout="row" fxLayoutGap="16px">
                            <app-multiselect fxFlex="100" [isSearchEnable]="true"
                                [generalTextIsSearchPlaceholder]="pageText.HSNTSNPlaceholder" [isLocalList]="false"
                                [isClearIconVisible]="true" [items]="cars" [selectedItems]="selectedCars"
                                [isSearchEnable]="true" [displayName]="'value'" [uniqueField]="'key'"
                                [placeholder]="pageText.generalTextIsSelectCar" [panelStyleClass]="'app-filter__multiselect-panel'"
                                [componentStyleClass]="'app-filter__multiselect hide-field-hint'"
                                (selectedValue)="onSelectCars($event)"
                                (selectedItemsChange)="onUpdateSelectedItems($event, 'selectedCars')"
                                (searchChange)="onSearchChange($event, 'cars')">
                            </app-multiselect>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-scrollbar>
</div>