import { Component, Input, OnInit, OnDestroy, ViewChild, Output, EventEmitter, SimpleChange, OnChanges } from '@angular/core';
import { SimpleDataFilter } from 'src/app/contracts/simple-data-filter';
import { NgbCalendar, NgbDate, NgbInputDatepicker, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerI18n, I18n } from './app-datepickerI18n';
import { Subscription, fromEvent } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DatepickerToggles, Period } from './datepicker-toggles';
import { AppDatepickerRanges } from './app-datepicker-ranges';
import { trigger, transition, animate, style } from '@angular/animations';
import * as moment from 'moment';
import { AppText } from 'src/app/text/app-text';



/**@constant {string} */
const DEFAULT_PERIOD = 'thisWeek';
/**@constant {string} */
const DATE_NOT_SELECT = 'date no select';
/**@constant {number} */
const ROUND_TIME = 1000;
/**@constant {number} */
const ZERO = 0;
/**@constant {number} */
const PARSE_INT = 10;
/**@constant {number} */
const TWO = 2;
@Component({
    selector: 'app-datepicker',
    templateUrl: './app-datepicker.component.html',
    styleUrls: ['./app-datepicker.component.scss'],
    providers: [I18n, { provide: NgbDatepickerI18n, useClass: DatepickerI18n }],
    animations: [
        trigger('showElement', [
            transition(':enter', [
                style({ opacity: '0', 'max-width': '0' }),
                animate('.2s ease', style({ opacity: '1', 'max-width': '190px' }))
            ]),
            transition(':leave', [
                style({ opacity: '1', 'max-width': '190px' }),
                animate('.2s ease', style({ opacity: '0', 'max-width': '0' }))
            ])
        ]),
    ]
})
export class AppDatepickerComponent implements OnChanges, OnInit, OnDestroy {
    /**
     * Возможность скрытия дэйтапикера
     * @type {boolean}
     */
    @Input() enableMinimizedMode: boolean = true;
    /**
     * Флаг включения даты по умолчанию
     * обязательно будет стоять дефолтная дата - хардкод неделя
     * @type {boolean}
     */
    @Input() enableDefaultPeriod: boolean = true;
    /**
     * Флаг сброса даты по умолчанию
     * Поумолчанию его сбросить нельзя
     * @type {boolean}
     */
    @Input() enableResetDefaultPeriod: boolean = false;
    /**
     * Работает с флагом `enableDefaultPeriod`
     * В этом поле указывается желаемый дефолтный период
     * по умолчанию - `thisWeek`
     * @type {boolean}
     */
    @Input() defaultPeriod: Period = 'thisWeek';
    /**
     * Disabled flag for input
     * @type {boolean}
     */
    @Input() disabled: boolean = false;
    /**
     * Наименование для поля
     * @type {string}
     */
    @Input() dateInputName!: string;
    // /**
    //  * Наименование для периода в параметрах
    //  * @type {string}
    //  */
    // @Input() periodName: string = 'period';
    // /**
    //  * Наименование для даты в параметрах
    //  * @type {string}
    //  */
    // @Input() fromDateName: string = 'fromDate';
    // /**
    //  * Наименование для даты в параметрах
    //  * @type {string}
    //  */
    // @Input() toDateName: string = 'toDate';
    /**
     * Максимаьная дата сегодня
     * @type {boolean}
     */
    @Input() enableMaxDate: boolean = true;

    // Вместо параметров будет всего три переменные
    @Input() valuePeriod: string | null = null;
    @Input() valueFromDate: string | number | null = null;
    @Input() valueToDate: string | number | null = null;


    /**
     * Событие о том, что дата изменилась
     * @type {EventEmitter<SimpleDataFilter>}
     */
    @Output() dateChange: EventEmitter<{ period: string | null, fromDate: number | null, toDate: number | null }>;

    /** Тексты для страницы */
    pageText = {
        period: AppText.generalTextIsPeriod,
        selectDate: AppText.selectDateAction,
        today: AppText.generalTextIsToday,
        yesterday: AppText.generalTextIsYesterday,
        thisWeek: AppText.generalTextIsThisWeek,
        lastWeek: AppText.generalTextIsLastWeek,
        thisMonth: AppText.generalTextIstThisMonth,
        lastMonth: AppText.generalTextIsLastMonth
    };

    /**
     * Объект для дефолтного значения
     * @type {DatepickerToggles}
     */
    private _defaultPeriodObj!: DatepickerToggles;
    /**
     * Is required for to avoid cloning subscriptions
     * @type {Subscription}
     */
    protected documSubscription!: Subscription;
    /**
     *
     * @type {string[]}
     */
    toggles: DatepickerToggles[] = [];

    /**
     * Выбранный период - его текстовое отображение
     * @type {string}
     */
    selectedPeriodTitle: string;

    calendarBlockEnter!: boolean;
    /** Text for input value */
    // selectedRange: string;
    hoveredDate!: NgbDate | null;
    /** Date from */
    fromDate: NgbDate;
    /** Date to */
    toDate: NgbDate | null;

    today: NgbDate;

    /** Hide outside days in month */
    outsideDays = 'hidden';
    dateRanges: AppDatepickerRanges;

    isDatepickerHide: boolean = true;

    /** Календарь */
    @ViewChild('rangeDPicker', { static: false })
    private inputDatePicker!: NgbInputDatepicker;

    /**
     * расчитывает количество блоков
     * для тогглов чтобы они отображались
     * по 2 элемента в строке
     * здесь вся длина списка делится на 2 и округляется
     * в сторону большего числа
     * если длина 0 или список пуст возвращается 0
     */
    _blocksOfToggles: number = ZERO;
    /** Display month for desktop - 2 and mobile - 1 */
    // get displayMonths(): number {
    //     return document.documentElement.clientWidth > 900 ? 2 : 1;
    // }

    constructor(
        private calendar: NgbCalendar,
    ) {
        this.dateRanges = new AppDatepickerRanges();
        this.dateRanges.setNowDate();
        this.toggles = this.getToggles();

        this.selectedPeriodTitle = DATE_NOT_SELECT;
        this.dateInputName = this.dateInputName != null ? this.dateInputName : 'Creation date';

        this.fromDate = this.calendar.getToday();
        this.toDate = this.calendar.getToday();

        this.today = this.calendar.getToday();

        /** Событие изменения даты */
        this.dateChange = new EventEmitter<{ period: string | null, fromDate: number | null, toDate: number | null }>();

        /** Период по умолчанию, будет включен флагом enableDefaultPeriod */
        this.defaultPeriod = this.defaultPeriod ? this.defaultPeriod : DEFAULT_PERIOD;
        /** Объект с дефолтными данными, будет использватся при enableDefaultPeriod === true */
        this._defaultPeriodObj = this.setDefaultPeriod(this.defaultPeriod);

        this._blocksOfToggles = this.toggles.length ? Math.ceil(this.toggles.length / TWO) : ZERO;

    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {

        if (changes['valuePeriod']) {
            this.valuePeriod = !changes['valuePeriod'].currentValue || changes['valuePeriod'].currentValue == '' ? null : changes['valuePeriod'].currentValue;
        }

        if (changes['valueFromDate']) {
            this.valueFromDate = !changes['valueFromDate'].currentValue || changes['valueFromDate'].currentValue == '' ? null : Number(changes['valueFromDate'].currentValue);
        }

        if (changes['valueToDate']) {
            this.valueToDate = !changes['valueToDate'].currentValue || changes['valueToDate'].currentValue == '' ? null : Number(changes['valueToDate'].currentValue);
        }

        this.checkPeriod();
    }

    /**
     * Инициализация компонента
     * @returns {void}
     */
    ngOnInit(): void {
        this.datePickerSubscription();
    }

    /**
     * Уничтожение компонента
     * @returns {void}
     */
    ngOnDestroy(): void {
        /** Is required for to avoid cloning subscriptions */
        if (this.documSubscription) {
            this.documSubscription.unsubscribe();
        }
    }

    /**
     * Метод изменения периода
     * @param {DatepickerToggles} toggleButton Выбранный период
     *
     * @returns {void}
     */
    onChangePeriodByToggles(toggleButton: DatepickerToggles): void {
        /** Если период тот же самый выбран выходим из метода */
        if (this.valuePeriod === toggleButton.Period) {
            return;
        }

        /**
         * Проверка периода. Если значение period задано для какого-то конкретного промежутка времени,
         * например today, но дата не совпадает с этим значением(например это происходит, когда выбрать today и
         * оставить сайт открытым на сутки, тогда для приложения дата будет уже yesterday , однако период
         * по прежнему будет называться today) в таких случая устанавливается действительная для выбранного
         * периода дата
         */

        this.dateRanges.setNowDate();
        this.toggles = this.getToggles();

        let period = this.toggles.find(toggle => toggle.Period === toggleButton.Period);

        if (period) {
            if (toggleButton.FromDate !== period.FromDate || toggleButton.ToDate !== period.ToDate) {
                (toggleButton.FromDate as number) = period.FromDate;
                (toggleButton.ToDate as number) = period.ToDate;
            }
        }

        if (toggleButton.FromDate !== period?.FromDate || toggleButton.ToDate !== period?.ToDate) {
            (toggleButton.FromDate as number | null) = period ? period.FromDate : null;
            (toggleButton.ToDate as number | null) = period ? period.ToDate : null;
        }

        this.valuePeriod = toggleButton.Period;
        this.valueFromDate = toggleButton.FromDate;
        this.valueToDate = toggleButton.ToDate;

        this.dateChange.emit({
            period: this.valuePeriod,
            fromDate: this.valueFromDate != null ? (this.valueFromDate as number) : null,
            toDate: this.valueToDate != null ? (this.valueToDate as number) : null
        });
    }

    /**
     *
     * @param {DatepickerToggles} toggleButton
     *
     * @returns {void}
     */
    onChangePeriodByCalendar(date: NgbDate): void {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
            this.toDate = date;
            this.inputDatePicker.close();
            /** Перенос в параметры */
            this.valuePeriod = this.pageText.period;
            this.valueFromDate = this.getTime(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, ZERO, ZERO, ZERO, ZERO));
            this.valueToDate = this.getTime(new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day, 23, 59, ZERO, ZERO));
            /** Проверить период на повторение тоглов */
            if (this.valueFromDate === this.getTime(this.dateRanges.yesterdayStart) && this.valueToDate === this.getTime(this.dateRanges.yesterdayEnd)) {
                this.valuePeriod = this.pageText.yesterday;
            }
            if (this.valueFromDate === this.getTime(this.dateRanges.todayStart) && this.valueToDate === this.getTime(this.dateRanges.todayEnd)) {
                this.valuePeriod = this.pageText.today;
            }
            if (this.valueFromDate === this.getTime(this.dateRanges.thisWeekStart) && this.valueToDate === this.getTime(this.dateRanges.thisWeekEnd)) {
                this.valuePeriod = 'thisWeek';
            }
            if (this.valueFromDate === this.getTime(this.dateRanges.lastWeekStart) && this.valueToDate === this.getTime(this.dateRanges.lastWeekEnd)) {
               this.valuePeriod = 'lastWeek';
            }
            if (this.valueFromDate === this.getTime(this.dateRanges.thisMonthStart) && this.valueToDate === this.getTime(this.dateRanges.thisMonthEnd)) {
                this.valuePeriod = 'thisMonth';
            }
            if (this.valueFromDate === this.getTime(this.dateRanges.lastMonthStart) && this.valueToDate === this.getTime(this.dateRanges.lastMonthEnd)) {
                this.valuePeriod = 'lastMonth';
            }
            if (this.valueFromDate === this.getTime(this.dateRanges.thisYearStart) && this.valueToDate === this.getTime(this.dateRanges.thisYearEnd)) {
                this.valuePeriod = 'thisYear';
            }
            if (this.valueFromDate === this.getTime(this.dateRanges.lastYearStart) && this.valueToDate === this.getTime(this.dateRanges.lastYearEnd)) {
                this.valuePeriod = 'lastYear';
            }

            this.dateChange.emit({
                period: this.valuePeriod,
                fromDate: this.valueFromDate != null ? (this.valueFromDate as number) : null,
                toDate: this.valueToDate != null ? (this.valueToDate as number) : null
            });
            // Выбран один день
        } else if (date.equals(this.fromDate) && !this.toDate) {

            this.toDate = date;
            this.inputDatePicker.close();
            /** Перенос в параметры */
            this.valuePeriod = this.pageText.period;
            this.valueFromDate = this.getTime(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, ZERO, ZERO, ZERO, ZERO));
            this.valueToDate = this.getTime(new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day, 23, 59, ZERO, ZERO));
            /** Проверить период на повторение тоглов */
            if (this.valueFromDate === this.getTime(this.dateRanges.yesterdayStart) && this.valueToDate === this.getTime(this.dateRanges.yesterdayEnd)) {
                this.valuePeriod = this.pageText.yesterday;
            }
            if (this.valueFromDate === this.getTime(this.dateRanges.todayStart) && this.valueToDate === this.getTime(this.dateRanges.todayEnd)) {
                this.valuePeriod = this.pageText.today;
            }

            this.dateChange.emit({
                period: this.valuePeriod,
                fromDate: this.valueFromDate != null ? (this.valueFromDate as number) : null,
                toDate: this.valueToDate != null ? (this.valueToDate as number) : null
            });

        } else {
            this.toDate = null;
            this.fromDate = date;
        }
    }

    /**
     * The method open calendar
     * @reterns void
     */
    onShowCalendar(): void {
        !this.disabled ? this.inputDatePicker.toggle() : null;
    }

    /**
     * Метод сбрасывает дату
     * Если дата установлена дефолтная, то она не сбрасывается
     * @returns {void}
     */
    onResetDate(): void {
        this.resetDate();

        this.dateChange.emit({
            period: this.valuePeriod,
            fromDate: this.valueFromDate != null ? (this.valueFromDate as number) : null,
            toDate: this.valueToDate != null ? (this.valueToDate as number) : null
        });
    }

    /**
     * Метод сбрасывает дату родителем, без редиректа
     * Если дата установлена дефолтная, то она не сбрасывается
     * @returns {void}
     */
    onResetDateByParent(): void {
        this.resetDate();
    }

    isHovered(date: NgbDate): boolean {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate) ? true : false;
    }

    isInside(date: NgbDate): boolean {
        return date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate): boolean {
        return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
    }

    private checkPeriod(): void {
        if (this.valuePeriod && this.valueFromDate && this.valueToDate) {

            /**
             * Проверка периода. Если значение period задано для какого-то конкретного промежутка времени,
             * например today, но дата не совпадает с этим значением(например это происходит, когда выбрать today и
             * оставить сайт открытым на сутки, тогда для приложения дата будет уже yesterday , однако период
             * по прежнему будет называться today) в таких случая устанавливается действительная для выбранного
             * периода дата
             */
             if (this.valuePeriod !== 'period') {
                let period = this.toggles.find(toggle => toggle.Period === this.valuePeriod);

                if (this.valueFromDate !== period?.FromDate || this.valueToDate !== period?.ToDate) {
                    this.valueFromDate = period ? period?.FromDate : null;
                    this.valueToDate = period ? period?.ToDate : null;
                }
            }

            /** Установка даты в календарь и тогглы */
            this.setDateToCalendar();

            // обязательная перезагрузка в случае неверной даты
            this.dateChange.emit({
                period: this.valuePeriod,
                fromDate: this.valueFromDate != null ? (this.valueFromDate as number) : null,
                toDate: this.valueToDate != null ? (this.valueToDate as number) : null
            });

        }
        else if (!this.valuePeriod && !this.valueFromDate && !this.valueToDate && this.enableDefaultPeriod) {
            /** Параметры фильтра, нужны основные: period, dateFrom и dateTo  */
            this.valuePeriod = this._defaultPeriodObj.Period;
            this.valueFromDate = this._defaultPeriodObj.FromDate;
            this.valueToDate = this._defaultPeriodObj.ToDate;

            /** Установка даты в календарь и тогглы */
            this.setDateToCalendar();

            // обязательная перезагрузка в случае обязательного периода
            this.dateChange.emit({
                period: this.valuePeriod,
                fromDate: this.valueFromDate != null ? (this.valueFromDate as number) : null,
                toDate: this.valueToDate != null ? (this.valueToDate as number) : null
            });
        }
    }

    /**
     * Сброс даты
     * @returns {void}
     */
    private resetDate(): void {
        /** Если период уже сброшен на дефолтный, но ничего не делаем */
        if (this.enableDefaultPeriod && this.valuePeriod === DEFAULT_PERIOD && !this.enableResetDefaultPeriod) {
            return;
        }
        /** Если период не сброшен на дефолтный, но дефолтный должен быть, то сбрасываем */
        if (this.enableDefaultPeriod && this.valuePeriod !== DEFAULT_PERIOD && !this.enableResetDefaultPeriod) {
            (this.valuePeriod as string) = this._defaultPeriodObj.Period;
            (this.valueFromDate as number) = this._defaultPeriodObj.FromDate;
            (this.valueToDate as number) = this._defaultPeriodObj.ToDate;
            this.fromDate.year = new Date().getFullYear();
            this.fromDate.month = new Date().getMonth() + 1;
            this.fromDate.day = new Date().getDate();

            this.toDate = new NgbDate(
                new Date().getFullYear(),
                new Date().getMonth() + 1,
                new Date().getDate()
            );
            // return;
        }
        /** Если дефолтный период не установен, сбрасываем все */
        if (!this.enableDefaultPeriod || this.enableResetDefaultPeriod) {
            (this.valuePeriod as string | null) = null;
            (this.valueFromDate as number | null) = null;
            (this.valueToDate as number | null) = null;
            this.fromDate.year = new Date().getFullYear();
            this.fromDate.month = new Date().getMonth() + 1;
            this.fromDate.day = new Date().getDate();

            this.toDate = new NgbDate(
                new Date().getFullYear(),
                new Date().getMonth() + 1,
                new Date().getDate()
            );

        }
    }

    /**
     * Устанавливает дату в календарь и тогглы
     * @returns {void}
     */
    private setDateToCalendar(): void {

        if (this.valueFromDate && this.valueToDate) {
            if (!this.fromDate) {
                this.fromDate = this.calendar.getToday();
            }
            if (!this.toDate) {
                this.toDate = this.calendar.getToday();
            }
            /** Set date and input value */
            this.fromDate.year = new Date((this.valueFromDate as number) * ROUND_TIME).getFullYear();
            this.fromDate.month = new Date((this.valueFromDate as number) * ROUND_TIME).getMonth() + 1;
            this.fromDate.day = new Date((this.valueFromDate as number) * ROUND_TIME).getDate();

            this.toDate.year = new Date((this.valueToDate as number) * ROUND_TIME).getFullYear();
            this.toDate.month = new Date((this.valueToDate as number) * ROUND_TIME).getMonth() + 1;
            this.toDate.day = new Date((this.valueToDate as number) * ROUND_TIME).getDate();
        } else if (this.enableDefaultPeriod && !this.enableResetDefaultPeriod) {
            this.onChangePeriodByToggles(this.setDefaultPeriod(this.defaultPeriod));
        }

        /** Установка тайтла где это требуется (например кнопка сброса) */
        this.setDateTitle();
    }

    /**
     * Document click subscription
     * @returns void
     */
    private datePickerSubscription(): void {
        // close datepricker, if click outside the datepricker area
        this.documSubscription = fromEvent(document, 'click').subscribe(res => {
            const nowDate = new Date();
            // this.inputDatePicker.maxDate = {
            //     year: nowDate.getFullYear(),
            //     month: nowDate.getMonth() + 1,
            //     day: nowDate.getDate()
            // }

            if (this.calendarBlockEnter) {
                const event: any = res;
                if (!event || !event.target) {
                    return;
                }
                const coordX = event.clientX;
                const datepickerInputWidth = event.target.clientWidth;
                coordX > ZERO && coordX < datepickerInputWidth && !this.disabled
                    ? this.inputDatePicker.toggle()
                    : null;
                return;
            }
            if (!this.calendarBlockEnter && this.inputDatePicker) {
                this.inputDatePicker.close();
                // this.calendarClosed.next();
            }
        });
    }

    /**
     * Устанавливает текст для выбранной даты
     * в формате 01.01.2000
     * @returns {void}
     */
    private setDateTitle(): void {
        if (!this.valueFromDate && !this.valueToDate) {
            this.selectedPeriodTitle = DATE_NOT_SELECT;
            return;
        }

        this.selectedPeriodTitle =
            this.valuePeriod === this.pageText.yesterday || this.valuePeriod === this.pageText.today
                ? `${moment.unix((this.valueFromDate as number)).format('DD.MM.YYYY')}`
                    : `${moment.unix((this.valueFromDate as number)).format('DD.MM.YYYY')} - ${moment.unix((this.valueToDate as number)).format('DD.MM.YYYY')}`;
    }

    /**
     * Метод устанавливает деволтное значение для компонента
     * Это значение будет установлено при сбросе фильтра
     * если флаг `enableDefaultPeriod === true`
     * @param {Period} _period Дефолтный период
     *
     * @returns {DatepickerToggles} объект с установленными значениями
     */
    private setDefaultPeriod(_period: Period): DatepickerToggles {
        switch (_period) {
            case this.pageText.yesterday:
                return new DatepickerToggles({
                    Period: _period,
                    FromDate: this.getTime(this.dateRanges.yesterdayStart),
                    ToDate: this.getTime(this.dateRanges.yesterdayEnd)
                });
            case this.pageText.today:
                return new DatepickerToggles({
                    Period: _period,
                    FromDate: this.getTime(this.dateRanges.todayStart),
                    ToDate: this.getTime(this.dateRanges.todayEnd)
                });
            case 'thisWeek':
                return new DatepickerToggles({
                    Period: _period,
                    FromDate: this.getTime(this.dateRanges.thisWeekStart),
                    ToDate: this.getTime(this.dateRanges.thisWeekEnd)
                });
            case 'lastWeek':
                return new DatepickerToggles({
                    Period: _period,
                    FromDate: this.getTime(this.dateRanges.lastWeekStart),
                    ToDate: this.getTime(this.dateRanges.lastWeekEnd)
                });
            case 'thisMonth':
                return new DatepickerToggles({
                    Period: _period,
                    FromDate: this.getTime(this.dateRanges.thisMonthStart),
                    ToDate: this.getTime(this.dateRanges.thisMonthEnd)
                });
            case 'lastMonth':
                return new DatepickerToggles({
                    Period: _period,
                    FromDate: this.getTime(this.dateRanges.lastMonthStart),
                    ToDate: this.getTime(this.dateRanges.lastMonthEnd)
                });
            case 'lastYear':
                return new DatepickerToggles({
                    Period: _period,
                    FromDate: this.getTime(this.dateRanges.lastYearStart),
                    ToDate: this.getTime(this.dateRanges.lastYearEnd)
                });
            case 'thisYear':
                return new DatepickerToggles({
                    Period: _period,
                    FromDate: this.getTime(this.dateRanges.lastYearStart),
                    ToDate: this.getTime(this.dateRanges.lastYearEnd)
                });
            default:
                return new DatepickerToggles({
                    Period: 'thisWeek',
                    FromDate: this.getTime(this.dateRanges.thisWeekStart),
                    ToDate: this.getTime(this.dateRanges.thisWeekEnd)
                });
        }
    }

    /**
     * Возвращает список тоггл кнопок
     * @returns {DatepickerToggles[]}
     */
    private getToggles(): DatepickerToggles[] {
        return [
            new DatepickerToggles({
                Period: this.pageText.yesterday,
                FromDate: this.getTime(this.dateRanges.yesterdayStart),
                ToDate: this.getTime(this.dateRanges.yesterdayEnd)
            }),
            new DatepickerToggles({
                Period: this.pageText.today,
                FromDate: this.getTime(this.dateRanges.todayStart),
                ToDate: this.getTime(this.dateRanges.todayEnd)
            }),
            new DatepickerToggles({
                Period: 'lastWeek',
                FromDate: this.getTime(this.dateRanges.lastWeekStart),
                ToDate: this.getTime(this.dateRanges.lastWeekEnd)
            }),
            new DatepickerToggles({
                Period: 'thisWeek',
                FromDate: this.getTime(this.dateRanges.thisWeekStart),
                ToDate: this.getTime(this.dateRanges.thisWeekEnd)
            }),
            new DatepickerToggles({
                Period: 'lastMonth',
                FromDate: this.getTime(this.dateRanges.lastMonthStart),
                ToDate: this.getTime(this.dateRanges.lastMonthEnd)
            }),
            new DatepickerToggles({
                Period: 'thisMonth',
                FromDate: this.getTime(this.dateRanges.thisMonthStart),
                ToDate: this.getTime(this.dateRanges.thisMonthEnd)
            }),
            new DatepickerToggles({
                Period: 'lastYear',
                FromDate: this.getTime(this.dateRanges.lastYearStart),
                ToDate: this.getTime(this.dateRanges.lastYearEnd)
            }),
            new DatepickerToggles({
                Period: 'thisYear',
                FromDate: this.getTime(this.dateRanges.thisYearStart),
                ToDate: this.getTime(this.dateRanges.thisYearEnd)
            }),
        ]
    }

    /**
     * Метод получения числового формата даты
     * @param {Date} _date Параметры из url
     *
     * @returns {number} дата в формате числа
     */
    private getTime(_date: Date): number {
        return Math.round(_date.getTime() / ROUND_TIME);
    }
}
