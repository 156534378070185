import { Breadcrumbs } from '../contracts';
import { routeMap } from '../route-map';
import { MatchingAction } from '../rules/shared/matching-action';
import { MatchingMode } from '../rules/shared/matching-mode';
import { SourceArticles } from '../rules/shared/source-articles';
import { ContextMenuItem } from '../shared/components/app-menu/shared/context-menu-item';
import { dashboardIcon } from '../svg/icons/dashboard';
import { expandLinkIcon } from '../svg/icons/expand-link';
import { groupIcon } from '../svg/icons/group';
import { settingsIcon } from '../svg/icons/settings';
import { feedIcon } from '../svg/icons/feed';
import { tagIcon } from '../svg/icons/tag';
import { competitorsSearchQueriesIconIcon } from '../svg/icons/competitors-search-queries-icon';
import { RateType } from '../strategies/shared/strategy.model';

export class AppText {
    static unknownValue = '???';
    static getDisplayValue(value: string | number | null | undefined): string {
        return (value != '' && value != null && value != undefined) || value + '' === '0' ? value + '' : AppText.unknownValue;
    }
    // User Roles
    static userRoleSystemAdministrator = $localize`:@@user.role.is.system.administrator:System Administrator`;
    static userRoleUser = $localize`:@@user.role.is.user:User`;
    // Menu
    static mainMenuItemIsDashboard = $localize`:@@main.menu.item.is.dashboard:Dashboard`;
    static mainMenuItemIsArticleGroups = $localize`:@@main.menu.item.is.article.groups:Article Groups`;
    static mainMenuItemIsArticles = $localize`:@@main.menu.item.is.articles:Articles`;
    static mainMenuItemIsArticleDataBase = $localize`:@@main.menu.item.is.article.database:Article database`;
    static mainMenuItemIsTagAssignmentRules = $localize`:@@main.menu.item.is.tag.assignment.rules:Tags`;
    static mainMenuItemIsStrategies = $localize`:@@main.menu.item.is.strategies:Strategies`;
    static mainMenuItemIsCompetitorRank = $localize`:@@main.menu.item.is.competitor.rank:Competitor Rank`;
    static mainMenuItemIsTagsDocumentation = $localize`:@@main.menu.item.is.tags.documentation:Tags Documentation`;
    static mainMenuItemIsCompetitors = $localize`:@@main.menu.item.is.competitors:Competitors`;
    static mainMenuItemIsLogout = $localize`:@@main.menu.item.is.logout:Logout`;
    static mainMenuItemIsFeedSettings = $localize`:@@main.menu.item.is.feed.settings:Feed Settings`;
    static mainMenuItemIsPlatformAccounts = $localize`:@@main.menu.item.is.platform.accounts:Platform Accounts`;
    // Articles
    static pageHeaderIsAddNewCompetitors = $localize`:@@page.header.is.add.new.competitors:Add New Competitors`;
    static pageHeaderIsEditArticle = $localize`:@@page.header.is.edit.article:Edit Article`;
    static pageHeaderIsNewArticle = $localize`:@@page.header.is.new.article:New Article`;
    static pageHeaderIsOneArticle = $localize`:@@page.header.is.one.article:Article`;
    static pageHeaderIsOneArticleDetails = $localize`:@@page.header.is.one.article.details:Article details`;
    static pageHeaderIsManualCompetitorsSearch = $localize`:@@page.header.is.manual.competitors.search:Manual Competitors Search`;
    static dialogHeaderIsEditTags = $localize`:@@dialog.header.is.edit.tags:Edit Tags`;
    static dialogHeaderIsArticleMove = $localize`:@@dialog.header.is.article.move:Article Move`;
    static dialogHeaderIsApplyStrategy = $localize`:@@dialog.header.is.apply.strategy:Apply Strategy`;
    static dialogHeaderIsArticleImport = $localize`:@@dialog.header.is.article.import:Article Import`;
    // Competitors
    static pageHeaderIsCompetitors = $localize`:@@page.header.is.competitors:Competitors`;
    static pageHeaderIsEditCompetitor = $localize`:@@page.header.is.edit.competitor:Edit Competitor`;
    static pageHeaderIsNewCompetitor = $localize`:@@page.header.is.new.competitor:New Competitor`;
    static pageHeaderIsOneCompetitor = $localize`:@@page.header.is.one.competitor:Competitor`;
    // Article Groups
    static pageHeaderIsEditArticleGroup = $localize`:@@page.header.is.edit.article.group:Edit Article Group`;
    static pageHeaderIsNewArticleGroup = $localize`:@@page.header.is.new.article.group:New Article Group`;
    static pageHeaderIsOneArticleGroup = $localize`:@@page.header.is.one.article.group:Article Group`;
    static pageSubheaderIsNewGroup = $localize`:@@page.subheader.is.new.group:New Group`;
    static pageHeaderIsOneGroup = $localize`:@@page.header.is.one.group:Group`;
    static pageSubheaderIsArticleGroupCompetitorsSearchRules = $localize`:@@page.subheader.is.article.group.competitors.search.rules:Competitors search rules`;
    static pageSubheaderIsArticleGroupPtCompetitorsSearchQueries = $localize`:@@page.subheader.is.article.group.pt.competitors.search.queries:PT competitors search queries`;
    static pageSubheaderIsArticleGroupSettings = $localize`:@@page.subheader.is.article.group.settings:Group settings`;
    static pageSubheaderIsArticleGroupAnalytics = $localize`:@@page.subheader.is.article.group.analytics:Analytics`;
    static pageHeaderIsArticleGroupQueries = $localize`:@@page.header.is.article.group.queries:Queries`;
    static pageHeaderIsArticleGroupEditQuery = $localize`:@@page.header.is.article.group.edit.query:Edit Query`;
    static pageHeaderIsArticleGroupNewQuery = $localize`:@@page.header.is.article.group.new.query:New Query`;
    static pageHeaderIsOneQuery = $localize`:@@page.header.is.one.query:Query`;
    // Rules
    static pageHeaderIsRules = $localize`:@@page.header.is.rules:Rules`;
    static pageHeaderIsCopyRules = $localize`:@@page.header.is.copy.rules:Copy Rules`;
    static pageHeaderIsEditRule = $localize`:@@page.header.is.edit.rule:Edit Rule`;
    static pageHeaderIsNewRule = $localize`:@@page.header.is.new.rule:New Rule`;
    static pageHeaderIsOneRule = $localize`:@@page.header.is.one.rule:Rule`;
    // Article Group Validation
    static pageHeaderIsArticleGroupValidation = $localize`:@@page.header.is.article.group.validation:Article Group Validation`;
    // Tag Assignment Rules
    static pageHeaderIsTagAssignmentRules = $localize`:@@page.header.is.tag.assignment.rules:Tags`;
    static pageHeaderIsEditTagAssignmentRule = $localize`:@@page.header.is.edit.tag.assignment.rule:Edit Rule`;
    static pageHeaderIsNewTagAssignmentRule = $localize`:@@page.header.is.new.tag.assignment.rule:New Rule`;
    static pageHeaderIsOneTag = $localize`:@@page.header.is.one.tag:Tag`;
    // Strategies
    static pageHeaderIsEditStrategy = $localize`:@@page.header.is.edit.strategy:Edit Strategy`;
    static pageHeaderIsNewStrategy = $localize`:@@page.header.is.new.strategy:New Strategy`;
    static pageHeaderIsOneStrategy = $localize`:@@page.header.is.one.strategy:Strategy`;
    // Platform Accounts`;
    static pageHeaderIsEditPlatformAccount = $localize`:@@page.header.is.edit.platform.account:Edit Platform Account`;
    static pageHeaderIsNewPlatformAccount = $localize`:@@page.header.is.new.platform.account:New Platform Account`;
    static pageHeaderIsAddPlatformAccount = $localize`:@@page.header.is.add.platform.account:Add Account`;
    static pageHeaderIsOnePlatformAccount = $localize`:@@page.header.is.one.platform.account:Account`;
    // Potential Competitors
    static pageHeaderIsPotentialCompetitors = $localize`:@@page.header.is.potential.competitors:Potential Competitors`;
    // Account / Profile
    static pageHeaderIsEditAccount = $localize`:@@page.header.is.edit.account:Edit Account`;
    static dialogHeaderIsChangeAccountPassword = $localize`:@@dialog.header.is.change.account.password:Change Password`;
    // GENERAL
    static dialogHeaderIsConfirmation = $localize`:@@dialog.header.is.confirmation:Confirmation`;
    // Entities
    static entityIsArticles = $localize`:@@entity.is.articles:Articles`;
    static entityIsOneArticle = $localize`:@@entity.is.one.article:Article`;
    static entityIsArticleGroups = $localize`:@@entity.is.article.groups:Article Groups`;
    static entityIsOneArticleGroup = $localize`:@@entity.is.one.article.group:Article Group`;
    static entityIsStrategies = $localize`:@@entity.is.strategies:Strategies`;
    static entityIsOneStrategy = $localize`:@@entity.is.one.strategy:Strategy`;
    static entityIsRules = $localize`:@@entity.is.rules:Rules`;
    static entityIsOneRule = $localize`:@@entity.is.one.rule:Rule`;
    static entityIsQueries = $localize`:@@entity.is.queries:Queries`;
    static entityIsOneQuery = $localize`:@@entity.is.one.query:Query`;
    static entityIsTags = $localize`:@@entity.is.tags:Tags`;
    static entityIsOneTag = $localize`:@@entity.is.one.tag:Tag`;
    static entityIsCompetitors = $localize`:@@entity.is.competitors:Competitors`;
    static entityIsOneCompetitor = $localize`:@@entity.is.one.competitor:Competitor`;
    static entityIsPotentialCompetitors = $localize`:@@entity.is.potential.competitors:Potential Competitors`;
    static entityIsOnePotentialCompetitor = $localize`:@@entity.is.one.potential.competitor:Potential Competitor`;
    static entityIsCars = $localize`:@@entity.is.cars:Cars`;
    static entityIsOneCar = $localize`:@@entity.is.one.car:Car`;
    static entityIsAccounts = $localize`:@@entity.is.accounts:Accounts`;
    static entityIsOneAccount = $localize`:@@entity.is.one.account:Account`;
    static entityIsProfiles = $localize`:@@entity.is.profiles:Profiles`;
    static entityIsOneProfile = $localize`:@@entity.is.one.profile:Profile`;
    // Sort Fields
    static sortFieldIsCreationDate = $localize`:@@sort.field.is.creation.date:Creation Date`;
    static sortFieldIsUpdateDate = $localize`:@@sort.field.is.update.date:Update Date`;
    static sortFieldIsStrategyName = $localize`:@@sort.field.is.strategy.name:Strategy Name`;
    static sortFieldIsGroupName = $localize`:@@sort.field.is.group.name:Group Name`;
    static sortFieldIsRuleName = $localize`:@@sort.field.is.rule.name:Rule Name`;
    static sortFieldIsArticleName = $localize`:@@sort.field.is.article.name:Article Name`;
    static sortFieldIsCompetitorName = $localize`:@@sort.field.is.competitor.name:Competitor Name`;
    static sortFieldIsArticleGroupName = $localize`:@@sort.field.is.article.group.name:Article Group Name`;
    static sortFieldIsTagName = $localize`:@@sort.field.is.tag.name:Tag Name`;
    static sortFieldIsProductId = $localize`:@@sort.field.is.product.id:Product ID`;
    static sortFieldIsListerId = $localize`:@@sort.field.is.lister.id:Lister ID`;
    static sortFieldIsValue = $localize`:@@sort.field.is.value:Value`;
    static sortFieldIsCompetitivePrice = $localize`:@@sort.field.is.competitive.price:Competitive Price`;
    static sortFieldIsMaxCompetitorPrice = $localize`:@@sort.field.is.max.competitor.price:Max Competitor Price`;
    static sortFieldIsMinCompetitorPrice = $localize`:@@sort.field.is.min.competitor.price:Min Competitor Price`;
    static sortFieldIsTotalActualCompetitors = $localize`:@@sort.field.is.total.actual.competitors:Total Actual Competitors`;
    static sortFieldIsTotalCompetitorsSold = $localize`:@@sort.field.is.total.competitors.sold:Total Competitors Sold`;
    static sortFieldIsLastArticlesUpdate = $localize`:@@sort.field.is.last.articles.update:Last Articles Update`;
    static sortFieldIsLastCompetitorsUpdate = $localize`:@@sort.field.is.last.competitors.update:Last Competitors Update`;
    static sortFieldIsLastCompetitorsSearch = $localize`:@@sort.field.is.last.competitors.search:Last Competitors Search`;
    static sortFieldIsArticleGroup = $localize`:@@sort.field.is.article.group:Article Group`;
    static sortFieldIsAdRateType = $localize`:@@sort.field.is.ad.rate.type:Ad Rate Type`;
    static sortFieldIsMarketplaceRank = $localize`:@@sort.field.is.marketplace.rank:Marketplace Rank`;
    static sortFieldIsVisibilityRank = $localize`:@@sort.field.is.visibility.rank:Visibility Rank`;
    static sortFieldIsPriceDifference = $localize`:@@sort.field.is.price.difference:Price Difference (%)`;
    static sortFieldIsSoldLast7Days = $localize`:@@sort.field.is.sold.last.7.days:Sold (Last 7 Days)`;
    static sortFieldIsSearchPosition = $localize`:@@sort.field.is.search.position:Search Position`;
    static sortFieldIsTotalPrice = $localize`:@@sort.field.is.total.price:Total Price`;
    static sortFieldIsValidationStatus = $localize`:@@sort.field.is.validation.status:Validation Status`;
    static sortFieldIsIsCompetitor = $localize`:@@sort.field.is.is.competitor:Is Competitor`;
    static sortFieldIsTitle = $localize`:@@sort.field.is.title:Title`;
    static sortFieldIsActive = $localize`:@@sort.field.is.active:Active`;
    static sortFieldIsExcluded = $localize`:@@sort.field.is.excluded:Excluded`;
    static sortFieldIsBoosted = $localize`:@@sort.field.is.boosted:Boosted`;
    static sortFieldIsNotBoosted = $localize`:@@sort.field.is.not.boosted:Not Boosted`;
    static sortFieldIsContent = $localize`:@@sort.field.is.content:Content`;
    static sortFieldIsName = $localize`:@@sort.field.is.name:Name`;
    // Buttons
    static applyAction = $localize`:@@button.placeholder.apply:Apply`;
    static removeAction = $localize`:@@button.placeholder.remove:Remove`;
    static deleteAction = $localize`:@@button.placeholder.delete:Delete`;
    static resetAction = $localize`:@@button.placeholder.reset:Reset`;
    static cancelAction = $localize`:@@button.placeholder.cancel:Cancel`;
    static backAction = $localize`:@@button.placeholder.back:Back`;
    static closeAction = $localize`:@@button.placeholder.close:Close`;
    static saveAction = $localize`:@@button.placeholder.save:Save`;
    static okAction = $localize`:@@button.placeholder.ok:Ok`;
    static yesAction = $localize`:@@button.placeholder.yes:Yes`;
    static skipAction = $localize`:@@button.placeholder.skip:Skip`;
    static noAction = $localize`:@@button.placeholder.no:No`;
    static switchAction = $localize`:@@button.placeholder.switch:Switch`;
    static onAction = $localize`:@@button.placeholder.switch.on:On`;
    static offAction = $localize`:@@button.placeholder.switch.off:Off`;
    static importAction = $localize`:@@button.placeholder.import:Import`;
    static exportAction = $localize`:@@button.placeholder.export:Export`;
    static searchAction = $localize`:@@button.placeholder.search:Search`;
    static activeAction = $localize`:@@button.placeholder.active:Active`;
    static inactiveAction = $localize`:@@button.placeholder.inactive:Inactive`;
    static enableAction = $localize`:@@button.placeholder.enable:Enable`;
    static disableAction = $localize`:@@button.placeholder.disable:Disable`;
    static updateAction = $localize`:@@button.placeholder.update:Update`;
    static createAction = $localize`:@@button.placeholder.create:Create`;
    static selectDateAction = $localize`:@@button.placeholder.select.date:Select date`;
    static copyAction = $localize`:@@button.placeholder.copy:Copy`;
    static addAction = $localize`:@@button.placeholder.add:Add`;
    static editAction = $localize`:@@button.placeholder.edit:Edit`;
    static moveAction = $localize`:@@button.placeholder.move:Move`;
    static selectAllAction = $localize`:@@button.placeholder.select.all:Select All`;
    static showAction = $localize`:@@button.placeholder.show:Show`;
    static showAllAction = $localize`:@@button.placeholder.show.all:Show All`;
    static hideAction = $localize`:@@button.placeholder.hide:Hide`;
    static showOnlyDiffAction = $localize`:@@button.placeholder.show.only.diff:Show only Diff`;
    static doneAction = $localize`:@@button.placeholder.done:Done`;
    static syncAction = $localize`:@@button.placeholder.sync:Sync`;
    // General
    static generalTextIsPlaceholder = $localize`:@@general.text.is.placeholder:Placeholder`;
    static generalTextIsLabel = $localize`:@@general.text.is.label:Label`;
    static generalTextIsActions = $localize`:@@general.text.is.actions:Actions`;
    static generalTextIsInProgress = $localize`:@@general.text.is.in.progress:In Progress...`;
    static generalTextIsCompleted = $localize`:@@general.text.is.completed:Completed`;
    static generalTextIsThePasswordsDonTMatch = $localize`:@@general.text.is.the.passwords.do.not.match:The passwords don\'t match`;
    static generalTextIsOr = $localize`:@@general.text.is.or:Or`;
    static generalTextIsAuto = $localize`:@@general.text.is.auto:auto`;
    static generalTextIsUpdatedRecently = $localize`:@@general.text.is.updated.recently:updated recently`;
    static generalTextIsSettings = $localize`:@@general.text.is.settings:Settings`;
    static generalTextIsGeneralSettings = $localize`:@@general.text.is.general.settings:General Settings`;
    static generalTextIsGeneral = $localize`:@@general.text.is.general:General`;
    static generalTextIsActivityHistory = $localize`:@@general.text.is.activity.history:Activity history`;
    static generalTextIsImportMaximumItems = $localize`:@@general.text.is.import.max.25000.items:You can export a maximum of 25 000 items`;
    static generalTextIsMarkAs = $localize`:@@general.text.is.mark.as:Mark As`;
    static generalTextIsCompetitor = $localize`:@@general.text.is.competitor:Competitor`;
    static generalTextIsNotCompetitor = $localize`:@@general.text.is.not.competitor:Not Competitor`;
    static generalTextIsNotACompetitor = $localize`:@@general.text.is.not.a.competitor:Not a Competitor`;
    static generalTextIsTools = $localize`:@@general.text.is.tools:Tools`;
    static generalTextIsToolsManageTags = $localize`:@@general.text.is.tools.manage.tags:Manage tags`;
    static generalTextIsToolsMoveToGroup = $localize`:@@general.text.is.tools.move.to.target.group:Move to target group`;
    static generalTextIsToolsApplyStrategy = $localize`:@@general.text.is.tools.apply.strategy:Apply Strategy`;
    static generalTextIsEnabled = $localize`:@@general.text.is.enabled:Enabled`;
    static generalTextIsDisabled = $localize`:@@general.text.is.disabled:Disabled`;
    static generalTextIsTotal = $localize`:@@general.text.is.total:Total`;
    static generalTextIsGeneralInformation = $localize`:@@general.text.is.general.information:General Information`;
    static generalTextIsShowGeneralInformation = $localize`:@@general.text.is.show.general.information:Show General Information`;
    static generalTextIsHideGeneralInformation = $localize`:@@general.text.is.hide.general.information:Hide General Information`;
    static generalTextIsSearchInProgress = $localize`:@@general.text.is.search.in.progress:Search in progress...`;
    static generalTextIsNoFilters = $localize`:@@general.text.is.no.filters:No Filters`;
    static generalTextIsFilters = $localize`:@@general.text.is.filters:Filters`;
    static generalTextIsFilter = $localize`:@@general.text.is.filter:Filter`;
    static generalTextIsPlatformFilters = $localize`:@@general.text.is.platform.filters:Platform Filters`;
    static generalTextIsFeatures = $localize`:@@general.text.is.features:Features`;
    static generalTextIsFrom = $localize`:@@general.text.is.from:From`;
    static generalTextIsTo = $localize`:@@general.text.is.to:To`;
    static generalTextIsFound = $localize`:@@general.text.is.found:Found`;
    static generalTextIsNotFound = $localize`:@@general.text.is.not.found:Not Found`;
    static generalTextIsValidated = $localize`:@@general.text.is.validated:Validated`;
    static generalTextIsNotValidated = $localize`:@@general.text.is.not.validated:Not Validated`;
    static generalTextIsVariant = $localize`:@@general.text.is.variant:Variant`;
    static generalTextIsIsVariant = $localize`:@@general.text.is.is.variant:Is Variant`;
    static generalTextIsNotVariant = $localize`:@@general.text.is.not.variant:Not Variant`;
    static generalTextIsIsFeed = $localize`:@@general.text.is.is.feed:Is Feed`;
    static generalTextIsNotFeed = $localize`:@@general.text.is.not.feed:Not Feed`;
    static generalTextIsMatch = $localize`:@@general.text.is.match:Match`;
    static generalTextIsNotMatch = $localize`:@@general.text.is.not.match:Not Match`;
    static generalTextIsIsMatch = $localize`:@@general.text.is.is.match:Is Match`;
    static generalTextIsIsMismatch = $localize`:@@general.text.is.is.mismatch:Is Mismatch`;
    static generalTextIsFullMatch = $localize`:@@general.text.is.full.match:Full Match`;
    static generalTextIsOnMatch = $localize`:@@general.text.is.on.match:On Match`;
    static generalTextIsOnMismatch = $localize`:@@general.text.is.on.mismatch:On Mismatch`;
    static generalTextIsExcluded = $localize`:@@general.text.is.excluded:Excluded`;
    static generalTextIsIncluded = $localize`:@@general.text.is.included:Included`;
    static generalTextIsUpdatedByAdministrator = $localize`:@@general.text.is.updated.by.administrator:Updated by Administrator`;
    static generalTextIsSorryYourFilterParamsDidNotMatchAnyResultPleaseChangeIt = $localize`:@@general.text.is.sorry.your.filter.params.did.not.match.any.result.please.change.it.and.try.again:Sorry, your filter params did not match any result. Please change it and try again.`;
    static generalTextIsSorryYourFilterParamsDidNotMatchAnyResult = $localize`:@@general.text.is.sorry.your.filter.params.did.not.match.any.result:Sorry, your filter params did not match any result.`;
    static generalTextIsSorryPleaseChangeIt = $localize`:@@general.text.is.please.change.it.and.try.again:Please change it and try again.`;
    static generalTextIsMarkAsACompetitor = $localize`:@@general.text.is.mark.as.a.competitor:Mark as a competitor`;
    static generalTextIsMarkAsNotACompetitor = $localize`:@@general.text.is.mark.as.not.a.competitor:Mark as Not a competitor`;
    static generalTextIsAssignCompetitor = $localize`:@@general.text.is.assign.competitor:Assign a Competitor`;
    static generalTextIsAssignNotACompetitor = $localize`:@@general.text.is.assign.not.a.competitor:Assign Not a Competitor`;
    static generalTextIsIncreaseConfidence = $localize`:@@general.text.is.increase.confidence:Increase Confidence`;
    static generalTextIsDecreaseConfidence = $localize`:@@general.text.is.decrease.confidence:Decrease Confidence`;
    static generalTextIsNone = $localize`:@@general.text.is.none:None`;
    static generalTextIsTargetContainsSource = $localize`:@@general.text.is.target.contains.source:Target Contains Source`;
    static generalTextIsAnyContainsAny = $localize`:@@general.text.is.any.contains.any:Any Contains Any`;
    static generalTextIsDecisive = $localize`:@@general.text.is.decisive:Decisive`;
    static generalTextIsSetAndSubArticles = $localize`:@@general.text.is.set.and.sub.articles:Set And Sub Articles`;
    static generalTextIsSetArticle = $localize`:@@general.text.is.set.article:Set Article`;
    static generalTextIsSubArticles = $localize`:@@general.text.is.sub.articles:Sub Articles`;
    static generalTextIsName = 'name'; // не переводить
    static generalTextIsSearchPlaceholder = $localize`:@@general.text.is.search.placeholder:Search...`;
    static generalTextIsAll = $localize`:@@general.text.is.all:All`;
    static generalTextIsIndex = $localize`:@@general.text.is.index:Index`;
    static generalTextIsBecause = $localize`:@@general.text.is.because:Because`;
    static generalTextIsPeriod = $localize`:@@general.text.is.period:period`;
    static generalTextIsToday = $localize`:@@general.text.is.today:today`;
    static generalTextIsYesterday = $localize`:@@general.text.is.yesterday:yesterday`;
    static generalTextIsThisWeek = $localize`:@@general.text.is.this.week:this week`;
    static generalTextIsLastWeek = $localize`:@@general.text.is.last.week:last week`;
    static generalTextIstThisMonth = $localize`:@@general.text.is.this.month:this month`;
    static generalTextIsLastMonth = $localize`:@@general.text.is.last.month:last month`;
    static generalTextIsFileUploading = $localize`:@@general.text.is.file.uploading:File uploading`;
    static generalTextIsOffers = $localize`:@@general.text.is.offers:offers`;
    static generalTextIsFindMatchingRules = $localize`:@@general.text.is.find.matching.rules:Find Matching Rules`;
    static generalTextIsFindArticleGroups = $localize`:@@general.text.is.find.article.groups:Find Article Groups`;
    static generalTextIsFindStrategies = $localize`:@@general.text.is.find.strategies:Find Strategies`;
    static generalTextIsFindSearchRequestTemplates = $localize`:@@general.text.is.find.search.request.templates:Find Queries`;
    static generalTextIsFindItems = $localize`:@@general.text.is.find.items:Find Items`;
    static generalTextIsFindRecordByContent = $localize`:@@general.text.is.find.record.by.content:Find record by content`;
    static generalTextIsFindPlatformAccounts = $localize`:@@general.text.is.find.platform.accounts:Find Platform Accounts`;
    static generalTextIsDisplayOptions = $localize`:@@general.text.is.display.options:Display options`;
    static generalTextIsArticlesOnRepricing = $localize`:@@general.text.is.articles.on.repricing:Articles on repricing`;
    static generalTextIsCompetitorsOnRepricing = $localize`:@@general.text.is.competitors.on.repricing:Competitors On Repricing`;
    static generalTextIsStatusOnRepricing = $localize`:@@general.text.is.status.on.repricing:On Repricing`;
    static generalTextIsManualGroup = $localize`:@@general.text.is.manual.group:Manual group`;
    static generalTextIsAllowRepricing = $localize`:@@general.text.is.allow.repricing:Allow repricing`;
    static generalTextIsIn = $localize`:@@general.text.is.in:In`;
    static generalTextIsPc = $localize`:@@general.text.is.pc:Pc`;
    static generalTextIsRepricing = $localize`:@@general.text.is.repricing:Repricing`;
    static generalTextIsBoosting = $localize`:@@general.text.is.boosting:Boosting`;
    static generalTextIsManuallyCreatedRequests = $localize`:@@general.text.is.manually.created.requests:Manually Created Requests`;
    static generalTextIsValidationMatchesRules = $localize`:@@general.text.is.filter.by.validation.matches.rules:Validation Matches Rules`;
    static generalTextIsCompetitorCars = $localize`:@@general.text.is.competitor.cars.title:Compatible cars`;
    static generalTextIsCompetitorCarComparedTotal = $localize`:@@general.text.is.competitor.cars.compared.total:Compared`;
    static generalTextIsCompetitorCarPercentOfCompared = $localize`:@@general.text.is.competitor.cars.percent.of.compared:of Compared`;
    static generalTextIsCompetitorCarMatchedTotal = $localize`:@@general.text.is.competitor.cars.matched.total:Matched`;
    static generalTextIsItem = $localize`:@@general.text.is.item:item`;
    static generalTextIsItems = $localize`:@@general.text.is.items:items`;
    static generalTextIsFixedRate = $localize`:@@general.text.is.fixed.rate:Fixed`;
    static generalTextIsDynamicRate = $localize`:@@general.text.is.dynamic.rate:Dynamic`;
    static generalTextIsHotkey = $localize`:@@general.text.is.hotkey:Hotkey`;
    static generalTextIsBackspaceHotkey = $localize`:@@general.text.is.backspace.hotkey:Backspace`;
    static generalTextIsSpaceHotkey = $localize`:@@general.text.is.space.hotkey:Space`;
    static generalTextIsYHotkey = $localize`:@@general.text.is.y.hotkey:Y`;
    static generalTextIsNHotkey = $localize`:@@general.text.is.n.hotkey:N`;
    static generalTextIsSimilarity = $localize`:@@general.text.is.similarity:Similarity`;
    static generalTextIsAdvertising = $localize`:@@general.text.is.advertising:Advertising`;
    static generalTextIsStatus = $localize`:@@general.text.is.status:Status`;
    static generalTextIsConfidence = $localize`:@@general.text.is.confidence:Confidence`;
    static generalTextIsNoManualSearchRequests = $localize`:@@general.text.is.no.manual.search.requests:No Manual Search Requests`;
    static generalTextIsAgo = $localize`:@@general.text.is.ago:ago`;
    static generalTextIsBy = $localize`:@@general.text.is.by:by`;
    static generalTextIsIsRequired = $localize`:@@general.text.is.is.required:is required`;
    static generalTextIsWithout = $localize`:@@general.text.is.without:Without`;
    static generalTextIs404NotFound = $localize`:@@general.text.is.404.not.found:404 - Not Found`;
    static generalTextIsTryOnePageInTheMenu = $localize`:@@general.text.is.try.one.page.in.the.menu:Try one page in the menu`;
    static generalTextIsTargetArticleGroup = $localize`:@@general.text.is.target.article.group:Target article group`;
    static generalTextIsDays = $localize`:@@general.text.is.days:Days`;
    static generalTextIsMin = $localize`:@@general.text.is.min:Min`;
    static generalTextIsMax = $localize`:@@general.text.is.max:Max`;
    static generalTextIsStats = $localize`:@@general.text.is.stats:Stats`;
    static generalTextIsIncl = $localize`:@@general.text.is.incl:incl`;
    static generalTextIsPlatform = $localize`:@@general.text.is.platform:Platform`;
    static generalTextIsEbay = $localize`:@@general.text.is.ebay:Ebay`;
    static generalTextIsAmazon = $localize`:@@general.text.is.amazon:Amazon`;
    static generalTextIsEts = $localize`:@@general.text.is.ets:ETS`;
    static generalTextIsCompetitivePriceWithBidPercentage = $localize`:@@general.text.is.the.competitive.price.with.an.bid.percentage:Competitive Price (with Ad)`;
    static generalTextIsReleaseDate = $localize`:@@general.text.is.release.date:Release date`;

    // Filters
    static generalTextIsSelectCar = $localize`:@@general.text.is.select.car:Select Car`;
    static generalTextIsPriceFrom = $localize`:@@general.text.is.price.from:Price From`;
    static generalTextIsPriceTo = $localize`:@@general.text.is.price.to:Price To`;
    static generalTextIsConfidenceFrom = $localize`:@@general.text.is.confidence.from:Confidence From`;
    static generalTextIsConfidenceTo = $localize`:@@general.text.is.confidence.to:Confidence To`;
    static generalTextIsMarketplaceRankFrom = $localize`:@@general.text.is.marketplace.rank.from:Marketplace Rank From`;
    static generalTextIsMarketplaceRankTo = $localize`:@@general.text.is.marketplace.rank.to:Marketplace Rank To`;
    static generalTextIsVisibilityRankFrom = $localize`:@@general.text.is.visibility.rank.from:Visibility Rank From`;
    static generalTextIsVisibilityRankTo = $localize`:@@general.text.is.visibility.rank.to:Visibility Rank To`;
    static generalTextIsHsn = $localize`:@@general.text.is.hsn:HSN`;
    static generalTextIsTsn = $localize`:@@general.text.is.tsn:TSN`;
    static generalTextIsPriceDifferencePercentFrom = $localize`:@@general.text.is.price.difference.percent.from:Price Difference(%) From`;
    static generalTextIsPriceDifferencePercentTo = $localize`:@@general.text.is.price.difference.percent.to:Price Difference(%) To`;
    static generalTextIsFindTagsToAdd = $localize`:@@general.text.is.find.tags.to.add:Find tags to add`;
    static generalTextIsFindTagsInSelectedArticles = $localize`:@@general.text.is.find.tags.in.selected.articles:Find tags in selected articles`;
    static generalTextIsThePasswordIsHiddenForSecurityReason = $localize`:@@general.text.is.the.password.is.hidden.for.security.reasons:The password is hidden for security reasons`;
    // Fields
    static propertyPlaceholderPlatformId = $localize`:@@property.placeholder.platform.id:Platform ID`;
    static propertyPlaceholderEBayId = $localize`:@@property.placeholder.ebay.id:eBay ID`;
    static propertyPlaceholderImage = $localize`:@@property.placeholder.image:Image`;
    static propertyPlaceholderSeller = $localize`:@@property.placeholder.seller:Seller`;
    static propertyPlaceholderPrice = $localize`:@@property.placeholder.price:Price`;
    static propertyPlaceholderDeliveryPrice = $localize`:@@property.placeholder.delivery.price:Delivery Price`;
    static propertyPlaceholderProductPrice = $localize`:@@property.placeholder.product.price:Product Price`;
    static propertyPlaceholderProductRecommendedSalePrice = $localize`:@@property.placeholder.product.recommended.sale.price:Recommended Sale Price`;
    static propertyPlaceholderProductCalculatedPrice = $localize`:@@property.placeholder.product.calculated.price:Calculated Price`;
    static propertyPlaceholderTotalInStock = $localize`:@@property.placeholder.in.stock:In Stock`;
    static propertyPlaceholderTotalStock = $localize`:@@property.placeholder.stock:Stock`;
    static propertyPlaceholderSold = $localize`:@@property.placeholder.sold:Sold`;
    static propertyPlaceholderTopSeller = $localize`:@@property.placeholder.top.seller:Top Seller`;
    static propertyPlaceholderNotActive = $localize`:@@property.placeholder.not.active:Not Active`;
    static propertyPlaceholderAccount = $localize`:@@property.placeholder.account:Account`;
    static propertyPlaceholderSearchQuery = $localize`:@@property.placeholder.search.query:Search Query`;
    static propertyPlaceholderCreatedDate = $localize`:@@property.placeholder.created.date:Created Date`;
    static propertyPlaceholderUpdatedDate = $localize`:@@property.placeholder.updated.date:Updated Date`;
    static propertyPlaceholderUpdatedBy = $localize`:@@property.placeholder.updated.by:Updated By`;
    static propertyPlaceholderIncluded = $localize`:@@property.placeholder.included:Included`;
    static propertyPlaceholderDate = $localize`:@@property.placeholder.date:Date`;
    static propertyPlaceholderVariation = $localize`:@@property.placeholder.variation:Variation`;
    static propertyPlaceholderValidation = $localize`:@@property.placeholder.validation:Validation`;
    static propertyPlaceholderRule = $localize`:@@property.placeholder.rule:Rule`;
    static propertyPlaceholderName = $localize`:@@property.placeholder.name:Name`;
    static propertyPlaceholderValue = $localize`:@@property.placeholder.value:Value`;
    static propertyPlaceholderSellNumber = $localize`:@@property.placeholder.sell.number:Sell Number`;
    static propertyPlaceholderBestSeller = $localize`:@@property.placeholder.best.seller:Best seller`;
    static propertyPlaceholderManualValidation = $localize`:@@property.placeholder.manual.validation:Manual Validation`;
    static propertyPlaceholderCompetitor = $localize`:@@property.placeholder.competitor:Competitor`;
    static propertyPlaceholderInactiveCompetitors = $localize`:@@property.placeholder.inactive.competitors:Inactive Competitors`;
    static propertyPlaceholderMatchingRule = $localize`:@@property.placeholder.matching.rule:Matching Rule`;
    static propertyPlaceholderShowPotentialCompetitors = $localize`:@@property.placeholder.show.potential.competitors:Show Potential Competitors`;
    static propertyPlaceholderShowCompetitors = $localize`:@@property.placeholder.show.competitors:Show Competitors`;
    static propertyPlaceholderCompetition = $localize`:@@property.placeholder.competition:Competition`;
    static propertyPlaceholderArticle = $localize`:@@property.placeholder.article:Article`;
    static propertyPlaceholderArticleTitle = $localize`:@@property.placeholder.article.title:Article title`;
    static propertyPlaceholderArticleAccount = $localize`:@@property.placeholder.article.account:Article account`;
    static propertyPlaceholderPlatformArticleId = $localize`:@@property.placeholder.platform.article.id:Platform Article ID`;
    static propertyPlaceholderArticleImageUri = $localize`:@@property.placeholder.article.image.uri:Article Image URI`;
    static propertyPlaceholderImageUri = $localize`:@@property.placeholder.image.uri:Image URI`;
    static propertyPlaceholderSynonyms = $localize`:@@property.placeholder.synonyms:Synonyms`;
    static propertyPlaceholderRequest = $localize`:@@property.placeholder.request:Request`;
    static propertyPlaceholderShowCompetitorsWithoutSales = $localize`:@@property.placeholder.show.competitors.without.sales:Show competitors without sales`;
    static propertyPlaceholderShowCompetitorsWithLongShippingTimes = $localize`:@@property.placeholder.show.competitors.with.long.shipping.times:Show competitors with long shipping times`;
    static propertyPlaceholderFirstName = $localize`:@@property.placeholder.first.name:First name`;
    static propertyPlaceholderLastName = $localize`:@@property.placeholder.last.name:Last name`;
    static propertyPlaceholderUserName = $localize`:@@property.placeholder.user.name:Username`;
    static propertyPlaceholderUserEmail = $localize`:@@property.placeholder.user.email:Email`;
    static propertyPlaceholderUserPassword = $localize`:@@property.placeholder.user.password:Password`;
    static propertyPlaceholderUserNewPassword = $localize`:@@property.placeholder.user.new.password:New password`;
    static propertyPlaceholderUserReenterPassword = $localize`:@@property.placeholder.user.reenter.password:Re-enter password`;
    static propertyPlaceholderUserPasswordStrength = $localize`:@@property.placeholder.user.password.strength:Password Strength`;
    static propertyPlaceholderCompanyName = $localize`:@@property.placeholder.company:Company name`;
    // static generalTextIsOr = $localize`:@@general.text.is.or:Or`;

    // Field Hints
    static hintMessageIsNameIsRequired = $localize`:@@hint.message.is.name.is.required:Name is required`;
    static hintMessageIsEnterConditionBasedOnArticleData = $localize`:@@hint.message.is.enter.condition.based.on.article.data:Enter condition based on article data (like \"{Ean} != null && {Viskositat} != null\")`;
    static hintMessageIsPatternOfConditionBasedOnArticleData = $localize`:@@hint.message.is.attribute.length.is:Valid attribute length is from 3 to 50. It starts with \"{\" and ends with \"}\"`;
    static hintMessageIsEnterTagAssignment = $localize`:@@hint.message.is.enter.tags.to.assign.separated.by.a.space.you.can.generate.tags.based.on.article.attribute.value:Enter tags to assign separated by a space. You can generate tags based on article attribute value (like #{ean})`;
    static hintMessageIsPatternOfTagAssignment = $localize`:@@hint.message.is.attribute.or.tag.length.is.invalid:Valid tag\'s (and attribute\'s) length is from 3 to 50. Tag starts with \"#\". Attribute starts with \"{\" or \"#{\" and ends with \"}\". For tags only lowercase letters, numbers, and _`;
    static hintMessageIsInvalidTagAssignment = $localize`:@@hint.message.is.enter.tags.separated.by.a.space.required.format:Enter tags separated by a space. Required format: #engine #oil #{viskositat}{farbe}`;
    static hintMessageIsEnterRequestField = $localize`:@@hint.message.is.enter.request.you.can.article.features.by.enter.them.names:Enter request. You can use article features by enter them names. Example: Engine oil {EAN}`;
    static hintMessageIsPatternOfCategories = $localize`:@@hint.message.is.categories.are.separated.by.commas.only.numbers:Categories are separated by commas. Only numbers`;

    static hintMessageIsEnterCategories = $localize`:@@hint.message.is.enter.category.ids.separated.by.commas:Enter category IDs separated by commas`;
    static hintMessageIsPatternOfArticleIds = $localize`:@@hint.message.is.pattern.any.id.must.have:Any ID must have at least 8 numbers (from 8 to 12)`;

    // Account Hints
    static hintMessageIsLengthOfPassword = $localize`:@@hint.message.is.passwords.must.have.at.least.8.characters:Passwords must have at least 8 characters`;
    static hintMessageIsPasswordNotStrongEnough = $localize`:@@hint.message.is.password.is.not.strong.enough:Password is not strong enough`;
    static hintMessageIsPatternOfPassword = $localize`:@@hint.message.is.passwords.must.have.at.least.one.of.the.following:Passwords must have at least 8 characters and contain at least one of the following: upper case letters, lower case letters, numbers and symbols \` ~ ! @ # $ % ^ & * () - _ = + [] {} ; : ' " < > , .`;
    // Strategy Hints
    static hintMessageIsStrategyNameIsRequired = $localize`:@@hint.message.is.strategy.name.is.required:Strategy Name is required`;
    static hintMessageIsAdRateCapIsRequired = $localize`:@@hint.message.is.ad.rate.cap.is.required:Ad Rate Cap is required`;
    static hintMessageIsAdRateAdjustmentIsRequired = $localize`:@@hint.message.is.ad.rate.adjustment.is.required:Ad Rate Adjustment is required`;
    static hintMessageIsAdRateIsRequired = $localize`:@@hint.message.is.ad.rate.is.required:Ad Rate is required`;
    static hintMessageIsEnterTags = $localize`:@@hint.message.is.enter.tags.to.assign.separated.by.a.space.you.can.generate.tags.based.on.article.attribute.value:Enter tags to assign separated by a space. You can generate tags based on article attribute value (like #{ean})`;
    static hintMessageIsEnterRequiredTags = $localize`:@@hint.message.is.required.tags:Enter the tags that the searched articles should have`;
    static hintMessageIsEnterForbiddenTags = $localize`:@@hint.message.is.forbidden.tags:Enter the tags that the searched articles should not have`;
    static hintMessageIsPatternOfTags = $localize`:@@hint.message.is.tags.are.separated.by.spaces.and.symbols:Tags are separated by spaces and symbols |, &, (). Valid tag\'s length is from 3 to 50. Tag starts with \"#\". It contains lowercase letters, numbers, and _`;
    static hintMessageIsInvalidTags = $localize`:@@hint.message.is.tags.can.only.be.separated.by.spaces.or.symbols:Tags can only be separated by spaces or symbols |, &, (). Required format: #engine #oil`;
    static hintMessageIsBoostDurationInDaysIsRequired = $localize`:@@hint.message.is.boost.period.is.required:Boost Period is required`;
    static hintMessageIsBoostDurationInDaysIsInvalid = $localize`:@@hint.message.is.invalid.boost.period:Invalid Boost Period`;

    // Competitors Hints
    static hintMessageIsOnlyNumbersPattern = $localize`:@@hint.message.is.only.numbers:Only numbers`;
    static hintMessageIsOnlyCharactersPattern = $localize`:@@hint.message.is.only.characters.numbers.spaces.and:Only word characters, numbers, spaces and (), -, _`;
    static hintMessageIsOnlyCharactersAndNumbersPattern = $localize`:@@hint.message.is.only.word.characters.and.numbers:Only word characters and numbers`;
    static hintMessageIsOnlyUriPattern = $localize`:@@hint.message.is.only.characters.for.uri:Only characters acceptable for the URI address`;
    static hintMessageIsProductIdIsRequired = $localize`:@@hint.message.is.product.id.is.required:Product ID is required`;
    static hintMessageIsListerIdIsRequired = $localize`:@@hint.message.is.lister.id.is.required:Lister ID is required`;
    // Hash
    static hintMessageIsTagHashIsOptional = $localize`:@@hint.message.is.the.hash.symbol.is.optional: The hash (symbol \"#\") is optional`;
    static hintMessageIsItIsCopiedToClipboard = $localize`:@@hint.message.is.it.is.copied.to.clipboard:It\'s copied to clipboard`;
    // Article's tools dialogs
    static hintMessageIsEnterTag = $localize`:@@hint.message.is.enter.tag:Enter tag`;
    static hintMessageIsBoostInfo(startDate: string, endDate: string) {
        return $localize`:@@hint.message.is.boost.started.on.x.date.and.ends.on.x.date:Boost started on ${startDate} and will end on ${endDate}`;
    }

    static hintMessageIsInvalidEmail = $localize`:@@hint.message.is.invalid.email:Invalid e-mail`;

    // lister edit
    static hintMessageIsEnterAccount = $localize`:@@hint.message.is.enter.article.account:Enter account`;





    // Errors
    // static errorMessageIsNameIsRequired = $localize`:@@error.message.is.name.is.required:Name is required`;


    // static errorMessageIsNameIsOr = $localize`:@@error.message.is.or:Or`;

    static errorMessageTitle = $localize`:@@error.message.title:Error message`;
    static requestFailed = $localize`:@@error.message.request.failed:Request failed`;
    static requestFailedResourceNotFound = $localize`:@@error.message.request.failed.resource.not.found:Resource not found`;
    static requestFailedUnknownError = $localize`:@@error.message.request.failed.unknown.error:An unknown error has occurred`;
    static requestFailedUserNotUnauthorized = $localize`:@@error.message.request.failed.unauthorized:HTTP 401 Unauthorized`;
    static requestFailedRequestForbidden = $localize`:@@error.message.request.failed.forbidden:HTTP 403 Forbidden`;
    static requestFailedWrongLoginOrPassword = $localize`:@@error.message.request.failed.wrong.login.or.password:Wrong login or password`;
    static formFieldInvalidInputValueRequired = $localize`:@@error.message.form.field.invalid.input.value.required:You must enter a value`;
    static formFieldIsRequiredWarnMessage(fieldName: string) {
        return $localize`:@@error.message.form.field.is.required: ${fieldName} is required`;
    }
    static formFieldInvalidInputPattern = $localize`:@@error.message.form.field.invalid.input.pattern:The field contains invalid characters or does not match the input format`;
    static formFieldInvalidInputMinlength = $localize`:@@error.message.form.field.invalid.input.minlength:The minimum content length must be at least`;
    static formFieldInvalidInputMaxlength = $localize`:@@error.message.form.field.invalid.input.maxlength:Maximum content length must be less than`;
    static formFieldInvalidSelectValueRequired = $localize`:@@error.message.form.field.invalid.select.value.required:Nothing selected`;
    static formFieldInvalidSelectedValueNotFilled = $localize`:@@error.message.form.field.invalid.selected.value.not.filled:Selected fields not filled`;
    static formFieldInvalidInputSynonymsPattern = $localize`:@@error.message.form.field.invalid.input.synonyms.pattern:Synonyms not in the correct format`;
    static formFieldInvalidInputTagsPattern = $localize`:@@error.message.form.field.invalid.input.tags.pattern:Valid tag's length is from 3 to 50. Only lowercase letters, numbers, and _`;

    static formFieldInvalidInputTagAlreadyExistPattern = $localize`:@@error.message.form.field.invalid.input.tag.already.exist.pattern:This tag already exist`;
    static formFieldForbiddenTagsPattern = $localize`:@@error.message.form.field.forbidden.tags.pattern:This tag cannot be created`;
    static formFieldInvalidInputTagsAssignmentPattern = $localize`:@@error.message.form.field.invalid.input.tags.assignment.pattern:Enter tags separated by a space. Required format: #engine #oil #{viskositat}{farbe}`;

    static errorMessageFormFieldEmptyStrategyList = $localize`:@@error.message.form.field.empty.strategy.list:Strategy is not selected`;

    static errorMessageForGroupsToExclude(value: string | number | null | undefined) {
        const localizedMessagePartOne = $localize`:@@tooltip.message.error.message.for.groups.to.exclude.start:For group(s)`;
        const localizedMessagePartTwo = $localize`:@@tooltip.message.error.message.for.groups.to.exclude.end:repricing is disabled. To enable repricing for selected articles, enable repricing for these groups.`;
        return `${localizedMessagePartOne} ${AppText.getDisplayValue(value)} ${localizedMessagePartTwo}`;
    }


    // Popup Messages
    static infoPopupErrorTitle = $localize`:@@info.popup.error.title:Something went wrong`;
    static infoPopupResetChangesTitle = $localize`:@@info.popup.reset.changes.title:Reset current changes?`;
    static infoPopupSuccessTitle = $localize`:@@info.popup.success.title:Success!`;
    static infoPopupDoneTitle = $localize`:@@info.popup.done.title:Done!`;
    static infoPopupSuccessSaveMessage = $localize`:@@info.popup.success.save.message:Saved successfully`;
    static infoPopupSuccessDeleteMessage = $localize`:@@info.popup.success.delete.message:Deleted successfully`;
    static infoPopupSuccessRemoveMessage = $localize`:@@info.popup.success.removed.message:Removed successfully`;

    static infoPopupPasswordChangedMessage = $localize`:@@info.popup.success.save.message.your.password.has.been.changed:Your password has been changed`;

    static confirmationMessageChangeArticleGroupForArticle(value: string | number | null | undefined) {
        const localizedMessagePartOne = $localize`:@@confirmation.message.you.want.to.change.article.group.for.article.part.one.of.phrase:Are you sure you want to change an article group for`;
        const localizedMessagePartTwo = $localize`:@@confirmation.message.you.want.to.change.article.group.for.article.part.two.of.phrase:?`;
        return `${localizedMessagePartOne} ${AppText.getDisplayValue(value)} ${localizedMessagePartTwo}`;
    }

    // Tooltips
    static tooltipMessageSellerLogo = $localize`:@@tooltip.message.seller.logo:Seller logo`;
    static tooltipMessageRulesSettingsForMatch = $localize`:@@tooltip.message.match.rules.settings:Rules settings for match`;
    static tooltipMessageRulesSettingsForNoMatch = $localize`:@@tooltip.message.nomatch.rules.settings:Rules settings for nomatch`;
    static tooltipMessageShowsPercentageForRuleWorked = $localize`:@@tooltip.message.shows.percentage.for.rule.worked:Shows % of competitors on which this rule match`;
    static tooltipMessageShowsPercentageForRuleWasDecisive = $localize`:@@tooltip.message.shows.percentage.for.rule.was.decisive:Shows % of competitors for which this rule was decisive`;
    static tooltipMessageShowOutOfStockCompetitors = $localize`:@@tooltip.message.show.out.of.stock.competitors:Show out of stock competitors`;
    static tooltipMessageShowsPercentForRuleWorkedWith(value: string | number | null | undefined) {
        const localizedMessagePartOne = $localize`:@@tooltip.message.shows.percent.for.rule.worked.start:That rule match on`;
        const localizedMessagePartTwo = $localize`:@@tooltip.message.shows.percent.for.rule.worked.end:of potential competitors`;
        return `${localizedMessagePartOne} ${AppText.getDisplayValue(value)}% ${localizedMessagePartTwo}`;
    }
    static tooltipMessageShowsPercentForRuleWasDecisive(value: string | number | null | undefined) {
        const localizedMessagePartOne = $localize`:@@tooltip.message.shows.percent.for.rule.was.decisive.start:That rule was decisive on`;
        const localizedMessagePartTwo = $localize`:@@tooltip.message.shows.percent.for.rule.was.decisive.end:of competitors`;
        return `${localizedMessagePartOne} ${AppText.getDisplayValue(value)}% ${localizedMessagePartTwo}`;
    }
    static tooltipMessageOpenDashboard = $localize`:@@tooltip.message.open.dashboard:Open dashboard with detailed statistics`;
    static tooltipMessageCompetitorSoldQuantityAtPeriod(value: string | number | null | undefined) {
        const localizedMessage = $localize`:@@tooltip.message.competitor.sold.quantity.at.period:Competitor sold quantity at`;
        return `${localizedMessage} ${AppText.getDisplayValue(value)}`;
    }
    static tooltipMessageCompetitorTotalTurnoverAtPeriod(value: string | number | null | undefined) {
        const localizedMessage = $localize`:@@tooltip.message.competitor.total.turnover.at.period:Competitor total turnover at`;
        return `${localizedMessage} ${AppText.getDisplayValue(value)}`;
    }
    static tooltipMessageCompetitorTurnoverPartAtPeriod(value: string | number | null | undefined, period: string | number | null | undefined) {
        const localizedMessagePartOne = $localize`:@@tooltip.message.competitor.turnover.part.at.period.start:This competitor take`;
        const localizedMessagePartTwo = $localize`:@@tooltip.message.competitor.turnover.part.at.period.end:of total turnover between all competitors at`;
        return `${localizedMessagePartOne} ${AppText.getDisplayValue(value)}% ${localizedMessagePartTwo} ${AppText.getDisplayValue(period)}`;
    }
    static tooltipMessageArticleSoldQuantityAtPeriod(value: string | number | null | undefined) {
        const localizedMessage = $localize`:@@tooltip.message.article.sold.quantity.at.period:Article sold quantity at`;
        return `${localizedMessage} ${AppText.getDisplayValue(value)}`;
    }
    static tooltipMessageArticleTotalTurnoverAtPeriod(value: string | number | null | undefined) {
        const localizedMessage = $localize`:@@tooltip.message.article.total.turnover.at.period:Article total turnover at`;
        return `${localizedMessage} ${AppText.getDisplayValue(value)}`;
    }
    static tooltipMessageArticleTurnoverPartAtPeriod(value: string | number | null | undefined, period: string | number | null | undefined) {
        const localizedMessagePartOne = $localize`:@@tooltip.message.article.turnover.part.at.period.start:This article take`;
        const localizedMessagePartTwo = $localize`:@@tooltip.message.article.turnover.part.at.period.end:of total turnover between all competitors at`;
        return `${localizedMessagePartOne} ${AppText.getDisplayValue(value)}% ${localizedMessagePartTwo} ${AppText.getDisplayValue(period)}`;
    }
    static tooltipMessageDiffBetweenBestCompetitorAndArticlePrices = $localize`:@@tooltip.message.diff.between.best.competitor.and.article.prices:Difference between best competitor and article prices`;
    static tooltipMessageMaxCompetitorPrice = $localize`:@@tooltip.message.max.competitor.price:The price of the most expensive competitor`;
    static tooltipMessageMinCompetitorPrice = $localize`:@@tooltip.message.min.competitor.price:The price of the cheapest competitor`;
    static tooltipMessageBestCompetitorPrice = $localize`:@@tooltip.message.best.competitor.price:The price of the best competitor`;
    static tooltipMessageArticleCompetitorsCount = $localize`:@@tooltip.message.article.competitors.count:Article competitors count`;
    static tooltipMessageArticleCompetitorsFeed = $localize`:@@tooltip.message.article.competitors.is.feed:Is Feed`;
    static tooltipMessageArticleCompetitorsNotFeed = $localize`:@@tooltip.message.article.competitors.is.not.feed:Not Feed`;

    static tooltipMessageTotalQuantityThatWasSoldByArticleCompetitors(value: string | number | null | undefined) {
        const localizedMessage = $localize`:@@tooltip.message.total.quantity.that.was.sold.by.article.competitors:Total quantity that was sold by article competitors at`;
        return `${localizedMessage} ${AppText.getDisplayValue(value)}`;
    }
    static tooltipMessageTotalTurnoverOfAllCompetitorArticlesAtPeriod(value: string | number | null | undefined) {
        const localizedMessage = $localize`:@@tooltip.message.total.total.turnover.of.all.competitor.articles.at.period:Total turnover of all competitor articles at`;
        return `${localizedMessage} ${AppText.getDisplayValue(value)}`;
    }
    static tooltipMessageCompetitorSimilarity(value: string | number | null | undefined) {
        const localizedMessage = $localize`:@@tooltip.message.competitor.similarity:This competitor similar to presented article by`;
        return `${localizedMessage} ${AppText.getDisplayValue(value)}%`;
    }
    static tooltipMessageRequestIcon = $localize`:@@tooltip.message.request.icon:The search text. Click to open on eBay`;
    static tooltipMessageRulesIcon = $localize`:@@tooltip.message.rules.icon:Competitor RuleModel`;
    static tooltipMessageNumberOfArticlesThatNotValidated = $localize`:@@tooltip.message.number.of.articles.that.not.validated:Number of articles that not validated`;
    static tooltipMessageArticlesAndCompetitorsCount = $localize`:@@tooltip.message.articles.and.competitors.count:Articles and competitors count, competitor recognition quality`;
    static tooltipMessageWhenCompetitorsWasSentToRepricing(
        createDate: string | number | null | undefined,
        updatedDate: string | number | null | undefined,
        userName: string | number | null | undefined
    ) {
        const localizedMessagePartOne = $localize`:@@tooltip.message.when.competitors.was.sent.to.repricing.part.one.of.phrase:Articles updated`;
        const localizedMessagePartTwo = $localize`:@@tooltip.message.when.competitors.was.sent.to.repricing.part.two.of.phrase:Competitors data updated`;
        const userData = userName ? ` ${AppText.generalTextIsBy} ${userName} ` : ' ';
        return `${localizedMessagePartOne}: ${AppText.getDisplayValue(createDate)}. ${localizedMessagePartTwo}: ${AppText.getDisplayValue(updatedDate)}`;
    }
    static tooltipMessageNewGroupSettings = $localize`:@@tooltip.message.new.group.settings:Update competitor list by new group settings`;
    static tooltipMessageOldGroupSettings = $localize`:@@tooltip.message.old.group.settings:No new competitors available. To get new one update group settings`;
    static tooltipMessageCountOfValidatedCompetitors(
        countOfValidated: string | number | null | undefined,
        totalCompetitors: string | number | null | undefined
    ) {
        const localizedMessage = $localize`:@@tooltip.message.count.of.validated.competitors:validated competitors from`;
        return `${AppText.getDisplayValue(countOfValidated)} ${localizedMessage} ${AppText.getDisplayValue(totalCompetitors)}`;
    }

    static tooltipMessageCountOfArticlesForTags(articleCount: number | null) {
        if (articleCount == null) {
            return $localize`:@@tooltip.message.new.tag.no.article.found:New tag, no article found`;
        }
        return `${AppText.getDisplayValue(articleCount)} ${AppText.mainMenuItemIsArticles}`;
    }

    static tooltipMessageAllSelectedArticlesAlreadyHaveThisTag = $localize`:@@tooltip.message.all.selected.articles.already.have.this.tag:All selected articles already have this tag`;
    static tooltipMessageApplyTagToAllSelectedArticles = $localize`:@@tooltip.message.apply.tag.to.all.selected.articles:Apply tag to all selected articles`;
    static tooltipMessageRemoveTagFromAllSelectedArticles = $localize`:@@tooltip.message.remove.tag.from.all.selected.articles:Remove tag from all selected articles`;

    static regexTooltip = $localize`:@@text.content.of.regex.dialog.what.is:What is RegEx?`;
    static regexTooltipHeader = $localize`:@@text.content.of.regex.dialog.dialog.header:Regular Expressions`;
    static regexTooltipStartText = $localize`:@@text.content.of.regex.dialog.expressions.is:A regular expression (regex for short) allow developers to match strings against a pattern, extract submatch information, or simply test if the string conforms to that pattern.`;
    static regexTooltipRegexReferencePartOne = $localize`:@@text.content.of.regex.dialog.detailed.information.part.one:For detailed information of regular expression syntax, read the `;
    static regexTooltipRegexReferencePartTwo = $localize`:@@text.content.of.regex.dialog.detailed.information.part.two:regular expression reference.`;
    static regexTooltipRegexGuidePartOne = $localize`:@@text.content.of.regex.dialog.guide.part.one:You are also encouraged to read the `;
    static regexTooltipRegexGuidePartTwo = $localize`:@@text.content.of.regex.dialog.guide.part.two:regular expressions guide`;
    static regexTooltipRegexGuidePartThree = $localize`:@@text.content.of.regex.dialog.guide.part.three: to get an overview of the available regex syntaxes and how they work.`;
    static regexTooltipRegexEditorPartOne = $localize`:@@text.content.of.regex.dialog.editor.part.one:For practice, you can use an online regex builder/debugger - `;
    static regexTooltipRegexEditorPartTwo = $localize`:@@text.content.of.regex.dialog.editor.part.two:Regex Tester.`;
    static regexTooltipRegexExamplesLabel = $localize`:@@text.content.of.regex.dialog.examples:Simple Examples`;
    static regexTooltipRegexDescriptionTitle = $localize`:@@text.content.of.regex.dialog.description.title:Description`;
    static regexTooltipRegexExpressionTitle = $localize`:@@text.content.of.regex.dialog.expression.title:Expression`;
    static regexTooltipRegexExamplesOftenUsedInRepricerLabel = $localize`:@@text.content.of.regex.dialog.examples.often.used.in.repricer:Examples often used in Repricer`;


    // UNIQUE TEXT OF PAGES


    // Manual Competitors Search
    static manualCompetitorsSearchPageInProgressMessage = $localize`:@@manual.competitors.page.text.is.please.wait.or.cancel:Please wait or cancel search process`;
    static manualCompetitorsSearchPageToFoundCompetitors = $localize`:@@manual.competitors.page.text.is.to.found.competitors.enter.your.request:To found competitors enter your request`;
    static manualCompetitorsSearchPageFoundBySearch = $localize`:@@manual.competitors.page.text.is.found.by.selected.search:Found by selected search`;
    static manualCompetitorsSearchPageOnValidation = $localize`:@@manual.competitors.page.text.is.on.validation:On Validation`;
    static manualCompetitorsSearchPageSearchOnPlatform = $localize`:@@manual.competitors.page.text.is.search.competitors.on.platform:Search competitors on Platform`;
    static manualCompetitorsSearchPageRequestsHistory = $localize`:@@manual.competitors.page.text.is.requests.history:Requests History`;
    static manualCompetitorsSearchPageNoRequestsHistory = $localize`:@@manual.competitors.page.text.is.no.requests.history:No request history`;
    static manualCompetitorsSearchPageShowOrHideArticleTitle = $localize`:@@manual.competitors.page.text.is.show.or.hide.article.title:Show or Hide Article Title`;
    // Article List
    static articlesListPageMaxCompetitorPrice = $localize`:@@text.content.max.competitor.price:Most Expensive`;
    static articlesListPageArticlesFound(value: string | number | null | undefined) {
        if (value === 1) {
            return $localize`:@@articles.page.text.is.article.found:article found`;
        }
        return $localize`:@@articles.page.text.is.articles.found:articles found`;
    }

    static articlesListPageMinCompetitorPrice = $localize`:@@text.content.min.competitor.price:Cheapest`;
    static articlesListPageBestCompetitorPrice = $localize`:@@text.content.best.competitor.price:Competitive`;
    static articlesListPagePcSoldWith = $localize`:@@text.content.pc.sold.with:pc. sold with`;
    static articlesListPageTurnover = $localize`:@@text.content.turnover:turnover`;
    static articlesListPageSearchCompetitors = $localize`:@@text.content.search.competitors:Search Competitors`;
    static articlesListPageMaximumArticlesForExport(value: string | number | null | undefined) {
        const localizedMessagePartOne = $localize`:@@tooltip.message.maximum.articles.for.export.start:Maximum`;
        const localizedMessagePartTwo = $localize`:@@tooltip.message.maximum.articles.for.export.end:articles`;
        return `${localizedMessagePartOne} ${AppText.getDisplayValue(value)} ${localizedMessagePartTwo}`;
    }
    static articlesPageTextIsRepricerStrategy = $localize`:@@articles.page.text.is.repricer.strategy:Repricer Strategy`;
    static articlesPageTextIsSelectGroup = $localize`:@@articles.page.text.is.select.group:Select group`;
    static articlesPageTextIsSelectStrategy = $localize`:@@articles.page.text.is.select.strategy:Select strategy`;
    static articlesPageTextIsTargetStrategy = $localize`:@@articles.page.text.is.target.strategy:Target strategy`;
    static articlesPageTextIsNewArticlesHaveBeenUploaded = $localize`:@@articles.page.text.is.new.articles.have.been.uploaded:New articles have been uploaded to Repricer. The system will process the uploaded articles within a few minutes.`;
    static articlesPageTextIsSelectFileWithArticles = $localize`:@@articles.page.text.is.select.file.with.articles:Select the file with articles`;
    static articlesPageTextIsMaxFileSizeIs10MB = $localize`:@@articles.page.text.is.max.file.size.is.10MB:Max file size is 10MB`;
    static articlesPageTextIsInvalidFileTypeItMustBeZip = $localize`:@@articles.page.text.is.invalid.file.type.it.must.be.zip:Invalid file type. Type must be .zip`;

    static articlesPageTextIsExcludedFromRepricing = $localize`:@@articles.page.text.is.excluded.from.repricing:Excluded from Repricing`;
    static articlesPageTextIsWhatIsRecommendedSalePrice = $localize`:@@articles.page.text.is.what.is.recommended.price:What is recommended price?`;
    static articlesPageTextIsClickHereToAddNewTags = $localize`:@@articles.page.text.is.click.here.to.add.new.tags:Click here to add new tags`;
    static articlesPageTextIsAttributeListEmpty = $localize`:@@articles.page.text.is.attribute.list.empty:List is empty`;

    // Sub Articles Component
    static subArticlesComponentIsVariant = $localize`:@@text.content.articles.has.few.variants:Articles has few variants`;
    static subArticlesComponentHasSubs = $localize`:@@text.content.items.included.in.this.bundle:Items included in this bundle`;

    // Competitor validation
    static competitorValidationStopValidation = $localize`:@@text.content.stop.validation.:Stop Validation`;
    static competitorValidationFinishValidation = $localize`:@@text.content.finish.validation:Finish Validation`;
    static competitorValidationCurrentCompetitor = $localize`:@@text.content.current.competitor:Current Competitor`;
    static competitorValidationCompetitorsValidated = $localize`:@@text.content.competitors.validated:Competitors Validated`;
    static competitorValidationCurrentSelection = $localize`:@@text.content.in.the.current.selection:In The Current Selection`;
    static competitorValidationAllCompetitorsValidated = $localize`:@@text.content.all.competitors.validated:All competitors validated`;
    static competitorValidationNoCompetitorsForValidation = $localize`:@@text.content.no.competitors.for.validation:No competitors for validation`;
    static competitorValidationNoArticlesForValidation = $localize`:@@text.content.no.articles.for.validation:No articles for validation`;
    static competitorValidationNoRulesForValidation = $localize`:@@text.content.no.rules.for.validation:No rules for validation`;
    static competitorValidationItsCompetitor = $localize`:@@text.content.its.a.competitor:It\'s a competitor`;
    static competitorValidationRuleActionApplied = $localize`:@@text.content.rule.action.applied:Rule Action Applied`;
    static competitorValidationConfidenceAbove = $localize`:@@text.content.confidence.is.above:Confidence is above`;
    static competitorValidationItsNotCompetitor = $localize`:@@text.content.its.not.a.competitor:It\'s not a competitor`;
    static competitorValidationConfidenceBellow = $localize`:@@text.content.confidence.is.bellow:Confidence is bellow`;
    static competitorValidationYourDecision = $localize`:@@text.content.your.decision:Your Decision`;
    static competitorValidationProductsAreCompetitors = $localize`:@@text.content.what.do.you.think:What do you think the products are competitors?`;
    static competitorValidationDefineAutomaticDecision = $localize`:@@text.content.define.automatic.decision:That define automatic decision`;
    static competitorValidationSameVolume = $localize`:@@text.content.its.a.same.volume:It\'s a same volume`;
    static competitorValidationIncreaseConfidenceBy = $localize`:@@text.content.increase.confidence.by:Increase Confidence by`;
    static competitorValidationDecreaseConfidenceBy = $localize`:@@text.content.decrease.confidence.by:Decrease Confidence by`;
    static competitorValidationShowAttributeTable = $localize`:@@text.content.show.attribute.table:Show Attribute Table`;
    static competitorValidationStopButton = $localize`:@@text.content.all.unqualified.potential.competitors.will.be.excluded:All unqualified potential competitors will be excluded from the sample`;
    static competitorValidationPreviousPotentialCompetitor = $localize`:@@text.content.previous.potential.competitor:Previous potential competitor`;
    static competitorValidationPreviousRuleSettings = $localize`:@@text.content.match.not.match.settings:Match / Not Match Settings`;

    // Rule List
    static ruleListPageListEmpty = $localize`:@@rules.page.text.is.empty.rule.list:Rules list is empty`;
    static ruleListPageAddRule = $localize`:@@rules.page.text.is.add.rule:Add new matching rule to find competitors`;
    static ruleListPageFromAnotherGroup = $localize`:@@rules.page.text.is.from.another.group:From another group`;
    // Rule Edit
    static rulePageRuleEnabled = $localize`:@@rules.page.text.is.rule.enabled:Rule Enabled`;
    static rulePageTargetingRules = $localize`:@@rules.page.text.is.targeting.rules:Targeting Rules`;
    static rulePageValueFromField = $localize`:@@rules.page.text.is.value.from.a.field:Value from a field`;
    static rulePageOriginalArticleFields = $localize`:@@rules.page.text.is.original.article.fields:Original Article Fields`;
    static rulePageValueFromList = $localize`:@@rules.page.text.is.value.from.list:Value From List`;
    static rulePageValues = $localize`:@@rules.page.text.is.values:Values`;
    static rulePageMatchRegex = $localize`:@@rules.page.text.is.match.regex:Match Regex`;
    static rulePageRegex = $localize`:@@rules.page.text.is.regex:Regex`;
    static rulePageUnitRegex = $localize`:@@rules.page.text.is.unit.regex:Unit Regex`;
    static rulePageSourceArticles = $localize`:@@rules.page.text.is.source.articles:Source Articles`;
    static rulePageMatchAllSourceArticleValues = $localize`:@@rules.page.text.is.match.all.source.article.values:Match All Source Article Values`;
    static rulePageValuePreprocessing = $localize`:@@rules.page.text.is.value.preprocessing:Value Preprocessing`;
    static rulePageSkipValues = $localize`:@@rules.page.text.is.skip.values.with.length.less.than:Skip values with length less than`;
    static rulePageMinAllowedValueLength = $localize`:@@rules.page.text.is.length:Length`;
    static rulePageCharacters = $localize`:@@rules.page.text.is.characters:Characters`;
    static rulePageSeparateValues = $localize`:@@rules.page.text.is.separate.on.multiple.values.by:Separate on multiple values by`;
    static rulePageSeparator = $localize`:@@rules.page.text.is.separator:Separator`;
    static rulePageCharactersToRemove = $localize`:@@rules.page.text.is.remove.characters:Remove Characters`;
    static rulePageMatchingMode = $localize`:@@rules.page.text.is.matching.mode:Matching Mode`;
    static rulePageSearchInTitle = $localize`:@@rules.page.text.is.search.in.the.title:Search in the Title`;
    static rulePageSearchInAttributes = $localize`:@@rules.page.text.is.search.in.attributes:Search in Attributes`;
    static rulePageAttributeNameRegex = $localize`:@@rules.page.text.is.attribute.name.regex:Attribute Name Regex`;
    static rulePageMinMultiplierValue = $localize`:@@rules.page.text.is.min.multiplier.value:Min Multiplier Value`;
    static rulePageMaxMultiplierValue = $localize`:@@rules.page.text.is.max.multiplier.value:Max Multiplier Value`;
    static rulePageSourceField = $localize`:@@rules.page.text.is.source.field:Source Field`;
    static rulePageTargetAttributeNameRegex = $localize`:@@rules.page.text.is.target.attribute.name.regex:Target Attribute Name Regex`;
    static rulePageArticleAttributeNameRegex = $localize`:@@rules.page.text.is.article.attribute.name.regex:Article Attribute Name Regex`;
    static rulePageSearchInUnitsTitle = $localize`:@@rules.page.text.is.search.for.units.in.title:Search for units in the Title`;
    static rulePageSearchInUnitsAttributes = $localize`:@@rules.page.text.is.search.for.units.in.attributes:Search for units in Attributes`;

    // Article groups
    static articleGroupListPageConfidenceThreshold = $localize`:@@text.content.confidence.threshold:Confidence Threshold`;
    static articleGroupListRecognitionQuality = $localize`:@@text.content.recognition.quality:Recognition Quality`;
    static articleGroupListPageMinimumRecognitionQuality = $localize`:@@text.content.minimum.recognition.quality:Minimum Recognition Quality`;
    static articleGroupListPageInProgressStatus = $localize`:@@text.content.data.collection.in.progress:Data collection in progress`;
    static articleGroupListPageArticleUploadStatus = $localize`:@@text.content.article.upload.in.progress:Article upload in progress`;
    static articleGroupListPageRuleRequiredStatus = $localize`:@@text.content.rule.configuration.required:Rule configuration required`;
    static articleGroupListPageArticlesQuantity = $localize`:@@text.content.article.quantity:Articles Quantity`;
    static articleGroupListPageCompetitorsQuantity = $localize`:@@text.content.competitors.quantity:Competitors Quantity`;
    static articleGroupListPagePotentialCompetitorsQuantity = $localize`:@@text.content.potential.competitors.quantity:Potential Competitors Quantity`;
    static articleGroupListPageSendToRepricingWarnMessage(sendList: boolean = true) {
        const localizedPretext = sendList ? 'these groups' : 'the group';
        return $localize`:@@text.content.are.you.sure.you.want.to.send.to.repricing:You have not checked all the articles, so the rules for finding competitors may work unpredictably. Are you sure you want to send ${localizedPretext} to repricing?`;
    }
    static articleGroupListPageAddedToRepricingMessage = $localize`:@@text.content.groups.added.to.repricing:Groups added to repricing`;
    static articleGroupListPageCompetitorsSendStatus = $localize`:@@text.content.competitors.send.on.repricing.inprogress:Competitors send on repricing in progress`;
    static articleGroupListPageCompetitorsSearchStatus = $localize`:@@text.content.competitors.search.inprogress:Competitors search in progress`;
    static articleGroupListPageCompetitorsUpdateStatus = $localize`:@@text.content.competitors.update.inprogress:Competitors update in progress`;
    static articleGroupListPageGroupPurgedStatus = $localize`:@@text.content.group.was.purged.from.repricing:Group was purged from repricing`;
    static articleGroupListPageNewSettingsStatus = $localize`:@@text.content.new.group.settings.was.applied:New group settings was applied`;
    static articleGroupListPagePotentialCompetitorsUpdateStatus = $localize`:@@text.content.potential.competitors.update.inprogress:Potential competitors update in progress`;

    // Article group
    static editArticleGroupCompetitorsFromPotentialCompetitors = $localize`:@@text.content.competitors.from.potential.competitors:actual competitors from potential competitors`;
    static editArticleGroupRuleList = $localize`:@@text.content.rule.list:Rule List`;

    static editArticleGroupAnalytics = $localize`:@@text.content.analytics:Analytics`;
    static editArticleGroupOnRepricing = $localize`:@@text.content.on.repricing:On Repricing`;
    static editArticleGroupExcludedFromRepricing = $localize`:@@text.content.excluded.from.repricing:Excluded From Repricing`;
    static editArticleGroupNewGroup = $localize`:@@text.content.new.group:New Group`;
    static editArticleGroupUpload = $localize`:@@text.content.upload:Upload`;
    static editArticleGroupUploadNew = $localize`:@@text.content.upload.new:Upload New`;
    static editArticleGroupUpdate = $localize`:@@text.content.update:Update`;
    static editArticleGroupToUpdateUploadNewArticles = $localize`:@@text.content.to.update.upload.new.articles:To Update Upload New Articles`;
    static editArticleGroupToUpdateUploadNewArticlesTooltip = $localize`:@@text.content.repricer.will.automatically.update:Repricer will automatically update potential competitor list`;
    static editArticleGroupSendToRepricing = $localize`:@@text.content.send.to.repricing:Send To Repricing`;
    static editArticleGroupAlreadyOnRepricing = $localize`:@@text.content.already.on.repricing:Already On Repricing`;
    static editArticleGroupAddedToRepricingMessage = $localize`:@@text.content.group.added.to.repricing:Group added to repricing`;
    static articleListPageArticleGroupCreated = $localize`:@@text.content.article.group.created:Article Group Created`;
    static articleListPageArticleGroupChanged = $localize`:@@text.content.article.group.changed:Article Group Changed`;
    static editArticleGroupSettingsChangedMessage = $localize`:@@text.content.group.settings.changed:Group settings have been changed, new competitors may be available`;
    static editArticleGroupValidateAdditionalCompetitors = $localize`:@@text.content.validate.additional.competitors:Validate Additional Competitors`;
    static editArticleGroupGroupIsEmpty = $localize`:@@text.content.group.is.empty:List of articles is empty. Use tag filters in group settings to add article in the group.`;
    static editArticleGroupArticleIsUpdating = $localize`:@@text.content.article.is.updating:Article is updating, please stand by.`;
    static editArticleGroupPotentialCompetitorsIsEmpty = $localize`:@@text.content.potential.competitors.is.empty:List of potential competitors is empty. To find them run potential competitors update.`;
    static editArticleGroupFoundByCurrentRules = $localize`:@@text.content.found.by.current.rules:Found by current rules`;
    static editArticleGroupCompetitorsIsEmpty = $localize`:@@text.content.list.of.competitors.is.empty:List of competitors is empty. To find them run competitors update.`;
    static editArticleGroupReturnToRepricing = $localize`:@@text.content.return.to.repricing:Return To Repricing`;
    static editArticleGroupInValidation(value: string | number | null | undefined) {
        const localizedMessagePartOne = $localize`:@@tooltip.message.group.in.validation.start:In`;
        const localizedMessagePartTwo = $localize`:@@tooltip.message.group.in.validation.end:validations`;
        return `${localizedMessagePartOne} ${AppText.getDisplayValue(value)} ${localizedMessagePartTwo}`;
    }
    static editArticleGroupRecognitionQualityIsNotAvailable = $localize`:@@text.content.list.of.competitors.not.validated:The current list of competitors has not been validated. Recognition Quality is not available.`;
    static editArticleGroupGroupWasAutomaticallyPurge(value: string | number | null | undefined) {
        const localizedMessage = $localize`:@@tooltip.message.group.was.automatically.purge:Group was automatically purge from repricing because recognition quality has dropped below`;
        return `${localizedMessage} ${AppText.getDisplayValue(value)}%.`;
    }
    static articleGroupsPageTextIsNewGroupWillBeSentForReprising = $localize`:@@article.groups.page.text.is.new.group.will.be.sent.for.reprising:new group will be sent for reprising`;
    // Strategies
    static strategiesPageTextIsStrategySettings = $localize`:@@strategies.page.text.is.settings.title:Strategy settings`;
    static strategiesPageTextIsPriceManagement = $localize`:@@strategies.page.text.is.price.management:Price Management`;
    static strategiesPageTextIsPriceManagementInfo = $localize`:@@strategies.page.text.is.when.this.parameter.is.enabled.the.competitive.price.is.passed:When this parameter is enabled, the competitive price is passed to ets. In other words, the strategy included to repricing.`;
    static strategiesPageTextIsRestrictionOnCompetitivePrice = $localize`:@@strategies.page.text.is.restriction.on.competitive.price:Restriction on competitive price`;
    static strategiesPageTextIsRestrictionOnCompetitivePriceInfo = $localize`:@@strategies.page.text.is.when.this.parameter.is.enabled.you.can.adjust.the.competitive.price.limits:When this parameter is enabled, you can adjust the competitive price limits. The lower limit contains values from 0.0 to 100.0. The upper limit contains values from 100.0 to 200.0.`;
    static strategiesPageTextIsEbayAdsSettings = $localize`:@@strategies.page.text.is.ebay.ads.settings:Ebay Ads Settings`;
    static strategiesPageTextIsEbayAdsSettingsInfo = $localize`:@@strategies.page.text.is.enabling.this.setting.allows.you.to.customize.your.strategy.and.ad rate:Enabling this setting allows you to customize your strategy and ad rate.`;
    static strategiesPageTextIsStrategyRate = $localize`:@@strategies.page.text.is.rate:Strategy Rate`;
    static strategiesPageTextIsStrategyRateInfo = $localize`:@@strategies.page.text.is.choose.strategy.rate:Choose how you\'d like your ad rate to be set up - dynamic or fixed. An ad rate is the percentage of an item\'s total sale amount (including item price shipping, taxes, and any other applicable fees) that is charged when a buyer clicks one of your Standard ads and then purchases any of your Promoted Listings Standard items within 30 days`;
    static strategiesPageTextIsStrategyDynamicType = $localize`:@@strategies.page.text.is.dynamic.advertisement.rate.type:Dynamic ad rate`;
    static strategiesPageTextIsStrategyFixedType = $localize`:@@strategies.page.text.is.fixed.advertisement.rate.type:Fixed ad rate`;
    static strategiesPageTextIsStrategyDynamicTypeDescription = $localize`:@@strategies.page.text.is.dynamic.ad.rate.type.description:Stay competitive with an updated ad rate that automatically adjusts to eBay\'s daily suggestion.`;
    static strategiesPageTextIsStrategyFixedTypeDescription = $localize`:@@strategies.page.text.is.fixed.ad.rate.type.description:Keep a consistent ad rate that will not change once it is set. You can always edit the ad rates at a later time.`;
    static strategiesPageTextIsStrategyDynamicAdRateCap = $localize`:@@strategies.page.text.is.dynamic.ad.rate.cap:Set ad rate cap`;
    static strategiesPageTextIsStrategyDynamicAdRateCapInfo = $localize`:@@strategies.page.text.is.dynamic.set.the.maximum.percentage.of.a.sale:Set the maximum percentage of a sale you\'re willing to spend on ad fees when your promoted item sells. Your ad rate will not exceed your selection. The minimum rate is \"2%\" and the maximum rate is \"20%\"`;
    static strategiesPageStrategyDynamicAdRateAdjustment = $localize`:@@strategies.page.text.is.dynamic.ad.rate.adjustment:Adjust dynamic ad rate`;
    static strategiesPageStrategyDynamicAdRateAdjustmentInfo = $localize`:@@strategies.page.text.is.dynamic.ad.rate.adjustment.info:The dynamic ad rate will automatically update your ad rate to match eBay\'s daily suggestion. You may modify this by choosing a percentage above or below the daily suggested ad rate that you\'re willing to pay. To set an ad rate lower than the daily suggestion, input a (-) before the number (I.e, \"-1.0%\"). The minimum rate is \"-20%\" and the maximum rate is \"20%\"`;
    static strategiesPageStrategyFixedAdRate = $localize`:@@strategies.page.text.is.fixed.ad.rate:Apply single ad rate`;
    static strategiesPageStrategyFixedAdRateInfo = $localize`:@@strategies.page.text.is.fixed.ad.rate.info:The minimum rate is \"2%\" and the maximum rate is \"20%\"`;
    static strategiesPageTextIsInProcessing = $localize`:@@strategies.page.text.is.processing:In Processing`;

    static strategiesPageTextIsBoostManagement = $localize`:@@strategies.page.text.is.boost.management:Boost Management`;
    static strategiesPageTextIsBoostManagementInfo = $localize`:@@strategies.page.text.is.when.the.boost.parameter.is.enabled.then:If boost is enabled, then all recently posted listings will be included in Ebay advertising campaigns with boost settings specified below.`;
    static strategiesPageTextIsBoostPeriod = $localize`:@@strategies.page.text.is.boost.period:Boost Period`;
    static strategiesPageTextIsBoostPeriodInfo = $localize`:@@strategies.page.text.is.boost.period.info:All recently posted listings will be included in Ebay advertising campaigns, provided that the specified number of days has NOT passed since they were posted:`;

    static strategiesPageTextIsRequiredTags = $localize`:@@strategies.page.text.is.required.tags:Required Tags`;
    static strategiesPageTextIsForbiddenTags = $localize`:@@strategies.page.text.is.forbidden.tags:Forbidden Tags`;
    // static strategiesPageInvalidInputPattern = $localize`:@@error.message.form.field.invalid.input.pattern.text.content.of.strategy.page:Set an ad rate between 2% and 100%`;
    // static strategiesPageInvalidInputPattern2 = $localize`:@@error.message.form.field.invalid.input.pattern.text.content.of.strategy.page2:Set an adjustment between -100% and 100%.`;
    static strategiesPageArticleListEmpty = $localize`:@@strategies.page.text.is.article.list.is.empty:List of articles for this strategy is empty.`;
    static strategiesPageStrategyIsUpdating = $localize`:@@strategies.page.text.is.strategy.is.updating:Strategy is updating, please stand by.`;
    // Competitor
    static competitorsPageTextIsSingleCompetitor = $localize`:@@competitors.page.text.is.single.competitor:Single Competitor`;
    static competitorsPageTextIsMultipleCompetitor = $localize`:@@competitors.page.text.is.multiple.competitor:Multiple Competitor`;

    static getDeleteWarnMessage(value: string | number | null | undefined) {
        const localizedMessage = $localize`:@@general.text.is.are.you.sure.you.want.to.delete:Are you sure you want to delete`;
        return `${localizedMessage} ${AppText.getDisplayValue(value)}?`;
    }

    //feed settings
    static feedSettingsPageTextIsResetConfirmation = $localize`:@@feeds.settings.page.text.is.are.you.sure.you.want.to.reset.this.settings:Are you sure you want to reset this settings`;
    static feedSettingsPageTextIsTestConnectionDefaultText = $localize`:@@feeds.settings.page.text.is.the.results.of.the.test.connection.will.be.displayed.here:The results of the test connection will be displayed here`;

    // Platform Accounts
    static platformAccountListPageTextIsSyncInProgress = $localize`:@@platform.account.list.page.text.is.sync.in.progress:Synchronization in progress`;
    static platformAccountListPageTextIsLastSyncTooltip = $localize`:@@platform.account.list.page.text.is.last.sync.tooltip:Last synchronization was`;
    static platformAccountListPageTextIsStartSyncTooltip = $localize`:@@platform.account.list.page.text.is.start.sync.tooltip:Start synchronization`;
    static platformAccountListPageTextIsStartManualSyncTooltip = $localize`:@@platform.account.list.page.text.is.start.manual.sync.tooltip:Start manual synchronization`;
    static platformAccountListPageTextIsAddAccountConfirmation = $localize`:@@platform.account.list.page.text.is.add.account.confirmation:You need to log out of the eBay platform to add a new eBay account to Repricer.`;
    static platformAccountListPageTextIsRestartSyncConfirmation = $localize`:@@platform.account.list.page.text.is.restart.sync.confirmation:Warning. You will not be able to stop or restart account synchronization if it is already in progress. Do you want to continue`;
    static platformAccountListPageTextIsRestartSyncConfirmationAgreeBtn = $localize`:@@platform.account.list.page.text.is.restart.sync.confirmation.agree.btn:Start Sync`;
    static platformAccountListPageTextIsFullSync = $localize`:@@platform.account.list.page.text.is.full.sync:Full Sync`;
    static platformAccountListPageTextIsManualSync = $localize`:@@platform.account.list.page.text.is.manual.sync:Manual Sync`;
    static platformAccountListPageTextIsManualSynchronization = $localize`:@@platform.account.list.page.text.is.manual.synchronization:Manual Synchronization`;
    static platformAccountListPageTextIsArticlesIdsFieldError = $localize`:@@hint.message.is.article.ids.invalid:Enter article IDs separated by commas. Any ID must have at least 8 numbers (from 8 to 12)`;

    // названия свойств - не переводить
    static propertyNameIsEbayLister = 'EbayLister';
    static propertyNameIsAmazonLister = 'AmazonLister';

    static userRoles = [
        { id: 'system-admin', title: AppText.userRoleSystemAdministrator },
        { id: 'user', title: AppText.userRoleUser }
    ];
    static menu: ContextMenuItem[] = [
        {
            name: 'dashboard',
            routerLink: `/${routeMap.dashboard}`,
            title: AppText.mainMenuItemIsDashboard,
            icon: dashboardIcon.data
        },
        {
            name: 'article-groups',
            routerLink: `/${routeMap.articleGroups}`,
            title: AppText.mainMenuItemIsArticleGroups,
            icon: groupIcon.data
        },
        {
            name: 'articles',
            routerLink: `/${routeMap.articles}`,
            title: AppText.mainMenuItemIsArticles,
            icon: tagIcon.data
        },
        {
            name: 'tag-assignment-rules',
            routerLink: `/${routeMap.tagAssignmentRules}`,
            title: AppText.mainMenuItemIsTagAssignmentRules,
            icon: competitorsSearchQueriesIconIcon.data
        },
        {
            name: 'strategies',
            routerLink: `/${routeMap.strategies}`,
            title: AppText.mainMenuItemIsStrategies,
            icon: settingsIcon.data
        },
        {
            name: 'feedSettings',
            routerLink: `/${routeMap.feedSettings}`,
            title: AppText.mainMenuItemIsFeedSettings,
            icon: feedIcon.data
        },
        {
            name: routeMap.platformAccounts,
            routerLink: `/${routeMap.platformAccounts}`,
            title: AppText.mainMenuItemIsPlatformAccounts,
            icon: feedIcon.data
        }
    ];
    static documentation: ContextMenuItem[] = [
        {
            name: 'competitor-rank',
            routerLink: 'https://insyscompany.atlassian.net/wiki/spaces/EUD/pages/1199669479/Competitor+Rank',
            title: AppText.mainMenuItemIsCompetitorRank,
            icon: expandLinkIcon.data
        },
        {
            name: 'tags-documentation',
            routerLink: 'https://docs.google.com/document/d/1_dqVdFL9nU0Mu_hZoWZ8ZYroAKYUt2ANxW_Dp6MwZKU',
            title: AppText.mainMenuItemIsTagsDocumentation,
            icon: expandLinkIcon.data
        }
    ];
    static breadcrumbs = <Breadcrumbs[]>[
        {
            label: AppText.mainMenuItemIsCompetitors,
            route: routeMap.competitors,
            url: `/${routeMap.competitors}`
        },
        {
            label: AppText.mainMenuItemIsArticles,
            route: routeMap.articles,
            url: `/${routeMap.articles}`
        },
        {
            label: AppText.mainMenuItemIsArticleGroups,
            route: routeMap.articleGroups,
            url: `/${routeMap.articleGroups}`
        }
    ];
    static regexTooltipExamplesTableHeaderData = [AppText.regexTooltipRegexDescriptionTitle, AppText.regexTooltipRegexExpressionTitle];
    static regexTooltipExamplesTableBodyData = [
        {
            expression: '[abc]',
            description: $localize`:@@text.content.of.regex.dialog.table.row.1:A single character of: a, b or c`
        },
        {
            expression: '[^abc]',
            description: $localize`:@@text.content.of.regex.dialog.table.row.2:A character except: a, b or c`
        },
        {
            expression: '[a-z]',
            description: $localize`:@@text.content.of.regex.dialog.table.row.3:A character in the range: a-z`
        },
        {
            expression: '[^a-z]',
            description: $localize`:@@text.content.of.regex.dialog.table.row.4:A character not in the range: a-z`
        },
        {
            expression: '[a-zA-Z]',
            description: $localize`:@@text.content.of.regex.dialog.table.row.5:A character in the range: a-z or A-Z`
        },
        {
            expression: '.',
            description: $localize`:@@text.content.of.regex.dialog.table.row.6:Any single character`
        },
        {
            expression: 'a|b',
            description: $localize`:@@text.content.of.regex.dialog.table.row.7:Alternate - match either a or b`
        },
        {
            expression: '\\s',
            description: $localize`:@@text.content.of.regex.dialog.table.row.8:Any whitespace character`
        },
        {
            expression: '\\S',
            description: $localize`:@@text.content.of.regex.dialog.table.row.9:Any non-whitespace character`
        },
        {
            expression: '\\d',
            description: $localize`:@@text.content.of.regex.dialog.table.row.10:Any digit`
        },
        {
            expression: '\\D',
            description: $localize`:@@text.content.of.regex.dialog.table.row.11:Any non-digit`
        },
        {
            expression: '\\w',
            description: $localize`:@@text.content.of.regex.dialog.table.row.12:Any word character`
        },
        {
            expression: '\\W',
            description: $localize`:@@text.content.of.regex.dialog.table.row.13:Any non-word character`
        },
        {
            expression: '(?:...)',
            description: $localize`:@@text.content.of.regex.dialog.table.row.14:Match everything enclosed`
        },
        {
            expression: '(...)',
            description: $localize`:@@text.content.of.regex.dialog.table.row.15:Capture everything enclosed`
        },
        {
            expression: 'a?',
            description: $localize`:@@text.content.of.regex.dialog.table.row.16:Zero or one of a`
        },
        {
            expression: 'a*',
            description: $localize`:@@text.content.of.regex.dialog.table.row.17:Zero or more of a`
        },
        {
            expression: 'a+',
            description: $localize`:@@text.content.of.regex.dialog.table.row.18:One or more of a`
        },
        {
            expression: 'a{3}',
            description: $localize`:@@text.content.of.regex.dialog.table.row.19:Exactly 3 of a`
        },
        {
            expression: 'a{3,}',
            description: $localize`:@@text.content.of.regex.dialog.table.row.20:3 or more of a`
        },
        {
            expression: 'a{3,6}',
            description: $localize`:@@text.content.of.regex.dialog.table.row.21:Between 3 and 6 of a`
        },
        {
            expression: '^',
            description: $localize`:@@text.content.of.regex.dialog.table.row.22:Start of string`
        },
        {
            expression: '$',
            description: $localize`:@@text.content.of.regex.dialog.table.row.23:End of string`
        },
        {
            expression: '\\b',
            description: $localize`:@@text.content.of.regex.dialog.table.row.24:A word boundary`
        },
        {
            expression: '\\B',
            description: $localize`:@@text.content.of.regex.dialog.table.row.25:Non-word boundary`
        }
    ];

    static regexTooltipExamplesOftenUsedInRepricerTable = [
        {
            expression: '^(?!.*(abc|def|ghi)).*$',
            description: $localize`:@@text.content.of.regex.dialog.table.row.26:Result must not include abc, def and ghi`
        },
        {
            expression: '^(?=.*abc)(?!.*def)(?!.*ghi).*',
            description: $localize`:@@text.content.of.regex.dialog.table.row.27:Result must include abc, but not def and ghi`
        },
        {
            expression: '^(?=.*abc).*|((?=.*def)(?=.*ghi)).*',
            description: $localize`:@@text.content.of.regex.dialog.table.row.28:Result must include abc or def and ghi`
        }
    ];

    static excludedFilterOptions = [
        {
            key: AppText.generalTextIsAll,
            value: null
        },
        {
            key: AppText.generalTextIsIncluded,
            value: false
        },
        {
            key: AppText.sortFieldIsExcluded,
            value: true
        }
    ];
    static boostedFilterOptions = [
        {
            key: AppText.generalTextIsAll,
            value: null
        },
        {
            key: AppText.generalTextIsEnabled,
            value: true
        },
        {
            key: AppText.generalTextIsDisabled,
            value: false
        }
    ];
    static platformFilterOptions = [
        {
            key: AppText.generalTextIsAll,
            value: null
        },
        {
            key: AppText.generalTextIsEbay,
            value: AppText.propertyNameIsEbayLister
        },
        {
            key: AppText.generalTextIsAmazon,
            value: AppText.propertyNameIsAmazonLister
        }
    ];
    static manuallyCreatedSearchRequestsFilterOptions = [
        {
            key: AppText.generalTextIsAll,
            value: null
        },
        {
            key: AppText.generalTextIsIncluded,
            value: false // Пишется в noManualSearchRequests, поэтому Included противоположно false
        },
        {
            key: AppText.sortFieldIsExcluded,
            value: true // Пишется в noManualSearchRequests, поэтому Excluded противоположно true
        }
    ];
    static competitorFilterOptions = [
        {
            key: AppText.generalTextIsAll,
            value: null
        },
        {
            key: AppText.pageHeaderIsOneCompetitor,
            value: true
        },
        {
            key: AppText.generalTextIsNotCompetitor,
            value: false
        }
    ];
    static validationFilterOptions = [
        {
            key: AppText.generalTextIsAll,
            value: null
        },
        {
            key: AppText.generalTextIsValidated,
            value: true
        },
        {
            key: AppText.generalTextIsNotValidated,
            value: false
        }
    ];

    static variationFilterOptions = [
        {
            key: AppText.generalTextIsAll,
            value: null
        },
        {
            key: AppText.generalTextIsVariant,
            value: true
        },
        {
            key: AppText.generalTextIsNotVariant,
            value: false
        }
    ];
    static validationMatchesRulesFilterOptions = [
        {
            key: AppText.generalTextIsAll,
            value: null
        },
        {
            key: AppText.generalTextIsMatch,
            value: true
        },
        {
            key: AppText.generalTextIsNotMatch,
            value: false
        }
    ];
    static matchingActions = [
        {
            key: MatchingAction.ASSIGN_COMPETITOR,
            value: AppText.generalTextIsAssignCompetitor
        },
        {
            key: MatchingAction.INCREASE_CONFIDENCE,
            value: AppText.generalTextIsIncreaseConfidence
        },
        {
            key: MatchingAction.ASSIGN_NOT_COMPETITOR,
            value: AppText.generalTextIsAssignNotACompetitor
        },
        {
            key: MatchingAction.NONE,
            value: AppText.generalTextIsNone
        },
        {
            key: MatchingAction.DECREASE_CONFIDENCE,
            value: AppText.generalTextIsDecreaseConfidence
        }
    ];
    static matchingModes = [
        {
            key: MatchingMode.TARGET_CONTAINS_SOURCE,
            value: AppText.generalTextIsTargetContainsSource
        },
        {
            key: MatchingMode.FULL_MATCH,
            value: AppText.generalTextIsFullMatch
        },
        {
            key: MatchingMode.ANY_CONTAINS_ANY,
            value: AppText.generalTextIsAnyContainsAny
        }
    ];
    static sourceArticles = [
        {
            key: SourceArticles.SET_AND_SUB_ARTICLES,
            value: AppText.generalTextIsSetAndSubArticles
        },
        {
            key: SourceArticles.SET_ARTICLE,
            value: AppText.generalTextIsSetArticle
        },
        {
            key: SourceArticles.SUB_ARTICLES,
            value: AppText.generalTextIsSubArticles
        }
    ];
    static dynamicKey = 'Dynamic' as RateType;
    static fixedKey = 'Fixed' as RateType;
    static adRateTypes = [
        {
            key: AppText.dynamicKey,
            value: AppText.generalTextIsDynamicRate,
            title: AppText.strategiesPageTextIsStrategyDynamicType,
            description: AppText.strategiesPageTextIsStrategyDynamicTypeDescription
        },
        {
            key: AppText.fixedKey,
            value: AppText.generalTextIsFixedRate,
            title: AppText.strategiesPageTextIsStrategyFixedType,
            description: AppText.strategiesPageTextIsStrategyFixedTypeDescription
        }
    ];

    static feedFileTypes: Array<{ id: string, title: string }> = [
        { id: 'csv', title: 'CSV' }
    ];

    static feedEncodingTypes: Array<{ id: string, title: string }> = [
        { id: 'utf-8', title: 'UTF-8' }
    ];

    static feedFieldsSeparators: Array<{ id: string, title: string }> = [
        { id: ',', title: 'Symbol \",\"' },
        { id: ';', title: 'Symbol \";\"' }
    ];

    static feedDecimalSeparators: Array<{ id: string, title: string }> = [
        { id: '.', title: '0.00' },
        { id: ',', title: '0,00' }
    ];

    static feedDateFormats: Array<{ id: string, title: string }> = [
        { id: 'dd-MMM-yyyy', title: '05-Apr-17' },
        { id: 'M/d/yyyy', title: '4/5/2017' },
        { id: 'M/d/yy', title: '4/5/17' },
        { id: 'MM/dd/yy', title: '04/05/17' },
        { id: 'MM/dd/yyyy', title: '04/05/2017' },
        { id: 'yy/MM/dd', title: '17/04/05' },
        { id: 'yyyy/MM/dd', title: '2017/04/05' },
        { id: 'yyyy-MM-dd', title: '2017-04-05' },
    ];

    static platformAccounts: Array<{ id: string, title: string }> = [
        { id: 'auto-carparts24', title: 'auto-carparts24 (eBay)' },
        { id: 'crw-carparts', title: 'crw-carparts (eBay)' },
        { id: 'crw-carparts24', title: 'crw-carparts24 (eBay)' },
        { id: 'crw-autoteile', title: 'crw-autoteile (eBay)' },
        { id: 'crw-autoteile', title: 'crw-autoteile (eBay)' },
        { id: 'parts-online24', title: 'parts-online24 (eBay)' },
        { id: 'qrparts24', title: 'qrparts24 (eBay)' },
        { id: 'Teile-Online24', title: 'Teile-Online24 (Amazon)' }
    ];
}
