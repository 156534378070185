import { Observable, Subscriber } from 'rxjs';
import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlSegment } from '@angular/router';
import { Response, Headers, ResponseOptions } from '@angular/http';
import { ApiService } from './abstracts/api.service';
import { ErrorResponse } from '.';
import { DataMock } from './data-mock';
import { DataType } from '../shared/types';
import { ItemList } from '../contracts';
import * as _ from 'underscore';

@Injectable({
    providedIn: 'root'
})
export class InMemoryApiService implements ApiService {
    private data!: DataType;
    urlSerializer: DefaultUrlSerializer;

    constructor(dataMock: DataMock) {
        this.urlSerializer = new DefaultUrlSerializer();
        this.data = dataMock.data;
    }

    apiUnsubscribe(): void {
        // TODO
    }

    get<TContract>(url: string): Observable<TContract> {
        const ctx = this;
        const id = this.getItemId(url);
        const key = this.getDataKey(url);
        console.log('GET ' + url);
        return new Observable((s: Subscriber<TContract>) => {
            ctx.doWithDelay(() => {
                let result = null;
                if (!id || key === '/matchingResults') {
                    if (url.includes('?')) {
                        let paramsBlock = url.split('?')[1];
                        let params = paramsBlock.split('&');
                        let pageParam = _.find(params, param => param.includes('page='));
                        let pageSizeParam = _.find(params, param => param.includes('pageSize='));

                        let page = parseInt(pageParam ? pageParam.split('=')[1] : '0');
                        let pageSize = parseInt(pageSizeParam ? pageSizeParam.split('=')[1] : '0');

                        let end = ctx.data[key].length - page * pageSize < pageSize;
                        if (!end && ctx.data[key]?.total > 0) {
                            result = new ItemList<TContract>({
                                page: page,
                                pageSize: pageSize,
                                total: ctx.data[key].data.length,
                                data: ctx.data[key].data.slice(page * pageSize, page * pageSize + pageSize)
                            });
                        } else {
                            result = ctx.data[key].slice(page * pageSize, ctx.data[key].length);
                        }
                    } else {
                        result = ctx.data[key];
                    }
                } else {
                    result = ctx.data[key];
                }
                if (result) {
                    s.next(result);
                    s.complete();
                } else {
                    const response = this.getItemNotFoundResponse(id);
                    s.error(response);
                    s.complete();
                }
            });
        });
    }

    post<TContract, TResponseContract>(url: string, payload: TContract): Observable<TResponseContract> {
        console.log('POST ' + url + ' ' + JSON.stringify(payload));
        const ctx = this;
        const key = this.getDataKey(url);
        return new Observable((s: Subscriber<TResponseContract>) => {
            ctx.doWithDelay(() => {
                this.data[key]['data'] = !this.data[key]['data'] ? [] : this.data[key]['data'];
                this.data[key]['data'].push(payload);
                (<any>payload).id = this.getRandomId();
                s.next(<any>payload);
                s.complete();
            });
        });
    }

    patch<TContract, TResponseContract>(url: string, payload: TContract): Observable<TResponseContract> {
        console.log('PATCH ' + url + ' ' + JSON.stringify(payload));
        const ctx = this;
        const key = this.getDataKey(url);
        return new Observable((s: Subscriber<TResponseContract>) => {
            ctx.doWithDelay(() => {
                this.data[key]['data'] = !this.data[key]['data'] ? [] : this.data[key]['data'];
                this.data[key]['data'].push(payload);
                (<any>payload).id = this.getRandomId();
                s.next(<any>payload);
                s.complete();
            });
        });
    }

    put<TContract, TResponseContract>(url: string, payload: TContract): Observable<TResponseContract> {
        console.log('PUT ' + url + ' ' + JSON.stringify(payload));

        const ctx = this;
        const key = this.getDataKey(url);
        const id = this.getItemId(url);

        return new Observable((s: Subscriber<TResponseContract>) => {
            ctx.doWithDelay(() => {
                const foundItem = _.find(ctx.data[key]['data'], (i: any) => i.id === id);
                if (foundItem) {
                    for (const i in payload) {
                        // if (item.hasOwnProperty(i)) {
                        foundItem[i] = payload[i];
                        // }
                    }
                    s.next(foundItem);
                    s.complete();
                } else {
                    const response = this.getItemNotFoundResponse(id);
                    s.error(response);
                    s.complete();
                }
            });
        });
    }

    delete<TContract>(url: string, payload?: any): Observable<TContract> {
        console.log('DELETE ' + url);
        const ctx = this;
        const id = this.getItemId(url);
        const key = this.getDataKey(url);
        return new Observable<TContract>((s: Subscriber<TContract>) => {
            ctx.doWithDelay(() => {
                const foundItem = _.find(ctx.data[key]['data'], (i: any) => {
                    return i['id'] === id;
                });
                if (foundItem) {
                    ctx.data[key]['data'] = _.without(ctx.data[key]['data'], foundItem);
                    s.next();
                    s.complete();
                } else {
                    const response = this.getItemNotFoundResponse(id);
                    s.error(response);
                    s.complete();
                }
            });
        });
    }

    download<Blob>(): Observable<Blob> {
        let ctx = this;
        return new Observable((s: Subscriber<Blob>) => {
            s.next();
            s.complete();
        });
    }

    private getItemId(url: string): string | null {
        url = this.trimUrl(url);
        const tree = this.urlSerializer.parse(url);
        const segments = tree.root.children['primary'].segments;

        const id = parseInt(segments[segments.length - 1].path);

        return !id ? null : id.toString();
    }

    private getDataKey<TContract>(url: string): string {
        url = this.trimUrl(url);
        const tree = this.urlSerializer.parse(url);
        // get all segments without last one
        let segments = tree.root.children['primary'].segments;
        if (segments.length === 1) {
            return url;
        }
        // segments = _.without(segments, segments[segments.length - 1]);
        const reducedUrl = _.reduce(
            _.map(segments, (s: UrlSegment) => s.path),
            (a, b) => a + '/' + b,
            ''
        );
        return reducedUrl;
    }

    private trimUrl(url: string) {
        if (url.startsWith('http')) {
            const len = 'https://'.length;
            const trimFrom = url.indexOf('/', len);
            url = url.substr(trimFrom, url.length - trimFrom);
        }
        // Remove query string
        url = url.split('?')[0];
        return url;
    }

    private getItemNotFoundResponse(id: string | null) {
        const error = new ErrorResponse();
        error.errorMessage = 'Item not found';

        return new Response({
            status: 404,
            body: error,
            headers: new Headers(),
            url: null,
            merge: () => new ResponseOptions()
        });
    }

    private getRandomId() {
        return Math.round(Math.random() * 100000).toString();
    }

    private doWithDelay(f: any) {
        setTimeout(f, 4000);
    }
}
