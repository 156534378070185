/**
 * UserActionPopupComponent - компонент, появляющийся при вызове метода show* из UserActionService
 * принимает данные для отображения с типом UserActionPopupData
 */

import { Component, OnInit, Inject, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { UserActionPopupData } from './user-action-popup.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

const SUCCESS_TYPE = 'success';
const WARN_TYPE = 'warn';
const ERROR_TYPE = 'error';
const PROCESS_TYPE = 'process';
@Component({
    selector: 'app-user-action-popup',
    templateUrl: './user-action-popup.component.html',
    styleUrls: ['./user-action-popup.component.scss']
})
export class UserActionPopupComponent implements AfterViewInit {
    public get successType(): boolean {
        return this.config?.type == SUCCESS_TYPE;
    }

    public get warnType(): boolean {
        return this.config?.type == WARN_TYPE;
    }

    public get errorType(): boolean {
        return this.config?.type == ERROR_TYPE;
    }

    public get processType(): boolean {
        return this.config?.type == PROCESS_TYPE;
    }

    public get popupSubheader(): string {
        if (this.warnType) {
            return 'Warning';
        }
        if (this.errorType) {
            return 'Error';
        }
        return '';
    }

    constructor(private dialogRef: MatDialogRef<UserActionPopupComponent>, @Inject(MAT_DIALOG_DATA) public config: UserActionPopupData) {
        // TODO
    }

    public ngAfterViewInit(): void {
        // const overlayPanes = document.getElementsByClassName('cdk-overlay-pane');
        // // tslint:disable-next-line:prefer-for-of
        // for (let i = 0; i < overlayPanes.length; i++) {
        //   const isActionOverlayPane = overlayPanes[i].getElementsByClassName('uniq-action-popup-class-name') ?
        //     overlayPanes[i].getElementsByClassName('uniq-action-popup-class-name')[0] : null;
        //   if (isActionOverlayPane) {
        //     overlayPanes[i].setAttribute('style', 'min-height:unset!important;');
        //   }
        // }
    }

    public onButtonClick(value: any): void {
        this.dialogRef.close(value);
    }
}
