import { ArticleModel } from "src/app/contracts";
import { TagModel } from "src/app/tags/shared/tag.model";

export class FoundItemsModel {
    id!: string;
    title!: string | null;
    subTitle!: string | null;
    notExistInDatabase!: boolean; // чтобы отличать от нового созданного на фронте
    itemIncludedInAllSelectedVariables?: boolean;

    constructor(data: any) {
        if (data) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    this[key as keyof FoundItemsModel] = <never>data[key];
                }
            }
        }
    }
}