import { ConnectionPositionPair, Overlay, OverlayConfig, OverlayRef, PositionStrategy, ScrollStrategyOptions } from "@angular/cdk/overlay";
import { ComponentPortal, PortalInjector } from "@angular/cdk/portal";
import { Component, ComponentRef, EventEmitter, HostListener, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { AppText } from "src/app/text/app-text";
import { FoundItemsModel } from "../found-items-list/found-items-model";
import { TagModel } from "src/app/tags/shared/tag.model";
import { TEXTAREA_AUTOCOMPLETE_OVERLAY_PANEL, TextareaAutocompleteOverlayComponent } from "../textarea-autocomplete/textarea-autocomplete-overlay/textarea-autocomplete-overlay.component";
import { findAllTagsPattern, searchLastTagPattern } from "../../patterns-config";
import { getTextWithQuotationMarks } from "../../utils/util-functions";

@Component({
    selector: 'app-search',
    templateUrl: './app-search.component.html',
    styleUrls: ['./app-search.component.scss']
})
export class AppSearchComponent implements OnInit, OnDestroy, OnChanges {
    /**
     * Строка поиска
     * @type {string}
     */
    @Input() borderRadiusRightDisable!: boolean;

    /**
     * Строка поиска
     * @type {string}
     */
    @Input() borderRadiusLeftDisable!: boolean;

    /**
     * Выключить бордеры
     */
    @Input() borderDisable!: boolean;

    /**
     * Строка поиска
     * @type {string}
     */
    @Input() search!: string;

    /**
     * Паттерн для поля поиска
     * @type {RegExp}
     */
    @Input() searchPattern!: RegExp;

    /**
     * Флаг включения события поиска по нажатию на Enter
     * @type {boolean}
     */
    @Input() searchByEnter: boolean = true;

    /**
     * Флаг расположения иконки поиска до или после инпута
     * @type {string}
     */
    @Input() searchIconAfter: boolean = true;

    /**
     * Плейсхолдер поля поиска
     * @type {string}
     */
    @Input() placeholder!: string;

    /**
     * Флаг блокировки поиска
     * @type {boolean}
     */
    @Input() disabled: boolean = false;

    /**
     * Распознование тегов в строке
     * @type {boolean}
     */
    @Input() tagRecognitionModeEnable: boolean = false;

    /**
     * Список тегов для отображения
     */
    @Input()  tags: Array<FoundItemsModel> = [];

    /**
     * Событие о том, что строка поиска изменилась
     * @type {EventEmitter<string>}
     */
    @Output() searchChanged!: EventEmitter<string>;

    /**
     * Событие о том, что последний тег изменился
     * @type {EventEmitter<string>}
     */
    @Output() lastTagChanged!: EventEmitter<string>;

    /**
     * Поиск тега в конце строки.
     * Он обязательно должен начинаться с решетки
     */
    private lastTagPattern: RegExp = searchLastTagPattern;
    private quotationMarksForTags: Array<string> = ['#'];
    /**
     * Последний тег в строке
     */
    private lastTagName: string = '';
    /**
     * Для подписок
     */
    private componentSubscriptions: Subscription[] = [];
    /**
     * Объект overlay с тегами
     */
    private tagListOverlayRef!: OverlayRef;
    /**
     * Объект компонент с тегами
     */
    private tagListOverlayComponentRef!: ComponentRef<TextareaAutocompleteOverlayComponent>;
    /**
     * Флаг состояния панели с тегами - открыта или закрыта
     */
    public get tagListOverlayOpened(): boolean {
        return this.tagListOverlayRef && this.tagListOverlayRef.hasAttached() ? true : false;
    }
    /**
     * Элемент относительно которого будет overlay с тегами
     */
    public get targetElementForTagListOverlay(): any {
        return this.searchInputBottomLine;
    }

    public get searchFocused(): boolean {
        return document?.activeElement?.id === 'searchInput';
    }

    public get lastIndexOfLastTagName(): number {
        if (this.search || this.lastTagName) {
            let lastIndex = this.search.lastIndexOf(this.lastTagName);
            if (this.search.length === this.lastTagName.length) {
                return lastIndex;
            }
            return (lastIndex + this.lastTagName.length) === this.search.length ? 0 : lastIndex;
        }
        return -1;
    }

    constructor(
        private overlay: Overlay,
        private injector: Injector,
        private scrollStrategyOptions: ScrollStrategyOptions
    ) {
        this.componentSubscriptions = [];
        this.borderRadiusRightDisable = false;
        this.placeholder = AppText.searchAction;
        this.searchChanged = new EventEmitter<string>();
        this.lastTagChanged = new EventEmitter<string>();
    }

    /**
     * Слушает нажатие esc и закрывает окно
     * @param event событие гнажатие esc
     */
    @HostListener('document:keyup.escape', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        if (this.tagRecognitionModeEnable && this.tagListOverlayOpened) {
            event?.stopPropagation();
            this.closeTagListOverlay();
        }
    }

    /**
     * Слушает нажатие enter и прервыет событие, чтобы пользователь выбрал по enter тег
     * @param event событие гнажатие enter
     */
    @HostListener('document:keydown.enter', ['$event'])
    handleKeyboardEnterEvent(event: KeyboardEvent): boolean {
        if (this.tagRecognitionModeEnable && (this.tagListOverlayOpened || this.searchFocused)) {
            event?.stopPropagation();
            event?.preventDefault();
            return false;
        }
        return true;
    }

    @ViewChild('searchInputBottomLine') searchInputBottomLine!: any;
    @ViewChild('searchInput') searchInput!: any;

    public ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
        if (changes['tags'] && this.tagRecognitionModeEnable) {
            if (!this.tags?.length) {
                this.closeTagListOverlay();
            }
            else {
                if (this.userEnteredExistingItem()) {
                    this.closeTagListOverlay();
                }
                else {
                    if (this.tagListOverlayOpened) {
                        this.tagListOverlayComponentRef?.instance?.parentItemsChange.next(this.tags);
                    }
                    else if (this.lastTagName && this.searchFocused && !this.lastIndexOfLastTagName) {
                        this.onOpenTagListOverlay();
                    }
                }
            }
        }
    }

    public ngOnInit(): void {
        // TODO
    }

    public ngOnDestroy(): void {
        this.closeTagListOverlay();
    }

    /**
     * Метод поиска - изменяет строку поиска
     * @param {string} search Строка поиска
     */
    public onSearch(search?: string, event?: any, sendEvent: boolean = true): void {
        event?.stopPropagation();
        this.search = search || this.search;
        this.findTagNameAtEndOfSearch();
        if (sendEvent && !this.tagListOverlayOpened) {
            this.searchChanged.next(this.search);
        }
    }

    /**
     * Метод изменяет строку поиска, сбрасывая ее совсем
     */
    public onResetSearch(event?: any): void {
        event?.stopPropagation();
        this.search = '';
        this.findTagNameAtEndOfSearch();
        this.searchChanged.next(this.search);
    }

    /**
     * Выбор тега из списка
     */
    public onSelectTag(seletcedTag: FoundItemsModel | null): void {
        if (!seletcedTag) { // this.tagAlreadyExistInSearchField(seletcedTag?.title + '')
            this.search = this.search.trim() + ' ';
            this.closeTagListOverlay();
            return;
        }
        let lastTagNameIndex = this.search?.lastIndexOf(this.lastTagName);
        this.search = this.search?.substring(0, lastTagNameIndex);
        this.search = this.search?.trim();
        if (this.search?.length) {
            let spaceChar = this.search[this.search.length - 1] != '-' ? ' ' : '';
            this.search += `${spaceChar}${getTextWithQuotationMarks(seletcedTag.title + '', this.quotationMarksForTags)} `;
        }
        else {
            this.search += `${getTextWithQuotationMarks(seletcedTag.title + '', this.quotationMarksForTags)} `;
        }
        this.lastTagName = this.search?.match(this.lastTagPattern)?.[0] || '';
        this.closeTagListOverlay();
        this.lastTagChanged.next('');
        // this.searchChanged.next(this.search);
    }

    /**
     * Проверяет позицию каретки, после ее установки
     * Если кликнули в конце строки и там же есть тег, то откроется окно
     */
    public onCheckCaretPosition(): void {
        if (!this.searchInput) {
            return; 
        }
        if (!this.searchInput?.nativeElement?.selectionEnd && (this.searchInput?.nativeElement?.selectionEnd + '') !== '0') {
            return; 
        }
        let currentCaretPosition = Number(this.searchInput.nativeElement.selectionEnd) || 0;
        let caretPositionInEndOfString = currentCaretPosition === this.search?.length;
        if (this.lastTagName && caretPositionInEndOfString &&
            !this.tagListOverlayOpened && this.tags?.length && !this.userEnteredExistingItem()
            && !this.lastIndexOfLastTagName) {
            this.onOpenTagListOverlay();
        }
    }

    /**
     * Поиск тега в конце строки
     */
    protected findTagNameAtEndOfSearch(): void {
        this.lastTagName = this.search?.match(this.lastTagPattern)?.[0] || '';
        if (this.lastTagName?.length) {
            this.lastTagChanged.next(this.lastTagName);
        }
        else if (this.tagListOverlayOpened) {
            this.closeTagListOverlay();
        }
    }

    /**
     * Проверка на присутствие тега в строке поиска
     * @param tagName имя тега, уникальное
     * @returns истинно, если результат отвечает условию
     */
    protected tagAlreadyExistInSearchField(tagName: string): boolean {
        if (!this.search || !tagName) {
            return false;
        }
        let searchTags: string[] = this.search?.match(findAllTagsPattern) || [];
        const foundTagInSearch = searchTags?.findIndex((_tagName: string) => _tagName === getTextWithQuotationMarks(tagName, this.quotationMarksForTags));
        return foundTagInSearch > -1 ? true : false;
    }

    /**
     * Пользователь сам ввел тег руками, который нашелся в списке,
     * т е выбирать его из списка тег нет необходимости, поэтому панель должна закрываться
     * @returns истинно, если результат отвечает условию
     */
    protected userEnteredExistingItem(): boolean {
        if (this.tags?.length == 1 && this.tags[0] && getTextWithQuotationMarks(this.tags[0].title || '', this.quotationMarksForTags) === this.lastTagName) {
            return this.tagAlreadyExistInSearchField(this.tags[0].title + '');
        }
        return false;
    }

    /**
     * Метод открытия панели со списком
     */
    private onOpenTagListOverlay(): void {
        if (this.tagListOverlayOpened || !this.targetElementForTagListOverlay) {
            return;
        }
        this.tagListOverlayRef = this.overlay.create(this.createTagListOverlayConfig());
        let componentRef = new ComponentPortal(
            TextareaAutocompleteOverlayComponent,
            null,
            this.createInjectorForTagListOverlay({ items: this.tags, customStyleClassForList: 'search-custom-style' })
        );
        this.tagListOverlayComponentRef = this.tagListOverlayRef.attach(componentRef);
        this.subscribeTagListOverlayEvents();
    }

    /**
     * Создание конфига для панели с элементами
     * @returns объект с конфигурацией
     */
    private createTagListOverlayConfig(): OverlayConfig {
        return new OverlayConfig({
            // Цепляемся к элементу, указываем позицию относительно элемента
            positionStrategy: this.createTagListOverlayPositionStrategy(this.targetElementForTagListOverlay),
            scrollStrategy: this.scrollStrategyOptions.block(), // блокируется скроллинг на заднем плане
            hasBackdrop: false,
            backdropClass: 'backdrop',
            width: '504px',
            height: '340px',
        });
    }

    /**
     * Создание инжектора для передачи данных в панель
     */
    private createInjectorForTagListOverlay(data: any): PortalInjector {
        return new PortalInjector(this.injector, new WeakMap<any, any>([[TEXTAREA_AUTOCOMPLETE_OVERLAY_PANEL, data]]));
    }

    /**
     * Стратегия для расположения панели overlay
     * @param originElement Элемента относительно которого будет панель overlay
     * @returns объект с настройками позиции панели overlay
     */
    private createTagListOverlayPositionStrategy(originElement: any): PositionStrategy {
        const positionStrategy = this.overlay.position()
            .flexibleConnectedTo(originElement)
            .withPositions(this.createTagListOverlayConnectionPositionPair())
            .withPush(false);

        return positionStrategy;
    }

    /**
     * Точки соединения родительского элемента и элемента панели
     * @returns объект с парами - обозначения позиции
     */
    private createTagListOverlayConnectionPositionPair(): ConnectionPositionPair[] {
        return [
            {
                originX: 'start',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'top',
            },
            {
                originX: 'start',
                originY: 'bottom',
                overlayX: 'end',
                overlayY: 'bottom'
            },
        ];
    }

    /**
     * Подписка на все события overlay
     */
    private subscribeTagListOverlayEvents(): void {
        if (!this.tagListOverlayComponentRef) {
            return;
        }
        const itemChangeSub = this.tagListOverlayComponentRef.instance.itemChange
            .subscribe((res: FoundItemsModel | null) => {
                this.onSelectTag(res);
            });
        const insideOverlayClosedSub = this.tagListOverlayComponentRef.instance.insidePanelClose
            .subscribe(() => {
                this.closeTagListOverlay();
            });

        const outsidePointerEventsSub = this.tagListOverlayRef.outsidePointerEvents()
            .subscribe((res: any) => {
                if (res?.target?.id !== 'searchInput') {
                    this.closeTagListOverlay();
                }
            });
        this.componentSubscriptions.push(itemChangeSub);
        this.componentSubscriptions.push(insideOverlayClosedSub);
        this.componentSubscriptions.push(outsidePointerEventsSub);
    }

    /**
     * Закрытие панели со списком
     */
    private closeTagListOverlay(): void {
        this.unsubscribeFromAllTagsListOverlayEvents();
        if (!this.tagListOverlayOpened) {
            return;
        }
        this.tagListOverlayRef.detach();
    }

    /**
     * Отписки
     */
    private unsubscribeFromAllTagsListOverlayEvents(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.forEach((s: Subscription) => s.unsubscribe());
            this.componentSubscriptions = [];
        }
    }
}