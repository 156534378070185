<div class="app-content" fxLayout="row" fxLayoutAlign="start space-between">
    <div class="app-content-wrapper" fxLayout="column" fxLayoutAlign="start stretch" fxFlex="auto" fxLayoutGap="24px">
        <app-header [labelVisible]="false" [backButtonVisible]="false"></app-header>

        <div class="table-container block" fxLayout="column" fxLayoutAlign="center center">
            <div class="email-confirmation-container"
            [class.progress]="isVerificationInProgress || isVerificationFailed"
            fxLayout="column" fxLayoutAlign="center center">
                @if (isEbayAuthFailed || isVerificationFailed) {
                    <div class="email-confirmation__success-icon" style="line-height: 14px;">
                        <svg-icon key="error" fontSize="90px" color="#F55826"></svg-icon>
                    </div>
                    <h3 class="email-confirmation__title" i18n="general.text.is.email.confirmation.failed.title">Error</h3>
                    @if (isEbayAuthFailed) {
                        <span class="email-confirmation__info" i18n="platform.account.verification.page.text.is.authorization.failed.on.ebay.side">Authorization failed on eBay's side.</span>
                    }
                    @else {
                        <span class="email-confirmation__info" i18n="platform.account.verification.page.text.is.authorization.failed.your.link.is.probably.out.of.date">Authorization failed. Your link is probably out of date.</span>
                    }
                    <div class="resend-confirmation">
                        <span i18n="platform.account.verification.page.text.is.please.try.again">Please try again. </span>
                        <span class="btn-link" i18n="platform.account.verification.page.text.is.go.to.my.eBay" (click)="goToEbay()">Go to My eBay</span>
                    </div>
                }
                @else if (isVerificationSuccess) {
                    <div class="email-confirmation__success-icon" style="line-height: 14px;">
                        <svg-icon key="check" fontSize="75px" color="#00CC66"></svg-icon>
                    </div>
                    <h3 class="email-confirmation__title" i18n="platform.account.verification.page.text.is.successfully.added">Successfully Added</h3>
                    <span class="email-confirmation__info" i18n="platform.account.verification.page.text.is.your.ebay.account.has.been.added">
                        Your eBay account has been added to the Repricer</span>
                }
                @else {
                    <div class="email-confirmation__success-icon" style="line-height: 14px;">
                        <svg-icon key="clock" fontSize="90px" color="#00CC66"></svg-icon>
                    </div>
                    <h3 class="email-confirmation__title" i18n="general.text.is.email.verification.in.progress">Verification in progress</h3>
                    <span class="email-confirmation__info" i18n="general.text.is.please.do.not.refresh.or.leave.the.page">Please do not refresh or leave the page while we are processing your request</span>
                }
            </div>
        </div>
    </div>
</div>
