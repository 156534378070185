import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ListItem } from "../../contracts";

@Component({
  selector: 'app-sort',
  templateUrl: './app-sort.component.html',
  styleUrls: ['./app-sort.component.scss']
})
export class AppSortComponent implements OnInit {
  /**
   * Выбранное поле сортировки
   */
  protected selectedField!: Array<ListItem>;

  /**
   * Активное поле сортировки
   * @type {string}
   */
  @Input() orderBy!: string;

  /**
   * Флаг блокировки
   * @type {boolean}
   */
  @Input() disabled!: boolean;


  /**
   * Активное направление сортировки
   * true - сотривка с конца
   * @type {boolean}
   */
  @Input() orderDesc!: boolean;

  /**
   * Все поля для сортировки
   * Содержит ключ и значение для отображения на фронте
   * Данные нужны в таком формате потому что компонент
   * работает с другим компонентом - app-multiselect
   * @type {Array<ListItem>}
   */
  @Input() sortFields!: Array<ListItem>;

  /**
   * Событие о том, что изменилось поле сортировки
   * @type {EventEmitter<string>}
   */
  @Output() orderFieldChanged!: EventEmitter<string>;

  /**
   * Событие о том, что изменилось направление сортировки
   * @type {EventEmitter<boolean>}
   */
  @Output() orderDirectionChanged!: EventEmitter<boolean>;

  /**
   * Событие о том, что изменилась сортировка
   * @type {EventEmitter<boolean>}
   */
  @Output() sortChanged!: EventEmitter<{ orderBy: string, orderDesc: boolean}>;

  constructor() {
    this.sortFields = [];
    this.selectedField = [];
    this.orderBy = 'id';
    this.orderDesc = false;
    this.sortFields = this.defaultSort();
    this.orderFieldChanged = new EventEmitter<string>();
    this.orderDirectionChanged = new EventEmitter<boolean>();
    this.sortChanged = new EventEmitter<{ orderBy: string, orderDesc: boolean }>();
  }

  /**
   * Инициализация компонента
   */
  public ngOnInit(): void {
    let selectedItem = this.sortFields.find((f: ListItem) => { return f.key == this.orderBy });
    if (selectedItem) {
      this.selectedField.push(selectedItem);
    }
    else {
      this.selectedField.push(this.sortFields[0]);
      this.orderBy = this.selectedField[0].key + '';
      this.onSortChange(this.orderBy, this.orderDesc);
    }
  }

  /**
   * Метод меняет поле сортировки и отправляет события
   * Одна на отдельное поле, которое изменилось, второе для обоих полей
   * @param {ListItem | null} selectedOrderField Выбранный элемент из полей сортирвки
   */
  public onOrderBy(selectedOrderField: ListItem | null): void {
    if (selectedOrderField) {
      this.orderBy = selectedOrderField.key + '';
      this.orderFieldChanged.next(this.orderBy);
      this.onOrderDesc(true);
    }
  }

  /**
   * Метод меняет направление сортировки и отправляет события
   * Одна на отдельное поле, которое изменилось, второе для обоих полей
   * @param {boolean} orderDesc Направление сортировки
   */
  public onOrderDesc(orderDesc: boolean): void {
    this.orderDesc = orderDesc + '' === 'true' ? false : true;
    this.orderDirectionChanged.next(this.orderDesc);
    this.onSortChange(this.orderBy, this.orderDesc);
  }

  /**
   * Метод отправляет событие о том, что сортировка изменилась
   * Сделано так чтобы не переделывать компонент app-list и работу с таблицами
   * @param {string} orderByField Поле сортировки
   * @param {boolean} orderDescField Направление сортировки
   */
  public onSortChange(orderByField: string, orderDescField: boolean): void {
    setTimeout(() => {
      this.sortChanged.next({ orderBy: orderByField, orderDesc: orderDescField });
    }, 0);
  }

  /**
   * Возвращает дефолтный список сортировки
   * На случай если с родителя ничего не пришло - чтобы этот
   * компонент работал на начальном этапе подключения
   * @returns {Array<ListItem>}
   */
  private defaultSort(): Array<ListItem> {
    return [
      new ListItem({
        key: this.orderBy,
        value: this.orderBy?.toLocaleUpperCase(),
      })
    ];
  }
}
