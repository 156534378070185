export class FilterSettings {
    sidePanel!: boolean;

    articleFilters!: boolean;
    articleGroupFilter!: boolean;
    strategiesFilter!: boolean;
    carsFilter!: boolean;
    articleDisplayOptions!: boolean;
    articlesExcludedOption!: boolean;
    articlesWithoutCompetitorsOption!: boolean;
    manuallyCreatedSearchesFilter!: boolean;

    competitorFilters!: boolean;
    ruleFilters!: boolean;
    decisionRuleFilter!: boolean;
    rulesMatchFilter!: boolean;
    confidenceFilters!: boolean;
    fromConfidenceFilter!: boolean;
    toConfidenceFilter!: boolean;
    competitorDisplayOptions!: boolean;
    competitorsOption!: boolean;
    markedAsCompetitorsFilter!: boolean;
    validationCompetitorsFilter!: boolean;
    variationCompetitorsFilter!: boolean;
    validationMatchesRulesCompetitorsFilter!: boolean;
    rankFilters!: boolean;
    priceDifferenceFilters!: boolean;
    priceDifferenceCompetitorFilters!: boolean;
    articlesBoostedFilter!: boolean;
    articlesPlatformFilter!: boolean;

    platformFilters!: boolean;
    priceFilters!: boolean;
    hsnTsnFilters!: boolean;

    competitorsWithoutSales!: boolean;

    constructor(data?: any) {
        if (data) {
            for (const i in data) {
                if (data.hasOwnProperty(i)) {
                    this[i as keyof FilterSettings] = data[i];
                }
            }
        }
    }
}
