/**
 * функция для глубокого копирования любого JSON-валидного объекта
 * @param object - объект для копирования
 */

import { Params } from '@angular/router';
import { ErrorResponse } from 'src/app/api/error-response';
import { SimpleDataFilter } from 'src/app/contracts/simple-data-filter';
import { AppText } from 'src/app/text/app-text';
import { ListItem } from '../contracts';
import { DateTime } from './date-time';
import { FoundItemsModel } from '../components/found-items-list/found-items-model';
import { TagModel } from 'src/app/tags/shared/tag.model';
import { StringGenerator } from './string-generator';
import { MessageService } from '../services';
import { RouterHistoryModel, RouterHistoryService } from '../services/router-history.service';
import { FormGroup, ValidatorFn } from '@angular/forms';
import * as _ from 'underscore';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { CarModel } from '../contracts/car';

export function cloneDeep<T>(object: T, type?: { new(value: any): T }): T {
    return type ? object && new type(JSON.parse(JSON.stringify(object))) : object && JSON.parse(JSON.stringify(object));
}

export function cloneDeepArray<T>(list: T[], type?: { new(value: any): T }): T[] {
    return list.map(item => {
        return cloneDeep<T>(item, type);
    });
}

/**
 * Возвращает укороченный вариант имени и фамилии в виде строки из первых букв каждого слова
 * @param name имя (напр. человека в формате "имя фамилия" или бота)
 */
export function getShortName(name: string): string {
    const cut: string[] = name.split(' ');
    if (cut.length === 1) {
        return cut[0].substring(0, 2).toUpperCase();
    }
    if (cut.length > 1) {
        return cut[0].substring(0, 1).toUpperCase() + cut[1].substring(0, 1).toUpperCase();
    }
    return '';
}
/**
 * отрезает расширение файла
 * @param name имя файла с расширением
 */
export function cutFileNameExtention(name: string): string {
    let res = name.split('.');
    res.pop();
    return res.join('.');
}

/**
 * возвращает разницу unix дат между текущим временем и 00:00 текущего дня по местному времени
 */
export function getStartOfTheCurrentDay(): number {
    let now = new Date();
    let startOfDay: Date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return Math.floor((now.getTime() - startOfDay.getTime()) / 1000);
}

/**
 * возвращает разницу unix дат между текущим временем и 00:00 понедельника текущей недели по местному времени
 */
export function getStartOfTheCurrentWeek(): number {
    const dayToSet = 1; // monday
    let now = new Date();
    let startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    let currentDay = startOfDay.getDay();
    let distance = dayToSet - currentDay;
    startOfDay.setDate(startOfDay.getDate() + distance);
    return Math.floor((now.getTime() - startOfDay.getTime()) / 1000);
}

/**
 * возвращает разницу unix дат между текущим временем и 00:00 первого дня текущего месяца по местному времени
 */
export function getStartOfTheCurrentMonth(): number {
    const dayToSet = 1; // первый день месяца
    let now = new Date();
    let startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    startOfDay.setDate(dayToSet);
    return Math.floor((now.getTime() - startOfDay.getTime()) / 1000);
}

/**
 * Сравнить некоторое количество массивов на эквивалетность. Вернет true, если все массивы одинаковые
 * @param arrays массивы для сравнения
 */
export function compareArrays(...arrays: any[][]): boolean {
    const areAllArraysExists = arrays.every(array => !!array);
    if (!areAllArraysExists) {
        return false;
    }
    const sortedArrays = arrays.map(array => array.sort());
    const stringifiedArrays: string[] = sortedArrays.map(array => JSON.stringify(array));
    return stringifiedArrays.every(stringifiedArray => stringifiedArray === stringifiedArrays[0]);
}

export function getStringFromTime(milliseconds: number | null, isPeriodStart: boolean = true): string | null {
    if (milliseconds == null) return null;

    if (!milliseconds) return isPeriodStart ? '00:00' : '23:59';

    let minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    let hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

    let isHoursLessThanTen = hours ? hours.toString().length < 2 : false;
    let isMinutesLessThanTen = minutes ? minutes.toString().length < 2 : false;

    return `${hours ? (isHoursLessThanTen ? '0' : '') + hours + ':' : '00:'}${minutes ? (isMinutesLessThanTen ? '0' : '') + minutes + '' : '00'}`;
}

export function getTimeFromString(timeString: string | null, isPeriodStart: boolean = true, isEnableSeconds: boolean = false): number {
    if (!timeString) return 0;

    let seconds: string | number = timeString.split(':')?.[2];
    let minutes: string | number = timeString.split(':')?.[1];
    let hours: string | number = timeString.split(':')?.[0];

    minutes = !minutes || minutes == '' ? (isPeriodStart ? '00' : '59') : minutes;
    hours = !hours || hours == '' ? (isPeriodStart ? '00' : '23') : hours;

    // По умолчанию
    if (!isEnableSeconds) {
        seconds = minutes == '00' ? '00' : '59';
    } else {
        seconds = !seconds || seconds == '' ? (isPeriodStart ? '00' : '59') : seconds;
    }

    seconds = Number(seconds) * 1000;
    minutes = Number(minutes) * (1000 * 60);
    hours = Number(hours) * (1000 * 60 * 60);

    return hours + minutes + seconds;
}

// если UTC+10, то метод вернёт -600 (в минутах)
// если UTC-10, то метод вернёт +600
export function _getTimezoneOffset(): number | null {
    return new Date().getTimezoneOffset();
}

// В календарях всегда дата выбирается в формате UTC
// (вместо полуночи будет 18:00 часов предыдущего дня (-6 Омск))
// Но серверу нам нужно отправить ровно 00:00:00 в UTC для fromDate и 23:59:59 в UTC для toDate
// Поэтому надо прибавлять разницу часов в UTC и Local к текущей дате
// time приходит как строка например '10:00'
// Возвращает дату со временем 00:00:00 для fromDate и 23:59:59 для toDate в формате числа
export function getTimeForServer(dateAsNumber: number | null, isStartDate: boolean = true, time: string | null = null): number | null {
    let date: number | null = null;

    if (dateAsNumber == null) return date;

    dateAsNumber = Number(dateAsNumber);

    // getHours() - всегда 0 или 23(за исключением летнего времени - 23 и 22)
    let LOCALHours = new Date(Math.round(dateAsNumber * 1000)).getHours();
    let UTCHours = new Date(Math.round(dateAsNumber * 1000)).getUTCHours();

    // разница часов в UTC(GMT) и Local Time
    // (вместо TimezoneOffset, потому что оно не учитывает летнее время)
    let differenceInHours = isStartDate ? 24 - UTCHours : 23;

    // Привести к 00:00:00 по UTC для fromDate - if isStartDate
    // Привести к 23:59:59 по UTC для toDate - if !isStartDate
    let newDate = isStartDate
        ? Math.round(
            new Date(new DateTime(dateAsNumber).toLocalDate().setHours(new DateTime(dateAsNumber).toLocalDate().getHours() + (24 - UTCHours))).getTime() /
            1000
        )
        : Math.round(new Date(new DateTime(dateAsNumber).toLocalDate().setUTCHours(23, 59, 59, 59)).getTime() / 1000);

    // Вычет времени, если есть доп параметры
    if (time != null) {
        if (isStartDate) {
            let t = getTimeFromString(time);

            newDate += Math.round(t / 1000);
        } else {
            let t = getTimeFromString(time, false);

            newDate -= 86399; // 23:59:59

            newDate += Math.round(t / 1000);
        }
    }

    return newDate;
}

export function checkDatesIntoParams(_p?: SimpleDataFilter): SimpleDataFilter {
    let _param!: SimpleDataFilter;

    if (_p) {
        _param = JSON.parse(JSON.stringify(_p));
    }

    _param = _param ? _param : new SimpleDataFilter({});

    // fromDate
    // Установить 00:00:00
    _param.fromDate = getTimeForServer(_param.fromDate, true, _param.fromTime);

    // toDate
    // Установить 23:59:59
    _param.toDate = getTimeForServer(_param.toDate, false, _param.toTime);

    _param.fromDateAsString = _param.fromDate != null ? moment(new DateTime(Number(_param.fromDate)).toLocalDate()).format('YYYY-MM-DD') : null;

    _param.toDateAsString = _param.toDate != null ? moment(new DateTime(Number(_p?.toDate)).toLocalDate()).format('YYYY-MM-DD') : null;

    _param.period = null;
    _param.fromTime = null;
    _param.toTime = null;
    return _param;
}

/**
 * Метод перемещения выбранных элементов в начало списка
 * или возвращение отменённых элементов на их место в общем списке
 * @param {any[]} list Общий список элементов
 * @param {any[]} selectedList Список выбранных элементов
 *
 * @returns {void}
 */
export function moveSelectedItemsToTopList(list: any[], selectedList: any[]): any[] {
    if (!list?.length) return [];

    if (!selectedList?.length) return _.sortBy(list, 'name');

    // Сортировка при хотя бы одном selectedList
    // Удаление из общего списка выбранных элементов
    list = _.difference(list, selectedList);

    // Сортировка обоих листов по алфавиту
    list = _.sortBy(list, 'name');
    selectedList = _.sortBy(selectedList, 'name');

    // Объединение сортированных списков
    // В первую очередь выбранные элементы
    return selectedList.concat(list);
}

export function _handleError(error: HttpErrorResponse | any): any {
    let errorResponse = new ErrorResponse({});
    errorResponse.message = '';
    if (error?.error?.errorMessage) {
        errorResponse.status = error.status;
        errorResponse.message = error.error.errorMessage;
        return errorResponse;
    }
    let errorBody: any = null;
    try {
        errorBody = error && error['_body'] ? JSON.parse(error['_body']) : null;
    } catch (err) {
        errorBody = error && error['_body'] ? error['_body'] : null;
    }
    if (errorBody) {
        Object.entries(errorBody).forEach(([key, value]) => {
            errorResponse[key as keyof ErrorResponse] = <never>value;
        });
        errorResponse.status = error.status;
    }
    if (!errorBody || (errorBody && !errorResponse.message)) {
        if (error?.status === 401) {
            // Unauthorized
            errorResponse.message = AppText.requestFailedUserNotUnauthorized;
        } else if (error?.status === 403) {
            // Forbidden
            errorResponse.message = AppText.requestFailedRequestForbidden;
        } else if (error?.status === 402) {
            errorResponse.message = AppText.errorTextIsYourSubscriptionHasExpiredPleaseGoToYourProfile;
        } else if (error?.status === 404) {
            errorResponse.message = AppText.requestFailedResourceNotFound;
        } else if (error.status === 500 || error?.status === 0) {
            errorResponse.message = AppText.requestFailed;
        }

        errorResponse.status = error?.status;

        if (!errorResponse.message) {
            errorResponse.message = AppText.requestFailedUnknownError;
        }
    }
    return errorResponse;
}

/**
 * Получение параметров фильтра из queryParams
 * @param {Object} queryParams Параметры из url
 * @param {SimpleDataFilter} dataFilter Параметры для фильтра
 * @param {string[]} defaultFilterParams Параметры для фильтра,
 * которые сбрасывать не нужно
 *
 * @returns {SimpleDataFilter}
 */
export function getFilterParams(
    queryParams: Params,
    dataFilter: SimpleDataFilter,
    defaultDataFilter: string[] = ['page', 'pageSize', 'orderBy']
): SimpleDataFilter {
    // Сбрасываем все параметры которые пришли null, если
    // только они не содержаться в списке дефолтных параметров
    for (const key in dataFilter) {
        if (!queryParams[key] && queryParams[key] !== 0 && (!defaultDataFilter?.length || defaultDataFilter.indexOf(`${key}`) === -1)) {
            delete dataFilter[key as keyof SimpleDataFilter];
        }
    }

    for (const key in queryParams) {
        if (queryParams.hasOwnProperty(key)) {
            dataFilter[key as keyof SimpleDataFilter] = <never>queryParams[key];
        }
    }
    return dataFilter;
}

/**
 * Сокращает строку путем приобразования - вытаскивает только заглавные буквы
 * Если их нет - вытаскивает первую букву строки
 * @param {string | undefined} inputStr Входящая строка
 * @returns {string} Сокращенная сторка
 */
export function getUpperCaseChars(inputStr: string | undefined): string {
    let transformedStr = '';
    if (!inputStr?.trim()?.length) {
        return transformedStr;
    }

    const upperCaseRegExp = /[^A-ZА-Я]/g; // appercase only
    transformedStr = inputStr.replace(upperCaseRegExp, '');
    return transformedStr;
}

/**
 * Обновления тегов для просмотра по последнему поиску
 * @param search строка поиска
 */
export function updateTagsForView(tags: any[], search: string | null = null, subtitle: boolean = true): FoundItemsModel[] {
    let _tagsForView: FoundItemsModel[] = [];
    if (tags?.length) {
        tags?.forEach((tag: any, index: number) => {
            _tagsForView.push(
                new FoundItemsModel({
                    id: new StringGenerator().generateId(),
                    title: tag.name,
                    subTitle: subtitle ? (AppText.tooltipMessageCountOfArticlesForTags(tag.articleCount || 0)) : null
                })
            );
        });
    }
    return _tagsForView;
}

export function findQuotationMarks(str: string, quotationMarks: Array<Array<string>> = []): Array<string> {
    let _foundArrayMarks: Array<string> = [];
    if (!str?.length) {
        return _foundArrayMarks;
    }
    let newStr = str?.trim();
    if (!newStr?.length || !quotationMarks?.length) {
        return _foundArrayMarks;
    }
    for (let i = 0; i < quotationMarks?.length; i++) {
        let arrayMarks = quotationMarks[i];
        let startQuotationMark = arrayMarks?.[0] || '';
        let endQuotationMark = arrayMarks?.[1] || '';
        let startQuotationMarkIndex = newStr.indexOf(startQuotationMark);
        let endQuotationMarkIndex = newStr.lastIndexOf(endQuotationMark);
        if (startQuotationMark && endQuotationMark && (startQuotationMarkIndex !== -1 || endQuotationMarkIndex !== -1)) {
            _foundArrayMarks = arrayMarks;
            break;
        } else if (startQuotationMark && startQuotationMarkIndex !== -1) {
            _foundArrayMarks = arrayMarks;
            break;
        }
    }
    return _foundArrayMarks;
}

export function getTextWithotQuotationMarks(str: string, quotationMarks: Array<string> = []): string {
    if (!str?.length) {
        return '';
    }
    let newStr = str?.trim();
    if (!newStr?.length || !quotationMarks?.length) {
        return newStr;
    }
    let startQuotationMark = quotationMarks?.[0] || '';
    let endQuotationMark = quotationMarks?.[1] || '';
    let startQuotationMarkIndex = newStr.indexOf(startQuotationMark);
    let endQuotationMarkIndex = newStr.lastIndexOf(endQuotationMark);
    if (startQuotationMarkIndex === 0) {
        newStr = newStr.substring(startQuotationMark.length || 1, newStr.length);
    }
    if (endQuotationMarkIndex !== -1) {
        newStr = newStr.substring(0, endQuotationMarkIndex);
    }
    return newStr;
}

export function getTextWithQuotationMarks(str: string, quotationMarks: Array<string> = [], startQuotationMarksOnly: boolean = false): string {
    str = getTextWithotQuotationMarks(str, quotationMarks);
    if (!str?.length) {
        return '';
    }
    let startQuotationMark = getStartQuotationMark(quotationMarks);
    let endQuotationMark = getEndQuotationMark(quotationMarks);
    if (startQuotationMarksOnly) {
        return `${startQuotationMark}${str}`;
    }
    return `${startQuotationMark}${str}${endQuotationMark}`;
}

export function getStartQuotationMark(quotationMarks: Array<string> = []): string {
    if (!quotationMarks?.length) {
        return '';
    }
    return quotationMarks?.[0] || '';
}

export function getEndQuotationMark(quotationMarks: Array<string> = []): string {
    if (!quotationMarks?.length || quotationMarks?.length === 1) {
        return '';
    }
    return quotationMarks?.[quotationMarks.length - 1] || '';
}

export function setHashtagToString(str: string): string {
    if (!str?.length) {
        return '';
    }
    let stringWithHashtag = str || '';
    if (stringWithHashtag?.length) {
        stringWithHashtag = stringWithHashtag?.indexOf('#') === 0 ? `${stringWithHashtag}` : `#${stringWithHashtag}`;
    }
    return stringWithHashtag;
}

export function filterArray(array: any[], search: string, fieldName: string, sliceLength: number = 0): any[] {
    if (!array?.length) {
        return [];
    }
    let filteredItems = [];
    let transformedSearchToLowerCase = search ? search.toLowerCase() : '';
    if (!fieldName) {
        filteredItems =
            array?.filter(item => {
                if (typeof item === 'object') {
                    return item;
                }
                return (
                    item
                        ?.toString()
                        ?.toLowerCase()
                        ?.indexOf(transformedSearchToLowerCase) === 0
                );
            }) || [];
    } else {
        filteredItems =
            array?.filter((item: any) =>
                item?.[fieldName]
                    ?.toString()
                    ?.toLowerCase()
                    ?.includes(transformedSearchToLowerCase)
            ) || [];
    }
    if (sliceLength) {
        filteredItems = filteredItems?.slice(0, sliceLength) || [];
    }
    return filteredItems;
}

/**
 * Копирование в буфер
 * @param data данные, как правило строка
 * @param clickEvent событие клика для прекращения распространения
 * @param messageService сервис для вывода сообщений
 */
export function copyToClipboard(data: any, clickEvent?: any, messageService?: MessageService): void {
    clickEvent && clickEvent.stopPropagation();
    if (data) {
        window.navigator.clipboard.writeText(data);
        messageService && messageService.snackbar(AppText.hintMessageIsItIsCopiedToClipboard);
    }
}

export function getPreviousRouterUrl(pageKey: string, defaultUrl: string): string {
    let existHistory: RouterHistoryModel[] = RouterHistoryService.getHistory() || [];
    if (!existHistory?.length) {
        return defaultUrl;
    }
    let foundIndex = existHistory.findIndex((pageData: RouterHistoryModel) => pageData.currentPage === pageKey);
    if (foundIndex === -1) {
        return defaultUrl;
    }
    return existHistory[foundIndex].previousPages || defaultUrl;
}

export function resetPreviousRouterUrl(currentPageName: string): void {
    RouterHistoryService.removeHistoryForPage(currentPageName);
}

export function getLastRouterUrl(): string | null {
    let existHistory: RouterHistoryModel[] = RouterHistoryService.getHistory() || [];
    if (!existHistory?.length) {
        return null;
    }
    return existHistory?.[existHistory.length - 1]?.previousPages || null;
}

export function removeLastRouterUrl(): void {
    let existHistory: RouterHistoryModel[] = RouterHistoryService.getHistory() || [];
    if (!existHistory?.length) {
        return;
    }
    existHistory.splice(existHistory.length - 1, 1);
    RouterHistoryService.setHistory(existHistory);
}

export function overwriteExistRouterHistoryByPageKey(pageKey: string, previousPage: string): RouterHistoryModel[] {
    let existHistory: RouterHistoryModel[] = RouterHistoryService.getHistory() || [];
    if (!existHistory?.length) {
        existHistory = [];
        existHistory.push({ currentPage: pageKey, previousPages: previousPage });
        return existHistory;
    }

    let foundIndex = existHistory.findIndex((pageData: RouterHistoryModel) => pageData.currentPage === pageKey);
    if (foundIndex === -1) {
        existHistory.push({ currentPage: pageKey, previousPages: previousPage });
        return existHistory;
    }

    existHistory[foundIndex].previousPages = previousPage;
    return existHistory;
}

export function updateExistRouterHistoryByPageKey(pageKey: string, pageUrl: string): void {
    let newHistory = overwriteExistRouterHistoryByPageKey(pageKey, pageUrl);
    RouterHistoryService.setHistory(newHistory);
}

export function changeControlValidator(form: FormGroup, controlName: string, predicateForValidators: any, validators: ValidatorFn[] = []): void {
    if (form && controlName) {
        if (form.controls[controlName]) {
            if (predicateForValidators && predicateForValidators()) {
                form.controls[controlName].setValidators(validators);
                form.controls[controlName].updateValueAndValidity();
            } else {
                form.controls[controlName].clearValidators();
                form.controls[controlName].updateValueAndValidity();
                form.controls[controlName].setErrors(null);
                form.controls[controlName].markAsPristine();
                form.controls[controlName].markAsUntouched();
            }
        }
    }
}

export function encodeCarData(carData: CarModel | null): string | null {
    return carData ? encodeURIComponent((JSON.stringify(carData))) : null;
}

export function decodeCarData(carDataParam: string | null): CarModel | null {
    if (!carDataParam || !decodeURIComponent(carDataParam)?.trim()) {
        return null;
    }

    let parsedCar = new CarModel();
    try {
        parsedCar = JSON.parse(decodeURIComponent(carDataParam));
        return parsedCar;
    } catch (error) {
        console.log(error);
    }
    return null;
}

export function parseParentParams(articlesPageParams: string | null): SimpleDataFilter | null {
    if (!articlesPageParams || !articlesPageParams?.trim() || !decodeURIComponent(articlesPageParams)?.trim()) {
        return null;
    }

    let parentParams: SimpleDataFilter = new SimpleDataFilter();
    try {
        parentParams = JSON.parse(decodeURIComponent(articlesPageParams));
        return parentParams;
    } catch (error) {
        console.log(error);
    }
    return null;
}

export function isObjectHasLeastOneValue(obj: any): boolean {
    if (!obj) { return false; }
    return Object.values(obj)
        ?.filter((val: any) => val != null && val?.toString()?.trim() !== '')
        ?.length ? true : false;
}