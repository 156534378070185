import { Model } from "../shared/interfaces";

export class Articles implements Model {
  id!: string | number | null;
  name!: string;

  constructor(data?: any) {
    this.id = data?.id;
    this.name = data?.name;
  }
}
