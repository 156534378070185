
export class AppDatepickerRanges {
    /** Сегодняшняя дата */
    private day!: number;
    private dayOfWeek!: number;
    private month!: number;
    private year!: number;
    private thisWeek!: Date;
    private lastWeek!: Date;

    /** Вчера */
    yesterdayStart!: Date;
    yesterdayEnd!: Date;

    /** Сегодня */
    todayStart!: Date;
    todayEnd!: Date;

    /** Эта неделя */
    thisWeekStart!: Date;
    _thisWeekEnd!: Date;
    thisWeekEnd!: Date;

    /** Последняя неделя */
    lastWeekStart!: Date;
    _lastWeekEnd!: Date;
    lastWeekEnd!: Date;

    /** Этот Месяц */
    thisMonthStart!: Date;
    thisMonthEnd!: Date;

    /** Последний месяц */
    lastMonthStart!: Date;
    lastMonthEnd!: Date;

    /** Год */
    thisYearStart!: Date;
    thisYearEnd!: Date;

    lastYearStart!: Date;
    lastYearEnd!: Date;

    constructor() {
        this.setNowDate();
    }

    setNowDate() {
        this.day = new Date().getDate();
        this.dayOfWeek = new Date().getDay();
        this.month = new Date().getMonth();
        this.year = new Date().getUTCFullYear();
        this.thisWeek = new Date(this.year, this.month, this.day, 0, 0, 0, 0);
        this.lastWeek = new Date(this.year, this.month, this.day, 0, 0, 0, 0);

        /** Вчера */
        this.yesterdayStart = new Date(this.year, this.month, this.day - 1, 0, 0, 0, 0);
        this.yesterdayEnd = new Date(this.year, this.month, this.day - 1, 23, 59, 59, 59);

        /** Сегодня */
        this.todayStart = new Date(this.year, this.month, this.day, 0, 0, 0, 0);
        this.todayEnd = new Date(this.year, this.month, this.day, 23, 59, 59, 59);

        /** Эта неделя */
        this.thisWeekStart = new Date(this.thisWeek.setDate(this.thisWeek.getDate() - (this.dayOfWeek ? (this.dayOfWeek - 1) : 6) ));
        // this.thisWeekStart = new Date(this.thisWeek.setDate(this.thisWeek.getDate() - (this.dayOfWeek - 1)));
        this._thisWeekEnd = new Date(this.thisWeek.setDate(this.thisWeek.getDate() + 7));
        this.thisWeekEnd = new Date(this.thisWeek.setMinutes(this._thisWeekEnd.getMinutes() - 1));

        /** Последняя неделя */
        this.lastWeekStart = new Date(this.lastWeek.setDate(this.lastWeek.getDate() - (this.dayOfWeek ? (this.dayOfWeek + 6) : 13) ));
        // this.lastWeekStart = new Date(this.lastWeek.setDate(this.lastWeek.getDate() - (this.dayOfWeek - 1) - 7));
        this._lastWeekEnd = new Date(this.lastWeek.setDate(this.lastWeek.getDate() + 7));
        this.lastWeekEnd = new Date(this.lastWeek.setMinutes(this._lastWeekEnd.getMinutes() - 1));

        /** Этот Месяц */
        this.thisMonthStart = new Date(this.year, this.month, 1, 0, 0, 0, 0);
        this.thisMonthEnd = new Date(this.year, this.month + 1, 0, 23, 59, 59, 59);

        /** Последний месяц */
        this.lastMonthStart = new Date(this.year, this.month - 1, 1, 0, 0, 0, 0);
        this.lastMonthEnd = new Date(this.year, this.month, 0, 23, 59, 59, 59);

        /** Год */
        this.thisYearStart = new Date(this.year, 0, 1, 0, 0, 0, 0);
        this.thisYearEnd = new Date(this.year, 11, 31, 23, 59, 59, 59);

        /** Год */
        this.lastYearStart = new Date(this.year - 1, 0, 1, 0, 0, 0, 0);
        this.lastYearEnd = new Date(this.year - 1, 11, 31, 23, 59, 59, 59);
    }
}