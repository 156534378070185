/**
 * Сервис для вызова всплывающих окон, зависящих от действий пользователя
 *
 * show() - метод для создания кастомного диалога. возвращает observable с результатом значения нажатой пользователем кнопки.
 * остальные методы есть специфичные случаи show(), использующиеся в нескольких местах.
 *
 * Интерфейсы:
 *
 * UserActionPopupData -
 * Интерфейс для данных, отображаемых в всплывающем окне
 *
 * UserActionPopuColor -
 * Тип, отвечающий за цвет фона всплывающего окна и иконки.
 * Значение используется как ключ к UserActionPopupColorsMap в компоненте UserActionPopup
 *
 * UserActionPopupButton -
 * Интерфейс кнопок всплывающего окна.
 * isFilled - флаг для стиля кнопки. при значении true кнопка залита цветом
 * emittingValue - значение кнопки, которое будет передано в возвращаемый сервисом observable.
 */

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserActionPopupComponent } from './user-action-popup.component';
import { Observable } from 'rxjs';
import * as popupConfigs from './popup-configs';

@Injectable({
    providedIn: 'root'
})
export class UserActionPopupService {
    constructor(private dialog: MatDialog) { }

    private show(config: any, disableClose: any) {
        const dialog = this.dialog.open(UserActionPopupComponent, {
            data: config,
            disableClose: disableClose
        });
        return dialog.afterClosed();
    }

    showDeletionPopup(subject: string, subjectType: string): Observable<any> {
        let config = popupConfigs.deletion(`${subjectType} ${subject}`);
        return this.show(config, true);
    }

    showActionConfirmPopup(text: string, continuePlaceholder?: string, cancelPlaceholder?: string): Observable<any> {
        let config = popupConfigs.confirmation(text, continuePlaceholder, cancelPlaceholder);
        return this.show(config, true);
    }

    showCollaborationCancellationPopup(subject: string): Observable<any> {
        const config = popupConfigs.cancellation(`${subject}`);
        return this.show(config, true);
    }

    showDefaultError(text: string): Observable<any> {
        const config = popupConfigs.defaultError(text);
        return this.show(config, false);
    }

    showChangesWarningPopup(): Observable<any> {
        const config = popupConfigs.resetChanges();
        return this.show(config, true);
    }

    showSuccessPopup(text: string, isHeaderRequired: boolean = true): Observable<any> {
        const config = popupConfigs.success(text);
        return this.show(config, false);
    }

    showWarningPopup(text: string, isHeaderRequired: boolean = true): Observable<any> {
        const config = popupConfigs.warning(text);
        return this.show(config, false);
    }
}

export type UserActionPopupType = 'success' | 'warn' | 'error' | 'process';
export type UserActionPopupButtonType = 'flat' | 'stroked';
export interface UserActionPopupData {
    type: UserActionPopupType;
    popupHeader: string;
    messageHeader: string | null;
    message: string;
    messageHint: string | null;
    buttons: UserActionPopupButton[];
}
export interface UserActionPopupButton {
    type: UserActionPopupButtonType;
    icon: string | null;
    text: string;
    emittingValue: any;
}
