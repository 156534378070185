import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import 'hammerjs';

import { FlexLayoutModule } from '@angular/flex-layout';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';

import { AppPreloadingStrategy } from './app-preloading-strategy';

import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { ApiModule } from './api';
import { GuardModule } from './guard/guard.module';
import { SharedModule } from './shared/shared.module';
import { LoadingModule } from './loading/loading.module';
import { SignInModule } from './sign-in/sign-in.module';
import { SignUpModule } from './sign-up/sign-up.module';
import { TagSharedModule } from './tags/shared/tag-shared.module';
import { ProfileModule } from './profile/profile.module';

import { provideSvgIcons, provideSvgIconsConfig } from '@ngneat/svg-icon';
import { iconsIcons } from './svg/icons';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { NotFoundComponent } from './not-found/not-found.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { EmailVerificationModule } from './email-verification/email-verification.module';
import { PlatformAccountVerificationModule } from './platform-account-verification/platform-account-verification.module';
import { SubscriptionPlanVerificationModule } from './subscription-plan-verification/subscription-plan-verification.module';
// import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
// import localeDe from '@angular/common/locales/de';

registerLocaleData(localeRu);
// registerLocaleData(localeDe);

@NgModule({
    declarations: [AppComponent, NotFoundComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FlexLayoutModule,
        // FormsModule,
        // ReactiveFormsModule,
        ApiModule,
        GuardModule,
        RouterModule,
        AppRoutingModule,
        SharedModule,
        LoadingModule,
        ProfileModule,
        SignInModule,
        SignUpModule,
        EmailVerificationModule,
        PlatformAccountVerificationModule,
        SubscriptionPlanVerificationModule,
        TagSharedModule,
        NgScrollbarModule
    ],
    providers: [
        AppPreloadingStrategy,
        provideSvgIcons(iconsIcons),
        { provide: LOCALE_ID, useValue: 'ru' },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } },
        // provideAnimationsAsync()
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
