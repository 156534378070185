import { Model } from "src/app/shared/interfaces";

export class SearchRequestTemplateModel implements Model {
    id!: string;
    articleGroupId!: string;
    articleGroupName!: string;
    categories!: string[];
    createdDate!: number;
    updatedDate!: number;
    enabled!: boolean;
    value!: string;
    useCompatibleCars!: boolean;
    maxCarRank!: number | null;

    constructor(data?: any) {
        if (data) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    this[key as keyof SearchRequestTemplateModel] = <never>data[key];
                }
            }
        }
    }

    serialize(): object {
        return {
            articleGroupId: this.articleGroupId,
            articleGroupName: this.articleGroupName,
            categories: this.categories,
            enabled: this.enabled,
            value: this.value,
            useCompatibleCars: this.useCompatibleCars,
            maxCarRank: this.maxCarRank,
        };
    }
}