import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiEndpoints, ApiService, AuthorizationService, ErrorResponse } from '../api';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { PlatformAccountModel } from '../platform-accounts/shared/platform-account.model';
import { routeMap } from '../route-map';
import { LocalStorage } from '../api/local-storage-new';
import { LocalStorageKeys } from '../api/local-storage-keys';

@Component({
    selector: 'app-platform-account-verification',
    templateUrl: './platform-account-verification.component.html',
    styleUrls: ['./platform-account-verification.component.scss', '../email-verification/email-verification.component.scss']
})
export class PlatformAccountVerificationComponent implements OnInit, OnDestroy {
    /**
     * Подписка на параметры url
     */
    protected queryParamsSubscription: Subject<void> = new Subject<void>();

    /**
     * Подписка на запрос верификации
     */
    protected verificationRequestSubscription: Subject<void> = new Subject<void>();

    /**
     * Запрос на редирект на ебей
     */
    protected ebayRedirectCancelRequest: Subject<void> = new Subject<void>();

    DELAY_FOR_REDIRECT_TO_PLATFORM_ACCOUNT_LIST = 1000;

    PLATFORM_ACCOUNT_ALREADY_EXIST_ERROR_MESSAGE = 'alreadyexistsinthesystem';

    /**
     * Код от eBay
     */
    code: string = '';

    /**
     * Срок истечения кода от eBay
     */
    expiresIn: string = '';

    /**
     * Флаг провалившейся авторизации на стороне eBay - ожидаемое значение только 0
     */
    eBayAuthFailureStatus: string = '';

    /**
     * Проверка в процессе
     */
    isVerificationInProgress: boolean = false;

    /**
     * Статус успешной проверки
     */
    isVerificationSuccess: boolean = false;

    /**
     * Статус не успешной проверки
     */
    isVerificationFailed: boolean = false;

    /**
     * Попытка добавить существующий аккаунт
     */
    isAccountAlreadyExist: boolean = false;

    ebayRedirectRequestInProgress: boolean = false;

    /**
     * Указатель на отложенную функ. setTimeout
     */
    delayedRedirectToPlatformAccountsHandle: NodeJS.Timeout | null = null;

    /**
     * Статус провалившейся авторизации на стороне eBay
     */
    get isEbayAuthFailed(): boolean {
        return this.eBayAuthFailureStatus === '0' ? true : false;
    }

    /**
     * Url уже прослушивается/имеет подписку
     */
    get isQueryParamsListening(): boolean {
        return this.queryParamsSubscription?.observers?.length ? true : false;
    }

    constructor(
        private authService: AuthorizationService,
        private router: Router,
        private route: ActivatedRoute,
        private apiService: ApiService,
    ) {
        this.isVerificationInProgress = true;
    }

    public ngOnInit(): void {
        this.subscribeToQueryParams();
    }

    /**
     * The method for destroying the component
     */
    public ngOnDestroy(): void {
        this.unsubscribeFromEbayRedirectRequest();
        this.unsubscribeFromQueryParamsVerification();
        this.unsubscribeFromQueryParams();
    }

    /**
     * В результате произойдет переход на площадку eBay
     */
    public goToEbay(): void {
        if (this.ebayRedirectRequestInProgress) {
            return;
        }
        this.unsubscribeFromEbayRedirectRequest();
        this.ebayRedirectRequestInProgress = true;
        this.redirectToEbayAuthorization()
            .pipe(takeUntil(this.ebayRedirectCancelRequest))
            .subscribe(_ => {
                this.ebayRedirectRequestInProgress = false;
            }, (error: any) => {
                this.ebayRedirectRequestInProgress = false;
            });
    }

    private checkQueryParams(): void {
        this.unsubscribeFromQueryParamsVerification();
        this.addEbayAccount({ authorizationCode: this.code, expiresIn: this.expiresIn })
            .pipe(takeUntil(this.verificationRequestSubscription))
            .subscribe(_ => {
                this.updateVerificationProgressStatus(false, true, false);
                this.redirectToPlatformAccounts(this.DELAY_FOR_REDIRECT_TO_PLATFORM_ACCOUNT_LIST);
            }, (error: ErrorResponse) => {
                console.log(error);
                // поймать для существующего аккаунта
                if (this.isAccountAlreadyExistError(error.errorMessage)) {
                    LocalStorage.set(LocalStorageKeys.userTriedToCreatePlatformAccount, 1);
                    this.redirectToPlatformAccounts(this.DELAY_FOR_REDIRECT_TO_PLATFORM_ACCOUNT_LIST);
                }
                else {
                    this.updateVerificationProgressStatus(false, false, true);
                }
            });
    }

    private subscribeToQueryParams(): void {
        this.unsubscribeFromQueryParams();
        this.route.queryParams
            .pipe(takeUntil(this.queryParamsSubscription))
            .subscribe((queryParams: Params) => {
                this.code = queryParams['code'] || '';
                this.expiresIn = queryParams['expires_in'] || '';
                this.eBayAuthFailureStatus = queryParams['auth'] || '';
                if (this.isEbayAuthFailed || !this.code || !this.expiresIn) {
                    this.updateVerificationProgressStatus(false, false, true);
                    return;
                }
                this.checkQueryParams();
            })
            .unsubscribe();
    }

    private updateVerificationProgressStatus(progress: boolean, success: boolean, failed: boolean, isExist: boolean = false): void {
        this.isVerificationInProgress = progress;
        this.isVerificationSuccess = success;
        this.isVerificationFailed = failed;
        this.isAccountAlreadyExist = isExist;
    }

    private isAccountAlreadyExistError(errorMessage: string | null): boolean {
        if (!errorMessage || !errorMessage?.trim()) {
            return false;
        }
        const transformedMessage = errorMessage?.trim()?.toLowerCase()?.replace(' ', '') || '';
        return transformedMessage.includes(this.PLATFORM_ACCOUNT_ALREADY_EXIST_ERROR_MESSAGE) || false;
    }

    private redirectToPlatformAccounts(delay: number): void {
        this.clearDelayedRedirectHandle();
        if (!delay) {
            this.router.navigate([routeMap.platformAccounts]);
            return;
        }
        const ctx = this;
        this.delayedRedirectToPlatformAccountsHandle = setTimeout(() =>
            ctx.router.navigate([routeMap.platformAccounts]), delay);
    }

    private clearDelayedRedirectHandle(): void {
        this.delayedRedirectToPlatformAccountsHandle != null &&
            clearTimeout(this.delayedRedirectToPlatformAccountsHandle);
    }

    private unsubscribeFromEbayRedirectRequest(): void {
        this.ebayRedirectCancelRequest?.observers?.length &&
            this.ebayRedirectCancelRequest.complete();
    }

    private unsubscribeFromQueryParamsVerification(): void {
        this.verificationRequestSubscription?.observers?.length &&
            this.verificationRequestSubscription.complete();
    }

    private unsubscribeFromQueryParams(): void {
        this.queryParamsSubscription?.observers?.length &&
            this.queryParamsSubscription.complete();
    }

    private addEbayAccount(data: { authorizationCode: string, expiresIn: string }): Observable<PlatformAccountModel> {
        const url = ApiEndpoints.addEbayAccount().listUrl();
        return this.apiService.post<{ authorizationCode: string, expiresIn: string }, PlatformAccountModel>(url, data);
    }

    private redirectToEbayAuthorization(): Observable<any> {
        const url = ApiEndpoints.ebayAuthorization().listUrl();
        return this.apiService.get<any>(url);
    }
}
