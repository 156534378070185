import { Component, HostListener, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiEndpoints, ApiService, BaseApiService } from 'src/app/api';
import { ArticleModel, ItemList } from 'src/app/contracts';
import { FoundItemsModel } from 'src/app/shared/components/found-items-list/found-items-model';
import { AppText } from 'src/app/text/app-text';
import { TagModel } from '../tag.model';
import { ArticleTagModel } from '../article-tag.model';
import * as _ from 'underscore';
import { forbiddenTagPattern, tagNamePattern } from 'src/app/shared/patterns-config';
import { StringGenerator } from 'src/app/shared/utils/string-generator';
import { filterArray, getTextWithotQuotationMarks } from 'src/app/shared/utils/util-functions';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { MatMenu } from '@angular/material/menu';

@Component({
    selector: 'tag-details-dialog',
    templateUrl: 'tag-details-dialog.component.html',
    styleUrls: ['tag-details-dialog.component.scss']
})
export class TagDetailsDialogComponent {
    /**
     * Для прервывания запроса на теги
     */
    private cancelTagsRequest: Subject<void> = new Subject<void>();
    /**
     * Паттерн для строки поиска
     */
    public fieldPattern: RegExp = tagNamePattern;
    public quotationMarksForTags: Array<string> = ['#'];
    /**
     * Текст страницы
     */
    public pageText = {
        dialogHeaderIsEditTags: AppText.dialogHeaderIsEditTags,
        addAction: AppText.addAction,
        removeAction: AppText.removeAction,
        closeAction: AppText.closeAction,
        allSelectedArticlesAlreadyHaveThisTag: AppText.tooltipMessageAllSelectedArticlesAlreadyHaveThisTag,
        applyTagToAllSelectedArticles: AppText.tooltipMessageApplyTagToAllSelectedArticles,
        removeTagFromAllSelectedArticles: AppText.tooltipMessageRemoveTagFromAllSelectedArticles,
        generalTextIsFindTagsToAdd: AppText.generalTextIsFindTagsToAdd,
        generalTextIsFindTagsInSelectedArticles: AppText.generalTextIsFindTagsInSelectedArticles,
    };
    /**
     * Флаг об изменениях
     */
    public hasBeenAnyChanges: boolean = false;
    /**
     * Вводимый текст в поле
     */
    public displayText!: string;
    /**
     * Включение кнопки "Добавить тег"
     * Если она false то считается что включен режим "Удалить тег"
     */
    public addTagsButtonEnabled: boolean = true;
    /**
     * Выбранные листеры
     */
    public selectedListers!: ArticleModel[];
    /**
     * Идентификаторы артиклей в листерах(не повторяются как листеры)
     */
    public selectedArticlesIds!: string[];
    /**
     * Список тегов
     */
    public selectedListersTags: TagModel[] = [];
    /**
     * Список тегов c сервера
     */
    private tagsFromServer: TagModel[] = [];
    /**
     * Отфильтрованный список тегов с сервера
     */
    public filteredTagsOfSelectedListers: TagModel[] = [];
    /**
     * Список тегов для работы со списком поиска тегов
     */
    public tagsForView: FoundItemsModel[] = [];
    /**
     * Общее количество артиклей (не листеров, т е число будет меньше,
     * т к берутся только уникальные)
     */
    public get selectedArticleCount(): number {
        return this.selectedArticlesIds?.length || 0;
    }

    get menuOpened(): boolean {
        return this.actionsMenu?.overlapTrigger ? true : false;
    }

    constructor(
        private dialogRef: MatDialogRef<TagDetailsDialogComponent>,
        private apiService: ApiService,
        private baseApiService: BaseApiService,
        @Inject(MAT_DIALOG_DATA) private data: { selectedListers: ArticleModel[] }
    ) {
        this.selectedListers = this.data?.selectedListers || [];
        this.getUniqArticlesIdsFromSelectedListers();
        this.getUniqTagsFromSelectedListers();
        this.filterTagsOfSelectedListers('');
    }

    @ViewChild('actionsMenu', { static: false }) actionsMenu!: MatMenu;

    @HostListener('document:keyup.escape', ['$event'])
    public onKeyupEscapeHandler(event: KeyboardEvent): void {
        event?.stopPropagation();
        this.onClose();
    }

    /**
     * Метод поиска - изменяет строку поиска
     * @param search Строка поиска
     */
    public onSearch(search: string): void {
        this.displayText = search;
        let searchWithoutTagChars = getTextWithotQuotationMarks(this.displayText || '', this.quotationMarksForTags);
        this.filterTagsOfSelectedListers(searchWithoutTagChars);
    }

    /**
     * Проверка валидности
     * @returns результат соответствия паттерну
     */
    public tagNameValid(): boolean {
        if (!this.displayText) {
            return true;
        }
        if (this.tagNameForbidden()) {
            return false;
        }
        return this.displayText?.match(this.fieldPattern) ? true : false;
    }

    public tagNameForbidden(): boolean {
        if (!this.displayText) {
            return false;
        }
        return this.displayText?.match(forbiddenTagPattern) ? true : false;
    }

    /**
     * Изменение режима (Добавление или удаление)
     * @param flag Флаг для изменения
     */
    public onChangeMode(flag: boolean): void {
        this.addTagsButtonEnabled = flag;
        if (!this.addTagsButtonEnabled) {
            this.updateTagsForView(this.displayText);
        } else {
            this.filterTagsOfSelectedListers(this.displayText);
        }
    }

    /**
     * Добавление элемента из списка
     * @param item элемент
     */
    public onAddItem(item: FoundItemsModel): void {
        if (this.addTagsButtonEnabled) {
            if (item?.title) {
                this.addTagToSelectedArticles(item.title);
            }
        }
    }

    /**
     * Удаление элемента из списка
     * @param item элемент
     */
    public onRemoveItem(item: FoundItemsModel): void {
        if (!this.addTagsButtonEnabled) {
            if (item?.title) {
                this.removeTagFromSelectedArticles(item.title);
            }
        }
    }

    /**
     * Выбор элемента из списка
     * @param event событие клика
     */
    public onSelectItem(item: FoundItemsModel | null): void {
        if (item?.title) {
            if (this.addTagsButtonEnabled && !item?.itemIncludedInAllSelectedVariables) {
                this.addTagToSelectedArticles(item.title);
            }
        }
    }

    /**
     * Закрытие дилогового окна
     */
    public onClose(): void {
        this.dialogRef.close({ selectedListers: this.selectedListers, changes: this.hasBeenAnyChanges });
    }

    // Фильтрация тегов
    protected filterTagsOfSelectedListers(tagName: string | null): void {
        this.unsubscribeFromApiRequest();
        this.unsubscribeFromTags();
        if (!tagName || !this.addTagsButtonEnabled) {
            this.filteredTagsOfSelectedListers = filterArray(this.selectedListersTags, tagName || '', 'name', 8);
            this.updateTagsForView(tagName);
        } else if (this.tagNameValid()) {
            this.getTagsFromServer(tagName);
        }
    }

    // сортировка тегов
    protected sortSelectedListersTags(tags: TagModel[]): TagModel[] {
        return (
            tags.sort(function (itemA: TagModel, itemB: TagModel) {
                return itemB?.articleCount - itemA?.articleCount || itemA.name.localeCompare(itemB.name);
            }) || []
        );
    }

    /**
     * Получение уникальных идентификаторов артиклей у листеров
     */
    protected getUniqArticlesIdsFromSelectedListers(): void {
        let articleIds = this.selectedListers?.map((item: ArticleModel) => item.articleId);
        this.selectedArticlesIds = _.uniq(articleIds, false);
    }

    /**
     * Получение уникальных тегов у листеров
     */
    protected getUniqTagsFromSelectedListers(): void {
        this.selectedListersTags = [];
        this.selectedListers?.forEach((item: ArticleModel, listerIndex: number) => {
            if (item.tags?.length) {
                item.tags?.forEach((tag: TagModel) => {
                    let index = this.selectedListersTags.findIndex((_tag: TagModel) => _tag.name === tag.name);
                    if (index === -1) {
                        tag.articleCount = 1;
                        this.selectedListersTags.push(tag);
                    } else {
                        this.selectedListersTags[index].articleCount += 1;
                    }
                });
            }
        });
        this.selectedListersTags = this.sortSelectedListersTags(this.selectedListersTags);
    }

    /**
     * Обновления тегов для просмотра по последнему поиску
     * @param search строка поиска
     */
    protected updateTagsForView(search: string | null = null): void {
        this.tagsForView = [];
        let searchWithoutTagChars = getTextWithotQuotationMarks(search || '', this.quotationMarksForTags);
        const foundExistTag: TagModel | undefined = searchWithoutTagChars
            ? this.filteredTagsOfSelectedListers?.find((tag: TagModel) => tag?.name === searchWithoutTagChars)
            : undefined;
        if (this.filteredTagsOfSelectedListers?.length) {
            this.filteredTagsOfSelectedListers.forEach((tag: TagModel) => {
                if (this.addTagsButtonEnabled || tag.articleCount) {
                    this.tagsForView.push(
                        new FoundItemsModel({
                            id: new StringGenerator().generateId(),
                            title: tag.name,
                            subTitle: AppText.tooltipMessageCountOfArticlesForTags(tag.articleCount || 0),
                            itemIncludedInAllSelectedVariables: this.tagBelongsToAllSelectedListers(tag.name)
                        })
                    );
                }
            });
        }
        if (searchWithoutTagChars && !foundExistTag && this.addTagsButtonEnabled && this.tagNameValid()) {
            this.tagsForView.unshift(
                new FoundItemsModel({
                    id: new StringGenerator().generateId(),
                    title: getTextWithotQuotationMarks(searchWithoutTagChars, this.quotationMarksForTags),
                    subTitle: AppText.tooltipMessageCountOfArticlesForTags(null),
                    notExistInDatabase: true
                })
            );
        }
    }

    /**
     * Обновить изменившиеся теги во всех листерах
     * @param newListers Новые листеры, которые приходят с
     * сервера, с новым списком тегов
     */
    protected updateTagsOfSelectedListers(newListers: ArticleModel[]): void {
        newListers.forEach((newLister: ArticleModel) => {
            let articleId: string = newLister.articleId;
            if (!articleId) {
                return;
            }
            let oldListersForUpdate: ArticleModel[] =
                _.filter(this.selectedListers, (oldLister: ArticleModel) => {
                    return String(oldLister.articleId) === String(articleId);
                }) || [];

            if (!oldListersForUpdate?.length) {
                return;
            }
            oldListersForUpdate.forEach((oldLister: ArticleModel) => {
                let foundIndex = _.findIndex(this.selectedListers, (_oldLister: ArticleModel) => {
                    return String(_oldLister.id) === String(oldLister.id);
                });
                if (foundIndex > -1) {
                    this.selectedListers[foundIndex].tags = newLister.tags || [];
                }
            });
        });
    }

    protected findTagIndexByName(tagName: string, tags: TagModel[] = this.selectedListersTags): number {
        return _.findIndex(tags, (tag: TagModel) => {
            return tag.name === tagName;
        });
    }

    /**
     * Провернка на принадлежность тега ко всем выделенным артиклям
     * @param tagName имя тега, уникальное
     * @returns истинно, если результат отвечает условию
     */
    protected tagBelongsToAllSelectedListers(tagName: string): boolean {
        if (!this.selectedListers?.length) {
            return false;
        }
        const filteredListers = this.selectedListers?.filter((lister: ArticleModel) => {
            return lister.tags?.find((tag: TagModel) => {
                return tag?.name === tagName;
            });
        });
        return this.selectedListers?.length === filteredListers?.length ? true : false;
    }

    /**
     * Сзданние данных для отправки на сервер
     * @param tagName имя выбранного тега
     * @returns модель для отправки
     */
    private createPayload(tagName: string): ArticleTagModel {
        return new ArticleTagModel({
            articleIds: this.selectedArticlesIds,
            tagNames: [tagName]
        });
    }

    /**
     * Добавление выбранного тега в артикли
     * @param selectedTagName Выбранный тег
     */
    private addTagToSelectedArticles(selectedTagName: string): void {
        let url = ApiEndpoints.articleTags().listUrl();
        let searchWithoutTagChars = getTextWithotQuotationMarks(selectedTagName || '', this.quotationMarksForTags);
        this.apiService.post<ArticleTagModel, ArticleModel[]>(url, this.createPayload(searchWithoutTagChars)).subscribe((res: ArticleModel[]) => {
            if (res) {
                this.updateTagsOfSelectedListers(res || []);
                this.getUniqTagsFromSelectedListers();
                this.filterTagsOfSelectedListers(this.displayText);
                this.hasBeenAnyChanges = true;
            }
        });
    }

    /**
     * Удаление выбранного теги из артиклей
     * @param selectedTagName выбранный тег
     */
    private removeTagFromSelectedArticles(selectedTagName: string): void {
        let url = ApiEndpoints.articleTags().listUrl();
        let searchWithoutTagChars = getTextWithotQuotationMarks(selectedTagName || '', this.quotationMarksForTags);
        this.apiService.delete<ArticleModel[]>(url, this.createPayload(searchWithoutTagChars)).subscribe((res: ArticleModel[]) => {
            if (res) {
                this.updateTagsOfSelectedListers(res || []);
                this.getUniqTagsFromSelectedListers();
                this.filterTagsOfSelectedListers(this.displayText);
                this.hasBeenAnyChanges = true;
            }
        });
    }

    private unsubscribeFromApiRequest(): void {
        this.baseApiService?.apiUnsubscribe();
    }

    private unsubscribeFromTags(): void {
        if (this.cancelTagsRequest) {
            this.cancelTagsRequest.next();
            this.cancelTagsRequest.complete();
            this.cancelTagsRequest = new Subject<void>();
        }
    }

    private getTagsFromServer(search: string | null = null): void {
        let url = ApiEndpoints.tags().listUrl({
            search: search ? encodeURIComponent(search) : null,
            pageSize: 8,
            orderBy: 'articleCount',
            orderDesc: true
        });
        this.apiService
            .get<ItemList<TagModel>>(url)
            .pipe(
                takeUntil(this.cancelTagsRequest),
                catchError((error: any) => throwError(error))
            )
            .subscribe(
                (res: ItemList<TagModel>) => {
                    this.tagsFromServer = res?.data || [];
                    if (!this.tagsFromServer?.length) {
                        this.filteredTagsOfSelectedListers = [];
                        this.updateTagsForView(search);
                    } else {
                        this.tagsFromServer.forEach((tagFromServer: TagModel) => {
                            let tagExist = this.selectedListersTags.find((_existTag: TagModel) => _existTag.name === tagFromServer.name);
                            tagFromServer.articleCount = tagExist?.articleCount || 0;
                        });
                        this.filteredTagsOfSelectedListers = [];
                        this.tagsFromServer = this.sortSelectedListersTags(this.tagsFromServer);
                        this.filteredTagsOfSelectedListers.push(...this.tagsFromServer);
                        this.updateTagsForView(search);
                    }
                },
                (error: any) => {
                    // this.messageService.error(error);
                }
            );
    }
}
