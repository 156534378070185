import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toPercent'
})
export class ToPercentPipe implements PipeTransform {
    transform(value?: number, toFixedNumber?: number): number | string {
        let percent: number = 0;
        const onehundredPercent: number = 100;
        if (!value) {
            return percent;
        }
        percent = Number(value) * onehundredPercent;
        return percent >= onehundredPercent ?
            onehundredPercent
            : !toFixedNumber ? percent : percent.toFixed(toFixedNumber).replace('.00', '');
    }
}
