export class Token {
  access_token!: string;
  token_type!: string;
  expires_in!: number;
  issued!: number;

  constructor(data?: any) {
    this.access_token = data?.access_token;
    this.token_type = data?.token_type;
    this.expires_in = data?.expires_in;
    this.issued = data?.issued;
  }
}
