import { Pipe, PipeTransform } from '@angular/core';
import { AppText } from 'src/app/text/app-text';

const timePeriods = [
  {
    name: 'minute',
    period: 60
  },
  {
    name: 'hour',
    period: 60
  },
  {
    name: 'day',
    period: 24
  },
  {
    name: 'week',
    period: 7
  },
  {
    name: 'month',
    period: 4
  },
  {
    name: 'year',
    period: 12
  }
]

@Pipe({
    name: 'ago'
})
export class TimeAgoPipe implements PipeTransform {
    transform(value?: number): string {
      if (!value || value == 0) {
        return '';
      }

      let ago = (new Date().getTime() / 1000) - value;
      for (let i = 0; i < timePeriods.length; i++) {
        ago = Math.trunc(ago / timePeriods[i].period);
        try {
          if (ago < timePeriods[i+1].period) {
            return `${ago} ${ago === 1 ? timePeriods[i].name : timePeriods[i].name + 's' } ${AppText.generalTextIsAgo}`;
          }
        } catch {
          return `${ago} ${ago === 1 ? timePeriods[i].name : timePeriods[i].name + 's' } ${AppText.generalTextIsAgo}`;
        }
      }

      return '';
    }
}
