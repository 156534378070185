/**
 * Represents list item in select list
 */
export class ListItem {
  key!: string | number | null;
  value!: string | number | null;

  constructor(data?: any) {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this[key as keyof ListItem] = data[key];
        }
      }
    }
  }
}