import { Pipe, PipeTransform } from '@angular/core';
import { AppText } from 'src/app/text/app-text';
import * as _ from 'underscore';

@Pipe({
  name: 'mappingStatus',
})
export class MapingStatusPipe implements PipeTransform {
  /**
   * Возвращает русскоязычное значение
   * @param {string} value Значение
   * @param {string} mapName Карта значений с русскими аналогами
   *
   * @returns {string} наименование поля на русском языке
   */
  transform(value: string, mapName: string): string {
    return valueMap[mapName] && valueMap[mapName][value] ? valueMap[mapName][value] : value;
  }
}

const valueMap: IValueMap = {
  datapickerToggle: {
    yesterday: 'Yesterday',
    today: 'Today',
    thisWeek: 'This Week',
    lastWeek: 'Last Week',
    thisMonth: 'This Month',
    lastMonth: 'Last Month',
    thisYear: 'This Year',
    lastYear: 'Last Year'
  },
  ruleMatchingStatus: {
    0: AppText.generalTextIsAssignCompetitor,
    1: AppText.generalTextIsIncreaseConfidence,
    2: AppText.generalTextIsAssignNotACompetitor,
    3: AppText.generalTextIsNone,
    4: AppText.generalTextIsDecreaseConfidence,
  },
  strategyTypes: {
    fixed: AppText.generalTextIsFixedRate,
    dynamic: AppText.generalTextIsDynamicRate,
  },
};

interface IValueMap {
  [i: string]: { [j: string]: string };
}