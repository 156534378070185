import { Routes } from '@angular/router';
// import { AppPreloadingStrategy } from './app-preloading-strategy';
import { InitGuard } from './guard/init-guard/init.guard';
import { RoleGuard } from './guard/role-guard/role.guard';
import { routeMap } from './route-map';
import { LoadingComponent } from './loading/loading.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ProfileComponent } from './profile/profile.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { EmailVerifyGuard } from './guard/email-verify-guard/email-verify-guard.guard';
// import { ResetPasswordComponent } from './recover-password/recover-password.component';

/** Guards */
// import { InitGuard, RoleGuard } from './api/guards';

/** Components */
// import { LoadingComponent } from './authorization/loading/loading.component';

export const appRoutes: Routes = [
    /** Lazy loading */
    {
        path: routeMap.confirmAccount,
        canActivate: [InitGuard, RoleGuard],
        loadChildren: () => import('./email-verification/email-verification.module').then(m => m.EmailVerificationModule)
    },
    {
        path: routeMap.ebayAuthorization,
        canActivate: [InitGuard, RoleGuard],
        loadChildren: () => import('./platform-account-verification/platform-account-verification.module').then(m => m.PlatformAccountVerificationModule)
    },
    {
        path: routeMap.competitors,
        canActivate: [InitGuard, RoleGuard],
        loadChildren: () => import('./competitors/competitors.module').then(m => m.CompetitorsModule)
    },
    {
        path: routeMap.articles,
        canActivate: [InitGuard, RoleGuard],
        loadChildren: () => import('./articles/articles.module').then(m => m.ArticlesModule)
    },
    {
        path: routeMap.articleGroups,
        canActivate: [InitGuard, RoleGuard],
        loadChildren: () => import('./article-groups/article-groups.module').then(m => m.ArticleGroupsModule)
    },
    {
        path: routeMap.rules,
        canActivate: [InitGuard, RoleGuard],
        loadChildren: () => import('./rules/rule.module').then(m => m.RuleModule)
    },
    {
        path: routeMap.searchRequestTemplates,
        canActivate: [InitGuard, RoleGuard],
        loadChildren: () => import('./search-request-templates/search-request-templates.module').then(m => m.SearchRequestTemplatesModule)
    },
    {
        path: routeMap.tagAssignmentRules,
        canActivate: [InitGuard, RoleGuard],
        loadChildren: () => import('./tag-assignment-rules/tag-assignment-rule.module').then(m => m.TagAssignmentRuleModule)
    },
    {
        path: routeMap.strategies,
        canActivate: [InitGuard, RoleGuard],
        loadChildren: () => import('./strategies/strategies.module').then(m => m.StrategiesModule)
    },
    {
        path: routeMap.feedSettings,
        canActivate: [InitGuard, RoleGuard],
        loadChildren: () => import('./feeds/feeds.module').then(m => m.FeedsModule)
    },
    {
        path: routeMap.platformAccounts,
        canActivate: [InitGuard, RoleGuard],
        loadChildren: () => import('./platform-accounts/platform-accounts.module').then(m => m.PlatformAccountsModule)
    },
    { path: routeMap.profile, component: ProfileComponent },
    {
        path: routeMap.signIn,
        canActivate: [InitGuard, RoleGuard],
        component: SignInComponent
    },
    {
        path: routeMap.signUp,
        canActivate: [InitGuard, RoleGuard],
        component: SignUpComponent
    },
    {
        path: 'recover',
        canActivate: [EmailVerifyGuard],
        loadChildren: () => import('./recover-password/recover-password.module').then(m => m.RecoverPasswordModule)
    },
    { path: routeMap.notFound, canActivate: [EmailVerifyGuard], component: NotFoundComponent },
    { path: routeMap.loading, component: LoadingComponent },
    { path: '', redirectTo: routeMap.loading, pathMatch: 'full' },
    { path: '**', redirectTo: routeMap.signIn }
];