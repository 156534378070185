import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';



@Component({
  selector: 'app-button-toggle',
  templateUrl: './app-button-toggle.component.html',
  styleUrls: ['./app-button-toggle.component.scss']
})
export class AppButtonToggleComponent implements OnChanges {
  @Input() items!: Array<{ id: string, name: string }>;

  @Input() value!: string;

  @Input() enableMinimizedMode: boolean = true;

  @Output() change!: EventEmitter<{ value: string }>;

  isFilterHide: boolean = true;

  constructor() {
    this.value = '';
    this.change = new EventEmitter<{ value: string }>();
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
    if (changes['value']) {
      this.value = !changes['value'].currentValue || changes['value'].currentValue == '' ? '' : changes['value'].currentValue;
    }
  }

  onToggle(event: MatButtonToggleChange) {
    this.value = event.value;
    this.change.emit({ value: this.value });
  }
}
