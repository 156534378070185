<div class="app-multiselect-panel {{panelStyleClass}}">

  <!-- Поле поиска -->
  <div *ngIf="isSearchEnable" class="app-multiselect-panel__search-wrapper">
    <mat-form-field  #searchInputHTMLElement class="app-multiselect-panel__search" >
      <div matPrefix class="app-multiselect-panel__icon-search" fxLayout="row" fxLayoutAlign="start center">
        <svg-icon key="search"></svg-icon>
      </div>
      <input #searchInput matInput type="text" placeholder="{{this.generalTextIsSearchPlaceholder}}" name="search" [(ngModel)]="search" (ngModelChange)="onSearch(search)" autocomplete="off">
      <!-- <div matSuffix class="app-multiselect-panel__icon-clear" [class.app-multiselect-panel__icon-clear--show]="showClearIconInSearchInput" (click)="onClearSearch()"></div> -->
      <div *ngIf="showClearIconInSearchInput" matSuffix disableRipple class="app-multiselect-panel__icon-clear" fxLayout="row" fxLayoutAlign="start center" (click)="onClearSearch()">
        <svg-icon key="notmatch" fontSize="18px"></svg-icon>
      </div>
      <div *ngIf="!showClearIconInSearchInput" matSuffix class="app-multiselect__icon-arrow app-multiselect__icon-arrow--down" [class.app-multiselect__icon-arrow--up]="showArrow" (click)="onClose()"></div>
    </mat-form-field>
  </div>

  <!-- select all checkbox -->
  <div *ngIf="isMultipleModeEnable && isSelectAllOptionVisible && items?.length" class="app-multiselect-panel__selectall-wrapper">

    <!-- Выбор всех элементов -->
    <mat-checkbox
      readonly color="primary" disableRipple
      (change)="onSelectAll($event)"
      [checked]="isAllSelected()"
      [disabled]="!isMultipleModeEnable">{{generalPageText.selectAllAction}}</mat-checkbox>

  </div>

  <!-- Обертка для элементов -->
  <!-- tabindex="0" для фокусировки -->
  <!-- fxFlex="auto" -->
  <div tabindex="0" class="app-multiselect-panel__options" id="optionsPanelId" #itemsContainerHTMLElement
    [style.height]="itemsContainerHTMLHeight"
    [class.app-multiselect-panel__options--multiple]="isMultipleModeEnable"
    [class.app-multiselect-panel__options--single]="!isMultipleModeEnable">

    <!-- Пустой список -->
    <div *ngIf="!isArrayValid(items)" class="app-multiselect-panel__not-found" fxLayout="row" fxLayoutAlign="center center">
      <span>{{generalPageText.notFoundText}}</span>
    </div>

    <!-- Список в обортке cdk-virtual-scroll-viewport - position: relative -->
    <!-- Без обертки не работает на iphone 12 pro -->
    <ng-scrollbar *ngIf="isArrayValid(items)" class="virtual-scroll">
      <cdk-virtual-scroll-viewport scrollViewport
        appendOnly [itemSize]="itemSizePx"
        style="width:100%;height:100%;">

        <!-- fxLayout="row" fxLayoutAlign="start center" -->
        <div *cdkVirtualFor="let item of items | slice:0:(selectedItemsLimit ? selectedItemsLimit : items?.length); let index = index;" [id]="index"
          class="app-multiselect-panel__option {{getOverlayItemStyleClass(item)}}"
          [class.app-multiselect-panel__option--selected]="isOptionSelected(item)"
          [class.app-multiselect-panel__option--focused]="isOptionFocused(item)"
          [class.app-multiselect-panel__option--disabled]="isLimitExceeded(item)">
          <!-- Блокирует скролл на iphone 12 pro -->
          <!-- matTooltip="{{isItemTooltipVisible && item ? item[displayName] : ''}}" matTooltipPosition="after" -->

          <!-- Включен множественный выбор -->
          <mat-checkbox *ngIf="isMultipleModeEnable"
            readonly color="primary" disableRipple
            (change)="onSelectOption(item)"
            [checked]="isOptionSelected(item)"
            [disabled]="isLimitExceeded(item)">

            <!-- <div *ngIf="getOverlayItemIconClass(item)" class="app-multiselect-panel__option-single-icon {{getOverlayItemIconClass(item)}}"></div> -->

            <div class="app-multiselect-panel__option-single {{getOverlayItemStyleClass(item,'content')}}">{{ item ? item[displayName] : '' }}</div>
          </mat-checkbox>

          <!-- Выключен множественный выбор. Иконка -->
          <!-- <span *ngIf="!isMultipleModeEnable && getOverlayItemIconClass(item)" class="app-multiselect-panel__option-single-icon {{getOverlayItemIconClass(item)}}"></span> -->

          <!-- Выключен множественный выбор -->
          <div *ngIf="!isMultipleModeEnable"
            class="app-multiselect-panel__option-single {{getOverlayItemStyleClass(item,'content')}}"
            (click)="onSelectOption(item)">{{ item ? item[displayName] : '' }}</div>

        </div>
      </cdk-virtual-scroll-viewport>
    </ng-scrollbar>

    <!-- <div *ngFor="let item of items | slice:0:(selectedItemsLimit ? selectedItemsLimit : items?.length); let index = index;" [id]="index"
      class="app-multiselect-panel__option {{item && optionBackgroundTrigger ? item[optionBackgroundTrigger.key] == optionBackgroundTrigger.value ? optionBackground : '' : ''}}"
      fxLayout="row" fxLayoutAlign="start center"
      [class.app-multiselect-panel__option--selected]="isOptionSelected(item)"
      [class.app-multiselect-panel__option--focused]="isOptionFocused(item)"
      [class.app-multiselect-panel__option--disabled]="isLimitExceeded(item)"
      matTooltip="{{isItemTooltipVisible && item ? item[displayName] : ''}}" matTooltipPosition="after">


      <mat-checkbox *ngIf="isMultipleModeEnable"
        readonly color="primary" disableRipple
        (change)="onSelectOption(item)"
        [checked]="isOptionSelected(item)"
        [disabled]="isLimitExceeded(item)"
        class="{{item && optionColorTrigger ? item[optionColorTrigger.key] == optionColorTrigger.value ? optionColor : '' : ''}}">
        {{ item ? item[displayName] : '' }}
      </mat-checkbox>

      <div *ngIf="!isMultipleModeEnable"
        class="app-multiselect-panel__option-single {{item && optionColorTrigger ? item[optionColorTrigger.key] == optionColorTrigger.value ? optionColor : '' : ''}}"
        (click)="onSelectOption(item)">{{ item ? item[displayName] : '' }}</div>

    </div> -->

  </div>
</div>
