import { Pipe, PipeTransform } from '@angular/core';
import { getUpperCaseChars } from '../utils/util-functions';

@Pipe({
    name: 'abbreviation'
})
export class AbbreviationPipe implements PipeTransform {
  transform(value?: string | undefined): string {
    let transformedName = '--';
    if ( !value?.trim()?.length) {
      return transformedName;
    }
    const namesSeparatedBySpaces = value.split(' ');
    if (!namesSeparatedBySpaces?.length || namesSeparatedBySpaces?.length == 1) {
      transformedName = getUpperCaseChars(value);
      transformedName = !transformedName?.length ? value.substring(0, 1) : transformedName;
    }
    else {
      transformedName = '';
      namesSeparatedBySpaces.forEach((str: string) => {
        transformedName += str.substring(0, 1);
      });
    }
    return transformedName;
  }
}
