import { Component, Output, EventEmitter, OnInit, InjectionToken, Inject } from '@angular/core';
import { FoundItemsModel } from '../../found-items-list/found-items-model';
import { AppText } from 'src/app/text/app-text';
import { Subscription } from 'rxjs';


export const TEXTAREA_AUTOCOMPLETE_OVERLAY_PANEL = new InjectionToken<{}>('TEXTAREA_AUTOCOMPLETE_OVERLAY_PANEL');
@Component({
    selector: 'app-textarea-autocomplete-overlay',
    templateUrl: './textarea-autocomplete-overlay.component.html',
    styleUrls: ['./textarea-autocomplete-overlay.component.scss']
})
export class TextareaAutocompleteOverlayComponent implements OnInit {
    /**
     * Событие изменения(пользователь выбрал новый элемент)
     */
    @Output() itemChange: EventEmitter<FoundItemsModel | null> = new EventEmitter<FoundItemsModel | null>();

    /**
     * Событие удаления
     */
    @Output() itemRemove: EventEmitter<FoundItemsModel> = new EventEmitter<FoundItemsModel>();

    /**
     * Событие добавления
     */
    @Output() itemAdd: EventEmitter<FoundItemsModel> = new EventEmitter<FoundItemsModel>();

    /**
     * Закрытие панели
     */
    @Output() insidePanelClose: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Изменение листа
     */
    @Output() parentItemsChange: EventEmitter<FoundItemsModel[] | null> = new EventEmitter<FoundItemsModel[] | null>();

    /**
     * Для подписок
     */
    private componentSubscriptions: Subscription[] = [];
    
    /**
     * Необходимый текст для верстки
     */
    public generalPageText = {
        addItem: AppText.addAction,
        deleteAction: AppText.deleteAction,
        removeAction: AppText.removeAction,
        notFoundText: AppText.generalTextIsNotFound,
    };

    /**
     * Размер items в пикселях
     */
    public itemSizeAsPixel: number = 37;

    /**
     * Список элементов для отображения
     */
    public items!: Array<FoundItemsModel>;

    /**
     * Выбранный элемент из списка
     */
    public selectedItem!: FoundItemsModel | null;

    /**
     * Включено отображение иконки Add
     */
    public addIconEnable: boolean = false;

    /**
     * Включено отображение иконки Apply
     */
    public applyIconEnable: boolean = false;

    /**
     * Включено отображение иконки Remove
     */
    public removeIconEnable: boolean = false;

    /**
     * Стили для всплывающего окна
     */
    public customStyleClassForList: string = '';

    constructor(
        @Inject(TEXTAREA_AUTOCOMPLETE_OVERLAY_PANEL) public overlayData: any
    ) {
        this.items = this.overlayData?.items;
        this.selectedItem = this.overlayData?.selectedItem;
        this.addIconEnable = this.overlayData?.addIconEnable || this.addIconEnable;
        this.applyIconEnable = this.overlayData?.applyIconEnable || this.applyIconEnable;
        this.removeIconEnable = this.overlayData?.removeIconEnable || this.removeIconEnable;
        this.customStyleClassForList = this.overlayData?.customStyleClassForList || this.customStyleClassForList;
        const parentItemsChangedSub = this.parentItemsChange
            .subscribe((newItems: FoundItemsModel[]) => {
                this.items = newItems|| [];
            });
        this.componentSubscriptions.push(parentItemsChangedSub);
    }

    public ngOnInit(): void {
        // TODO
    }

    public ngOnDestroy(): void {
        this.unsubscribeFromAllEvents();
    }

    /**
     * Выбор элемента из списка
     * @param item элемент
     */
    public onSelectItem(item: FoundItemsModel | null): void {
        this.itemChange.next(item);
    }

    /**
     * Добавление элемента из списка
     * @param item элемент
     */
    public onAddItem(item: FoundItemsModel): void {
        if (this.addIconEnable) {
            this.itemAdd.next(item);
        }
    }

    /**
     * Удаление элемента из списка
     * @param item элемент
     */
    public onRemoveItem(item: FoundItemsModel): void {
        if (this.removeIconEnable) {
            this.itemRemove.next(item);
        }
    }

    /**
     * Закрытие панели
     */
    public onClosePanel(): void {
        this.insidePanelClose.next();
    }

    /**
     * Метод проверки массива на его наличие и длину
     * @param array Массив для проверки длины
     * @returns результат - удовлетворяет ли массив условию
     */
    public isArrayValid(array: any[]): boolean {
        return array?.length ? true : false;
    }

    /**
     * Отписки
     */
    private unsubscribeFromAllEvents(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.forEach((s: Subscription) => s.unsubscribe());
            this.componentSubscriptions = [];
        }
    }
}