<app-found-items-list style="width: 100%;"
    [items]="items"
    [selectedItem]="selectedItem"
    [addIconEnable]="addIconEnable"
    [applyIconEnable]="applyIconEnable"
    [removeIconEnable]="removeIconEnable"
    [customStyleClassForList]="customStyleClassForList"
    (itemChange)="onSelectItem($event)"
    (itemRemove)="onRemoveItem($event)"
    (itemAdd)="onAddItem($event)"
    (panelClose)="onClosePanel()">
</app-found-items-list>