<ng-template #t let-date let-focused="focused">
    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
        {{ date.day }}
    </span>
</ng-template>

<div *ngIf="enableMinimizedMode"
    class="app-datepicker-front"
    [class.active]="valuePeriod && valuePeriod != ''"
    [class.open]="!isDatepickerHide"
    fxLayout="row" fxLayoutAlign="space-between center"
    (click)="isDatepickerHide = !isDatepickerHide">

    <span>{{ dateInputName }}</span>

    <div matSuffix class="app-datepicker-front__icon-arrow"></div>
</div>

<div class="app-datepicker" [class.animate]="enableMinimizedMode" [class.hide]="enableMinimizedMode && isDatepickerHide" fxLayout="column" fxLayoutAlign="center " fxLayoutGap="2px">

    <!-- Инпут для даты и календарь-->
    <div class="app-datepicker__selector">

        <form class="form-inline" [class.disabled]="disabled">
            <div class="form-group">
                <div class="input-group" (mouseenter)="calendarBlockEnter=true" (mouseleave)="calendarBlockEnter=false">

                    <input class="form-control" placeholder="dd-mm-yyyy" ngbDatepicker #rangeDPicker="ngbDatepicker"
                        name="rangeDPicker" [outsideDays]="outsideDays" [firstDayOfWeek]="1"
                        [displayMonths]="2" [dayTemplate]="t" autoClose="'outside'" [disabled]="disabled"
                        (dateSelect)="onChangePeriodByCalendar($event)" [startDate]="fromDate" [minDate]="{year: 1970, month: 1, day: 1}">

                        <!-- [maxDate]="enableMaxDate ? today : null" -->

                    <div class="app-datepicker__selector-date" [class.active]="valuePeriod === pageText.period" [class.disabled]="disabled" fxLayout="row" fxLayoutAlign="start center"
                        fxLayoutGap="8px" (click)="onShowCalendar()">
                        <div class="app-datepicker__calendar-icon"></div>
                        <span style="width: 100%;">{{ valuePeriod === pageText.period ? selectedPeriodTitle : pageText.selectDate }}</span>
                    </div>

                </div>
            </div>
        </form>

    </div>


    <!-- Тогл кнопки для дат-->
    <div class="app-datepicker__toggles" fxLayout="column" fxLayoutAlign="center " fxLayoutGap="2px">
        <div *ngFor="let block of toggles | slice:0:_blocksOfToggles; let k = index;" class="app-datepicker__toggle-pair" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
            <div *ngFor="let item of toggles | slice:(k*2):((k*2)+2); let i = index;" class="app-datepicker__toggle"
             [class.active]="valuePeriod === item?.Period" fxLayout="row" fxLayoutAlign="center center" (click)="onChangePeriodByToggles(item)">
                <span>{{ item.Period | mappingStatus: 'datapickerToggle' }}</span>
            </div>
        </div>
    </div>
    <!-- Сброс даты, если включен дефолт, то он не сбрасывается -->
    <div *ngIf="valuePeriod" @showElement class="app-datepicker__reset" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
        <span class="app-datepicker__reset-date">{{ selectedPeriodTitle }}</span>
        <div class="app-datepicker__reset-btn" (click)="onResetDate()"></div>
    </div>
</div>

