export class LocalStorage {

    public static get(key: string): string | null {
        let parsedData: any = null;
        if (!key) {
            return parsedData;
        }

        try {
            const data = localStorage.getItem(key);
            parsedData = data ? JSON.parse(data) : null;
        } catch (error) {
            console.log(error);
            return parsedData;
        }

        return parsedData;
    }

    public static set(key: string, value: any): void {
        if (!key || !value) {
            return;
        }

        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.log(error);
        }
    }

    public static remove(key: string): void {
        key && localStorage.removeItem(key);
    }
}