import {
    Component,
    ViewChild,
    InjectionToken,
    Inject,
    OnDestroy,
    OnInit,
    HostListener,
    ChangeDetectorRef,
    ElementRef,
    AfterContentInit,
    AfterViewInit,
    Output,
    EventEmitter
} from '@angular/core';
import { MatFormField } from '@angular/material/form-field';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { AppText } from 'src/app/text/app-text';
import * as _ from 'underscore';
import { AppMultiselectItemIconClass, AppMultiselectItemIconSettings, AppMultiselectItemStyleSettings } from './app-multiselect-overlay-settings';

export const SELECT_PANEL_DATA = new InjectionToken<{}>('SELECT_PANEL_DATA');
export type StyleType = 'content' | 'wrapper';

const DEFAULT_DISPLAY_NAME = 'name';
const DEFAULT_UNIQUE_NAME = 'id';
export const ARROWUP_KEY_CODE = 'ArrowUp';
export const ARROWDOWN_KEY_CODE = 'ArrowDown';
export const SPACE_KEY_CODE = 'Space';
export const ENTER_KEY_CODE = 'Enter';
@Component({
    selector: 'app-multiselect-overlay',
    templateUrl: './app-multiselect-overlay.component.html',
    styleUrls: ['./app-multiselect-overlay.component.scss']
})
export class AppMultiselectOverlayComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
    /**
     * Подписка на изменение строки поиска
     * @type {Subject<string>}
     */
    private debouncer!: Subject<string>;
    /**
     * Для подписок
     * @type {Subscription}
     */
    private overlaySub!: Subscription;
    /**
     * Не изменяющийся лист, для локальной фильтрации
     * @type {any[]}
     */
    private staticOptions: any[];
    /**
     * Фокусированный элемент
     * (нужен для перемещания по списку с keyboard)
     * @type {any}
     */
    private focusedOption: any;
    /**
     * Индекс элемента в фокусе
     * @type {number}
     */
    private focusedOptionIndex: number;

    /**
     * Подписка на изменение списка элементов
     * @type {Subject<any[]>}
     */
    public itemsChange: Subject<any[]> = new Subject<any[]>();

    public generalPageText = {
        generalTextIsSearchPlaceholder: AppText.searchAction,
        selectAllAction: AppText.selectAllAction,
        notFoundText: AppText.generalTextIsNotFound,
        nameField: AppText.generalTextIsName
    };

    /**
     * Строка поиска
     * @type {string}
     */
    public search: string = '';

    /**
     * Заголовок поля поиска
     * @type {string}
     */
    generalTextIsSearchPlaceholder!: string;

    /**
     * Флаг отражающий что мультиселект работает с единым локальным
     * списком или список приходит в запросе при каждом изменении поиска
     * @type {boolean}
     */
    isLocalList!: boolean;

    /**
     * Мультирежим
     * @type {boolean}
     */
    isMultipleModeEnable: boolean;
    /**
     * Размер items в пикселях
     * @type {number}
     */
    itemSizePx: number = 28;
    /**
     * Количество отображаемых элементов в листе
     * @type {number}
     */
    displayItemsCount: number = 0;
    /**
     * Список элементов
     * @type {any[]}
     */
    items: any[];
    /**
     * Список выбранных элементов
     * @type {any[]}
     */
    selectedItems: any[];
    /**
     * Лимит на выбор опций
     * @type {number}
     */
    selectedItemsLimit: number;
    /**
     * Имя, которое будет отображаться в списке
     * @type {string}
     */
    displayName: string = DEFAULT_DISPLAY_NAME;
    /**
     * Уникальное поле(для поиска элементов)
     * @type {string}
     */
    uniqueField: string = DEFAULT_UNIQUE_NAME;
    /**
     * Имеется возможность отключения сортировки
     * @type {boolean}
     */
    isSortEnable: boolean = true;
    /**
     * Поле для сортировки списков
     * @type {string}
     */
    sortBy: string = DEFAULT_DISPLAY_NAME;
    /**
     * Включение поиска
     * @type {boolean}
     */
    isSearchEnable: boolean;
    /**
     * Чекбокс Select All в листе
     * @type {boolean}
     */
    isSelectAllOptionVisible: boolean;
    /**
     * Тултип на элементах
     * @type {boolean}
     */
    isItemTooltipVisible?: boolean;
    /**
     * Доп класс для панели
     * @type {string}
     */
    panelStyleClass?: string;
    /**
     * Регулярное выражение для поиска
     * @type {RegExp}
     */
    pattern!: RegExp;

    // ???????
    /**
     * Фильтрует не только по displayName но и по id
     * @type {boolean}
     */
    isIncludeIdIntoSearch: boolean;

    /**
     * настройка для изменения цвета текста и цвета бэкграунда
     * для item в списке
     * (клиенту нужно было чтобы items
     * подкрашивались красным или серым поэтому эта настройка тут)
     */
    itemHtmlStyleSettings: AppMultiselectItemStyleSettings;
    /**
     * настройка для иконок в списке
     * если не задано - иконки не отображаются
     * каждый класс сам устанавливает display:block/flex и другие стили
     */
    itemHtmlIconSettings: AppMultiselectItemIconSettings;

    /**
     * Отображение иконки скрытия списка
     */
    showArrow: boolean = false;

    /**
     * Передает выбранный элемент
     * @type {EventEmitter<any>}
     */
    @Output() selectedValue: EventEmitter<any | null> = new EventEmitter<any | null>();
    /**
     * Передает всю колллекцию выбранных элементов (например для мультиселекта)
     * @type {EventEmitter<any[]>}
     */
    @Output() selectedItemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    /**
     * При нажатии на Select all
     * @type {EventEmitter<boolean>}
     */
    @Output() selectedAllChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    /**
     * При нажатии на Select all
     * @type {EventEmitter<boolean>}
     */
    @Output() insidePanelClose: EventEmitter<void> = new EventEmitter<void>();

    @Output() searchChange: EventEmitter<string | null> = new EventEmitter<string | null>();

    /**
     * Устанавливает класс для стилизации иконки поиска
     * @type {boolean}
     */
    get setFocusClassForSearchIconInSearchInput(): boolean {
        return this.isSearchEnable && this.searchInputHTMLElement?._control?.focused ? true : false;
    }
    /**
     * Устанавливает класс для стилизации кнопки крестика
     * @type {boolean}
     */
    get showClearIconInSearchInput(): boolean {
        return this.search?.length ? true : false;
    }

    get itemsContainerHTMLHeight(): string {
        return !this.isArrayValid(this.items) ? 'unset' : this.items?.length * this.itemSizePx >= 300 ? '300px' : this.items?.length * this.itemSizePx + 'px';
    }

    constructor(@Inject(SELECT_PANEL_DATA) public overlayData: any) {
        this.focusedOptionIndex = 0;

        this.isMultipleModeEnable = this.overlayData.isMultipleModeEnable;
        this.itemSizePx = this.overlayData.itemSizePx != null ? this.overlayData.itemSizePx : 28;
        this.displayItemsCount = this.overlayData.displayItemsCount != null ? this.overlayData.displayItemsCount : 0;
        this.generalTextIsSearchPlaceholder = this.overlayData.generalTextIsSearchPlaceholder != null ? this.overlayData.generalTextIsSearchPlaceholder : this.generalPageText.generalTextIsSearchPlaceholder;

        /** Неизменяющийся лист */
        this.staticOptions = this.overlayData.items ? this.overlayData.items : [];
        this.items = this.staticOptions.slice();
        this.selectedItems = this.overlayData.selectedItems ? this.overlayData.selectedItems : [];
        this.isLocalList = this.overlayData.isLocalList != null ? this.overlayData.isLocalList : false;
        // Раньше было 10 по дефолту
        this.selectedItemsLimit = this.overlayData.selectedItemsLimit != null ? this.overlayData.selectedItemsLimit : null;
        this.displayName = this.overlayData.displayName != null ? this.overlayData.displayName : DEFAULT_DISPLAY_NAME;
        this.uniqueField = this.overlayData.uniqueField != null ? this.overlayData.uniqueField : DEFAULT_UNIQUE_NAME;
        this.isSortEnable = this.overlayData.isSortEnable != null ? this.overlayData.isSortEnable : true;
        this.sortBy = this.overlayData.sortBy != null ? this.overlayData.sortBy : this.displayName;
        this.isSearchEnable = this.overlayData.isSearchEnable;
        this.isSelectAllOptionVisible = this.overlayData.isSelectAllOptionVisible;
        this.isItemTooltipVisible = this.overlayData.isItemTooltipVisible;
        this.panelStyleClass = this.overlayData.panelStyleClass;

        // ???????
        this.isIncludeIdIntoSearch = this.overlayData.isIncludeIdIntoSearch;

        this.itemHtmlStyleSettings = this.overlayData.itemHtmlStyleSettings;
        this.itemHtmlIconSettings = this.overlayData.itemHtmlIconSettings;

        this.itemsChange.subscribe(newItems => {
            this.items = newItems ? newItems : [];
            this.staticOptions = newItems ? newItems : [];
        });
    }

    /**
     * Поле поиска в панели
     * @type {MatFormField}
     */
    @ViewChild('searchInputHTMLElement', { static: false }) searchInputHTMLElement?: MatFormField;
    /**
     * Поле поиска в панели
     * @type {MatFormField}
     */
    @ViewChild('searchInput', { static: false }) searchInput?: ElementRef;
    /**
     * Див со списком элементов
     * @type {ElementRef}
     */
    @ViewChild('itemsContainerHTMLElement', { static: false }) itemsContainerHTMLElement?: ElementRef;

    /**
     * Отписываться не нужно, умирает с компонентом
     * Слушает нажатие esc и закрывает окно
     * @param {KeyboardEvent} event Событие
     *
     * @returns {void}
     */
    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): boolean {
        if (this.isSearchEnable && this.searchInputHTMLElement && this.searchInputHTMLElement._control?.focused) {
            // С поля search по enter прыгаем на список options
            if (event && event.code == ENTER_KEY_CODE && this.itemsContainerHTMLElement) {
                this.itemsContainerHTMLElement.nativeElement.focus();
            }

            return true;
        }

        if (!this.isArrayValid(this.items)) return false;

        let key = event.code;

        // space, enter, arrowup, arrowdown
        if (!(key == SPACE_KEY_CODE || key == ENTER_KEY_CODE || key == ARROWUP_KEY_CODE || key == ARROWDOWN_KEY_CODE)) {
            return false;
        }

        // space, enter
        if (key == ENTER_KEY_CODE || key == SPACE_KEY_CODE) {
            this.onSelectOption(this.focusedOption);

            return false;
        }

        // arrowup, arrowdown
        if (key == ARROWUP_KEY_CODE || key == ARROWDOWN_KEY_CODE) {
            // arrowup
            if (key == ARROWUP_KEY_CODE) {
                if (this.focusedOptionIndex == 0) {
                    return false;
                }

                this.focusedOptionIndex = this.focusedOptionIndex > 0 ? (this.focusedOptionIndex -= 1) : 0;
            }

            // arrowdown
            if (key == ARROWDOWN_KEY_CODE) {
                /**
                 * Если !displayItemsCount всегда брать длину списка
                 *
                 * Индекс последнего элемента в списке
                 *
                 * Если список больше displayItemsCount, то берем displayItemsCount
                 */
                let lastIndex =
                    this.displayItemsCount && this.items?.length > this.displayItemsCount
                        ? this.displayItemsCount - 1
                        : this.items?.length
                            ? this.items?.length - 1
                            : 0;

                if (this.focusedOptionIndex == lastIndex) {
                    return false;
                }

                this.focusedOptionIndex = this.focusedOptionIndex < lastIndex ? (this.focusedOptionIndex += 1) : lastIndex;
            }

            this.focusedOption = this.items[this.focusedOptionIndex];

            try {
                let item = document.getElementById(`${this.focusedOptionIndex}`);
                item?.scrollIntoView({ block: 'center', behavior: 'smooth' });
            } catch (ex) {
                console.log(ex);
            }
        }
        return false;
    }

    /**
     * Инициализация
     * @returns {void}
     */
    ngOnInit(): void {
        this.searchSubscription();
    }

    /**
     * Обновление дом элементов после инициализации
     * @returns {void}
     */
    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.searchInput) {
                this.searchInput.nativeElement.focus();
            }
            this.showArrow = true;
        }, 0);
    }

    /**
     * Обновление дом элементов после ngDoCheck
     * @returns {void}
     */
    ngAfterContentInit(): void {
        this.focusedOption = this.isArrayValid(this.selectedItems)
            ? this.selectedItems[this.selectedItems.length - 1]
            : this.isArrayValid(this.items)
                ? this.items[0]
                : null;

        if (this.focusedOption) {
            /** Поиск в обновленных данных */
            let index = _.findIndex(this.items, (_item: any) => {
                return String(this.focusedOption[this.uniqueField]) == String(_item[this.uniqueField]);
            });

            // Если выбранный элемент уже содерджится, то удаляем его
            this.focusedOptionIndex = index > -1 ? index : 0;
        }
    }

    /**
     * Уничтожение компонента
     * @returns {void}
     */
    ngOnDestroy(): void {
        if (this.overlaySub) {
            this.overlaySub.unsubscribe();
        }

        if (this.debouncer) {
            this.debouncer.unsubscribe();
        }
    }

    /**
     * Закрытие списка
     * @returns {void}
     */
    onClose() {
        this.insidePanelClose.next();
    }

    /**
     * Метод генерирует событие при изменении строки
     * @param {string} search Строка поиска
     *
     * @returns {void}
     */
    onSearch(search: string): void {
        if (this.isSearchEnable && this.searchInputHTMLElement) this.search = search;

        this.debouncer.next(this.search);
    }

    /**
     * Метод очищает строку поиска и генерирует событие
     * @returns {void}
     */
    onClearSearch(): void {
        if (this.isSearchEnable && this.search !== '' && this.searchInputHTMLElement) {
            this.search = '';

            this.debouncer.next(this.search);
        }
    }

    /**
     * Метод выбора элементов из списка
     * @param {any} item Выбранный элемент
     *
     * @returns {void}
     */
    onSelectOption(item: any): void {
        this.selectedItems = this.selectedItems ? this.selectedItems : [];

        if (!this.isMultipleModeEnable) {
            this.selectedItems.splice(0, this.selectedItems.length);
            this.selectedItems.push(item);
        } else {
            /** Поиск индекса */
            let index = this.selectedItems.indexOf(item);

            // Удаление, если уже есть в списке
            if (index > -1) {
                this.selectedItems.splice(index, 1);

                // Добавляем только в том случае, если количество не привышает лимит
            } else if (this.selectedItemsLimit == null || (this.selectedItemsLimit != null && this.selectedItems?.length < this.selectedItemsLimit)) {
                this.selectedItems.push(item);
            }
        }

        this.selectedItemsChange.next(this.selectedItems);
        this.selectedValue.next(item);
    }

    /**
     * Выбор всех элементов по чекбоксу
     * @param {MatCheckboxChange} event Событие `checkbox`, привязанное к `change`
     *
     * @returns {void}
     */
    onSelectAll(event: MatCheckboxChange): void {
        if (!this.isMultipleModeEnable || !this.isArrayValid(this.items)) {
            return;
        }

        if (event.checked) {
            this.selectedItems = this.selectedItemsLimit != null ? _.first(this.items.slice(), this.selectedItemsLimit) : this.items.slice();
        } else {
            this.selectedItems.splice(0, this.selectedItems.length);
        }

        this.selectedItemsChange.next(this.selectedItems);
        this.selectedAllChange.next(event.checked);
    }

    /**
     * Проверка выбраны ли все элементы
     * @returns {boolean}
     */
    isAllSelected(): boolean {
        if (!this.isMultipleModeEnable) {
            return false;
        }

        const numSelected = this.selectedItems ? this.selectedItems.length : 0;
        const numRows = this.items ? this.items.length : 0;

        return this.selectedItemsLimit != null ? numSelected == this.selectedItemsLimit : numSelected == numRows;
    }

    /**
     * Метод проверки элемента на содержание
     * его в массиве `selectedOptions`
     * @param {any} item Элемент
     *
     * @returns {boolean}
     */
    isOptionSelected(item: any): boolean {
        if (!item) return false;

        return this.selectedItems.find((opt: any) => opt[this.uniqueField] == item[this.uniqueField]) ? true : false;
    }

    /**
     * Метод проверки фокусировки на элементе
     * @param {any} item Элемент
     *
     * @returns {boolean}
     */
    isOptionFocused(item: any): boolean {
        if (!item || !this.focusedOption || !this.isArrayValid(this.items)) return false;

        return item[this.uniqueField] == this.focusedOption[this.uniqueField];
    }

    /**
     * Метод проверки привышения лимита
     * @param {any} item Элемент
     *
     * @returns {boolean}
     */
    isLimitExceeded(item: any): boolean {
        if (this.isOptionSelected(item) || !this.selectedItemsLimit || !this.isMultipleModeEnable) {
            return false;
        }

        return this.selectedItems.length >= this.selectedItemsLimit;
    }

    /**
     * Метод проверки массива на его наличие и длину
     * @param {any[]} array Массив для проверки длины
     *
     * @returns {boolean} результат - удовлетворяет ли массив условию
     */
    isArrayValid(array: any[]): boolean {
        return array?.length ? true : false;
    }

    /**
     * Возвращает название класса из объекта по входным данным
     * @param {any} item элемент из списка
     * @param {ColorStyleType} targetType тип того что будем красить, напр текст или бэкграунд
     *
     * @returns {string} название класса
     */
    getOverlayItemStyleClass(item: any, targetType: StyleType = 'wrapper'): string {
        if (!item) return '';

        if (!this.itemHtmlStyleSettings) return '';

        // Ключи не указаны
        if (!this.itemHtmlStyleSettings.itemCTTriggerKey && !this.itemHtmlStyleSettings.itemWRTriggerKey) return '';

        if (
            this.itemHtmlStyleSettings.itemCTTriggerKey &&
            targetType == 'content' &&
            item?.[this.itemHtmlStyleSettings.itemCTTriggerKey] == this.itemHtmlStyleSettings.itemCTTriggerValue
        )
            return this.itemHtmlStyleSettings.contentClass ? this.itemHtmlStyleSettings.contentClass : '';

        if (
            this.itemHtmlStyleSettings.itemWRTriggerKey &&
            targetType == 'wrapper' &&
            item?.[this.itemHtmlStyleSettings.itemWRTriggerKey] == this.itemHtmlStyleSettings.itemWRTriggerValue
        )
            return this.itemHtmlStyleSettings.wrapperClass ? this.itemHtmlStyleSettings.wrapperClass : '';

        return '';
    }

    /**
     * Возвращает название класса иконки из объекта по входным данным
     * @param {any} item элемент из списка
     *
     * @returns {string} название класса
     */
    getOverlayItemIconClass(item: any): string | null {
        if (!item) return null;

        if (!this.itemHtmlIconSettings) return null;

        // Ключ не указан
        if (!this.itemHtmlIconSettings.itemTriggerKey) return null;

        if (!this.isArrayValid(this.itemHtmlIconSettings.itemTriggerValues)) return null;

        let _data = _.find(this.itemHtmlIconSettings.itemTriggerValues, (i: AppMultiselectItemIconClass) => {
            return i.itemValue == item?.[this.itemHtmlIconSettings.itemTriggerKey];
        });

        return _data && _data.iconClass ? _data.iconClass : null;
    }

    /**
     * Подписка на поиск если он включен
     */
    private searchSubscription(): void {
        if (this.isSearchEnable) {
            this.debouncer = new Subject<string>();

            /** Подписка на изменение строки поиска */
            this.overlaySub = this.debouncer.pipe(debounceTime(this.isLocalList ? 100 : 1000), distinctUntilChanged()).subscribe((value: string) => {
                if (value != undefined) this.filter(value);

                this.searchChange.emit(value ? value : null);
            });
        }
    }

    /**
     * Получает фильтрованный лист
     * @param {string} value Строка для фильтрациии
     *
     * @returns {void}
     */
    private filter(value: string): void {
        if (value != null && value.toString().trim() != '') {
            const filterValue = value.toString().toLowerCase();

            // фильтрация по включению
            this.items = !this.isIncludeIdIntoSearch
                ? this.staticOptions.filter(option =>
                    option?.[this.displayName]
                        ?.toString()
                        .toLowerCase()
                        .includes(filterValue)
                )
                : this.staticOptions.filter(
                    option =>
                        option?.[this.displayName]
                            ?.toString()
                            .toLowerCase()
                            .includes(filterValue) ||
                        option?.[this.uniqueField]
                            ?.toString()
                            .toLowerCase()
                            .includes(filterValue)
                );
        } else {
            this.items = this.staticOptions.slice();
        }

        this.resetFocusedItem();
    }

    /**
     * Сброс элемента для фокуса
     * @returns {void}
     */
    private resetFocusedItem(): void {
        if (this.isArrayValid(this.items)) {
            this.focusedOptionIndex = 0;
            this.focusedOption = this.items[0];
            if (this.itemsContainerHTMLElement) {
                this.itemsContainerHTMLElement.nativeElement.scrollTop = 0;
            }
        }
    }
}
