import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from 'src/app/api';
import { routeMap } from 'src/app/route-map';

@Injectable({
  providedIn: 'root'
})
export class InitGuard  {

  constructor(
    private router: Router,
    private authService: AuthorizationService,
  ) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      const initialized = this.authService.initialized;

      if (!initialized) {
        if (state.url === `/${routeMap.loading}`) {
          return true;
        }
        this.router.navigate([routeMap.loading], { queryParams: { return_to: state.url } });
      }
      return initialized;
  }
}