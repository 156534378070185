import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitGuard } from './init-guard/init.guard';
import { RoleGuard } from './role-guard/role.guard';
import { EmailVerifyGuard } from './email-verify-guard/email-verify-guard.guard';



@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        InitGuard,
        RoleGuard,
        EmailVerifyGuard
    ]
})
export class GuardModule { }
