import { Component } from '@angular/core';
import { BaseApiService } from './api';
import { routeMap } from './route-map';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    /**
     * Флаг ожидания ответа на запрос
     */
    public get inProgressState(): boolean {
        return this.baseApiService?.loadInProgress;
    }

    /**
     * По умолчанию скрывает меню там,
     * где оно не нужно
     * @type {boolean}
     */
    public get isLoginStyle(): boolean {
        const route = window.location.pathname;
        return route == `/${routeMap.signIn}` || route == `${routeMap.signUp}` || route == '';
    }

    constructor(
        private baseApiService: BaseApiService,

    ) {
        // TODO
    }
}