import { Model } from '../../shared/interfaces';
import { RuleModel } from '../../rules/shared/rule.model';
import { ArticleGroupAnalysisModel } from './artcile-group-analysis.model';
import { ArticleGroupSettingsModel } from './artcile-group-settings.model';
import { ArticleGroupArticleStatus } from './article-group-article-status';
import { ArticleGroupPotentialCompetitorStatus } from './article-group-potential-competitor-status';
import { ArticleGroupCompetitorStatus } from './article-group-competitor-status';

export class ArticleGroupModel implements Model {
    id!: string | number | null;
    name!: string;
    rules!: RuleModel[];

    articleStatus!: ArticleGroupArticleStatus;
    potentialCompetitorStatus!: ArticleGroupPotentialCompetitorStatus;
    competitorStatus!: ArticleGroupCompetitorStatus;
    ruleConfigurationChanged!: boolean;
    ruleConfigurationRequired!: boolean;
    monitoringInProgress!: boolean;

    isManual!: boolean;

    confidenceThreshold!: number;
    minimumRecognitionQuality!: number;
    synonyms!: string | null;
    setSize!: number;
    itemsToVerify!: number;
    analysis!: ArticleGroupAnalysisModel;
    settings!: ArticleGroupSettingsModel;

    createdDate!: number;
    updatedDate!: number;
    updatedByUserId!: string;
    updatedByUserName!: string;

    requiredTags!: string;
    forbiddenTags!: string;

    constructor(data?: any) {
        if (data) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    this[key as keyof ArticleGroupModel] = <never>data[key];
                }
            }
        }
    }
}
