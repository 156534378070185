<div class="app-check-filter" fxLayout="column">
    <div *ngIf="enableMinimizedMode" class="app-check-filter__header" fxLayout="row"
        fxLayoutAlign="space-between center" [class.open]="!isFilterHide"
        [class.active]="valueDetected || valueNotDetected" (click)="isFilterHide = !isFilterHide">

        <span>Article detection algorithm</span>
        <div matSuffix class="app-check-filter__header__icon-arrow"></div>
    </div>
    <div class="app-check-filter__container" [class.animate]="enableMinimizedMode" [class.hide]="enableMinimizedMode && isFilterHide">
        <div class="app-check-filter__container__body" fxLayout="column" fxLayoutGap="4px">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div class="item-col">
                    <span>Name</span>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="algorithm-checkbox" fxLayout="row" fxLayoutAlign="center center">
                        <span>Article</span>
                    </div>
                    <div class="algorithm-checkbox" fxLayout="row" fxLayoutAlign="center center">
                        <span>Not article</span>
                    </div>
                </div>
            </div>
            <div *ngIf="algorithms" class="app-check-filter-list" fxLayout="column">
                <div *ngFor="let item of algorithms" fxLayout="row" fxLayoutAlign="start center">
                    <div class="algorithm-name item-col" fxLayout="row" fxLayoutAlign="start center"
                        matTooltip="{{item}}">
                        <span>{{item}}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="algorithm-checkbox" fxLayout="row" fxLayoutAlign="center center">
                            <mat-checkbox class="example-margin" [checked]="trendChecklist.isSelected(item)"
                                (change)="onToggleItem(0, item)">
                            </mat-checkbox>
                        </div>
                        <div class="algorithm-checkbox" fxLayout="row" fxLayoutAlign="center center">
                            <mat-checkbox class="example-margin" [checked]="notArticleChecklist.isSelected(item)"
                                (change)="onToggleItem(1, item)">
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
