export class Breadcrumbs {
  label!: string;
  route!: string;
  url!: string;

  constructor(data?: any) {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this[key as keyof Breadcrumbs] = data[key];
        }
      }
    }
  }
}
