import { IFilterSettings } from './ifilter-settings';

export class FilterSettings {
  routePage!: any;
  filterBySearch!: boolean;
  filterVisible!: boolean;
  backBtnVisible!: boolean;
  backBtnPlaceholder!: any;
  applyBtnVisible!: boolean;
  resetBtnVisible!: boolean;
  filterByDecisionRules!: boolean;
  filterByRulesMatch!: boolean;
  filterByConfidence!: boolean;

  constructor(data?: any) {
    if (data) {
      for (const i in data) {
        if (data.hasOwnProperty(i)) {
          this[i as keyof FilterSettings] = data[i];
        }
      }
    }
  }
}
