import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
// import { AppPreloadingStrategy } from './app-preloading-strategy';


@NgModule({
    imports: [
        // RouterModule.forRoot(appRoutes, {
        //     preloadingStrategy: AppPreloadingStrategy,
        //     anchorScrolling: 'enabled'
        // })
        RouterModule.forRoot(appRoutes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
