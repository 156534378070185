import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { LocalStorage } from './local-storage';
import { routeMap } from '../route-map';
import * as _ from 'underscore';
import { Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { AppText } from 'src/app/text/app-text';
import { UserProfile } from '../contracts';
import { Token, TokenRequest } from '.';
import { ApiEndpoints } from '.';
// import jwt from "jwt-decode";
// import { Role } from '../shared/types';
import { map } from 'rxjs/operators';
import { RouterHistoryService } from '../shared/services/router-history.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpResponseObserve, HttpResponseType, RequestOptions } from './request-options';


@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {
    private static tokenKey = 'api-token';
    private defaultLoginData = {
        email: 'default_user',
        password: 'default_user'
    };

    protected _user!: UserProfile | undefined;
    private requestAuthorization: boolean;
    private HTTP_NOT_FOUND = 404;
    private _initialized!: boolean;
    private _errorMessage!: string | null;
    private _day = 86400;
    _rememberMe = true;
    timeShowError = 500;
    logoutEvent: Subject<void> = new Subject();
    fillUserProfileSubject = new BehaviorSubject(false);

    EMAIL_NOT_VERIFIED = 'email_not_verified';

    get authorizationRequired() {
        return this.requestAuthorization;
    }

    get userProfile(): UserProfile | undefined {
        return this._user;
    }

    set userProfile(_profile: UserProfile | undefined) {
        this._user = _profile;
    }

    get initialized() {
        return this._initialized;
    }

    get errorMessage() {
        return this._errorMessage;
    }

    get rememberMe() {
        return this._rememberMe;
    }

    /** Navigate to the url after authorization */
    redirectUrl!: string | null;

    constructor(private http: HttpClient, private router: Router) {
        const token = AuthorizationService.getToken();
        this.requestAuthorization = token === null ? true : false;
    }

    appInit(): Observable<boolean> {
        const token = AuthorizationService.getToken();
        const tokenLifeTime = AuthorizationService.getTokenLifeTime();
        const today = Math.round(new Date().getTime() / 1000);
        const temporaryToken = AuthorizationService.getTemporaryTokenOfNotEmailVerifiedUser();
        const ctx = this;

        // Пока нет бэка
        // ctx.requestAuthorization = false;
        // ctx._initialized = true;
        // return of(true);

        if (token == null) {
            if (temporaryToken == null) {
                ctx.requestAuthorization = true;
                ctx._initialized = true;
                return of(false);
            }
            else {
                ctx.userProfile = new UserProfile({
                    id: this.EMAIL_NOT_VERIFIED,
                    email: temporaryToken,
                    role: 'notVerified',
                });
                ctx.requestAuthorization = false;
                ctx._initialized = true;
                return of(true);
            }
        }

        if (token && tokenLifeTime && today > tokenLifeTime) {
            ctx.requestAuthorization = true;
            ctx._initialized = true;
            return of(false);
        }

        return new Observable<boolean>((observable: any) => {
            ctx.fillUserProfile().subscribe(
                () => {
                    ctx.requestAuthorization = false;
                    ctx._initialized = true;
                    observable.next(true);
                    observable.complete();
                },
                (error: any) => {
                    ctx.requestAuthorization = true;
                    ctx._initialized = true;
                    observable.next(false);
                    observable.complete();
                }
            );
        });
    }

    setRememberMe(checked: boolean) {
        this._rememberMe = checked;
    }

    setAuthorizationRequired(loginParams?: any) {
        this.requestAuthorization = true;
        loginParams ? this.router.navigate([`/${routeMap.signIn}`], { queryParams: loginParams }) : this.router.navigate([`/${routeMap.signIn}`]);
    }

    authorize(username: string, password: string): Observable<void> {
        console.log(username);
        const request = new TokenRequest();
        request.grant_type = 'password';
        request.scope = 'admin_area';
        request.username = username;
        request.password = password;
        request.client_id = environment.clientId;
        request.client_secret = environment.clientSecret;

        const body = this.serialize(request);
        const url = ApiEndpoints.token();

        const options = {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            observe: 'body',
            responseType: 'json'
        };

        const ctx = this;
        return new Observable<void>((observable: any) => {
            /** TRERE IS DELETING FROM REAL PROJECT */
            // setTimeout(() => {
            //   if (username == this.defaultLoginData.email && password == this.defaultLoginData.password) {
            //     ctx.fillUserProfile();
            //     observable.next();
            //     observable.complete();
            //     return;
            // } else {
            //   this._errorMessage = 'Неверный логин или пароль';
            //   observable.error('Неверный логин или пароль');
            //   observable.complete();
            //   return;
            // }}, 2000);
            ctx.http
                .post(url, body, options as any)
                .pipe(map((res: any) => <Token>res))
                .subscribe(
                    (t: Token) => {
                        const today = Math.round(new Date().getTime() / 1000);
                        const month = 2629743;
                        t.issued = new Date().getTime();
                        LocalStorage.setToken(t);
                        this.rememberMe ? LocalStorage.setTokenLifeTime(today + month) : LocalStorage.setTokenLifeTime(today + this._day);
                        LocalStorage.setMenuCompactFlag(true);
                        this._errorMessage = '';
                        ctx.fillUserProfile().subscribe(
                            () => {
                                this.requestAuthorization = false;
                                this._errorMessage = '';
                                observable.next();
                                observable.complete();
                            },
                            () => {
                                observable.error(null);
                                observable.complete();
                            }
                        );
                    },
                    (error: any) => {
                        const _error: { error: string, error_description: string } = error?.error;
                        if (_error?.error_description === this.EMAIL_NOT_VERIFIED) {
                            LocalStorage.setToken(null);
                            LocalStorage.setTemporaryTokenOfNotEmailVerifiedUser(username);
                            this.userProfile = new UserProfile({
                                id: this.EMAIL_NOT_VERIFIED,
                                email: username,
                                role: 'notVerified',
                            });
                            this.fillUserProfileSubject.next(true);
                            this.requestAuthorization = false;
                            this._errorMessage = '';
                            observable.error(_error);
                            observable.complete();
                        }
                        else {
                            LocalStorage.setTemporaryTokenOfNotEmailVerifiedUser(null);
                            this.requestAuthorization = false;
                            this._errorMessage = AppText.requestFailedWrongLoginOrPassword;
                            observable.error(this._errorMessage);
                            observable.complete();
                        }
                    }
                );
        });
    }

    updateProfile(userProfile: UserProfile): void {
        if (this.userProfile) {
            this.userProfile.firstName = userProfile.firstName;
            this.userProfile.secondName = userProfile.secondName;
            this.userProfile.email = userProfile.email;
        }
    }

    fillUserProfile(): Observable<void> {
        const ctx = this;
        // return new Observable<void>((observable: any) => {
        //     this.userProfile = new UserProfile({
        //       id: '1ff213c421064bf2b84bba1153e6cd98',
        //       // timestamp: 1579253665,
        //       email: 'admin@mail.ru',
        //       userName: 'admin',
        //       role: 'admin',
        //       // profile: null,
        //       // clientId: null,
        //       // clientName: null,
        //       accessToTheSystem: true,
        //     });
        //     this.requestAuthorization = false;
        //     this._initialized = true;
        //     this.fillUserProfileSubject.next(true);
        //     observable.next();
        //     observable.complete();
        //     return;
        // });
        const token = AuthorizationService.getToken();
        if (!token || (!ctx.requestAuthorization && ctx.userProfile && ctx.userProfile.id)) {
            return new Observable<void>((observable: any) => {
                if (ctx.userProfile?.id != this.EMAIL_NOT_VERIFIED) {
                    LocalStorage.setTemporaryTokenOfNotEmailVerifiedUser(null);
                }
                observable.next();
                observable.complete();
            });
        }
        const options = AuthorizationService.createOptions();
        const url = ApiEndpoints.profile().listUrl();
        return new Observable<void>((observable: any) => {
            ctx.http
                .get(url, options as any)
                .pipe(map((res: any) => <UserProfile>res))
                .subscribe(
                    (res: UserProfile) => {
                        this.userProfile = res;
                        this.fillUserProfileSubject.next(true);
                        this._errorMessage = '';
                        LocalStorage.setTemporaryTokenOfNotEmailVerifiedUser(null);
                        observable.next();
                        observable.complete();
                    },
                    (error: any) => {
                        const errorJSON = error;
                        this._errorMessage = errorJSON.errorMessage;
                        this.logout();
                        observable.error(error);
                        observable.complete();
                    }
                );
        });
    }

    logout(loginParams?: any) {
        this.logoutEvent.next();
        LocalStorage.setToken(null);
        LocalStorage.setTokenLifeTime(null);
        LocalStorage.setTemporaryTokenOfNotEmailVerifiedUser(null);
        LocalStorage.setMenuCompactFlag(null);
        this.clearRouterHistory();
        this.redirectUrl = null;
        this.userProfile = new UserProfile();
        this.setAuthorizationRequired(loginParams);
    }

    clearRouterHistory(): void {
        RouterHistoryService.removeAllHistory();
    }

    static getToken(): Token | null {
        try {
            return LocalStorage.getToken();
        } catch (ex) {
            // do nothing
        }
        return null;
    }

    static getTemporaryTokenOfNotEmailVerifiedUser(): string | null {
        try {
            return LocalStorage.getTemporaryTokenOfNotEmailVerifiedUser();
        } catch (ex) {
            // do nothing
        }
        return null;
    }

    static getTokenLifeTime(): number | null {
        try {
            return LocalStorage.getTokenLifeTime();
        } catch (ex) {
            // do nothing
        }
        return null;
    }

    static getAuthorizationHeader(): string {
        const token = this.getToken();
        if (token != null) {
            return token.token_type + ' ' + token.access_token;
        }
        return '';
    }

    static createHeaders(_responseType: HttpResponseType = 'json'): HttpHeaders | { [header: string]: string | string[] } {
        return {
            'Content-Encoding': 'gzip, deflate, br',
            'Content-Type': 'application/json, text/plain, */*',
            Authorization: AuthorizationService.getAuthorizationHeader()
        };
    }

    static createOptions(body?: any, _responseType: HttpResponseType = 'json', _observeType: HttpResponseObserve = 'body'): any {
        const _options: RequestOptions = {
            headers: this.createHeaders(_responseType),
            observe: _observeType,
            responseType: _responseType,
            body: body || undefined
        };
        if (!body) {
            delete _options.body;
        }
        return _options;
    }

    private serialize(data: any) {
        const str = [];
        for (const p in data) {
            if (data.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(data[p]));
            }
        }
        return str.join('&');
    }
}
