import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PlatformAccountVerificationRoutingModule } from './platform-account-verification-routing.module';
import { PlatformAccountVerificationComponent } from './platform-account-verification.component';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SvgIconComponent } from '@ngneat/svg-icon';



@NgModule({
    declarations: [
        PlatformAccountVerificationComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        RouterModule,
        SharedModule,
        SvgIconComponent,
        PlatformAccountVerificationRoutingModule
    ]
})
export class PlatformAccountVerificationModule { }
