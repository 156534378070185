export class FilterSelectedField {
    collectionKey!: string; // array name for example 'rules'
    collectionTitle!: string; // array title for example 'Rules'
    selectedItemUniqueFieldKey!: string; // for example 'id'
    selectedItemUniqueFieldValue!: string; // for example '1'
    selectedItemValue!: string;

    constructor(data?: any) {
        if (data) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    this[key as keyof FilterSelectedField] = data[key];
                }
            }
        }
    }
}