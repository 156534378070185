import { Injectable } from '@angular/core';
import { SignInServicesModule } from './sign-in-services.module';
import { AuthorizationService } from 'src/app/api';
import { Router } from '@angular/router';
import { routeMap } from 'src/app/route-map';

@Injectable({
    providedIn: SignInServicesModule
})
export class SignInService {
    authorizationInProgress = false;
    checked = true;

    get errorMessage(): string | null {
        return this.authService.errorMessage;
    }

    constructor(
        private authService: AuthorizationService,
        private router: Router
    ) {
        // TODO
    }

    public isLoginPage(page: string | null): boolean {
        return page === `/${routeMap.signIn}` || page?.includes(`/${routeMap.signIn}?existingEmail=`) ? true : false;
    }

    setChecked(checked: boolean) {
        this.checked = checked;
    }

    /**
     *
     * @param login
     * @param password
     */
    public login(login: string, password: string) {
        this.authorizationInProgress = true;
        // this.authService.setRememberMe(this.checked);
        this.authService.setRememberMe(true);
        this.authService.authorize(login, password)
            .subscribe(() => {
                if (this.authService.redirectUrl && !this.isLoginPage(this.authService.redirectUrl)) {
                    this.router.navigateByUrl(`/${this.authService.redirectUrl}`).then(() => {
                        this.authorizationInProgress = false;
                    });
                } else {
                    this.router.navigate([`/${routeMap.articles}`]).then(() => {
                        this.authorizationInProgress = false;
                    });
                }
            }, (error: any) => {
                if (error?.error_description === this.authService?.EMAIL_NOT_VERIFIED) {
                    this.router.navigate([`/${routeMap.confirmAccount}`]).then(() => {
                        this.authorizationInProgress = false;
                    });
                }
                this.authorizationInProgress = false;
            });
    }
}