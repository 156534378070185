import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { AppFilterService } from './app-filter.service';
import { ItemList } from 'src/app/contracts/item-list';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseApiService, AuthorizationService, LocalStorage } from 'src/app/api';
import * as _ from 'underscore';
import { SimpleDataFilter } from 'src/app/contracts/simple-data-filter';
import { FilterSettings } from './filter-settings';
import { MatDialog } from '@angular/material/dialog';
import { AppDatepickerComponent } from '../app-datepicker/app-datepicker.component';
import { Period } from '../app-datepicker/datepicker-toggles';
import { routeMap } from 'src/app/route-map';
import { ListItem } from 'src/app/contracts/list-item';
// import { MenuService } from '../page-menu/page-menu.service';
import { takeUntil } from 'rxjs/operators';
import { MatchingResult } from 'src/app/contracts/matching-result';
import { AppMenuService } from '../app-menu/app-menu.service';
import { AppHeaderService } from '../app-header/app-header.service';

/**
 * Чтобы разместить фильтр на странице, в него нужно передавать настройки
 * в объекте `settings` (там указано какие фильтры включены, родитель их изменяет в зависимости от урла)
 * А сам фильтр смотрит какие фильтры ему нужно показать, помимо этого надо передать `_disabled`, чтобы блокировать фильтры
 * пока в родителях выполняются какие-то запросы не использующие `BasApiService` (!) (например скачивание файлов или их загрузка)
 * Фильтру не нужны никакие переменные вроде идентификаторов, он сам работает с урлом: помещает
 * туда параметры и достает при обновлении страницы, компонентам его использующим, нужно лишь самим подписаться на изменение урла
 * (и не забыть отписаться при уничтожении компонента)
 */

const ZERO = 0;
const PARSE_INT = 10;

const DATEFROM = 'dateFrom';
const DATETO = 'dateTo';
const ORDERBY = 'orderBy';
const ORDERASC = 'orderAsc';
const PAGE = 'page';
const PAGESIZE = 'pageSize';
@Component({
    selector: 'app-filter',
    templateUrl: './app-filter.component.html',
    styleUrls: ['./app-filter.component.scss']
})
export class AppFilterComponent implements OnInit, OnDestroy {
    /**
     * Для подписок
     * @type {Subscription}
     */
    private _filterSubs: Subscription[] = [];
    /**
     * Для отписок на запросы
     * @type {Subject<void>}
     */
    private unsubscribe!: Subject<void> | null;
    /**
     * Флаг блокировки полей от родителя
     * @type {boolean}
     */
    @Input() disabledFromParent!: boolean;
    /**
     * Настройки фильтра
     * @type {FilterSettings}
     */
    @Input() settings: FilterSettings | null = new FilterSettings();
    /**
     * Включить в компоненте фильтра дефолтный фильтр
     * @type {boolean}
     */
    @Input() enableDefaultPeriod: boolean;
    /**
     * Период по умолчанию
     * @type {Period}
     */
    @Input() defaultPeriod!: Period;
    /**
     * Список правил для страницы результатов матчинга
     * @type {MatchingResult[]}
     */
    @Input() rules!: MatchingResult[] | null;
    /**
     * Событие о том, что произошел инициализирующий
     * редирект со сменой параметров, генерится лишь раз
     * для родителей, которые как фильтр переопределяют свои параметры
     * @type {EventEmitter<void>}
     */
    @Output() filterInit: EventEmitter<void>;

    /**
     * Параметры в урле
     * @type {SimpleDataFilter}
     */
    public filterParams: any = new SimpleDataFilter({});
    /**
     * Измененные параметры, которые еще не записаны в url
     * @type {SimpleDataFilter}
     */
    public changedFilterParams: any = new SimpleDataFilter({});
    /**
     * Флаг для применения отдельных фильтров
     * То есть таких как группировка, дата, дерево групп
     * Эти фильтры не нуждаются в кнопке apply
     * Поэтому они должны изменять только свои параметры
     * @type {boolean}
     */
    public isRedirectWithSomeChanges: boolean;
    /**
     * Инициализация компонента завершена - все стартовые запросы выполнены
     * @type {boolean}
     */
    public initComplete!: boolean;
    /**
     * Поиск
     * @type {string}
     */
    public search!: string | null;
    /**
     * Список правил на основе которых было принято решение
     * @type {ListItem[]}
     */
    public decisionRules: ListItem[] = [];
    /**
     * Список выбранных правил на основе которых было принято решение
     * @type {ListItem[]}
     */
    public selectedDecisionRules: ListItem[] = [];
    /**
     * Список выбранных результатов матчинга
     * @type {ListItem[]}
     */
    public selectedRulesMatch: MatchingResult[] = [];
    /**
     * Список выбранных статусов компаний
     * @type {number}
     */
    confidenceFrom!: any;
    /**
     * Список выбранных статусов компаний
     * @type {number}
     */
    confidenceTo!: any;

    /** Блокировки для полей */
    decisionRulesDisabled: boolean;
    rulesMatchDisabled: boolean;
    confidenceDisabled: boolean;

    /**
     * Блокировка кнопок в groupBy
     * @type {boolean}
     */
    toggleDisabled!: boolean;

    /**
     * Для скрытия фильтров
     * @type {boolean}
     */
    public isFilterCompact!: boolean;

    /**
     * Флаг блокировки полей
     * @type {boolean}
     */
    disabled(): boolean {
        return this.baseApiService.loadInProgress;
    }
    /**
     * Включен хоть какой-то фильтр,
     * который можно сбросить на общую кнопку reset
     * @type {boolean}
     */
    isVisibleSomeFilter(): boolean {
        if (!this.settings?.filterVisible) {
            return false;
        }
        return this.settings?.filterByDecisionRules || false;
    }
    /**
     * Заполнено ли поле поиска
     * @type {boolean}
     */
    isSearchDirty(): boolean {
        return this.search ? true : false;
    }
    /**
     * Блокировка кнопки apply
     * @type {boolean}
     */
    applyBtnDisabled(): boolean {
        return this.btnDisabled() || !this.wereThereAnyChanges;
    }
    /**
     * Блокировка кнопки reset
     * @type {boolean}
     */
    resetBtnDisabled(): boolean {
        return this.btnDisabled();
    }
    /**
     * Блокировка кнопки reset и apply
     * @type {boolean}
     */
    btnDisabled(): boolean {
        if (!this.settings || (this.settings && !this.settings.filterVisible) || !this.filterParams) {
            return true;
        }
        return this.settings.filterByDecisionRules && this.decisionRulesDisabled;
    }
    /**
     * Флаг для обнаружения изменений в графе FILEDS
     * @type {boolean}
     */
    wereThereAnyChanges(): boolean {
        if (!this.settings) {
            return false;
        }

        if (!this.filterParams) {
            return false;
        }

        if (!this.changedFilterParams) {
            return false;
        }

        if (this.settings.filterByDecisionRules && !this.isValueTheSame(this.changedFilterParams?.statusId, this.filterParams.statusId)) {
            return true;
        }

        return false;
    }

    rulesMatchFilterIsOpen() {
        let menu = document.getElementsByClassName('rules-match-menu');
        return menu && menu.length > 0 ? true : false;
    }

    constructor(
        private service: AppFilterService,
        private route: ActivatedRoute,
        private router: Router,
        private baseApiService: BaseApiService,
        private authService: AuthorizationService,
        private dialog: MatDialog,
        private headerService: AppHeaderService
    ) {
        this.settings = new FilterSettings();
        this.settings = this.service.setDefaultSettings();
        this.filterParams = new SimpleDataFilter();
        this.changedFilterParams = new SimpleDataFilter();

        this.decisionRulesDisabled = true;
        this.rulesMatchDisabled = true;
        this.confidenceDisabled = true;

        this.isRedirectWithSomeChanges = false;

        this._filterSubs = [];

        /** Событие инициализирующего редиректа */
        this.filterInit = new EventEmitter<void>();

        this.enableDefaultPeriod = false;

        this.isFilterCompact = LocalStorage.getFilterCompactFlag() == true ? true : false;

        // Для подписки на кнопку logout,
        // чтобы при выходе удалить все подписки
        this.logoutSubscription();
    }

    /**
     * Инициализация компонента
     * @returns {void}
     */
    ngOnInit(): void {
        if (this.settings && this.settings.filterVisible) {
            /** Для отписок */
            this.unsubscribe = new Subject<void>();

            // // Проверка сохраненных параметров
            // this.checkSavedParams();

            const queryParamSub = this.route.queryParams.subscribe((queryParams: any) => {
                /**
                 * Если редикрект произошел от компонентов,
                 * Которые не зависят от того присутствует ли в фильтре кнопка apply или нет
                 * (т.е. для перенаправления такие компоненты не используют метод `redirect()` - он для обычного
                 * перенаправления с полными изменениями всех новых параметров, а
                 * используют метод `redirectWithSomeChanges()` - для частичных изменений - если быть точнее
                 * изменений только собственных параметров, например матод onSearch - он изменяет только
                 * параметр `Search` и `PageNumber`)
                 */
                if (this.isRedirectWithSomeChanges) {
                    this.isRedirectWithSomeChanges = false;
                } else {
                    this.filterParams = this.filterParams ? this.filterParams : new SimpleDataFilter();

                    for (const key in queryParams) {
                        if (queryParams.hasOwnProperty(key)) {
                            if (key === DATEFROM || key === DATETO || key === PAGE || key === PAGESIZE) {
                                this.filterParams[key] = queryParams[key] != null ? parseInt(queryParams[key], PARSE_INT) : this.filterParams[key];
                            } else {
                                this.filterParams[key] = queryParams[key] != null ? queryParams[key] : this.filterParams[key];
                            }
                        }
                    }

                    // Поиск
                    if (this.settings?.filterBySearch) {
                        this.search = this.filterParams.search;
                    }

                    // Запоминаем для дальнейшего обнаружения изменений
                    this.changedFilterParams = _.clone(this.filterParams);
                }
            });

            /** Происходит только один раз */
            this._filterSubs.push(queryParamSub);
            this.redirect().then(() => {
                /**
                 * Нужен только для компонентов, которые тоже переопределяют
                 * свои параметры, так как они теряются. чтобы этого не было
                 * родительский компонент будет переопределять свои параметры,
                 * строго после фильтра при инициализации один раз
                 */
                this.filterInit.emit();
                this.initFilter();
            });
        }
    }

    /**
     * Уничтожение компонента
     * @returns {void}
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe();

        if (this._filterSubs) {
            this._filterSubs.forEach(s => s?.unsubscribe);
            this._filterSubs = [];
        }
        // Сохранение параметров в сервисе при покидании любой страницы
        this.service.setSavedParams(this.settings, this.filterParams);

        this.filterParams = null;
    }

    /**
     * Отписка от запроса
     * @returns {void}
     */
    ngUnsubscribe(): void {
        if (this.unsubscribe) {
            this.unsubscribe.next();
            this.unsubscribe.complete();
            this.unsubscribe = null;
        }
    }

    /**
     * Получение данных
     * @returns {void}
     */
    initFilter(): void {
        /** Совершаем что-либо только, если фильтр включен */

        if (this.settings?.filterByDecisionRules) {
            this.getDecisionRules(this.filterParams ? this.filterParams.decisionRuleIds : null);
        }
        if (this.settings?.filterByRulesMatch) {
            this.getRulesMatch(this.filterParams ? this.filterParams.rulesMatch : null);
        }
        if (this.settings?.filterByConfidence) {
            this.confidenceFrom = this.filterParams ? this.filterParams.confidenceFrom : null;
            this.confidenceTo = this.filterParams ? this.filterParams.confidenceTo : null;

            this.confidenceDisabled = false;
        }
    }

    /**
     * Применение фильтра по общей кнопке apply
     * @returns {void}
     */
    onApplyFilter(): void {
        if (this.settings?.applyBtnVisible) {
            this.resetPage();
            this.resetItemId();
            this.redirect().then(() => {
                this.toggleDisabled = false;
            });
        }
    }

    /**
     * Сброс фильтра по общей кнопке reset
     * @returns {void}
     */
    onResetFilter(): void {
        this.changedFilterParams.search = null;

        // if (this.settings?.filterByDate) { this.onResetDateFilter(); }

        this.onResetAllFilters();
    }

    /**
     * Сброс всех параметров
     * @returns {void}
     */
    onResetAllFilters(): void {
        this.selectedDecisionRules = [];
        this.selectedRulesMatch = [];
        this.confidenceFrom = null;
        this.confidenceTo = null;

        this.changedFilterParams.decisionRuleIds = null;
        this.changedFilterParams.rulesMatch = null;
        this.changedFilterParams.confidenceFrom = null;
        this.changedFilterParams.confidenceTo = null;

        /**
         * Для датапикера сейчас отдельная кнопка
         * На которую сробатывает `onResetDateFilter(false)`
         * она отображается тогда, когда нет кнопки apply
         */
        // if (this.settings.filterByDate) { this.onResetDateFilter(); }

        this.service.setSavedParams(); // null

        this.resetPage();
        this.resetItemId();
        this.redirect();
    }

    /**
     * Изменение идентификатора статуса компании
     * @param {ListItem} item Выбранный статус
     *
     * @returns {void}
     */
    onSelectDecisionRules(item: ListItem): void {
        this.changedFilterParams.decisionRuleIds = null;

        if (!item) {
            // null передается при сбросе

            if (!this.settings?.applyBtnVisible) {
                this.resetPage();
                this.resetItemId();
                this.redirect();
            }
        } else {
            if (!this.checkLength(this.selectedDecisionRules)) {
                this.redirect();
                return;
            }
            this.changedFilterParams.decisionRuleIds = this.getKeysStringFromArray(this.selectedDecisionRules, 'id');
        }

        if (!this.settings?.applyBtnVisible) {
            this.resetPage();
            this.resetItemId();
            this.redirect();
        }
    }

    getRulesMatchInput() {
        if (this.selectedRulesMatch.length > 0) {
            return this.selectedRulesMatch[0].name;
        } else {
            return '';
        }
    }

    getRuleMatchValue(ruleMatch: ListItem) {
        let selectedRuleMatch = this.selectedRulesMatch.find(selectedRuleMatch => selectedRuleMatch.name === ruleMatch.name);
        if (selectedRuleMatch) {
            return selectedRuleMatch.match;
        } else {
            return;
        }
    }

    onChangeRulesMatch(ruleMatch: ListItem, match: string, event: any) {
        this.stopPropagation(event);

        this.changedFilterParams.rulesMatch = null;

        let selectedRuleMatch = this.selectedRulesMatch.find(selectedRuleMatch => selectedRuleMatch.name === ruleMatch.name);
        if (selectedRuleMatch) {
            if (selectedRuleMatch.match === match) {
                this.selectedRulesMatch = this.selectedRulesMatch.filter(r => r.name != selectedRuleMatch?.name);
            }
            selectedRuleMatch.match = match;
        } else {
            this.selectedRulesMatch.push(new MatchingResult({ name: ruleMatch.name, match: match }));
        }

        this.changedFilterParams.rulesMatch = JSON.stringify(this.selectedRulesMatch);

        if (!this.settings?.applyBtnVisible) {
            this.resetPage();
            this.resetItemId();
            this.redirect();
        }
    }

    onClearRulesMatch(event: any) {
        this.stopPropagation(event);

        this.selectedRulesMatch = [];
        this.changedFilterParams.rulesMatch = this.selectedRulesMatch;

        if (!this.settings?.applyBtnVisible) {
            this.resetPage();
            this.resetItemId();
            this.redirect();
        }
    }

    onChangeConfidence() {
        this.changedFilterParams.confidenceFrom = this.confidenceFrom;
        this.changedFilterParams.confidenceTo = this.confidenceTo;
    }

    /**
     * Поиск
     * @returns {void}
     */
    onSearch(search: string | null): void {
        this.search = search ? search.trim() : search;
        this.search = search === '' ? null : search;

        this.filterParams.search = this.search;

        this.changedFilterParams.search = _.clone(this.filterParams.search);

        this.resetPage();
        this.resetItemId();
        // Вместо редиректа изменяются только отдельные параметры
        // То есть все параметры берутся старые из filterParams
        // Кроме измененных
        this.redirectWithSomeChanges();
    }

    /**
     * Сброс поиска
     * @returns {void}
     */
    onResetSearchFilter(): void {
        this.search = null;

        this.filterParams.search = this.search;

        this.changedFilterParams.search = _.clone(this.filterParams.search);

        this.resetPage();
        this.resetItemId();

        // Вместо редиректа изменяются только отдельные параметры
        // То есть все параметры берутся старые из filterParams
        // Кроме измененных
        this.redirectWithSomeChanges();
    }

    /**
     * Метод обновляет списки выбранных элементов
     * @param {string} selectedItems Выбранные элементы
     * (если селект не с мультивыбором, то в массива всехда один элемент)
     * @param {any[]} arrayName Имя массива выбранных элементов
     *
     * @returns {void}
     */
    updateSelectedItems(selectedItems: any[], arrayName: string): void {
        this[arrayName as keyof AppFilterComponent] = selectedItems ? selectedItems : [];
    }

    /**
     * Метод проверки строки
     * @param {string} str Строка для проверки
     *
     * @returns {boolean} пустая строка или нет
     */
    checkString(str: string): boolean {
        return str && str !== '' ? true : false;
    }

    /**
     * Метод проверки массива и его длины
     * @param {any[]} array Массив для проверки длины
     *
     * @returns {boolean} результат - удовлетворяет ли массив условию
     */
    checkLength(array: any[]): boolean {
        return array && array.length ? true : false;
    }

    /**
     * Прекращение распространения события
     * @param {any} clickEvent Событие для прекращения распространения клика
     *
     * @returns {void}
     */
    private stopPropagation(clickEvent: any = null): void {
        /** Прекращение дальнейшей передачи текущего события */
        if (clickEvent) {
            clickEvent.stopPropagation();
        }
    }

    /**
     * Проверка параметров сохраненных в сервисе
     * @returns {void}
     */
    private checkSavedParams(): void {
        let page = this.router.url;
        let previousPage = this.service.previousPage;

        // // Пока что удаление всех параметров
        // this.service.setSavedParams(); // null

        /** Проверяем сохраненные фильтры в сервисе */
        this.filterParams = new SimpleDataFilter();

        // Достаются только параметры для указанных фильтров в settings
        if (this.service.savedParams) {
            this.filterParams = this.service.getSavedParams(this.settings);
            // this.service.setSavedParams(); // null
        }

        // Устанавливаем данную страницу как предыдущую
        this.service.previousPage = this.router.url;
    }

    /**
     * Слушатель события logout
     * После выхода из системы удаляет параметры
     * @returns {void}
     */
    private logoutSubscription(): void {
        const logoutSub = this.authService.logoutEvent?.subscribe(() => {
            this.settings = null;
            this.changedFilterParams = null;
            this.filterParams = null;
            this.service.setSavedParams(); // null
        });
        this._filterSubs.push(logoutSub);
    }

    /**
     * Метод сброса поиска
     * @returns {void}
     */
    private resetPage(): void {
        this.filterParams.page = 0;
        this.changedFilterParams.page = 0;
    }

    /**
     * Метод сброса идентификаторов
     * @returns {void}
     */
    private resetItemId(): void {
        this.filterParams.itemId = null;
        this.changedFilterParams.itemId = null;

        this.filterParams.subitemId = null;
        this.changedFilterParams.subitemId = null;
    }

    /**
     * Получает строку из ключей, разделенные сепаратором (`,` по умолчанию)
     * @param {any[]} array Выбранные элементы
     * @param {string} key Ключ который нужно вписывать в строку
     * @param {string} separator Разделитель ключей в строке
     *
     * @returns {string} Строка ключей через запятыми
     */
    private getKeysStringFromArray(array: any[], key: string | null = null, separator: string = ','): string | null {
        if (!this.checkLength(array)) {
            return null;
        }

        let str = '';

        if (typeof array === 'string') {
            str = str + array;
            return str === '' ? null : str;
        }

        array.forEach((el: any, index: number) => {
            // Для массива с ключами
            if (key != null) {
                if (el[key]) {
                    str = index === array.length - 1 ? str + el[key] : str + el[key] + separator;
                }
            } else {
                // Для массива строк, например
                if (el) {
                    str = index === array.length - 1 ? str + el : str + el + separator;
                }
            }
        });

        return str;
    }

    /**
     * Получение списка
     * @param {string} id Идентификатор
     *
     * @returns {void}
     */
    private getDecisionRules(decisionRuleIds?: string): void {
        this.decisionRulesDisabled = true;

        this.decisionRules = this.rules ? this.rules.map(rule => new ListItem({ id: rule.name, name: rule.name })) : [];
        this.selectedDecisionRules = [];

        // Поиск выбранных
        if (decisionRuleIds) {
            let ids = decisionRuleIds.split(',');
            ids = _.without(ids, '', 'null');

            if (this.checkLength(ids)) {
                ids.forEach((id: string) => {
                    const decisionRule = _.find(this.decisionRules, rule => {
                        return id === String(rule.id);
                    });
                    if (decisionRule) {
                        this.selectedDecisionRules.push(decisionRule);
                    }
                });
            }
        }

        this.decisionRulesDisabled = false;
    }

    /**
     * Получение списка
     * @param {string} id Идентификатор
     *
     * @returns {void}
     */
    private getRulesMatch(json?: string): void {
        this.rulesMatchDisabled = true;

        // Поиск выбранных
        if (json) {
            this.selectedRulesMatch = <MatchingResult[]>JSON.parse(json);
        }

        this.rulesMatchDisabled = false;
    }

    // /**
    //  * Метод для скрытия полей путем изменения флага
    //  * @param {string} fieldFlag Имя флага, который надо изменить
    //  *
    //  * @returns {void}
    //  */
    // private onChangeFlag(fieldFlag: string): void {
    //   this[fieldFlag as keyof AppFilterComponent] = !this[fieldFlag as keyof AppFilterComponent];
    // }

    /**
     * Метод перенаправления для смены адресной строки
     * Вместо редиректа изменяются только отдельные параметры
     * То есть все параметры быруться старые из filterParams
     * Кроме измененных
     * @returns {Promise<boolean> } Возвращает промис
     */
    private redirectWithSomeChanges(): Promise<boolean> {
        Object.keys(this.filterParams).map(key => {
            if (!this.filterParams[ORDERBY]) {
                delete this.filterParams[ORDERASC];
            }
            if (!this.filterParams[key] && this.filterParams[key] !== 0) {
                delete this.filterParams[key];
            }
        });

        this.isRedirectWithSomeChanges = true;

        this.resetItemId();

        return this.settings && this.settings.routePage
            ? this.router.navigate([this.settings.routePage], { queryParams: this.filterParams })
            : this.router.navigate([], { queryParams: this.filterParams });
    }

    /**
     * Метод перенаправления для смены адресной строки
     * @returns {Promise<boolean> } Возвращает промис
     */
    private redirect(): Promise<boolean> {
        Object.keys(this.changedFilterParams).map(key => {
            if (!this.changedFilterParams[ORDERBY]) {
                delete this.changedFilterParams[ORDERASC];
            }
            if (!this.changedFilterParams[key] && this.changedFilterParams[key] !== 0) {
                delete this.changedFilterParams[key];
            }
        });

        this.filterParams = _.clone(this.changedFilterParams);

        return this.settings && this.settings.routePage
            ? this.router.navigate([this.settings.routePage], { queryParams: this.filterParams })
            : this.router.navigate([], { queryParams: this.filterParams });
    }

    /**
     * Метод сбрасывает фокус в селектах типа autocomplete
     * @param {ElementRef} element Инпут для сброса фокуса
     *
     * @returns {void}
     */
    private resetInputFocus(element: any): void {
        setTimeout(() => {
            if (element && element.nativeElement) {
                element.nativeElement.blur();
            } else if (element && element.trigger) {
                element.trigger.nativeElement.blur();
            }
        }, ZERO);
    }

    /**
     * Метод проверяет равенство значений (старого и нового)
     * @param {string} newValue Новое значение
     * @param {string} formerValue Прежнее значение
     *
     * @returns {boolean} результат
     */
    private isValueTheSame(newValue: string, formerValue: string): boolean {
        return newValue == formerValue ? true : false;
    }
}
