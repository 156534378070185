<div class="system-information-popup"
    [class.success]="successType"
    [class.warn]="warnType"
    [class.error]="errorType"
    [class.process]="processType">

    <div class="system-information-popup__header">
        <h1 class="system-information-popup__header-title">{{config.popupHeader}}</h1>
        <span *ngIf="popupSubheader" class="system-information-popup__header-subtitle">{{popupSubheader}}</span>
    </div>
    <div class="system-information-popup__body">
        <div class="system-information-popup__messages">
            <h3 *ngIf="config?.messageHeader" class="system-information-popup__body-title">{{config.messageHeader}}</h3>
            <span class="system-information-popup__body-subtitle">{{config.message}}</span>
            <span *ngIf="config?.messageHint" class="system-information-popup__body-hint">{{config.messageHint}}</span>
        </div>

        <div *ngIf="config?.buttons" class="system-information-popup__options">

            <button *ngFor="let button of config.buttons" type="button" disableRipple
                class="mdc-button mat-unthemed mat-mdc-button-base"
                [ngClass]="{'mdc-button--unelevated mat-mdc-unelevated-button mat-primary'
                    : button.type == 'flat', 'mdc-button--outlined mat-mdc-outlined-button': button.type == 'stroked'}"
                (click)="onButtonClick(button.emittingValue)">

                <div class="system-information-popup__btn-text-wrapper">
                    <div *ngIf="button.icon" class="system-information-popup__btn-icon {{button.icon}}"></div>
                    <span class="system-information-popup__btn-text">{{button.text}}</span>
                </div>
            </button>
        </div>
    </div>
</div>