export class LocalStorageKeys {
    public static tokenKey = 'repricer.token';
    public static tokenLifeTime = 'repricer.token.life.time';
    public static isMenuCompact = 'menu-compact-flag';
    public static isFilterCompact = 'filter-compact-flag';
    public static isTegsHintDoNotShow = 'do-not-show-tag-hint';

    public static columnSettings = 'table-column-settings';
    public static customFieldColumnSettings = 'table-custom-field-column-settings';

    public static pageSize = 'page-size';

    public static filterWidth = 'filter-width';

    public static isTableCompact = 'table-compact';

    public static temporaryTokenOfNotEmailVerifiedUser = 'repricer.temporary.token';
    public static returnToListAfterDetails = 'repricer.return.to.list.after.details';
    public static userTriedToCreatePlatformAccount = 'repricer.user.tried.to.create.a.platform.account';
}