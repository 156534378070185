import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/** Components */
import { SignInComponent } from './sign-in.component';

const routes: Routes = [
    { path: '', component: SignInComponent },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class SignInRoutingModule { }