import { Component, Input, Output, EventEmitter, HostListener, OnInit, OnChanges, SimpleChange, AfterViewInit, OnDestroy } from '@angular/core';
import { FoundItemsModel } from './found-items-model';
import { ARROWDOWN_KEY_CODE, ARROWUP_KEY_CODE, ENTER_KEY_CODE, SPACE_KEY_CODE } from '../app-multiselect/app-multiselect-overlay/app-multiselect-overlay.component';
import { AppText } from 'src/app/text/app-text';


@Component({
    selector: 'app-found-items-list',
    templateUrl: './found-items-list.component.html',
    styleUrls: ['./found-items-list.component.scss']
})
export class FoundItemsListComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    /**
     * Список элементов для отображения
     */
    @Input() items!: Array<FoundItemsModel>;

    /**
     * Выбранный элемент из списка
     */
    @Input() selectedItem!: FoundItemsModel | null;

    /**
     * Включено отображение иконки Add
     */
    @Input() addIconEnable: boolean = false;

    /**
     * Включено отображение иконки Apply
     */
    @Input() applyIconEnable: boolean = false;

    /**
     * Включено отображение иконки Remove
     */
    @Input() removeIconEnable: boolean = false;

    /**
     * Стили для всплывающего окна
     */
    @Input() overlayStyleEnable: boolean = true;

    /**
     * Стили для всплывающего окна
     */
    @Input() customStyleClassForList: string = '';

    /**
     * Событие изменения(пользователь выбрал новый элемент)
     */
    @Output() itemChange: EventEmitter<FoundItemsModel | null> = new EventEmitter<FoundItemsModel | null>();

    /**
     * Событие изменения фокуса
     */
    @Output() focusedItemChange: EventEmitter<FoundItemsModel> = new EventEmitter<FoundItemsModel>();

    /**
     * Событие удаления
     */
    @Output() itemRemove: EventEmitter<FoundItemsModel> = new EventEmitter<FoundItemsModel>();

    /**
     * Событие добавления
     */
    @Output() itemAdd: EventEmitter<FoundItemsModel> = new EventEmitter<FoundItemsModel>();

    /**
     * Закрытие панели
     */
    @Output() insidePanelClose: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Элемент html в фокусе
     */
    private focusedElement!: any | null;

    /**
     * таймер для фокуса 
     */
    private timerForFocusFunction: any;

    /**
     * Необходимый текст для верстки
     */
    public generalPageText = {
        addItem: AppText.addAction,
        deleteAction: AppText.deleteAction,
        removeAction: AppText.removeAction,
        notFoundText: AppText.generalTextIsNotFound,
    };

    /**
     * Размер items в пикселях
     */
    public itemSizeInPixels: number = 37;

    /**
     * Элемент из списка в фокусе
     */
    public get focusedItem(): FoundItemsModel | undefined {
        let itemNotFound;
        if (!this.focusedElement) {
            return itemNotFound;
        }
        let isFocusOnItem = this.focusedElement.classList?.contains('found-items-list__item') && this.focusedElement.id?.includes('item_');
        if (!isFocusOnItem) { // фокус на чем-то другом
            return itemNotFound;
        }
        return this.items.find((item: FoundItemsModel, index: number) => { return `item_${index}_${item.id}` == this.focusedElement.id; }) || itemNotFound;
    }

    /**
     * Индекс элемента в фокусе
     */
    public get focusedItemIndex(): number {
        if (!this.focusedItem) {
            return -1;
        }
        return this.items.findIndex((item: FoundItemsModel) => { return item?.id?.toString() == this.focusedItem?.id; });
    }

    /**
     * Для проверки валидности при использовании в форме
     */
    public get isValid(): boolean {
        return this.isArrayValid(this.items) && this.selectedItem ? true : false;
    }

    /**
     * Проверка установлен ли фокус
     */
    public get isFocused(): boolean {
        return this.isArrayValid(this.items) && this.focusedItem ? true : false;
    }

    /**
     * Включение иконок
     */
    public get iconsEnable(): boolean {
        return this.addIconEnable || this.applyIconEnable || this.removeIconEnable ? true : false;
    }

    constructor() {
        this.items = [];
        // Список по дефолту
        this.items = this.setDefaultItems();
    }

    /**
     * Слушает нажатие клавиш (keydown)
     * Вынесено от keyup т.к. работа со скроллом лагает на keyup
     * @param event событие нажатия на клавишу
     */
    @HostListener('document:keydown', ['$event'])
    handleKeydownEvent(event: any): boolean {
        if (!this.isArrayValid(this.items)) {
            return true;
        }
        const key = event.code;
        // arrowup, arrowdown
        if (key !== ARROWUP_KEY_CODE && key !== ARROWDOWN_KEY_CODE) {
            return true;
        }
        if (!this.focusedElement || !this.focusedItem) {
            return true;
        }
        let targetIndex = -1;
        let targetElement;
        // arrowup
        if (key === ARROWUP_KEY_CODE) {
            if (!this.focusedItemIndex) {
                return false;
            }
            targetIndex = this.focusedItemIndex - 1;
        }
        else {
            if (this.focusedItemIndex >= (this.items?.length - 1)) {
                return false;
            }
            targetIndex = this.focusedItemIndex + 1;
        }
        if (targetIndex !== -1) {
            targetElement = document.getElementById(`item_${targetIndex}_${this.items[targetIndex].id}`);
            this.focusedElement = targetElement || this.focusedElement;
            this.focusedItemChange.next(this.focusedItem);
            try {
                this.focusedElement?.scrollIntoView({ block: "center", behavior: "smooth" });
                return false;
            } catch (ex) {
                console.log(ex);
            }
        }
        return true;
    }

    /**
     * Слушает нажатие клавиш (keyup)
     * @param event событие нажатия на клавишу
     */
    @HostListener('document:keyup', ['$event'])
    handleKeyupEvent(event: any): boolean {
        if (!this.isArrayValid(this.items)) {
            return true;
        }
        const key = event.code;
        // enter
        if (key !== ENTER_KEY_CODE) {
            return true;
        }
        if (!this.focusedElement || !this.focusedItem) {
            return true;
        }
        this.onSelectItem(null, this.focusedItem);
        return true;
    }

    public ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
        if (changes['items']) {
            this.setFocus();
        }
    }

    public ngOnInit(): void {
        // TODO
    }

    public ngAfterViewInit(): void {
        this.setFocus();
    }

    public ngOnDestroy(): void {
        if (this.timerForFocusFunction) {
            clearTimeout(this.timerForFocusFunction);
        }
    }

    /**
     * Выбор элемента из списка
     * @param event событие клика
     * @param item элемент
     */
    public onSelectItem(event: any = null, item: FoundItemsModel): void {
        if (event) {
            this.onFocusItem(event);
        }
        this.itemChange.next(item);
    }

    /**
     * Установка фокуса
     * @param event событие фокуса
     */
    public onFocusItem(event: any): void {
        this.focusedElement = event?.target || null;
        if (this.focusedItem) {
            this.focusedItemChange.next(this.focusedItem);
        }
    }

    /**
     * Добавление элемента из списка
     * @param item элемент
     */
    public onAddItem(event: any = null, item: FoundItemsModel): void {
        if (event) {
            event.stopPropagation();
        }
        if (this.addIconEnable) {
            this.itemAdd.next(item);
        }
    }

    /**
     * Удаление элемента из списка
     * @param item элемент
     */
    public onRemoveItem(event: any = null, item: FoundItemsModel): void {
        if (event) {
            event.stopPropagation();
        }
        if (this.removeIconEnable) {
            this.itemRemove.next(item);
        }
    }

    /**
     * Закрытие панели
     */
    public onClosePanel(): void {
        this.insidePanelClose.next();
    }

    /**
     * Установка фокуса на первый элемент
     * Как правило делается после обновления списка
     */
    public setFocus(): void {
        clearTimeout(this.timerForFocusFunction);
        this.timerForFocusFunction = setTimeout(() => {
            if (this.isArrayValid(this.items)) {
                let targetElement = document.getElementById(`item_${0}_${this.items[0].id}`);
                this.focusedElement = targetElement || this.focusedElement;
                this.focusedItemChange.next(this.focusedItem);
            }
        }, 200);
    }

    /**
     * Проверка - выбран ли элемент
     * @param item элемент
     * @returns результат - удовлетворение условию
     */
    public isItemSelected(item: FoundItemsModel): boolean {
        if (!item || !this.isValid) {
            return false;
        }
        return this.items.find((foundItem: FoundItemsModel) => foundItem?.id === item?.id) ? true : false;
    }

    /**
     * Проверка - есть ли на элементе фокус
     * @param item элемент
     * @returns результат - удовлетворение условию
     */
    public isItemFocused(item: FoundItemsModel): boolean {
        if (!item || !this.isFocused) {
            return false;
        }
        return item?.id === this.focusedItem?.id ? true : false;
    }

    /**
     * Метод проверки массива на его наличие и длину
     * @param array Массив для проверки длины
     * @returns результат - удовлетворяет ли массив условию
     */
    public isArrayValid(array: any[]): boolean {
        return array?.length ? true : false;
    }

    /**
     * Дефолтный список для примера
     */
    private setDefaultItems(): FoundItemsModel[] {
        return [
            new FoundItemsModel({ id: '0', title: '#blahblah', subTitle: 'New tag, no article found' }),
            new FoundItemsModel({ id: '1', title: '#blahblahblahblah', subTitle: '593 234 articles' }),
            new FoundItemsModel({ id: '2', title: '#blahblahblahblahblahblah', subTitle: '593 234 articles' }),
            new FoundItemsModel({ id: '3', title: '#blahblahblahblahblahblahblahblah', subTitle: '593 234 articles' }),
        ];
    }
}