import { Observable, Subscriber } from 'rxjs';
import { Injectable } from '@angular/core';
import { ErrorResponse } from '.';
import { ApiService } from './abstracts/api.service';
import { BaseApiService } from './base-api.service';
import { MessageService } from '../shared/services';
import { AppText } from 'src/app/text/app-text';

@Injectable({
    providedIn: 'root'
})
/**
 * Service allow interacts with api and displays error through toaster
 */
export class CompApiService implements ApiService {
    private FAILED = 0;
    private HTTP_NOT_FOUND = 404;
    private requestsInProgress = false;

    public get loadInProgress(): boolean {
        return this.requestsInProgress;
    }

    public lastError!: ErrorResponse;

    constructor(private apiService: BaseApiService, private messageService: MessageService) {}

    public apiUnsubscribe(): void {
        this.apiService.apiUnsubscribe();
    }

    public get<TContract>(url: string): Observable<TContract> {
        this.requestsInProgress = true;

        const ctx = this;
        return new Observable<TContract>((obs: Subscriber<TContract>) => {
            ctx.apiService.get<TContract>(url).subscribe(
                res => {
                    ctx.requestsInProgress = false;
                    obs.next(res);
                    obs.complete();
                },
                error => {
                    ctx.requestsInProgress = false;
                    ctx.handleError(error);
                    obs.error(error);
                    obs.complete();
                }
            );
        });
    }

    public post<TContract, TResponseContract>(url: string, payload: TContract): Observable<TResponseContract> {
        this.requestsInProgress = true;

        const ctx = this;
        return new Observable<TResponseContract>((obs: Subscriber<TResponseContract>) => {
            ctx.apiService.post<TContract, TResponseContract>(url, payload).subscribe(
                res => {
                    ctx.requestsInProgress = false;
                    obs.next(<any>res);
                    obs.complete();
                },
                error => {
                    ctx.requestsInProgress = false;
                    ctx.handleError(error);
                    obs.error(error);
                    obs.complete();
                }
            );
        });
    }

    public patch<TContract, TResponseContract>(url: string, payload: TContract): Observable<TResponseContract> {
        this.requestsInProgress = true;

        const ctx = this;
        return new Observable<TResponseContract>((obs: Subscriber<TResponseContract>) => {
            ctx.apiService.patch<TContract, TResponseContract>(url, payload).subscribe(
                res => {
                    ctx.requestsInProgress = false;
                    obs.next(<any>res);
                    obs.complete();
                },
                error => {
                    ctx.requestsInProgress = false;
                    ctx.handleError(error);
                    obs.error(error);
                    obs.complete();
                }
            );
        });
    }

    public put<TContract, TResponseContract>(url: string, payload: TContract): Observable<TResponseContract> {
        this.requestsInProgress = true;

        const ctx = this;
        return new Observable<TResponseContract>((obs: Subscriber<TResponseContract>) => {
            ctx.apiService.put<TContract, TResponseContract>(url, payload).subscribe(
                res => {
                    ctx.requestsInProgress = false;
                    obs.next(<TResponseContract>res);
                    obs.complete();
                },
                error => {
                    ctx.requestsInProgress = false;
                    ctx.handleError(error);
                    obs.error(error);
                    obs.complete();
                }
            );
        });
    }

    public delete<TContract>(url: string, payload?: any): Observable<any> {
        this.requestsInProgress = true;

        const ctx = this;
        return new Observable<any>((obs: Subscriber<any>) => {
            return ctx.apiService.delete<TContract>(url, payload).subscribe(
                res => {
                    ctx.requestsInProgress = false;
                    obs.next(<TContract>res);
                    obs.complete();
                },
                error => {
                    ctx.requestsInProgress = false;
                    ctx.handleError(error);
                    obs.error(error);
                    obs.complete();
                }
            );
        });
    }

    public download<Blob>(url: string, payload?: any): Observable<Blob> {
        this.requestsInProgress = true;

        let ctx = this;
        return new Observable<Blob>(observable => {
            return ctx.apiService.download(url, null).subscribe(
                res => {
                    ctx.requestsInProgress = false;
                    observable.next(<Blob>res);
                    observable.complete();
                },
                error => {
                    ctx.requestsInProgress = false;
                    ctx.handleError(error);
                    observable.error(error);
                    observable.complete();
                }
            );
        });
    }

    private handleError(response: ErrorResponse) {
        this.showErrorMessage(response);
    }

    private showErrorMessage(response: ErrorResponse) {
        console.log(response);
        let error = response ? response : new ErrorResponse();
        if (!error.message) {
            error.message = response?.errorMessage || response?.message || response?.title || AppText.requestFailedUnknownError;
        }
        this.messageService.error(error);
    }
}
