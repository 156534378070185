import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const I18N_VALUES = {
    ru: {
        weekdays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
        shortMonths: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
    },
    en: {
        weekdays: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
        shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
};

@Injectable()
export class I18n {
    language: string = 'en';
}

@Injectable()
export class DatepickerI18n extends NgbDatepickerI18n {
    constructor(private _i18n: I18n) {
        super();
    }

    getWeekdayLabel(weekday: number): string {
        return I18N_VALUES['en'].weekdays[weekday - 1];
    }

    getWeekdayShortName(weekday: number): string {
        return I18N_VALUES['en'].weekdays[weekday - 1];
    }

    getMonthShortName(month: number): string {
        return I18N_VALUES['en'].shortMonths[month - 1];
    }

    getMonthFullName(month: number): string {
        return I18N_VALUES['en'].months[month - 1];
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
}
