<div class="app-file-list" fxLayout="column" fxLayoutAlign="start " fxLayoutGap="10px">

    <div *ngFor="let file of files; let index = index" class="app-file-list__item" @showElement fxLayout="row"
        fxLayoutAlign="start center" fxLayoutGap="10px">

        <div class="app-file-list__item-data" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <svg class="repricer-icon icon-file btn-svg-icon-16">
                <use xlink:href="assets/icons/file.svg#fileIcon" />
            </svg>

            <span class="app-file-list__item-name" [class.app-file-list__link]="downloadEnable"
                (click)="onDownload(file)">{{ file.fileFakeName }}</span>
        </div>

        <svg *ngIf="deleteEnable" [class.disabled]="disabled" (click)="onDelete(file, index)"
            class="repricer-icon icon-trash btn-svg-icon-16">
            <use xlink:href="assets/icons/trash.svg#trashIcon" />
        </svg>

    </div>

</div>