import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'app-number-input',
    templateUrl: './app-number-input.component.html',
    styleUrls: ['./app-number-input.component.scss']
})
export class AppNumberInputComponent implements OnInit {
    touched = false;
    disabled = false;
    negativeEnable: boolean = false;

    @Input() value!: number | null;
    @Input() label!: string;
    @Input() max!: number;
    @Input() min!: number;
    @Input() float: boolean = true;
    @Input() floatLength: number = 1;
    @Input() step: number = 1;
    @Input() formDisabled!: boolean;
    @Input() formControlName!: string;
    @Input() formControlValid!: boolean;
    @Input() required!: boolean;
    @Input() regex!: RegExp;
    @Input() errorMessage!: string;
    @Input() suffix!: string;
    @Input() styleClass!: string;

    @Output() valueChange: EventEmitter<number | null> = new EventEmitter<number | null>();

    constructor() {
        // TODO
    }

    @ViewChild('numberInput', { static: false }) numberInput?: ElementRef;

    ngOnInit(): void {
        this.negativeEnable = this.min < 0 ? true : false;
    }

    /**
     * Поднимает значение имитируя работу инпута с типом Number
     */
    public onUp(event: any): void {
        event && event.stopPropagation();
        this.value = Number(this.value?.toString().replace(',', '.'));
        let value = this.value || this.value === 0 ? Number(this.value) + this.step : 1;
        value = value < this.min ? this.min : value;
        value = value > this.max ? this.max : value;
        this.value =
            this.float && this.floatLength ? Number(value.toFixed(this.floatLength)) : value;
        this.valueChange.emit(this.value);

        if (this.numberInput && this.numberInput?.nativeElement != document.activeElement) {
            this.numberInput.nativeElement.focus();
        }
    }

    /**
     * Опускает значение имитируя работу инпута с типом Number
     */
    public onDown(event: any): void {
        event && event.stopPropagation();
        this.value = Number(this.value?.toString().replace(',', '.'));
        let value = this.value || this.value === 0 ? Number(this.value) - this.step : this.min;
        value = value < this.min ? this.min : value;
        value = value > this.max ? this.max : value;
        this.value =
            this.float && this.floatLength ? Number(value.toFixed(this.floatLength)) : value;
        this.valueChange.emit(this.value);

        if (this.numberInput && this.numberInput?.nativeElement != document.activeElement) {
            this.numberInput.nativeElement.focus();
        }
    }

    /**
     * Проверяет строку на соответствие паттерну
     * Если нет - принудительно изменяет строку на null
     */
    public onChange(): void {
        const strValue = this.value?.toString() || '';
        if (strValue?.length) {
            let _plusFloatRegex = /^[0-9]+(,|\.)$/;
            let _minusFloatRegex = /^-[0-9]+(,|\.)$/;
            let _minusRegex = /^-$/;

            let _minus = strValue.substring(strValue?.length - 1, strValue?.length);
            let _floatSeparator = strValue.substring(strValue?.length - 1, strValue?.length);
            // Позволяем ввести минус
            if (_minus === '-' && strValue.match(_minusRegex) && this.negativeEnable) {
                return;
            }
            // Позволяем ввести точку или запятую
            if (this.float && (_floatSeparator === ',' || _floatSeparator === '.')) {
                if (
                    (this.negativeEnable &&
                        (strValue.match(_minusFloatRegex) || strValue.match(_plusFloatRegex))) ||
                    (!this.negativeEnable && strValue.match(_plusFloatRegex))
                ) {
                    return;
                }
            }
        }
        let isValid = strValue?.match(this.regex) ? true : false;
        this.value = isValid ? this.value : null;
        if (this.numberInput) {
            this.numberInput.nativeElement.value = this.value;
        }
        this.valueChange.emit(this.value);
    }

    public onCheckValue() {
        if (!this.value?.toString()?.length) {
            return;
        }

        let valueNum = Number(this.value?.toString().replace(',', '.'));
        if (this.max != null && valueNum > this.max) {
            valueNum = this.max;
        } else if (this.min != null && valueNum < this.min) {
            valueNum = this.min;
        }
        this.value = this.float && this.floatLength ?
            Number(valueNum.toFixed(this.floatLength))
            : valueNum;
        if (this.numberInput) {
            this.numberInput.nativeElement.value = this.value;
        }
        this.valueChange.emit(this.value);
    }
}
