import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiEndpoints, ApiService, AuthorizationService } from '../api';
import { Observable, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-email-verification',
    templateUrl: './email-verification.component.html',
    styleUrl: './email-verification.component.scss'
})
export class EmailVerificationComponent implements OnInit, OnDestroy {
    /**
     * For subscription on the route
     */
    private _routeSub: Subscription | null;
    private _redirectSub: NodeJS.Timeout | null = null;
    /**
     * Для отписок на запросы
     * @type {Subject<void>}
     */
    private _unsubscribe!: Subject<void>;

    codeFromUrl: string = '';
    userEmailFromUrl: string = '';
    expiresOnFromUrl: number = 0;
    userEmailFromLocalStorage: string = '';

    isEmailAddressVerified: boolean = false;
    isEmailVerificationInProgress: boolean = false;
    isEmailVerificationFailed: boolean = false;


    get isPageOpenedFromMail(): boolean {
        return this.userEmailFromLocalStorage && this.userEmailFromUrl && this.userEmailFromLocalStorage === this.userEmailFromUrl ? true : false;
    }

    constructor(
        private authService: AuthorizationService,
        private router: Router,
        private route: ActivatedRoute,
        private apiService: ApiService,
    ) {
        this._routeSub = new Subscription();
        this._unsubscribe = new Subject<void>();
        this.userEmailFromLocalStorage = AuthorizationService.getTemporaryTokenOfNotEmailVerifiedUser() || this.userEmailFromLocalStorage;
    }

    ngOnInit(): void {
        this._routeSub = this.route.queryParams
            .subscribe((queryParams: Params) => {
                this.codeFromUrl = queryParams['code'];
                this.userEmailFromUrl = queryParams['email'];
                this.expiresOnFromUrl = queryParams['expiresOn'];

                if (!this.userEmailFromUrl && this.userEmailFromLocalStorage) {
                    this.userEmailFromUrl = this.userEmailFromLocalStorage;
                }

                if (this.userEmailFromUrl && this.codeFromUrl && this.expiresOnFromUrl) {
                    if (!this.userEmailFromLocalStorage) {
                        this.userEmailFromLocalStorage = this.userEmailFromUrl;
                    }
                    else if (this.userEmailFromLocalStorage != this.userEmailFromUrl) {
                        this.onLogout();
                    }
                    else {
                        this.onConfirmAccount();
                    }
                }
            });
    }

    /**
     * The method for destroying the component
     */
    ngOnDestroy(): void {
        if (this._routeSub) {
            this._routeSub.unsubscribe();
            this._routeSub = null;
        }
        if (this._redirectSub != null) {
            clearTimeout(this._redirectSub);
            this._redirectSub = null;
        }
    }

    /**
     * The logout method
     */
    public onLogout(): void {
        this.authService.logout();
    }

    public onConfirmAccount(): void {
        this.isEmailVerificationInProgress = true;
        this.confirmAccount({ emailAddress: this.userEmailFromUrl, accountConfirmationToken: this.codeFromUrl })
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(_ => {
                this.isEmailAddressVerified = true;
                this.isEmailVerificationInProgress = false;
                this.isEmailVerificationFailed = false;
                this._redirectSub = setTimeout(() => this.authService.logout({ existingEmail: this.userEmailFromUrl }), 1000);
            }, (error: any) => {
                this.isEmailAddressVerified = false;
                this.isEmailVerificationInProgress = false;
                this.isEmailVerificationFailed = true;
                // console.log(error);
            });
    }

    public onSendMail(): void {
        this.sendAccountConfirmationMail({ emailAddress: this.userEmailFromUrl })
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(_ => {
                // TODO
            }, (error: any) => {
                console.log(error);
            });
    }

    private confirmAccount(data: { emailAddress: string, accountConfirmationToken: string }): Observable<void> {
        const url = ApiEndpoints.confirmAccount().listUrl();
        return this.apiService.post<{ emailAddress: string, accountConfirmationToken: string }, void>(url, data);
    }

    private sendAccountConfirmationMail(data: { emailAddress: string }): Observable<void> {
        const url = ApiEndpoints.sendAccountConfirmationMail().listUrl();
        return this.apiService.post<{ emailAddress: string }, void>(url, data);
    }
}