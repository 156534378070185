// tslint:disable-next-line:max-line-length
export const emailPattern = /^((([a-zA-Z]|\d|[!#\$%&'\*\+-\/=\?\^_`\{\|\}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#\$%&'\*\+-\/=\?\^_`\{\|\}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/;
export const passwordPattern = /((?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z`~!@#$%^&*\(\)\-_=+\[\]\{\};:'"<>,.]{8,})|(^P{8}$)/;
// tslint:disable-next-line: max-line-length
/**
 * Паттерн для сообщений чата, включая вопрос пользователя и ответ бота
 * Проверяет наличие хотя бы одного не спецсимвола в сообщении
 * !Можно вводить пробелы (не забудь обрезать строку при необходимости)
 */
export const msgPattern = /([a-zA-Zа-яА-Я0-9ёЁ*])/;
// export const URLPattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!%\$&'\(\)\*\+,;=.]+$/;
export const greetingPattern = /([a-zA-Zа-яА-Я0-9ёЁ]+)/;
export const groupNameRegexp = /^([a-zA-Z0-9]+[-_])*[a-zA-Z0-9]*$/;
export const innPattern = /((^[0-9]{10}$)|(^[0-9]{12}$))/;
export const ogrnPattern = /((^[0-9]{13}$)|(^[0-9]{15}$))/;
/**
 * Приоритет - целое число от 1 до 9999
 */
export const groupPriorityPattern = /(^[1-9]{1}[0-9]{0,3}$)/;
export const hashtagNameRegexp = /(^([a-zA-Zа-яА-Я0-9]{1,}[a-zA-Zа-яА-Я0-9_]{1,})$)|(^([a-zA-Zа-яА-Я0-9]*)$)|(^[a-zA-Zа-яА-Я0-9_]{1,}[a-zA-Zа-яА-Я0-9]{1,}[_]*$)/m;
export const proximityRegexp = /(^[01]{1}$)|((^[0][\.\,][0-9]{1}[1-9]{1}$)|(^[0][\.\,][1-9]{1}[0-9]?$))/m;
export const knowledgeBaseSettingsFileNameRegexp = /(^([]{0}$)|(^[\s]*?[a-zA-Z0-9\-]{1,}[a-zA-Z0-9\-\.]*[\s]*?)$)/;
export const linkPattern = /^(http[s]?:\/\/){1}[\w\-\._~:\/?#[\]@!%\$&'\(\)\*\+,;=.]+$/;
export const URL2Pattern = /^((http[s]?:\/\/)|(data:image\/)){1}[\w\-\._~:\/?#[\]@!%\$&'\(\)\*\+,;=.]+$/;

export const URLPattern = /^https?:\/\/(?:www\.)?[-a-zA-Zа-яёА-ЯЁäöüẞÄÖÜß0-9@:%._\+~#=]{0,255}[a-zA-Zа-яёА-ЯЁäöüẞÄÖÜß0-9]\.[a-zA-Zа-яёА-ЯЁäöüẞÄÖÜß0-9]{2,6}\b(?:[-a-zA-Zа-яёА-ЯЁäöüẞÄÖÜß0-9\(\)@:;%._\+~#?!=&\/{}\[\]]*)$/;

export const tagNamePattern = /^#?[a-zA-Z0-9äöüẞÄÖÜß_]{3,50}$/;
export const tagsPattern = /(#[a-zA-Z0-9äöüẞÄÖÜß_]{3,50})/;

export const searchLastTagPattern = /#[a-zA-Z0-9äöüẞÄÖÜß_]{3,50}$/;

export const searchLastAttributeWithHashPattern = /#?\{[a-zA-Zа-яА-Я0-9äöüẞÄÖÜß_ \/\-\*\+=\.,;:?!%@#$&\(\)\[\]~'"`]{3,50}\}?$/;
export const searchLastAttributeWithoutHashPattern = /\{[a-zA-Zа-яА-Я0-9äöüẞÄÖÜß_ \/\-\*\+=\.,;:?!%@#$&\(\)\[\]~'"`]{3,50}\}?$/;

export const searchLastTagOrLastAttributeWithHashPattern = /(#[a-zA-Z0-9äöüẞÄÖÜß_]{3,50}$)|(#?\{[a-zA-Zа-яА-Я0-9äöüẞÄÖÜß_ \/\-\*\+=\.,;:?!%@#$&\(\)\[\]~'"`]{3,50}\}?$)/;

export const findAllTagsPattern = /(#[a-zA-Z0-9äöüẞÄÖÜß_]{3,50})/g;
export const findAllAttributesWithHashPattern = /(#?\{[a-zA-Zа-яА-Я0-9äöüẞÄÖÜß_ \/\-\*\+=\.,;:?!%@#$&\(\)\[\]~'"`]{3,50}\})/g;
export const findAllAttributesWithoutHashPattern = /(\{[a-zA-Zа-яА-Я0-9äöüẞÄÖÜß_ \/\-\*\+=\.,;:?!%@#$&\(\)\[\]~'"`]{3,50}\})/g;

export const onlyTagsPattern = /^(#[a-zA-Z0-9äöüẞÄÖÜß_]{3,50}\s*)*$/;
export const excludeTagsPattern = /(-?(#[a-zA-Z0-9äöüẞÄÖÜß_]{3,50}))/;

export const forbiddenTagPattern = /^\#?_{3,}$/;