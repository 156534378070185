import { Model } from "../../shared/interfaces";

export class ArticleGroupAnalysisModel implements Model {
    id!: string | number | null;
    articlesQuantity!: string;
    competitorsQuantity!: number;
    potentialCompetitorsQuantity!: number;
    competitorsOnArticleAvg!: number;
    potentialCompetitorsOnArticleAvg!: number;
    validations!: number;
    itemsValidated!: number;
    competitorsOnRepricing!: number;
    potentialCompetitorsOnRepricing!: number;

    competitorOnRepricingCount!: number;
    notValidatedArticleCount!: number;
    notValidatedCompetitorCount!: number;
    recognitionQuality!: number;
    totalActualCompetitorCount!: number;
    totalArticleCount!: number;
    totalCompetitorCount!: number;
    validatedCount!: number;

    constructor(data?: any) {
        this.id = data?.id;
        this.articlesQuantity = data?.articlesQuantity;
        this.competitorsQuantity = data?.competitorsQuantity;
        this.potentialCompetitorsQuantity = data?.potentialCompetitorsQuantity;
        this.competitorsOnArticleAvg = data?.competitorsOnArticleAvg;
        this.potentialCompetitorsOnArticleAvg = data?.potentialCompetitorsOnArticleAvg;
        this.recognitionQuality = data?.recognitionQuality;
        this.validations = data?.validations;
        this.itemsValidated = data?.itemsValidated;
        this.competitorsOnRepricing = data?.competitorsOnRepricing;
        this.potentialCompetitorsOnRepricing = data?.potentialCompetitorsOnRepricing;

        this.competitorOnRepricingCount = data?.competitorOnRepricingCount;
        this.notValidatedArticleCount = data?.notValidatedArticleCount;
        this.notValidatedCompetitorCount = data?.notValidatedCompetitorCount;
        this.totalActualCompetitorCount = data?.totalActualCompetitorCount;
        this.totalArticleCount = data?.totalArticleCount;
        this.totalCompetitorCount = data?.totalCompetitorCount;
        this.validatedCount = data?.validatedCount;
    }
}