<div class="app-multiselect {{componentStyleClass}}" fxLayout="column">

  <!-- <label *ngIf="label">{{ label }}</label> -->

  <div class="app-multiselect__wrapper" #wrapperHTMLElement>
    <!-- Поле ввода -->
    <mat-form-field appearance="outline" class="app-multiselect__multiselect mat-form-field-suffix-padding {{componentShadowStyleClass}}"
      [class.ng-dirty]="isDirty"
      [class.app-multiselect__multiselect--selected]="isDirty"
      [class.app-multiselect__multiselect--focused]="setUPPositionForArrowIcon"
      [class.mat-focused]="focused"
       fxFlex="auto" (click)="onOpenPanel()">

      <mat-label *ngIf="label">{{label}}</mat-label>

      <input matInput type="text" readonly class="app-multiselect__input {{inputTextColorClass}}" [placeholder]="placeholder"
        [name]="inputHtmlName" [disabled]="disabled" [required]="required" [(ngModel)]="displayText"
        tabindex="0" (keyup.enter)="onOpenPanel()" (keyup.space)="onOpenPanel()" #inputHTMLElement
        (focus)="focused=true" (blur)="focused=false">

      <!-- Текст при множественном выборе -->
      <span *ngIf="selectedItems?.length" class="app-multiselect__multiple" [class.app-multiselect__multiple--show]="showCountOfDisplayItems">(+{{ selectedItems.length - 1 }})</span>
      <!-- Иконка удаления -->
      <div matSuffix class="app-multiselect__icon-clear" [class.app-multiselect__icon-clear--show]="showClearIcon" (click)="onDeleteAllItems($event)"></div>
      <!-- Иконка стрелочка -->
      <div matSuffix class="app-multiselect__icon-arrow" [class.disabled]="disabled"
        [class.app-multiselect__icon-arrow--up]="setUPPositionForArrowIcon" [class.app-multiselect__icon-arrow--hide]="hideArrowIcon"></div>
    </mat-form-field>
  </div>

</div>
