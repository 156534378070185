<div class="app-file-uploader" fxLayout="column" fxLayoutAlign="start ">

    <app-file-list *ngIf="checkArray(files)" [(filesFakeNames)]="filesFakeNames" [(files)]="files" [disabled]="disabled"
        (deleteEvent)="onDelete($event)">
    </app-file-list>

    <div class="app-file-uploader__uploader" [class.over]="hasBaseDropZoneOver && files.length < maxFiles"
        [class.disabled]="files && files.length === maxFiles" fxLayout="column" fxLayoutAlign="center center"
        ng2FileDrop (fileOver)="onFileOver($event)" [uploader]="uploader">

        <div fxLayout="column" fxLayoutAlign="center start">
            <span class="app-file-uploader__drag-drop-title" i18n="@@uploader.main.title">Drag & Drop or</span>
            <button [disabled]="files && files.length === maxFiles" style="padding: 0 17.4px !important;" type="button"
                mat-flat-button color="primary" style="min-width: 154px;padding: 0 17.14px !important;" disableRipple
                (click)="fileInput.click()">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                    <svg class="repricer-icon icon-file btn-svg-icon-16">
                        <use xlink:href="assets/icons/file.svg#fileIcon" />
                    </svg>
                    <span i18n="@@uploader.open.files.button">Open Files</span>
                </div>
            </button>
        </div>

        <input type="file" hidden class="app-file-uploader__input" #fileInput [accept]="acceptStrFormat" ng2FileSelect
            [uploader]="uploader" />

    </div>

</div>