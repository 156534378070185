<div class="system-information-popup change-password-dialog"
    [class.process]="!passwordChanged"
    [class.success]="passwordChanged">
    <div class="system-information-popup__header">
        <h1 class="system-information-popup__header-title">{{dialogHeader}}</h1>
    </div>
    <div class="system-information-popup__body">

        <div class="system-information-popup__messages">
            <h3 *ngIf="passwordChanged && !disableForm" class="system-information-popup__body-title">{{pageText.completedAction}}</h3>
            <h3 *ngIf="disableForm" class="system-information-popup__body-title">{{pageText.inProgressAction}}</h3>

            <span *ngIf="passwordChanged && !disableForm" class="system-information-popup__body-subtitle">{{pageText.dialogCompletedMessage}}</span>

            <form *ngIf="!passwordChanged && !disableForm" fxLayout="column" fxLayoutGap="10px"
                [formGroup]="detailsForm" (ngSubmit)="onSave(detailsForm)">

                <mat-form-field class="form-field upload-articles" disable="disabled" appearance="outline" fxFlex="100">
                    <mat-label>{{pageText.propertyPlaceholderUserNewPassword}}</mat-label>
                    <input matInput [type]="isPasswordHidden ? 'password' : 'text'" #userNewPasswordInput formControlName="userNewPassword"
                    autocomplete="off" minlength="8" maxlength="32" (keyup)="onSetPasswordStrength($event)" />
                    <div matSuffix (click)="isPasswordHidden = !isPasswordHidden"
                    onselectstart="return false" onmousedown="return false">
                        <div [class.pass-type]="isPasswordHidden" style="line-height:12px;">
                            <svg-icon key="show-password-icon" fontSize="26px" color="#00124D"></svg-icon>
                        </div>
                    </div>
                    @if (userNewPasswordInput.value && userNewPasswordInput.value.length < 8) {
                        <mat-error align="start" style="font-size: 12px !important;">{{pageText.minLengthTooltip}}</mat-error>
                    }
                    @else if (getControlError('userNewPassword', 'pattern')) {
                        <mat-error align="start" fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="6px" style="font-size: 12px !important;">
                            <div style="line-height: 10px; pointer-events: all;"
                                matTooltip="{{pageText.patternPasswordTooltip}}">
                                <svg-icon key="question" fontSize="14px" style="margin-right: 6px !important;"></svg-icon>
                            </div>
                            {{pageText.passwordNotStrongEnoughTooltip}}
                        </mat-error>
                    }
                    <mat-hint align="end" style="font-size: 12px !important;">{{userNewPasswordInput.value.length}}/32</mat-hint>
                </mat-form-field>

                <!-- <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" style="font-size:12px;margin-bottom: 10px;">
                    <span class="text" [class.red]="passwordStrength === 1" [class.orange]="passwordStrength === 2"
                        [class.green]="passwordStrength === 3">
                        {{pageText.propertyPlaceholderUserPasswordStrength}}
                    </span>
                    <div class="password-strength-indicator__container" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="3px">
                        <div class="password-strength-indicator" [class.red]="passwordStrength === 1"
                            [class.orange]="passwordStrength === 2" [class.green]="passwordStrength === 3">
                        </div>
                        <div class="password-strength-indicator" [class.orange]="passwordStrength === 2"
                            [class.green]="passwordStrength === 3">
                        </div>
                        <div class="password-strength-indicator" [class.green]="passwordStrength === 3">
                        </div>
                    </div>
                </div> -->

                <mat-form-field class="form-field upload-articles" disable="disabled" appearance="outline" fxFlex="100">
                    <!-- <mat-label>{{pageText.propertyPlaceholderUserReenterPassword}}</mat-label> -->
                    <input matInput type="password" #reenterPasswordInput formControlName="userReenterPassword"
                    [placeholder]="pageText.propertyPlaceholderUserReenterPassword" autocomplete="off" minlength="8" maxlength="32" />
                    @if (passwordsDoNotMatch) {
                        <mat-error align="start" style="font-size: 12px !important;">{{pageText.passwordsNotMatch}}</mat-error>
                    }
                    <mat-hint align="end" style="font-size: 12px !important;">{{reenterPasswordInput.value.length}}/32</mat-hint>
                </mat-form-field>
                <button type="submit" class="hide-btn"></button>
            </form>
        </div>

        <div class="system-information-popup__options">
            <button mat-flat-button *ngIf="!disableForm" type="button" disableRipple
            [disabled]="!passwordsFormControl || !reenterPasswordsFormControl || passwordsDoNotMatch"
                (click)="passwordChanged ? onCancel() : onSave(detailsForm)">
                <div class="system-information-popup__btn-text-wrapper">
                    <div class="system-information-popup__btn-icon" style="line-height:14px;">
                        <svg-icon [key]="passwordChanged ? 'check-popup-icon-dark' : 'execute'" fontSize="16px"></svg-icon>
                    </div>
                    <span class="system-information-popup__btn-text">
                        {{passwordChanged ? pageText.okAction : pageText.saveAction}}
                    </span>
                </div>
            </button>
            <button mat-stroked-button *ngIf="!passwordChanged && !disableForm"
                type="button" disableRipple (click)="onCancel()">
                <div class="system-information-popup__btn-text-wrapper">
                    <div class="system-information-popup__btn-icon popup-cancel-icon"></div>
                    <span class="system-information-popup__btn-text">{{pageText.cancelAction}}</span>
                </div>
            </button>
        </div>
    </div>
</div>