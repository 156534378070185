import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AnimationData } from '../contracts';

@Injectable({
  providedIn: 'root'
})
export class AnimationService {
  /**
   * Измененные данные
   * @type {AnimationData}
   */
  data!: AnimationData | null;

  /**
   * Для анимируемого элемента
   * @type {Subject}
   */
  itemChanged: Subject<AnimationData>;

  constructor() {
    this.itemChanged = new Subject<AnimationData>();
  }
}