<div class="app-page" fxLayout="column" fxLayoutAlign="start stretch">

    <!-- <div class="app-header-wrapper" fxLayout="column" fxLayoutAlign="start stretch">
    <app-header></app-header>
  </div> -->

    <!-- Индикатор загрузки -->
    <div class="app-progress-bar-wrapper" [class.app-progress-bar-wrapper--show]="!isLoginStyle">
        <mat-progress-bar *ngIf="inProgressState" mode="indeterminate"></mat-progress-bar>
    </div>

    <div class="app-dashboard-wrapper" fxLayout="row" fxLayoutAlign="start stretch" fxFlex="100">

        <div class="app-body-wrapper" fxLayout="column" fxLayoutAlign="start stretch" fxFlex="100">
            <router-outlet></router-outlet>
            <router-outlet name="article-group-outlet"></router-outlet>
        </div>

    </div>

</div>