import { Model } from "src/app/shared/interfaces";
import { MatchingAction } from "./matching-action";
import { MatchingMode } from "./matching-mode";
import { RuleType } from "./rule-type";


export class RuleModel implements Model {
  id!: string | number | null;
  actionOnMatch!: string; // каждая цифра -значение из MatchingAction
  actionOnMismatch!: string;
  articleGroupId!: string | null;
  articleGroupName!: string;
  createdDate!: number;
  name!: string;
  updatedDate!: number;
  ruleType!: RuleType;

  // Поля ниже не приходят
  enabled!: boolean;
  default!: boolean;
  enabledValuesFromFields!: boolean;
  articleFields!: string[];
  enabledValuesFromList!: boolean;
  articleValues!: string[];
  enabledArticleRegex!: boolean;
  articleRegex!: string[];
  enabledCompetitorTitleSearch!: boolean;
  enabledCompetitorAttributeSearch!: boolean;
  competitorAttributeRegex!: string;
  // actionOnMatch!: MatchingAction; // в виде цифр приходит
  // actionOnMismatch!: MatchingAction;
  confidenceDeviationOnMatch!: number | null;
  confidenceDeviationOnMismatch!: number | null;
  outputField!: string;
  enabledSkippedShortValues!: boolean;
  minAllowedValueLength!: number | null;
  matchingMode!: string;
  enabledSeparators!: boolean;
  separators!: string;
  enabledCharactersToRemove!: boolean;
  charactersToRemove!: string;
  minMultiplierValue!: number | null;
  maxMultiplierValue!: number | null;
  articleManufacturerField!: string;
  competitorAttributeNameRegex!: string;

  updatedByUserId!: string;
  updatedByUserName!: string;

  articleAttributeNameRegex!: string;
  searchInArticleAttributes!: string;
  searchInArticleTitle!: string;
  searchInCompetitorAttributes!: string;
  searchInCompetitorTitle!: string;
  unitRegex!: string;

  matchAllSourceArticleValues!: boolean;
  sourceArticles!: string;

  constructor(data?: any) {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this[key as keyof RuleModel] = <never>data[key];
        }
      }
    }
  }
}
