<div class="app-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <!-- блокируется если юзер уже на домашней странице -->
        <div class="logo-container" [class.disabled]="currentPageIsDefaultPage" fxLayout="row"
            fxLayoutAlign="start center" (click)="onDefaultPage()">
            <img src="../../../../assets/icons/logo.svg">
        </div>

        <div fxLayout="row">
            <div class="buttons-container" fxLayout="row">
                <app-menu></app-menu>

                <button *ngIf="backButtonVisible" class="button" (click)="onBackPage()">
                    <div style="line-height: 14px;">
                        <svg-icon key="back" fontSize="14"></svg-icon>
                    </div>
                </button>
            </div>
        </div>
    </div>


    <div class="app-header__wrapper" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100%">
        <div class="app-header__label-wrapper" fxLayout="column">
            <h1 *ngIf="labelVisible && !isLabelLoading" class="text ellipsis">{{label ? label : 'Header'}}</h1>
            <span *ngIf="postLabel && !isLabelLoading" class="subtitle ellipsis" matTooltip="{{postLabel}}">{{postLabel}}</span>

            <div *ngIf="labelVisible && isLabelLoading" class="app-header__skeleton skeleton-dark-block skeleton-block"></div>
        </div>

        <div fxLayoutAlign="end center">
            <ng-content select="[buttons]"></ng-content>
        </div>
    </div>

    <div fxLayout="row">
        <button class="btn-stroked disabled" type="button" mat-button disableRipple>
            <div class="" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <svg class="btn-svg-icon-16">
                    <use xlink:href="assets/icons/notification-icon.svg#notificationIcon" />
                </svg>
            </div>
        </button>
    </div>
</div>