import { Pipe, PipeTransform } from '@angular/core';

/**
 * Возвращает сумму чисел
 */
@Pipe({
  name: 'sum',
})
export class SumPipe implements PipeTransform {
  transform(amounts: number[]): number {
    return amounts.length > 0 ? amounts.reduce((acc, item) => acc + item): 0;
  }
}
