<div class="tag-details-dialog" fxLayout="column" fxFlex="100" fxLayoutGap="16">
    <div fxLayout="column" fxLayoutGap="4">
        <span class="tag-details-dialog__title">{{pageText.dialogHeaderIsEditTags}}</span>
        <span class="tag-details-dialog__subtitle" i18n="@@general.text.is.of.selected.articles">
            Of selected
            {selectedArticleCount, plural, =0 {articles} =1 {article} other {{{selectedArticleCount|thousandspipe}} articles}}
        </span>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center">
        <div class="add-remove-button-wrapper" style="width: 162px;">
            <button class="button" style="padding: 0;width: 100%;"
            #addOrRemoveButton [class.menu-opened]="addOrRemoveButton.ariaExpanded ==='true'"
            fxLayout="row" fxLayoutAlign="space-between center" [matMenuTriggerFor]="actionsMenu">
                <div class="button-link"
                    fxLayout="row" fxLayoutAlign="space-between center" style="height: 42px;">
                    <diV fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12">
                        <div *ngIf="addTagsButtonEnabled" style="line-height: 14px;">
                            <svg-icon key="add-circle" fontSize="16px"></svg-icon>
                        </div>
                        <span *ngIf="addTagsButtonEnabled" class="text">{{pageText.addAction}}</span>
                        <div *ngIf="!addTagsButtonEnabled" style="line-height: 14px;">
                            <svg-icon key="trash" fontSize="16px"></svg-icon>
                        </div>
                        <span *ngIf="!addTagsButtonEnabled" class="text">{{pageText.removeAction}}</span>
                    </diV>
                    <div style="line-height: 14px;transform: rotate(90deg);">
                        <svg-icon key="menu-arrow-right" fontSize="16px"></svg-icon>
                    </div>
                </div>
            </button>
        </div>

        <app-search style="width: 65%;"
            [borderRadiusLeftDisable]="true"
            [searchByEnter]="false"
            [search]="displayText || ''"
            [searchPattern]="fieldPattern"
            [placeholder]="addTagsButtonEnabled ? pageText.generalTextIsFindTagsToAdd : pageText.generalTextIsFindTagsInSelectedArticles"
            (searchChanged)="onSearch($event)">
        </app-search>
    </div>

    <div fxLayout="column" fxFlex="auto">
        <app-found-items-list
            [items]="tagsForView"
            [overlayStyleEnable]="false"
            [addIconEnable]="addTagsButtonEnabled"
            [removeIconEnable]="!addTagsButtonEnabled"
            (itemAdd)="onAddItem($event)"
            (itemRemove)="onRemoveItem($event)"
            (itemChange)="onSelectItem($event)">
        </app-found-items-list>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
        <button type="button" mat-flat-button color="primary" disableRipple (click)="onClose()">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                <div style="line-height: 14px;">
                    <svg-icon key="close" fontSize="16px"></svg-icon>
                </div>
                <span>{{pageText.closeAction}}</span>
            </div>
        </button>
    </div>
</div>

<mat-menu class="button-menu button-menu__sub mat-tags-menu" #actionsMenu="matMenu">
    <div fxLayout="column">
        <button mat-menu-item class="button" style="padding: 0;border-radius: 0 0 0 0 !important;"
            [class.button__disabled]="false"
            (click)="onChangeMode(true)">
            <div class="button-link"
            fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12">
                <div style="line-height: 14px;">
                    <svg-icon key="add-circle" fontSize="16px"></svg-icon>
                </div>
                <span class="text">{{pageText.addAction}}</span>
            </div>
        </button>
        <button mat-menu-item class="button" style="padding: 0;"
        (click)="onChangeMode(false)">
            <div class="button-link"
            fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12">
                <div style="line-height: 14px;">
                    <svg-icon key="trash" fontSize="16px"></svg-icon>
                </div>
                <span class="text">{{pageText.removeAction}}</span>
            </div>
        </button>
    </div>
</mat-menu>