import { IModel } from '../interfaces';

export class EntityModel implements IModel {
    id: string | number | null = null;

    constructor(data?: object) {
        this.setData(data);
    }

    setData(data?: object): void {
        if (!data) {
            return;
        }

        Object.keys(data).forEach((key: string) => {
            if (Object.prototype.hasOwnProperty.call(this, key)) {
                this[key as keyof this] = data[key as keyof typeof data];
            }
        });
    }
}
