import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'time',
  pure: true
})
export class TimePipe implements PipeTransform {
  transform(periodInMilliseconds: number): string {
    const period = moment.duration(periodInMilliseconds).toISOString();
    // const realWeeks = moment.duration(period).weeks();
    // // days() возвращает немного не то (плюсует неделю к дням: 1W3D => 10D), поэтому недели надо вычесть
    // const realDays = moment.duration(period).days() - (realWeeks * 7);
    // const workDays = realDays + (realWeeks * 5);
    // const hours = moment.duration(period).hours() + (workDays * 8);
    const minutes = (moment.duration(period).minutes() || 0) + '';
    const seconds = (moment.duration(period).seconds() || 0) + '';
    return `${minutes.length > 1 ? minutes : '0' + minutes}:${seconds.length > 1 ? seconds : '0' + seconds}`;
  }
}
