import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/api';
import { accessMap, routeMap } from 'src/app/route-map';
import { AppMenuService } from './app-menu.service';
import { MenuItem } from './shared/menu-item';
import { updateExistRouterHistoryByPageKey } from '../../utils/util-functions';

@Component({
    selector: 'app-menu',
    templateUrl: './app-menu.component.html',
    styleUrls: ['./app-menu.component.scss']
})
export class AppMenuComponent implements OnInit {

    /**
     * Menu items
     */
    public menuItems!: MenuItem[];

    /**
     * Menu items
     */
    public docItems!: MenuItem[];

    public get isEmailNotVerified(): boolean {
        return this.authService?.userProfile?.id === this.authService?.EMAIL_NOT_VERIFIED;
    }

    /**
     * The user name
     */
    public get userName(): string {
        return this.authService?.userProfile?.firstName || 'null';
    }

    /**
     * The user role
     */
    public get userRole(): string {
        return this.authService?.userProfile?.role ? this.authService?.userProfile?.role : 'null';
    }

    constructor(
        private service: AppMenuService,
        private authService: AuthorizationService,
        private router: Router
    ) {
    }

    public ngOnInit(): void {
        this.setItems();
    }

    public ngOnDestroy(): void {
    }

    /**
     * The navigate method
     */
    public onProfile(): void {
        this.onSaveCurrentRouterUrl('/profile');
        this.router.navigate([routeMap.profile]);
    }

    public onSaveCurrentRouterUrl(pageKey: string, previousPageUrl: string = this.router.url): void {
        updateExistRouterHistoryByPageKey(pageKey, previousPageUrl);
    }

    /**
     * The logout method
     */
    public onLogout(): void {
        this.authService.logout();
    }

    public onClearRouterHistory(): void {
        this.authService.clearRouterHistory();
    }

    private setItems(): void {
        this.docItems = this.service.docItems;
        this.authService.fillUserProfile().subscribe(() => {
            const userAccessMap = accessMap.find(map => map.role === this.userRole)?.accessMap;
            this.menuItems = this.service.menuItems.filter((item: MenuItem) => userAccessMap ? userAccessMap.indexOf(item.name) > -1 : -1);
        });
    }
}