import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LocalStorage } from 'src/app/api';
import { AppText } from 'src/app/text/app-text';
import { IMenuSettings } from '../../interfaces';
import { ContextMenuItem } from './shared/context-menu-item';



@Injectable({
    providedIn: 'root'
})
export class AppMenuService {

    public setSettings: BehaviorSubject<Partial<IMenuSettings>> = new BehaviorSubject({

        isEnable: false,
        isCompact: LocalStorage.getMenuCompactFlag() != null
            ? LocalStorage.getMenuCompactFlag()
            : true,

    } as Partial<IMenuSettings>);

    public menuItems: ContextMenuItem[] = AppText.menu;

    public docItems: ContextMenuItem[] = AppText.documentation;

    public setDefaultSettings(): Partial<IMenuSettings> {
        return {

            isEnable: false,
            isCompact: LocalStorage.getMenuCompactFlag() != null
                ? LocalStorage.getMenuCompactFlag()
                : true,

        } as Partial<IMenuSettings>;
    }
}