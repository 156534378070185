import { Component, Input, Inject, InjectionToken } from '@angular/core';
import { AppPaginatorOverlayService } from './app-paginator-overlay.service';



export const PAGINATOR_DATA = new InjectionToken<{}>('PAGINATOR_DATA');

@Component({
  selector: 'app-paginator-overlay',
  templateUrl: './app-paginator-overlay.component.html',
  styleUrls: ['./app-paginator-overlay.component.scss']
})
export class AppPaginatorOverlayComponent {
  /**
   * доступные размеры страницы, например [25, 50, 100]
   * @type {number}
   */
  @Input() pageSizeOptions: number[] = [];
  /**
   * Выбранный элемент
   * @type {Brand[]}
   */
  @Input() selectedPageSize: number;

  constructor(
    protected service: AppPaginatorOverlayService,
    @Inject(PAGINATOR_DATA) public data: any,
  ) {
    this.pageSizeOptions = this.data.data.pageSizeOptions;
    this.selectedPageSize = this.data.data.selectedPageSize;
  }

  /**
   * Метод изменения размера страницы
   * @param {number} pageSize Размер страницы
   *
   * @returns {void}
   */
  onChangePageSize(pageSize: number): void {
    this.selectedPageSize = pageSize;
    this.service.selectedPageSizeChange.next(this.selectedPageSize);
  }
}
