import { ListerPlatformTypes } from "../articles/shared/article.model";

export class SimpleDataFilter {
    page!: number;
    pageSize!: number;
    orderDesc!: boolean | null | undefined;
    orderBy!: string | null;
    fromDate!: number | null;
    toDate!: number | null;
    search!: string | null;
    tags!: string | null;

    fromTime!: string | null;
    toTime!: string | null;
    fromDateAsString!: string | null;
    toDateAsString!: string | null;

    period!: string | null;
    itemId!: string | null;

    articleId!: string | null;
    excluded!: boolean | null;
    boosted!: boolean | null;
    listerPlatformType!: ListerPlatformTypes | null;
    competitorsSearch!: string | null;

    articleGroupId!: string | null;
    excludedArticleGroupIds!: string | null;

    searchRequestId!: string | null;

    // For filter
    productId!: string | null;
    productIds!: string | null | undefined;
    decisionRuleIds!: string | null;
    matchRuleIds!: string | null;
    articleGroupIds!: string | null;
    carIds!: string | null;
    noCompetitors!: boolean | null; // для артиклей
    confidenceFrom!: string | null;
    confidenceTo!: string | null;
    fromMarketplaceRank!: string | null;
    toMarketplaceRank!: string | null;
    fromVisibilityRank!: string | null;
    toVisibilityRank!: string | null;
    fromPrice!: string | null;
    toPrice!: string | null;
    fromPriceDifferencePercent!: string | null;
    toPriceDifferencePercent!: string | null;
    fromCompetitorPrice!: string | null;
    toCompetitorPrice!: string | null;
    priceFrom!: number | null;
    priceTo!: number | null;
    hsn!: string | null;
    tsn!: string | null;
    inactiveCompetitors!: boolean | null;
    // articlesWithPotentialCompetitors и articlesWithCompetitors не одно и то же
    potentialCompetitors!: boolean | null;
    competitorsWithoutSales!: boolean | null;
    competitorsWithLongShipping!: boolean | null;
    isCompetitor!: boolean | null;
    validated!: boolean | null;
    validationMatchesRules!: boolean | null;
    markedAsVariant!: boolean | null;

    // For competitors validation
    markedForValidation!: boolean | null;
    noManualSearchRequests!: boolean | null;
    orderByRuleId!: string | null;
    orderByRuleMatch!: string | null;

    // For UI
    productIdOfSelectedArticle!: string | null;
    listerIdOfSelectedArticle!: string | null;
    competitorsRequestId!: string | null;
    backRoute!: string | null;
    ruleType!: string | null;
    carData!: string | null;

    listerIds!: string | null | undefined;

    strategyId!: string | null;
    strategyIds!: string | null;

    // for frontend
    articlesParams?: string | null;
    competitorsParams?: string | null;
    extractParams?: string | null;

    constructor(data?: any) {
        if (data) {
            for (const i in data) {
                if (data.hasOwnProperty(i)) {
                    this[i as keyof SimpleDataFilter] = <never>data[i];
                }
            }
        }
    }
}
