import { IApiEndpoint } from './interfaces';

export class ApiEndpointBase implements IApiEndpoint {
  constructor(
    private url: string
  ) { }

  listUrl(parameters?: any): string {
    const queryString = this.getQueryString(parameters);
    return this.url + queryString;
  }

  itemUrl(id: string, parameters?: any): string {
    const queryString = this.getQueryString(parameters);
    return this.url + '/' + id + queryString;
  }

  private getQueryString(parameters?: any) {
    let queryString = '';
    for (const parameter in parameters) {
      if (parameters.hasOwnProperty(parameter)) {
        const value = parameters[parameter];
        if (value && value.length && typeof value === 'object') {

          value.forEach((v: any) => {
            queryString += queryString ? '&' : '?';
            queryString += parameter + '=' + v;
          });

        } else if (value || value === 0 || value === false) {
          queryString += queryString ? '&' : '?';
          queryString += parameter + '=' + value;
        }
      }
    }
    return queryString;
  }
}
