/**
 * Represents list item in select list
 */
export class AppObject {
  key!: string;
  value: any;

  constructor(data?: any) {
    if (data) {
      for (const i in data) {
        if (data.hasOwnProperty(i)) {
          this[i as keyof AppObject] = data[i];
        }
      }
    }
  }
}
