import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SharedModule } from '../shared/shared.module';
import { ProfileComponent } from './profile.component';
import { ProfileSharedModule } from './shared/profile-shared.module';


@NgModule({
  declarations: [
    ProfileComponent
  ],
  imports: [
    SvgIconComponent,
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    LazyLoadImageModule,
    ScrollingModule,
    NgScrollbarModule,
    ProfileSharedModule
  ],
  exports: [
    ProfileComponent
  ],
  providers: [
    // { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }
  ]
})
export class ProfileModule { }
