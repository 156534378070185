import { Breadcrumbs } from '../contracts';

export class SessionStorage {
    private static breadcrumbs = '_b';

    public static setBreadcrumbs(b: Breadcrumbs[] | null): void {
        if (b === null) {
            sessionStorage.removeItem(SessionStorage.breadcrumbs);
            return;
        }
        sessionStorage[SessionStorage.breadcrumbs] = JSON.stringify(b);
    }

    public static getBreadcrumbs(): Breadcrumbs[] {
        const b = sessionStorage[SessionStorage.breadcrumbs];
        return b ? JSON.parse(b) : null;
    }

    public static get(key: string): any | null {
        if (!key) {
            return null;
        }
        const data = sessionStorage[key];
        return data ? JSON.parse(data) : null;
    }

    public static set(key: string, data: any): void {
        if (!key) {
            return;
        }
        sessionStorage[key] = JSON.stringify(data);
    }

    public static remove(key: string): void {
        if (!key) {
            return;
        }
        sessionStorage.removeItem(key);
    }
}