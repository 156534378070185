import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/** Components */
import { SignUpComponent } from './sign-up.component';

const routes: Routes = [
    { path: '', component: SignUpComponent },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class SignUpRoutingModule { }