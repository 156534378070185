import { RuleModel } from "../rules/shared/rule.model";
import { Model } from "../shared/interfaces";

export class RulesConfiguration implements Model {
  id!: string | number | null;
  name!: string;
  rules: RuleModel[] = [];
  recognitionQuality!: number;
  competitorsQuantity!: number;
  potentialCompetitorsQuantity!: number;
  potentialCompetitorsOnArticleAvg!: number;
  competitorsOnArticleAvg!: number;
  setSize!: number;
  itemsToVerify!: number;

  constructor(data?: any) {
    this.id = data?.id;
    this.name = data?.name;
    this.rules = data?.rules;
    this.recognitionQuality = data?.recognitionQuality;
    this.competitorsQuantity = data?.competitorsQuantity;
    this.potentialCompetitorsQuantity = data?.potentialCompetitorsQuantity;
    this.potentialCompetitorsOnArticleAvg = data?.potentialCompetitorsOnArticleAvg;
    this.competitorsOnArticleAvg = data?.competitorsOnArticleAvg;
    this.setSize = data?.setSize;
    this.itemsToVerify = data?.itemsToVerify;
  }
}
