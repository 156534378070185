export class FilterParams {
  onMatch!: any;
  ruleName!: any;

  constructor(data?: any) {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this[key as keyof FilterParams] = data[key];
        }
      }
    }
  }
}
