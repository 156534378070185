import { UserRoles } from "./contracts";

export const routeMap = {
    competitor: 'competitor',
    competitors: 'competitors',
    competitorFullPath: 'competitors/competitor',
    requests: 'requests',

    article: 'article',
    articles: 'articles',

    articleGroup: 'article-group',
    articleGroups: 'article-groups',
    settings: 'settings',
    analysis: 'analysis',
    statistics: 'statistics',

    strategy: 'strategy',
    strategies: 'strategies',
    strategyFullPath: 'strategies/strategy',

    rulesConfiguration: 'rulesConfiguration',
    rulesSettings: 'rulesSettings',
    rules: 'rules',
    tagAssignmentRules: 'tag-assignment-rules',
    validation: 'validation',
    matchingResults: 'matchingResults',

    searchRequestTemplates: 'search-request-templates',
    searchRequestTemplate: 'search-request-template',

    tags: 'tags',

    users: 'users',
    user: 'user',

    userFullPath: 'users/user',

    loading: 'loading',
    signIn: 'login',
    signUp: 'signup',
    notFound: 'not-found',

    dashboard: 'dashboard',

    profile: 'profile',
    resetPassword: 'resetPassword',

    feedSettings: 'feedSettings',
    confirmAccount: 'confirm-account',
    lister: 'lister',
    history: 'history',
    features: 'features',
    platformAccounts: 'platform-accounts',
    ebayAuthorization: 'ebay-authorization',
};

export const accessMap = [
    {
        role: 'notVerified',
        accessMap: [
            routeMap.confirmAccount,
        ],
        defaultRoute: routeMap.confirmAccount
    },
    {
        role: UserRoles.system_admin,
        accessMap: [
            routeMap.loading,
            routeMap.signIn,
            routeMap.notFound,

            routeMap.dashboard,
            routeMap.articles,
            routeMap.articleGroups,
            routeMap.rules,
            routeMap.tagAssignmentRules,
            routeMap.strategies,
            routeMap.competitors,
            routeMap.validation,
            routeMap.searchRequestTemplates,
            routeMap.feedSettings,
            routeMap.platformAccounts,
            routeMap.ebayAuthorization,
            // routeMap.profile
        ],
        defaultRoute: routeMap.articles
    },
    {
        role: UserRoles.admin,
        accessMap: [
            routeMap.loading,
            routeMap.signIn,
            routeMap.notFound,

            routeMap.dashboard,
            routeMap.articles,
            routeMap.articleGroups,
            routeMap.rules,
            routeMap.tagAssignmentRules,
            routeMap.strategies,
            routeMap.competitors,
            routeMap.validation,
            routeMap.searchRequestTemplates,
            routeMap.feedSettings,
            routeMap.platformAccounts,
            routeMap.ebayAuthorization,
            // routeMap.profile
        ],
        defaultRoute: routeMap.articles
    },
    {
        role: UserRoles.user,
        accessMap: [
            routeMap.loading,
            routeMap.signIn,
            routeMap.notFound,

            routeMap.dashboard,
            routeMap.articles,
            routeMap.articleGroups,
            routeMap.rules,
            routeMap.tagAssignmentRules,
            routeMap.strategies,
            routeMap.competitors,
            routeMap.validation,
            routeMap.searchRequestTemplates,
            routeMap.feedSettings,
            routeMap.platformAccounts,
            routeMap.ebayAuthorization,
            // routeMap.profile
        ],
        defaultRoute: routeMap.articles
    }
];
export const defaultRoute = routeMap.articles;
