import { Model } from "src/app/shared/interfaces";

export class TagAssignmentRuleModel implements Model {
    id!: string;
    areTagsRemovedWithoutCondition!: boolean;
    assignmentCondition!: string;
    assignmentTags!: string[];
    createdDate!: number;
    lastUpdatedUserName!: string;
    updatedDate!: string;
    name!: string;

    constructor(data?: any) {
        if (data) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    this[key as keyof TagAssignmentRuleModel] = <never>data[key];
                }
            }
        }
    }

    serialize(): object {
        return {
            areTagsRemovedWithoutCondition: this.areTagsRemovedWithoutCondition,
            assignmentCondition: this.assignmentCondition,
            assignmentTags: this.assignmentTags,
            createdDate: this.createdDate,
            lastUpdatedUserName: this.lastUpdatedUserName,
            updatedDate: this.updatedDate,
            name: this.name,
        };
    }
}