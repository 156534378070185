import { Model } from "../shared/interfaces";

export class MatchingResult implements Model {
  id!: string | number | null;
  name!: string;
  match!: boolean | number | string;
  isDecisive!: boolean;

	constructor(data?: any) {
    this.id = data?.id;
    this.name = data?.name;
    this.match = data?.match;
    this.isDecisive = data?.isDecisive;
	}
}
