import { Component, Input, Output, EventEmitter, OnChanges, SimpleChange, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { OverlayRef, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import { AppPaginatorService } from './app-paginator.service';
import { AppPaginatorOverlayService } from './app-paginator-overlay/app-paginator-overlay.service';
import { AppText } from 'src/app/text/app-text';

/** @constant {number} */
const ZERO = 0;
/** @constant {number} */
const ONE = 1;
@Component({
    selector: 'app-paginator',
    templateUrl: './app-paginator.component.html',
    styleUrls: ['./app-paginator.component.scss'],
    providers: [AppPaginatorService]
})
export class AppPaginatorComponent implements OnChanges, OnDestroy {
    /**
     * Объект для расчета позиции overlay
     * @type {ElementRef}
     */
    @ViewChild('pageSizeSelector') pageSizeSelector?: ElementRef;

    /** Тексты для страницы */
    // pageText = {
    //   pageSizeTitle: AppText.pageSizeTitle
    // };

    /**
     * Подписка на изменение страницы c открытого overlay
     * @type {Subscription}
     */
    private pageSizeChangeSub!: Subscription;
    /**
     * объект, содержащий pageSize, pageIndex, length
     * генерируется событием при изменении pageIndex или pageSize
     * @type {PageEvent}
     */
    private options: PageEvent = new PageEvent();
    /**
     * Далее страницы
     * Эти страницы будут постоянными
     * Их нужно только отображать в
     * некоторых случаях, где это требуется
     * Например если мы в середине списка то
     * _secondPage и _lastButOnePage будут скрываться
     * вместо них будут троеточия.
     * показываться они будут когда мы будем вначале списка
     * или вконце или же когда сдоступное количество
     * страниц будет до 8 или меньше
     */

    /**
     * номер первой страницы
     * @type {number}
     */
    private _firstPage: number;
    /**
     * номер второй страницы
     * @type {number}
     */
    private _secondPage: number;
    /**
     * номер предпоследней страницы
     * @type {number}
     */
    private _lastButOnePage: number;
    /**
     * номер последней страницы
     * @type {number}
     */
    private _lastPage: number;
    /**
     * количество доступных страниц
     * length делится на pageSize
     * @type {number}
     */
    private _availablePages: number;

    /**
     * Значение этих страниц будет изменяться
     * в зависимости от pageIndex и доступных страниц (_availablePages)
     * их расположение следующие:
     *
     * 1) если у нас < 7 доступных страниц, следующие страницы скрываются
     *  в зависимости от количиства доступных страниц (_availablePages):
     *  2, _previousPage, _middlePage, _nextPage и _lastButOnePage
     *  а на их место встает последняя n. Если страница одна то и n тоже скрывается.
     *  выглядит это так:
     *
     *  -если одна страница
     *    <   1   >
     *
     *  -если 4 страницы
     *    <   1  2  _previousPage  n   >
     *
     * 2) если у нас всего 7 доступных страниц
     *  <   1  2  _previousPage  _middlePage  _nextPage  _lastButOnePage  n   >
     *
     * 3) если у нас > 7 доступных страниц
     *  -мы(pageIndex) на первых страницах (до 5-ой от начала)
     *    <   1  2  _previousPage  _middlePage  _nextPage  ...  n   >
     *
     *  -мы на последних страницах (до 5-ой с конца)
     *    <   1  ...  _previousPage  _middlePage  _nextPage  _lastButOnePage  n   >
     *
     *  -мы в середине (дальше 5-ой страницы с начала и дальше 5-ой - с конца)
     *    <   1  ...  _previousPage  _middlePage  _nextPage  ...  n   >
     */

    /**
     * Предыдущая страница
     * @type {number}
     */
    private _previousPage!: number;
    /**
     * Средняя страница
     * @type {number}
     */
    private _middlePage!: number;
    /**
     * Следующая страница
     * @type {number}
     */
    private _nextPage!: number;
    /**
     * длина списка
     * @type {number}
     */
    @Input() length!: number;
    /**
     * страница
     * @type {number}
     */
    @Input() pageIndex!: number;
    /**
     * размер страницы
     * @type {number}
     */
    @Input() pageSize!: number;
    /**
     * доступные размеры страницы, например [25, 50, 100]
     * @type {number}
     */
    @Input() pageSizeOptions: number[] = [];
    /**
     * предыдущая страница
     * @type {number}
     */
    @Input() previousPageIndex: number;
    /**
     * Количество страниц вычисляется
     * @type {boolean}
     */
    @Input() pageCountCalculation: boolean = false;
    /**
     * Флаг для скрытия блока с размером страниц
     * В диалоговом окне с проектами данный блок не нужен
     * @type {boolean}
     */
    @Input() dialogPaginatorVersion: boolean;
    /**
     * событие генерируемое при
     * изменении pageSize, pageIndex или length
     * @type {EventEmitter<PageEvent>}
     */
    @Output() page: EventEmitter<PageEvent>;
    /**
     * Возвращает 0
     * @type {number}
     */
    get _zero(): number {
        return ZERO;
    }
    /**
     * Возвращает 1
     * @type {number}
     */
    get _one(): number {
        return ONE;
    }
    /**
     * получение _firstPage
     * @type {number}
     */
    get firstPage(): number {
        return this._firstPage;
    }
    /**
     * получение _secondPage
     * @type {number}
     */
    get secondPage(): number {
        return this._secondPage;
    }
    /**
     * получение _lastButOnePage
     * @type {number}
     */
    get lastButOnePage(): number {
        return this._lastButOnePage;
    }
    /**
     * получение _lastPage
     * @type {number}
     */
    get lastPage(): number {
        return this._lastPage;
    }
    /**
     * получение _availablePages
     * @type {number}
     */
    get availablePages(): number {
        return this._availablePages;
    }
    /**
     * получение _previousPage
     * @type {number}
     */
    get previousPage(): number {
        return this._previousPage;
    }
    /**
     * получение _middlePage
     * @type {number}
     */
    get middlePage(): number {
        return this._middlePage;
    }
    /**
     * получение _nextPage
     * @type {number}
     */
    get nextPage(): number {
        return this._nextPage;
    }
    /**
     * флаг для отображения/скрытия
     * номера второй страницы
     * @type {boolean}
     */
    get isShowSecondPage(): boolean {
        return this._isShowSecondPage();
    }
    /**
     * флаг для отображения/скрытия
     * номера предпоследней страницы
     * @type {boolean}
     */
    get isShowLastButOnePage(): boolean {
        return this._isShowLastButOnePage();
    }
    /**
     * флаг для отображения/скрытия
     * номера последней страницы
     * @type {boolean}
     */
    get isShowLastPage(): boolean {
        return this.availablePages > 6;
    }
    /**
     * флаг для отображения/скрытия
     * троеточия в начале
     * @type {boolean}
     */
    get isShowFirstThreeDots(): boolean {
        return this.availablePages > 6 && this.pageIndex > 3;
    }
    /**
     * флаг для отображения/скрытия
     * троеточия в конце
     * @type {boolean}
     */
    get isShowLastThreeDots(): boolean {
        return this.availablePages > 6 && this.pageIndexFromTheEnd > 4;
    }
    /**
     * поиск индекса с конца
     * для тех случаев когда pageIndex в конце
     * @type {number}
     */
    get pageIndexFromTheEnd(): number {
        return this.availablePages - this.pageIndex;
    }

    /**
     * Для overlay с размерами страниц
     * @type {OverlayRef}
     */
    get overlayRef(): OverlayRef {
        return this.paginatorService.overlayRef;
    }

    constructor(private overlay: Overlay, private paginatorService: AppPaginatorService, private overlayService: AppPaginatorOverlayService) {
        this._firstPage = 1;
        this._secondPage = 2;
        this._lastButOnePage = 0;
        this._lastPage = 0;
        this._availablePages = 0;
        this.previousPageIndex = 0;
        this.page = new EventEmitter<PageEvent>();
        this.dialogPaginatorVersion = false;
    }

    /**
     * Отслеживаются изменения pageIndex и pageSize
     * пересчитывается общее количество страниц на лист
     * изменяется _lastPage и _lastButOnePage
     * @param {SimpleChange} changes Изменения входных полей
     *
     * @returns {void}
     */
    ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
        if (
            (changes['pageIndex'] && Number(this.pageIndex) >= this._zero) ||
            (changes['pageSize'] && Number(this.pageSize) >= this._zero) ||
            (changes['length'] && Number(this.length) >= Number(this._zero))
        ) {
            const availablePages = Math.ceil(Number(this.length) / Number(this.pageSize));

            /**
             * общее количество страниц на лист
             * а так же значение для последней страницы
             */
            this._availablePages = availablePages ? availablePages : this._zero;

            /**
             * Предпоследняя страница - поэтому вычитаем единицу кроме случаев  <= 6
             * т к в случаях  < 6 данный элемент вообще не показывается на странице, а при == 6
             * он должен быть равен  _lastPage
             */
            this._lastButOnePage = this._availablePages > 6 ? this._availablePages - this._one : this._availablePages;

            /** Последняя страница */
            this._lastPage = this._availablePages;

            /** Индекс страницы больше чем их количество */
            if (this.pageIndex > this._lastPage) {
                this.pageIndex = this._lastPage;
            }

            /** Предыдущая страница */
            this.previousPageIndex = this.pageIndex > this._zero ? this.pageIndex - this._one : this._zero;
            this.setPages();
        }
    }

    /**
     * Уничтожение компонента
     * @returns {void}
     */
    ngOnDestroy(): void {
        this.closePageSizePanel();
    }

    /**
     * Метод изменения страницы
     * Номер страницы изменяется и генерируется событие page,
     * передающее PageEvent, на которое подписан родитель
     * @param {number} pageIndex Номер выбранной страницы
     *
     * @returns {void} ничего не возвращает
     */
    onSelectPage(pageIndex: number): void {
        this.pageIndex = pageIndex;
        this.setPaginatorOptions(this.length, this.pageIndex, this.pageSize, this.previousPageIndex);
    }

    /**
     * Метод изменения размера страницы
     * Генерируется событие page, передающее PageEvent, на
     * которое подписан родитель
     * @param {number} pageSize Размер страницы
     *
     * @returns {void}
     */
    onChangePageSize(pageSize: number): void {
        this.pageSize = pageSize;

        /**
         * При изменении размера страницы всегда
         * начинать с первой страницы
         */
        this.pageIndex = this._zero;
        this.setPaginatorOptions(this.length, this.pageIndex, this.pageSize, this.previousPageIndex);
    }

    /**
     * Метод открытия overlay с доступными
     * размерами страничника
     * @returns {void}
     */
    onOpenOverlayWithAvailablePageSizes(): void {
        if (this.overlayRef && this.overlayRef.hasAttached()) {
            this.closePageSizePanel();
            return;
        }

        if (!this.pageSizeSelector) {
            return;
        }

        // Цепляемся к элементу, указываем
        // стратегию выбора позиции относительно элемента
        let strategy = this.overlay
            .position()
            .flexibleConnectedTo(this.pageSizeSelector)
            .withPositions([
                {
                    originX: 'center',
                    originY: 'center',
                    overlayX: 'center',
                    overlayY: 'bottom'
                }
            ]);

        this.paginatorService.createOverlay(
            { pageSizeOptions: this.pageSizeOptions, selectedPageSize: this.pageSize },
            new OverlayConfig({ positionStrategy: strategy, hasBackdrop: true, backdropClass: 'app-paginator-backdrop' })
        );

        this.openPageSizePanel();

        this.overlayRef.backdropClick().subscribe(() => {
            this.closePageSizePanel();
        });
    }

    /**
     * Метод навигации по страницам вперед (по стрелке next)
     * pageIndex увеличивается на 1 и генерируется событие page,
     * передающее PageEvent
     * @returns {void}
     */
    onNavigateNext(): void {
        this.pageIndex += this._one;
        this.setPaginatorOptions(this.length, this.pageIndex, this.pageSize, this.previousPageIndex);
    }

    /**
     * Метод навигации по страницам назад (по стрелке before)
     * pageIndex уменьшается на 1 и генерируется событие page,
     * передающее PageEvent
     * @returns {void}
     */
    onNavigateBefore(): void {
        this.pageIndex -= this._one;
        this.setPaginatorOptions(this.length, this.pageIndex, this.pageSize, this.previousPageIndex);
    }

    /**
     * Метод установки номеров страниц в зависимости от положения pageIndex
     * и доступных страниц availablePages.
     * Если доступных страниц availablePages всего 6
     * или больше 6 и pageIndex <= 3, то страницы всегда будут
     * равны 3, 4 и 5.
     * Если pageIndex в конце (до 5-ой страницы с конца) и
     * availablePages > 7 то страницы считаются используя переменную
     * lastButOnePage + учитывается что к индексам страниц нужно прибавлять
     * единицу, так они начинаются с единицы.
     * Если страниц > 7 и индекс в центре ( после пятой странцы с начала
     * и за 5 страниц до конца), то используется pageIndex (именно в этом случае
     * показываются троеточия с обеих сторон пагинатора)
     * @returns {void}
     */
    private setPages(): void {
        /**
         * всего страниц <= 6 или
         * всего страниц >= 7 и при этом pageIndex <= 3
         * (страница 4) т.е. мы в начале
         */
        if (this.availablePages < 7 || (this.availablePages > 6 && this.pageIndex <= 3)) {
            this._previousPage = 3;
            this._middlePage = 4;
            this._nextPage = 5;
            return;
        }

        /**
         * всего страниц >= 7 и при этом
         * pageIndex в конце (до 5-ой страницы с конца) и
         */
        if (this.availablePages > 6 && this.pageIndexFromTheEnd <= 3) {
            this._previousPage = this.lastButOnePage - 3;
            this._middlePage = this.lastButOnePage - 2;
            this._nextPage = this.lastButOnePage - 1;
            return;
        }

        /** Всего страниц >= 8 */
        if (this.availablePages > 7 && this.pageIndex > 3 && this.pageIndexFromTheEnd > 3) {
            /** Прибавляем т.к. странички начинаются с 1 */
            this._previousPage = this.pageIndex;
            this._middlePage = this.pageIndex + 1;
            this._nextPage = this.pageIndex + 2;
            return;
        }
    }

    /**
     * Метод проверяет условия для отображения второй страницы.
     * Либо отображается она, либо троеточие. Все зависит от
     * pageIndex и availablePages
     * @returns {boolean} показатель того, показывать
     * ли вторую страницу
     */
    private _isShowSecondPage(): boolean {
        if ((this.availablePages > 1 && this.availablePages < 7) || (this.availablePages > 6 && this.pageIndex < 4)) {
            return true;
        }
        return false;
    }

    /**
     * Метод проверяет условия для отображения предпоследней страницы.
     * Либо отображается она, либо троеточие. Как и с второй страницей
     * - все зависит от pageIndex и availablePages
     * @returns {boolean} показатель того, показывать
     * ли предпоследнюю страницу
     */
    private _isShowLastButOnePage(): boolean {
        if (this.availablePages === 6 || (this.availablePages > 6 && this.pageIndexFromTheEnd < 5)) {
            return true;
        }
        return false;
    }

    /**
     * Метод для изменения всех параметров.
     * Измененные параметры записываются в поля объекта options
     * и генерируется событие, в котором и передается объект options типа PageEvent
     * @param {number} length Длина массива
     * @param {number} pageIndex Номер страницы
     * @param {number} pageSize Размер страницы
     * @param {number} previousPageIndex Предыдущая страниц (не обязательный параметр)
     *
     * @returns {void}
     */
    private setPaginatorOptions(length: number, pageIndex: number, pageSize: number, previousPageIndex?: number): void {
        this.options.length = length;
        this.options.pageIndex = pageIndex;
        this.options.pageSize = pageSize;
        this.options.previousPageIndex = previousPageIndex ? previousPageIndex : 0;
        this.page.emit(this.options);
    }

    /**
     * Открытие overlay
     * @returns {void}
     */
    private openPageSizePanel(): void {
        this.paginatorService.openPageSizePanel();
        this.pageSizeChangeSub = this.overlayService.selectedPageSizeChange.subscribe((_pageSize: number) => {
            this.closePageSizePanel();
            this.onChangePageSize(_pageSize);
        });
    }

    /**
     * Закрытие overlay
     * @returns {void}
     */
    private closePageSizePanel(): void {
        this.paginatorService.closePageSizePanel();
        if (this.pageSizeChangeSub) {
            this.pageSizeChangeSub.unsubscribe();
        }
    }
}
