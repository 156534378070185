export type Period = 'period' | 'yesterday' | 'today' | 'thisWeek' | 'lastWeek' | 'thisMonth' | 'lastMonth' | 'lastYear' | 'thisYear' | 'from' | 'to';

export class DatepickerToggles {
  Period!: Period;
  FromDate!: number;
  ToDate!: number;

  constructor(data?: any) {
    if (data) {
      this.Period = data?.Period;
      this.FromDate = data?.FromDate;
      this.ToDate = data?.ToDate;
    }
  }
}
