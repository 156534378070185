import { Component, HostListener, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { AppText } from '../../../text/app-text';
import { ApiEndpoints } from '../../../api/api-endpoints';
import { ApiService } from '../../../api';
import { takeUntil } from 'rxjs/operators';
import { UserProfile } from 'src/app/contracts';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'underscore';
import { passwordPattern } from 'src/app/shared/patterns-config';


@Component({
    selector: 'app-change-password-dialog',
    templateUrl: './change-password-dialog.component.html',
    styleUrls: ['./change-password-dialog.component.scss',
        './../../../shared/components/user-action-popup/user-action-popup.component.scss'
    ]
})
export class ChangePasswordDialogComponent implements OnDestroy {
    private passwordChars = 'abcdefghijklmnopqrstuvwxyz';
    private passwordNumbers = '0123456789';
    private passwordSymbols = '~`!@#$%^&*()";:?*}{[]/><-_=+|,.';
    /**
     * Переменная для подписки, к которой обязательна отписка
     * @type {Subscription}
     */
    protected subscriptions: Subscription[] = [];
    /**
     * Для отписок на запросы
     * @type {Subject<void>}
     */
    protected unsubscribe: Subject<void> = new Subject();

    /**
     * Форма для редактирования
     * @type {FormGroup}
     */
    detailsForm: FormGroup = new FormGroup({});
    /**
     * Флаг об изменениях
     * Передается при закрытии окна
     * @type {boolean}
     */
    passwordChanged!: boolean;
    /**
     * Флаг смены типа поля пароля
     * @type {boolean}
     */
    isPasswordHidden: boolean = true;
    /**
     * Блокировка
     * @type {boolean}
     */
    disableForm: boolean = false;
    /**
     * Пароль
     * @type {string}
     */
    password!: string | null;
    /**
     * Пароль повторить
     * @type {string}
     */
    reenterPassword!: string | null;
    /**
     * Сложность пароля
     * @type {string}
     */
    passwordStrength: number = 0;

    pageText = {
        pageHeaderIsEditAccount: AppText.pageHeaderIsEditAccount,
        applyAction: AppText.applyAction,
        editAction: AppText.editAction,
        propertyPlaceholderUserName: AppText.propertyPlaceholderUserName,
        propertyPlaceholderUserEmail: AppText.propertyPlaceholderUserEmail,
        propertyPlaceholderUserPassword: AppText.propertyPlaceholderUserPassword,
        propertyPlaceholderUserNewPassword: AppText.propertyPlaceholderUserNewPassword,
        propertyPlaceholderUserReenterPassword: AppText.propertyPlaceholderUserReenterPassword,
        propertyPlaceholderUserPasswordStrength: AppText.propertyPlaceholderUserPasswordStrength,
        passwordsNotMatch: AppText.generalTextIsThePasswordsDonTMatch,
        cancelAction: AppText.cancelAction,
        okAction: AppText.okAction,
        saveAction: AppText.saveAction,
        completedAction: AppText.generalTextIsCompleted,
        dialogCompletedMessage: AppText.infoPopupPasswordChangedMessage,
        inProgressAction: AppText.generalTextIsInProgress,
        minLengthTooltip: AppText.hintMessageIsLengthOfPassword,
        patternPasswordTooltip: AppText.hintMessageIsPatternOfPassword,
        passwordNotStrongEnoughTooltip: AppText.hintMessageIsPasswordNotStrongEnough
    };

    public get dialogHeader(): string {
        return AppText.dialogHeaderIsChangeAccountPassword;
    }

    public get passwordsFormControl(): string {
        return this.detailsForm?.controls?.['userNewPassword']?.value || '';
    }

    public get reenterPasswordsFormControl(): string {
        return this.detailsForm?.controls?.['userReenterPassword']?.value || '';
    }

    public get passwordsDoNotMatch(): boolean {
        return this.passwordsFormControl && this.reenterPasswordsFormControl
            && this.passwordsFormControl != this.reenterPasswordsFormControl ? true : false;
    }

    constructor(
        private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private data: { userProfileId: string }
    ) {
        this.password = '';
        this.reenterPassword = '';
        this.detailsForm = this.formBuilder.group({
            userNewPassword: [{ value: this.password, disabled: this.disableForm }, [
                Validators.required,
                Validators.pattern(passwordPattern),
                Validators.minLength(8),
                Validators.maxLength(32)
            ]],
            userReenterPassword: [{ value: this.reenterPassword, disabled: this.disableForm }, [
                Validators.required,
                Validators.minLength(8),
                Validators.maxLength(32)
            ]],
        });
        this.passwordChanged = false;
        const sub = this.dialogRef.backdropClick().subscribe(() => dialogRef?.close(this.passwordChanged));
        this.subscriptions.push(sub);
    }

    /**
     * Слушает нажатие esc и закрывает окно
     * @param event событие гнажатие esc
     */
    @HostListener('document:keyup.escape', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        event.stopPropagation();
        this.dialogRef?.close(this.passwordChanged);
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach(s => s.unsubscribe());
        }
    }

    /**
     * Метод создания/обновления
     * @param form Редактируемая форма
     * @param inputUrl Входной урл
     */
    public onSave(form: FormGroup): void {
        if (!form.valid || this.disableForm) {
            return;
        }
        this.disableForm = true;
        const payload = {
            password: form.controls['userNewPassword']?.value || '',
            id: this.data.userProfileId,
        };
        this.updatePassword(payload)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res: UserProfile) => {
                this.disableForm = false;
                this.passwordChanged = true;
            }, (error: any) => {
                this.disableForm = false;
            });
    }

    public onCancel(): void {
        this.dialogRef?.close(this.passwordChanged);
    }

    public onSetPasswordStrength(event: any): void {
        if (!event?.target) {
            return;
        }

        const pass = event?.target?.value + '';
        if (!pass.length || pass.length < 8) {
            this.passwordStrength = 0;
            return;
        }

        const lowerCaseCharExist = this.passwordChars.split('').some((item: string) => pass.includes(item));
        const upperCaseCharExist = this.passwordChars.toUpperCase().split('').some((item: string) => pass.includes(item));
        const numberExist = this.passwordNumbers.split('').some((item: string) => pass.includes(item));
        const symbolExist = this.passwordSymbols.split('').some((item: string) => pass.includes(item));

        const totalCharsExist = [lowerCaseCharExist, upperCaseCharExist, numberExist, symbolExist]
            .reduce((accumulator: number, currentValue: boolean, currentIndex: number) => {
                if (!currentIndex) { accumulator = 0; }
                accumulator = currentValue ? accumulator + 1 : accumulator;
                return accumulator;
            }, 0);

        if (totalCharsExist === 4) {
            this.passwordStrength = 3;
        }
        else if (totalCharsExist > 1) {
            this.passwordStrength = 2;
        }
        else {
            this.passwordStrength = 1;
        }
    }

    /**
     *
     * @param controlName
     * @param errorValidator
     * @returns
     */
    public getControlError(controlName: string, errorValidator: string): boolean {
        return this.detailsForm?.controls?.[controlName]?.hasError(errorValidator) ? true : false;
    }

    private updatePassword(payload: { password: string, id: string }): Observable<UserProfile> {
        let url = ApiEndpoints.profile().listUrl();
        return this.apiService.patch<{ password: string, id: string }, UserProfile>(url, payload);
    }
}