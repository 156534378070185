import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export abstract class ApiService {
    abstract get<TContract>(url: string): Observable<TContract>;
    abstract post<TContract, TResponseContract>(url: string, payload?: TContract): Observable<TResponseContract>;
    abstract patch<TContract, TResponseContract>(url: string, payload: TContract): Observable<TResponseContract>;
    abstract put<TContract, TResponseContract>(url: string, payload: TContract): Observable<TResponseContract>;
    abstract delete<TContract>(url: string, payload?: any): Observable<TContract>;
    abstract download<Blob>(url: string, payload?: any): Observable<Blob>;
    abstract apiUnsubscribe(): void;
}
