export class ArticleTagModel {
    articleIds!: Array<string>;
    tagName!: string;

    constructor(data?: any) {
        if (data) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    this[key as keyof ArticleTagModel] = <never>data[key];
                }
            }
        }
    }
}