export class TokenRequest {
  client_id!: string;
  client_secret!: string;
  username!: string;
  password!: string;
  scope!: string;
  grant_type!: string;

  constructor(data?: any) {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this[key as keyof TokenRequest] = data[key];
        }
      }
    }
  }
}
