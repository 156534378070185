import { Component, Input, ViewChild, ElementRef, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ApiEndpoints } from '../../../api';
import { FileService } from '../../services/file.service';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { MessageService } from '../../services/message.service';
import { FileViewData } from 'src/app/contracts/file-view-data';
import { throwError } from 'rxjs';
import { AppFileUploaderBarComponent } from './app-file-uploader-bar/app-file-uploader-bar.component';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'underscore';
import { AppText } from 'src/app/text/app-text';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './app-file-uploader.component.html',
    styleUrls: ['./app-file-uploader.component.scss'],
    providers: [FileService]
})
export class AppFileUploaderComponent implements OnInit, OnDestroy {
    /**
     * Отоброжаемые имена файлов для пользователей
     * @type {string[]}
     */
    @Input() filesFakeNames: string[];
    /**
     * Файлы для отображения
     * @type {FileViewData[]}
     */
    @Input() files: FileViewData[];
    /**
     * Флаг для блокировки кнопки удаления
     * @type {boolean}
     */
    @Input() disabled: boolean;
    /**
     * Флаг для блокировки кнопки удаления
     * @type {boolean}
     */
    @Input() required!: boolean;
    /**
     * Максимальное количество файлов
     * @type {number}
     */
    @Input() maxFiles!: number;
    /**
     * Максимальный размер файла
     * @type {number}
     */
    @Input() maxFileSize!: number;
    /**
     * Разсширения файлов
     * @type {string[]}
     */
    @Input() accept: string[] = ['xls', 'xlsx', 'csv', 'text/csv'];
    /**
     * Тип документа
     * @type {string}
     */
    @Input() documentType: string;
    /**
     * Событие о загруженном файле(ах)
     * @type {EventEmitter<void>}
     */
    @Output() fileUploaded: EventEmitter<void>;
    /**
     * Событие о том что файлы изменились
     * @type {EventEmitter<void>}
     */
    @Output() filesChange: EventEmitter<FileViewData[]>;

    /**
     * Инпут для отправки файла по клику
     * @type {ElementRef}
     */
    @ViewChild('fileInput') fileInput!: ElementRef;

    /** Тексты для страницы */
    pageText = {
        fileUploading: AppText.generalTextIsFileUploading
    };

    /**
     * Объект для файлов
     * @type {FileUploader}
     */
    uploader!: FileUploader;
    /**
     * Файл был перенесен в зону
     * @type {boolean}
     */
    hasBaseDropZoneOver: boolean;
    /**
     * Урл для отправки файлов
     * @type {string}
     */
    get baseUrl(): string {
        return ApiEndpoints.files().itemUrl(this.documentType);
    }
    /**
     * Валидность
     * @type {boolean}
     */
    get valid(): boolean {
        return this.files && this.files.length ? true : false;
    }
    /**
     * Типы файлов в строковом формате
     * @type {string}
     */
    get acceptStrFormat(): string {
        return this.checkArray(this.accept) ? this.accept.toString() : '';
    }
    /**
     * Максимальный размер файла в строковом формате
     * @type {number}
     */
    get maxSizeStrFormat(): string {
        return this.maxFileSize ? String(Math.ceil(this.maxFileSize / 1000000)) : String(20);
    }

    constructor(private fileService: FileService, private messageService: MessageService, private dialog: MatDialog) {
        this.disabled = false;
        this.maxFiles = 1;
        this.maxFileSize = 20000000; // 20MB
        this.documentType = 'none';
        this.hasBaseDropZoneOver = false;
        this.filesFakeNames = [];
        this.files = [];
        this.fileUploaded = new EventEmitter();
        this.filesChange = new EventEmitter();
    }

    /**
     * Инициализация компонента
     * @returns {void}
     */
    ngOnInit(): void {
        this.uploader = new FileUploader({
            url: this.baseUrl,
            queueLimit: this.maxFiles
        });
        this.uploader.onAfterAddingFile = (file: FileItem) => {
            this.onAddFile(file, this);
        };
    }

    /**
     * Уничтожение компонента
     * @returns {void}
     */
    ngOnDestroy(): void {
        this.filesFakeNames = [];
        this.files = [];
        this.maxFiles = 0;
        this.maxFileSize = 0;
    }

    /**
     * Изменяет флаг для добавления нового класса дроп зоне
     * @param {boolean} isActiveZone Флаг - файл над зоной
     *
     * @returns {void}
     */
    onFileOver(isActiveZone: any): void {
        this.hasBaseDropZoneOver = isActiveZone;
    }

    /**
     * Метод проверяет файл и складывает
     * его в лист используя отдельные методы
     * @param {FileItem} file Файл
     * @param {AppFileUploaderComponent} ctx Контекст
     *
     * @returns {void}
     */
    onAddFile(file: any, ctx: AppFileUploaderComponent): void {
        let reader = new FileReader();

        reader.onload = (e: any) => {
            // Проверка валидность
            if (ctx.isFileValid(e, file, ctx)) {
                // Если вайл прошел валидацию кладем в список
                ctx.addFile(file, ctx);
            }
        };
        reader.readAsDataURL(file._file);
    }

    /**
     * Метод удаления файла из списка
     * @param {number} index Индекс файла в списке
     *
     * @returns {void}
     */
    onDelete(index: number): void {
        try {
            this.fileInput.nativeElement.value = '';
            this.uploader?.removeFromQueue(this.uploader.queue[index]);
        } catch (error) {
            throwError(error);
        }
    }

    /**
     * Загрузка одного файла
     * @param {string} url Адрес
     *
     * @returns {void}
     */
    onUpload(url?: string, data?: any): void {
        const uploaders = this.uploader?.getNotUploadedItems();

        let _files: any[] = [];

        uploaders?.forEach(item => {
            _files.push(item.file ? item.file : null);
        });

        _files = _.without(_files, null);

        if (!_files.length) {
            return;
        }

        const _name = _files.length === 1 ? _files[_files.length - 1].name : _files.length + ' files';

        const ctx = this;

        ctx.onOpenProgressDialog(_name);
        /** Отправка файла(ов) на сервер */
        ctx.fileService.uploadQueueOfFiles(_files, url ? url : ctx.baseUrl, data).subscribe(
            res => {
                ctx.fileService.uploadComplete.next(true);

                ctx.fileInput.nativeElement.value = '';
                ctx.uploader?.clearQueue();
            },
            error => {
                ctx.fileService.uploadCompleteWithError.next(true);
                // ctx.clearFileInput();
                ctx.messageService.error(error);
            }
        );
    }

    /**
     * Метод проверяет наличие элементов в массиве - его содержание
     * @param {any[]} array Массив для проверки
     *
     * @returns {boolean} результат - удовлетворяет ли массив условию
     */
    checkArray(array: any[]): boolean {
        return array && array.length ? true : false;
    }

    /**
     * Метод складывает файлы в список для отображения пользователю
     * @param {FileItem} file Файл
     * @param {AppFileUploaderComponent} ctx Контекст
     *
     * @returns {void}
     */
    private addFile(file: FileItem, ctx: AppFileUploaderComponent): void {
        ctx.filesFakeNames = ctx.filesFakeNames ? ctx.filesFakeNames : [];
        ctx.files = ctx.files ? ctx.files : [];

        let f = new FileViewData();
        f.id = file._file.name;
        f.fileName = file._file.name;

        f.fileFakeName = ctx.checkArray(ctx.filesFakeNames)
            ? ctx.filesFakeNames[ctx.files.length]
                ? ctx.filesFakeNames[ctx.files.length]
                : file._file.name
            : file._file.name;

        ctx.files.push(f);
        ctx.filesChange.emit(ctx.files);
    }

    /**
     * Открытие диалогового окна с загрузкой
     * @param {string} fileName Имя файла для отображения
     *
     * @return {void}
     */
    private onOpenProgressDialog(fileName: string): void {
        const dialogRef = this.dialog.open(AppFileUploaderBarComponent, {
            width: '496px',
            disableClose: true
        });
        dialogRef.componentInstance.title = this.pageText.fileUploading;
        dialogRef.componentInstance.subtitle = fileName;
        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.fileUploaded.emit();
            }
        });
    }

    /**
     * Метод проверяет валидность файла, в случае нарушения установленных
     * правил, файл удаляется из очереди, чтобы можно было загрузить правильный
     * @param {ProgressEvent} eventAfterReadingFile Прогресс, чтение файла
     * @param {FileItem} file Файл
     * @param {AppFileUploaderComponent} ctx Контекст
     *
     * @returns {void}
     */
    private isFileValid(eventAfterReadingFile: ProgressEvent, file: FileItem, ctx: AppFileUploaderComponent): any {
        try {
            if (!ctx.isAllowableTypeFile(file)) {
                ctx.messageService.error(`Invalid file type. Type must be .csv`);
                ctx.fileInput.nativeElement.value = '';
                ctx.uploader?.removeFromQueue(file);
                return false;
            }

            if (!ctx.isAllowableSizeFile(file)) {
                ctx.messageService.error(`Max size is ${this.maxSizeStrFormat}MB`);
                ctx.fileInput.nativeElement.value = '';
                ctx.uploader?.removeFromQueue(file);
                return false;
            }

            const total = ctx.uploader ? ctx.uploader.queue.length : 0;
            if (total > this.maxFiles || total === 0) {
                ctx.messageService.error('Привышено общее количество файлов');
                ctx.fileInput.nativeElement.value = '';
                ctx.uploader?.clearQueue();
                return false;
            }

            return true;
        } catch (error) {
            throwError(error);
        }
    }

    /**
     * Проверяет тип файла
     * @param {FileItem} file Входной файл
     *
     * @returns {boolean}
     */
    private isAllowableTypeFile(file: FileItem): boolean {
        let isAllowableType = false;
        if (file instanceof FileItem) {
            let fileType = file.file ? file.file.type : null;
            console.log('fileType: ' + fileType);
            if (fileType === '') {
                // <- некоторые браузеры могут не знать о каких-то расширениях
                const typeInName = file.file.name ? _.last(file.file.name.split('.')) : null; // в этом случае они пишут в файл пустую строку
                console.log('typeInName: ' + typeInName);
                fileType = typeInName ? typeInName : fileType;
            }
            isAllowableType = _.any(this.accept, type => type === fileType);
        }
        return isAllowableType;
    }

    /**
     * Проверяет размер файла
     * @param {FileItem} file Входной файл
     *
     * @returns {boolean}
     */
    private isAllowableSizeFile(file: FileItem): boolean {
        const fileSize = file.file ? file.file.size : null;
        const isAllowableSize = fileSize <= this.maxFileSize;
        return isAllowableSize;
    }
}
