import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, HostListener, OnInit, Injector, OnChanges, SimpleChange, ComponentRef } from '@angular/core';
import { OverlayRef, OverlayConfig, Overlay, ScrollStrategyOptions, PositionStrategy, ConnectionPositionPair } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { AppMultiselectOverlayComponent, SELECT_PANEL_DATA } from './app-multiselect-overlay/app-multiselect-overlay.component';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { AppMultiselectItemIconSettings, AppMultiselectItemStyleSettings } from './app-multiselect-overlay/app-multiselect-overlay-settings';

export type MaterialType = 'legacy' | 'standard' | 'fill' | 'outline';

const DEFAULT_INPUT_NAME = 'multiselect';
const DEFAULT_PLACEHOLDER = 'placeholder';
const DEFAULT_MATERIAL_THEME = 'outline';
const DEFAULT_DISPLAY_NAME = 'name';
const DEFAULT_UNIQUE_NAME = 'id';
@Component({
    selector: 'app-multiselect',
    templateUrl: './app-multiselect.component.html',
    styleUrls: ['./app-multiselect.component.scss']
})
export class AppMultiselectComponent implements OnInit, OnDestroy, OnChanges {
    /**
     * Для подписок
     * @type {Subscription}
     */
    private subs: Subscription[];
    /**
     * Шаблон AppMultiselectOverlayComponent
     * @type {componentPortal}
     */
    private componentPortal!: ComponentPortal<AppMultiselectOverlayComponent>;
    /**
     * Объект для overlay
     * @type {OverlayRef}
     */
    private overlayRef!: OverlayRef;

    private _componentRef!: ComponentRef<any>;

    /**
     * Фокус на селекте
     * @type {boolean}
     */
    public focused!: boolean;

    /** ДЛЯ СЕЛЕКТА */
    /**
     * Блокировщик для поля
     * Для использования в форме
     * @type {boolean}
     */
    @Input() disabled: boolean;
    /**
     * Обязательность селекта
     * Для использования в форме
     * @type {boolean}
     */
    @Input() required: boolean;
    /**
     * Флаг отражающий что мультиселект работает единым с локальным
     * списком или список приходит в запросе при каждом изменении поиска
     * @type {boolean}
     */
    @Input() isLocalList: boolean = true;
    /**
     * Label для поля, если включена
     * @type {string}
     */
    @Input() label!: string;
    /**
     * Заголовок поля
     * @type {string}
     */
    @Input() placeholder: string = DEFAULT_PLACEHOLDER;
    /**
     * Заголовок поля поиска
     * @type {string}
     */
    @Input() generalTextIsSearchPlaceholder!: string;
    /**
     * Отображение иконки clear
     * Соответственно - возможность сброса значения по клику
     * Отображается вместо стрелочки(для фильтров включено)
     * @type {string}
     */
    @Input() isClearIconVisible?: boolean;
    /**
     * Имя для инпута
     * @type {string}
     */
    @Input() inputHtmlName: string = DEFAULT_INPUT_NAME;
    /**
     * Доп класс для стилизации
     * @type {string}
     */
    @Input() componentStyleClass?: string = 'app-default__multiselect';
    /**
     * Тема материала
     * @type {string}
     */
    @Input() componentMaterialTheme?: MaterialType = DEFAULT_MATERIAL_THEME;
    /**
     * Класс для тени
     * @type {string}
     */
    @Input() componentShadowStyleClass?: string = 'app-multiselect__multiselect--default-shadow';
    /**
     * Для окраски текста в селекте
     * @type {string}
     */
    @Input() inputTextColorClass?: string = 'default';



    /** ДЛЯ ОТКРЫВАЮЩЕЙСЯ ПАНЕЛИ СЕЛЕКТА */
    /**
     * Мультирежим
     * @type {boolean}
     */
    @Input() isMultipleModeEnable!: boolean;
    /**
     * Размер items в пикселях
     * @type {number}
     */
    @Input() itemSizePx: number = 28;
    /**
     * Количество отображаемых элементов в листе
     * @type {number}
     */
    @Input() displayItemsCount: number = 0;
    /**
     * Список элементов
     * @type {any[]}
     */
    @Input() items: any[];
    /**
     * Список выбранных элементов
     * @type {any[]}
     */
    @Input() selectedItems: any[];
    /**
     * Лимит на выбор опций
     * @type {number}
     */
    @Input() selectedItemsLimit!: number;
    /**
     * Имя, которое будет отображаться в списке
     * @type {string}
     */
    @Input() displayName: string = DEFAULT_DISPLAY_NAME;
    /**
     * Уникальное поле(для поиска элементов)
     * @type {string}
     */
    @Input() uniqueField: string = DEFAULT_UNIQUE_NAME;
    /**
     * Имеется возможность отключения сортировки
     * @type {boolean}
     */
    @Input() isSortEnable: boolean = true;
    /**
     * Поле для сортировки списков
     * @type {string}
     */
    @Input() sortBy: string = DEFAULT_DISPLAY_NAME;
    /**
     * Включение поиска
     * @type {boolean}
     */
    @Input() isSearchEnable!: boolean;
    /**
     * Чекбокс Select All в листе
     * @type {boolean}
     */
    @Input() isSelectAllOptionVisible!: boolean;
    /**
     * Тултип на элементах
     * @type {boolean}
     */
    @Input() isItemTooltipVisible?: boolean;
    /**
     * Доп класс для панели
     * @type {string}
     */
    @Input() panelStyleClass?: string;


    // ???????
    /**
     * Фильтрует не только по displayName но и по id
     * @type {boolean}
     */
    @Input() isIncludeIdIntoSearch!: boolean;

    /**
     * настройка для изменения цвета текста и цвета бэкграунда
     * для item в списке
     * (клиенту нужно было чтобы items
     * подкрашивались красным или серым поэтому эта настройка тут)
     */
    @Input() itemHtmlStyleSettings!: AppMultiselectItemStyleSettings;
    /**
     * настройка для иконок в списке
     * если не задано - иконки не отображаются
     * каждый класс сам устанавливает display:block/flex и другие стили
     */
    @Input() itemHtmlIconSettings!: AppMultiselectItemIconSettings;


    /**
     * Передает выбранный элемент
     * @type {EventEmitter<any>}
     */
    @Output() selectedValue: EventEmitter<any | null>;
    /**
     * Передает всю колллекцию выбранных элементов (например для мультиселекта)
     * @type {EventEmitter<any[]>}
     */
    @Output() selectedItemsChange: EventEmitter<any[]>;
    /**
     * При нажатии на Select all
     * @type {EventEmitter<boolean>}
     */
    @Output() selectedAllChange: EventEmitter<boolean>;
    /**
     * Событие генерируемое при закрытии панели
     * @type {EventEmitter<void>}
     */
    @Output() panelClose: EventEmitter<void>;
    /**
     * Событие генерируемое при закрытии панели
     * @type {EventEmitter<void>}
     */
    @Output() insidePanelClose: EventEmitter<void>;

    @Output() searchChange: EventEmitter<string | null>;

    /**
     * Для проверки валидности при использовании в форме
     * @type {boolean}
     */
    get isValid(): boolean {
        return this.required && !this.disabled
            ? this.isArrayValid(this.selectedItems)
            : true;
    }
    /**
     * Устанавливает класс для стилизации селекта
     * в состоянии, когда элементы выбраны
     * @type {boolean}
     */
    get isDirty(): boolean {
        return this.isArrayValid(this.selectedItems);
    }
    /**
     * Имя первого выбранного элемента
     * @type {string}
     */
    get displayText(): string {
        if (this.isSelectedAllItems) { return 'All'; }

        return this.isArrayValid(this.selectedItems)
            ? _.first(this.selectedItems)[this.displayName]
            : null;
    }
    /**
     * Имя первого выбранного элемента
     * @type {string}
     */
    set displayText(name: string) {
        this.displayText = name;
    }
    /**
     * Флаг при всех выбранных элементах
     * @type {string}
     */
    get isSelectedAllItems(): boolean {
        if (!this.isMultipleModeEnable || !this.isArrayValid(this.items)) { return false; }

        return this.isArrayValid(this.selectedItems) && this.selectedItems.length == this.items?.length;
    }
    /**
     * Поворачивает стрелочку селекта вверх если панель открыта
     * @type {boolean}
     */
    get setUPPositionForArrowIcon() {
        return this.overlayRef && this.overlayRef.hasAttached();
    }
    /**
     * Появляется числовыбранных элементов
     * (только когда выбрано более 1ого элемента)
     * @type {boolean}
     */
    get showCountOfDisplayItems() {
        if (!this.isMultipleModeEnable || !this.isArrayValid(this.items)) { return false; }
        if (this.isSelectedAllItems) { return false; }
        return this.selectedItems?.length > 1;
    }
    /**
     * Устанавливает класс для стилизации стрелочки селекта
     * Скрывает ее
     * @type {boolean}
     */
    get hideArrowIcon() {
        return this.showClearIcon;
    }
    /**
     * Устанавливает класс для стилизации кнопки крестика
     * Показывает кнопку
     * @type {boolean}
     */
    get showClearIcon(): boolean {
        return (this.isArrayValid(this.selectedItems)
            && !this.setUPPositionForArrowIcon && (this.isMultipleModeEnable || this.isClearIconVisible)) || false;
    }

    constructor(
        private overlay: Overlay,
        private injector: Injector,
        private scrollStrategyOptions: ScrollStrategyOptions
    ) {
        this.subs = [];

        /** Основные поля по дефолту */
        this.required = false;
        this.disabled = false;

        this.items = [];
        this.selectedItems = [];
        this.isSortEnable = true;
        this.sortBy = this.displayName;

        /** Список по дефолту */
        this.items = this.setDefaultItems();

        /** События */
        this.selectedValue = new EventEmitter<any | null>();
        this.selectedItemsChange = new EventEmitter<any[]>();
        this.selectedAllChange = new EventEmitter<boolean>();
        this.panelClose = new EventEmitter<void>();
        this.insidePanelClose = new EventEmitter<void>();
        this.searchChange = new EventEmitter<string | null>();
    }

    /**
     * Элемента для расчета позиции overlay
     * @type {ElementRef}
     */
    @ViewChild('wrapperHTMLElement') wrapperHTMLElement?: ElementRef;

    /**
     * Поле ввода input
     * @type {ElementRef}
     */
    @ViewChild('inputHTMLElement', { static: false }) inputHTMLElement?: ElementRef;


    /** Обновление длинны overlay отнительно элемента */
    @HostListener('window:resize')
    handleWindowResizeEvent(): void {
        if (this.overlayRef) {
            this.overlayRef.updateSize({
                width: this.wrapperHTMLElement?.nativeElement?.getBoundingClientRect()?.width
            });
            this.overlayRef.updatePosition();
        }
    }

    /**
     * Отписываться не нужно, умирает с компонентом
     * Слушает нажатие esc и закрывает окно
     * @param {KeyboardEvent} event Событие
     *
     * @returns {void}
     */
    @HostListener('document:keyup.escape', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        this.closePanel();

        event.stopPropagation();
    }

    /**
     * Отслеживаются изменения при поступлении
     * @param {SimpleChange} changes Изменения входных полей
     */
    public ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
        if (changes['items'] && this._componentRef) {
            this._componentRef.instance.itemsChange.next(this.items);
        }
    }

    /**
     * Инициализация
     * @returns {void}
     */
    ngOnInit(): void {
        this.disabled = this.disabled != null ? this.disabled : false;
        this.required = this.required != null ? this.required : false;
        this.placeholder = this.placeholder != null ? this.placeholder : DEFAULT_PLACEHOLDER;
        this.isClearIconVisible = this.isClearIconVisible != null ? this.isClearIconVisible : false;
        this.inputHtmlName = this.inputHtmlName != null ? this.inputHtmlName : DEFAULT_INPUT_NAME;

        this.componentStyleClass = this.componentStyleClass != null ? this.componentStyleClass : 'app-default__multiselect';
        this.componentMaterialTheme = this.componentMaterialTheme != null ? this.componentMaterialTheme : DEFAULT_MATERIAL_THEME;
        this.componentShadowStyleClass = this.componentShadowStyleClass != null ? this.componentShadowStyleClass : 'app-multiselect__multiselect--default-shadow';
        this.inputTextColorClass = this.inputTextColorClass != null ? this.inputTextColorClass : 'default';

        /** Панель */
        this.isMultipleModeEnable = this.isMultipleModeEnable != null ? this.isMultipleModeEnable : false;
        this.itemSizePx = this.itemSizePx != null ? this.itemSizePx : 45;
        this.displayItemsCount = this.displayItemsCount != null ? this.displayItemsCount : 0;
        this.items = this.items != null ? this.items : this.setDefaultItems();
        this.selectedItems = this.selectedItems != null ? this.selectedItems : [];
        if (this.selectedItemsLimit != null) {
            this.selectedItemsLimit = this.selectedItemsLimit;
        }
        this.displayName = this.displayName != null ? this.displayName : DEFAULT_DISPLAY_NAME;
        this.uniqueField = this.uniqueField != null ? this.uniqueField : DEFAULT_UNIQUE_NAME;

        this.isSortEnable = this.isSortEnable != null ? this.isSortEnable : true;
        this.sortBy = this.sortBy != null ? this.sortBy : this.displayName;
        this.isSearchEnable = this.isSearchEnable != null ? this.isSearchEnable : false;
        this.isSelectAllOptionVisible = this.isSelectAllOptionVisible != null ? this.isSelectAllOptionVisible : false;
        this.isItemTooltipVisible = this.isItemTooltipVisible != null ? this.isItemTooltipVisible : false;
        if (this.panelStyleClass != null) {
            this.panelStyleClass = this.panelStyleClass;
        }
        // ???????
        this.isIncludeIdIntoSearch = this.isIncludeIdIntoSearch != null ? this.isIncludeIdIntoSearch : false;

        if (this.itemHtmlStyleSettings != null) {
            this.itemHtmlStyleSettings = this.itemHtmlStyleSettings;
        }
        if (this.itemHtmlIconSettings != null) {
            this.itemHtmlIconSettings = this.itemHtmlIconSettings;
        }
    }

    /**
     * Уничтожение компонента
     * @returns {void}
     */
    ngOnDestroy(): void {
        this.subsUnsubscribe();
    }

    /**
     * Метод открытия overlay
     * @returns {void}
     */
    onOpenPanel(): void {

        if (this.disabled || (this.overlayRef && this.overlayRef.hasAttached())) {
            this.closePanel();
            return;
        }

        if (!this.wrapperHTMLElement)
            return;

        this.sortItems();

        let overlayConfig = new OverlayConfig({
            // Цепляемся к элементу, указываем позицию относительно элемента
            positionStrategy: this.getOverlayPosition(this.wrapperHTMLElement),
            scrollStrategy: this.scrollStrategyOptions.block(), // блокируется скроллинг на заднем плане
            hasBackdrop: true,
            backdropClass: 'app-multiselect-backdrop',
            width: this.wrapperHTMLElement?.nativeElement?.getBoundingClientRect()?.width
        });

        let data = {

            isMultipleModeEnable: this.isMultipleModeEnable,
            itemSizePx: this.itemSizePx,
            displayItemsCount: this.displayItemsCount,
            isLocalList: this.isLocalList,

            items: this.items,
            generalTextIsSearchPlaceholder: this.generalTextIsSearchPlaceholder,
            selectedItems: this.selectedItems,
            selectedItemsLimit: this.selectedItemsLimit,
            displayName: this.displayName,
            uniqueField: this.uniqueField,
            sortBy: this.sortBy,
            isSearchEnable: this.isSearchEnable,
            isSelectAllOptionVisible: this.isSelectAllOptionVisible,
            isItemTooltipVisible: this.isItemTooltipVisible,
            panelStyleClass: this.panelStyleClass,

            // ???????
            isIncludeIdIntoSearch: this.isIncludeIdIntoSearch,

            itemHtmlStyleSettings: this.itemHtmlStyleSettings,
            itemHtmlIconSettings: this.itemHtmlIconSettings
        };

        this.overlayRef = this.overlay.create(overlayConfig);
        this.componentPortal = new ComponentPortal(
            AppMultiselectOverlayComponent,
            null,
            this.createInjector(data)
        );

        this.openPanel();
    }

    /**
     * Метод удаления всех выбранных элементов
     * @param {Event} clickEvent Событие для прекращения
     * дальнейшей передачи текущего события
     *
     * @returns {void}
     */
    onDeleteAllItems(clickEvent?: Event): void {
        /** Прекращение дальнейшей передачи текущего события */
        if (clickEvent) { clickEvent.stopPropagation(); }

        if (this.disabled) { return; }

        this.selectedItems = [];
        this.sortItems();

        this.selectedItemsChange.emit(this.selectedItems);
        this.selectedValue.emit(null);
    }

    markAllAsTouched(): void {
        setTimeout(() => {
            if (this.inputHTMLElement) {
                this.inputHTMLElement.nativeElement?.focus();
                this.inputHTMLElement.nativeElement?.blur();
            }
        }, 0);
    }

    /**
     * Метод проверки массива на его наличие и длину
     * @param {any[]} array Массив для проверки длины
     *
     * @returns {boolean} результат - удовлетворяет ли массив условию
     */
    private isArrayValid(array: any[]): boolean {
        return array?.length ? true : false;
    }

    /**
     * Сортировка элементов
     * @returns {void}
     */
    private sortItems(): void {

        if (!this.isSortEnable)
            return;

        // Сортировка списков внутри данного компонента по заданному полю
        // обычно выполняется при открытии панели и
        // при сбросе всех selectedItems (нажатием на крестик в селекте)
        this.items = this.moveSelectedItemsToTopList(this.items, this.selectedItems);
    }

    /**
     * Метод перемещения выбранных элементов в начало списка
     * или возвращение отменённых элементов на их место в общем списке
     * @param {any[]} list Общий список элементов
     * @param {any[]} selectedList Список выбранных элементов
     * @param {string} sortBy Поле для сортировки
     *
     * @returns {void}
     */
    private moveSelectedItemsToTopList(list: any[], selectedList: any[], sortBy: string = this.sortBy): any[] {

        if (!this.isArrayValid(list))
            return [];

        if (!this.isArrayValid(selectedList))
            return _.sortBy(list, (item: any) => item?.[sortBy as string] != null ? item?.[sortBy as string]?.toString() : '');

        // Сортировка при хотя бы одном selectedList
        // Удаление из общего списка выбранных элементов
        list = _.difference(list, selectedList);

        // Сортировка обоих листов по алфавиту
        list = _.sortBy(list, (item: any) => item?.[sortBy as string] != null ? item?.[sortBy as string]?.toString() : '');
        selectedList = _.sortBy(selectedList, (item: any) => item?.[sortBy as string] != null ? item?.[sortBy as string]?.toString() : '');

        // Объединение сортированных списков
        // В первую очередь выбранные элементы
        return selectedList.concat(list);
    }

    /**
     * Отписки
     * @returns {void}
     */
    private subsUnsubscribe(): void {
        if (this.subs) {
            this.subs.forEach((s: Subscription) => s.unsubscribe());
            this.subs = [];
        }
    }

    /**
     * Открытие overlay
     * @returns {void}
     */
    private openPanel(): void {

        this._componentRef = this.overlayRef.attach(this.componentPortal);

        /** Событие передает выбранный элемент */
        const selectedValueSub = this._componentRef.instance.selectedValue
            .subscribe((item: any) => {

                this.selectedValue.emit(item);

                if (!this.isMultipleModeEnable)
                    this.closePanel();
            });

        /** Событие передает все выбранные элементы (для мультиселекта) */
        const selectedItemsSub = this._componentRef.instance.selectedItemsChange
            .subscribe((selectedItems: any[]) => {

                this.selectedItems = selectedItems;
                this.selectedItemsChange.emit(selectedItems);
            });

        const selectedAllSub = this._componentRef.instance.selectedAllChange
            .subscribe((flag: boolean) => {

                this.selectedAllChange.emit(flag);
            });

        const panelClosedSub = this.overlayRef.backdropClick()
            .subscribe(() => {
                this.closePanel();
            });

        const insidePanelClosedSub = this._componentRef.instance.insidePanelClose
            .subscribe(() => {
                this.closePanel();
            });

        const searchChangeSub = this._componentRef.instance.searchChange
            .subscribe((search: string | null) => {
                this.searchChange.emit(search);
            });

        this.subs.push(selectedValueSub);
        this.subs.push(selectedItemsSub);
        this.subs.push(selectedAllSub);
        this.subs.push(panelClosedSub);
        this.subs.push(insidePanelClosedSub);
        this.subs.push(searchChangeSub);
    }

    /**
     * Закрытие overlay
     * @returns {void}
     */
    private closePanel(): void {
        this.panelClose.emit();

        this.subsUnsubscribe();

        if (!this.overlayRef || !this.overlayRef.hasAttached()) {
            return;
        }

        this.overlayRef.detach();

        setTimeout(() => {
            if (this.inputHTMLElement) { this.inputHTMLElement.nativeElement.focus(); }
        }, 0);
    }

    /**
     * Создание инжектора с данными для их передачи в оверлей
     */
    private createInjector(data: any): PortalInjector {
        const injectorTokens = new WeakMap<any, any>([[SELECT_PANEL_DATA, data]]);
        return new PortalInjector(this.injector, injectorTokens);
    }

    /**
     * Стратегия для расположения overlay
     * @param {any} originElement Элемента относительно которого будет overlay
     *
     * @returns {PositionStrategy}
     */
    private getOverlayPosition(originElement: any): PositionStrategy {
        const positionStrategy = this.overlay.position()
            .flexibleConnectedTo(originElement)
            .withPositions(this.getOverlayPositions())
            .withPush(false);

        return positionStrategy;
    }

    /**
     * Позиции относительно элемента
     * @returns {ConnectionPositionPair}
     */
    private getOverlayPositions(): ConnectionPositionPair[] {
        return [
            {
                originX: 'start',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'top',
            },
            {
                originX: 'start',
                originY: 'bottom',
                overlayX: 'start',
                overlayY: 'bottom'
            },
        ];
    }

    /**
     * Дефолтный список для примера
     * @returns {any[]}
     */
    private setDefaultItems(): any[] {
        return [
            { id: '0', name: 'Option 1' },
            { id: '1', name: 'Option 2' },
            { id: '2', name: 'Option 3' }
        ];
    }
}
