export class FileViewData {
  id!: string;
  fileName!: string;
  file?: File;
  url?: string;
  size?: number;
  fileFakeName!: string;

  constructor (data?: any) {
    if (data) {
      for (const i in data) {
        if (data.hasOwnProperty(i)) {
          this[i as keyof FileViewData] = <never>data[i];
        }
      }
    }
  }
}
