import { AuthorizationService } from './authorization.service';
import { Injectable } from '@angular/core';
import { ApiService } from './abstracts/api.service';
import { throwError, Subject, Observable } from 'rxjs';
import { catchError, finalize, map, takeUntil } from 'rxjs/operators';
import { _handleError } from '../shared/utils/util-functions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpResponseObserve, HttpResponseType, RequestOptions } from './request-options';

@Injectable({
    providedIn: 'root'
})
export class BaseApiService implements ApiService {
    protected _unsubscribe = new Subject();

    private requestsInProgress: boolean = false;
    private errorMessage!: string;

    public get loadInProgress(): boolean {
        return this.requestsInProgress;
    }

    public set loadInProgress(progress: boolean) {
        this.requestsInProgress = progress;
    }

    constructor(private http: HttpClient, private authService: AuthorizationService) {}

    apiUnsubscribe(): void {
        this.loadInProgress = false;
        if (this._unsubscribe) {
            this._unsubscribe.next();
            this._unsubscribe.complete();
        }
        this._unsubscribe = new Subject();
    }

    get<TContract>(url: string): Observable<TContract> {
        this.requestsInProgress = true;
        const options = AuthorizationService.createOptions();
        return this.http.get(url, options).pipe(
            takeUntil(this._unsubscribe),
            map(res => <TContract>res),
            finalize(() => (this.requestsInProgress = false)),
            catchError(this.handleError<TContract>('GET'))
        );
    }

    post<TContract, TResponseContract>(url: string, payload: TContract): Observable<TResponseContract> {
        this.requestsInProgress = true;
        const body = JSON.stringify(payload);
        const options = AuthorizationService.createOptions();
        return this.http.post(url, body, options).pipe(
            takeUntil(this._unsubscribe),
            map(res => <TResponseContract>res),
            finalize(() => (this.requestsInProgress = false)),
            catchError(this.handleError<TResponseContract>('POST'))
        );
    }

    patch<TContract, TResponseContract>(url: string, payload: TContract): Observable<TResponseContract> {
        this.requestsInProgress = true;
        const body = JSON.stringify(payload);
        const options = AuthorizationService.createOptions();
        return this.http.patch(url, body, options).pipe(
            takeUntil(this._unsubscribe),
            map(res => <TResponseContract>res),
            finalize(() => (this.requestsInProgress = false)),
            catchError(this.handleError<TResponseContract>('PATCH'))
        );
    }

    put<TContract, TResponseContract>(url: string, payload: TContract): Observable<TResponseContract> {
        this.requestsInProgress = true;
        const body = JSON.stringify(payload);
        const options = AuthorizationService.createOptions();
        return this.http.put(url, body, options).pipe(
            takeUntil(this._unsubscribe),
            map(res => <TResponseContract>res),
            finalize(() => (this.requestsInProgress = false)),
            catchError(this.handleError<TResponseContract>('PUT'))
        );
    }

    delete<TContract>(url: string, payload?: any): Observable<TContract> {
        this.requestsInProgress = true;
        const body = JSON.stringify(payload);
        const options = AuthorizationService.createOptions(body);
        return this.http.delete(url, options).pipe(
            takeUntil(this._unsubscribe),
            map(res => <TContract>res),
            finalize(() => (this.requestsInProgress = false)),
            catchError(this.handleError<any>('DELETE'))
        );
    }

    download<Blob>(url: string, payload: any): Observable<Blob> {
        this.requestsInProgress = true;
        const body = JSON.stringify(payload);
        const options = AuthorizationService.createOptions(body, 'blob');
        return this.http.post(url, body, options).pipe(
            takeUntil(this._unsubscribe),
            map(res => <Blob>res),
            finalize(() => (this.requestsInProgress = false)),
            catchError(this.handleError<any>('DOWNLOAD'))
        );
    }

    private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
        return (error: any): Observable<T> => {
            // TODO: better job of transforming error for user consumption
            console.log(`${operation} failed: ${error.message}`);
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // Let the app keep running by returning an empty result.
            // return of(result as T);
            this.requestsInProgress = false;
            const errorResponse = _handleError(error);
            if (errorResponse?.status === 401 || errorResponse?.status === 403) {
                // Unauthorized
                this.authService.logout();
                this.apiUnsubscribe();
            }
            return throwError(errorResponse);
        };
    }
}
