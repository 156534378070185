<div class="app-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <div class="login-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <form [formGroup]="loginForm" (ngSubmit)="onLogin(loginForm)" class="login-form" fxLayout="column"
            fxLayoutAlign="center ">

            <div class="login__logo" fxLayout="column" fxLayoutAlign="center center">
                <div class="repricer-icons repricer-logo-icon large-size" height="128px" width="128px"></div>
            </div>

            <div fxLayout="column" fxLayoutAlign="center ">
                <mat-form-field appearance="outline">
                    <!-- <mat-label>Email</mat-label> -->
                    <input matInput type="text" formControlName="email" placeholder="Email" autocomplete="off" minLength="3"
                        maxlength="128" i18n-placeholder="@@hint.message.is.email">
                </mat-form-field>
    
                <mat-form-field appearance="outline">
                    <!-- <mat-label>Password</mat-label> -->
                    <input matInput type="password" formControlName="password" placeholder="Password" autocomplete="off"
                        minLength="6" maxlength="32" i18n-placeholder="@@hint.message.is.password">
                    <mat-hint class="mat-error" *ngIf="errorMessage" align="start">
                        <span>{{errorMessage}}</span>
                    </mat-hint>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="end ">
                <a routerLink="/recover" i18n="@@general.text.is.forgot.password">Forgot password?</a>
            </div>

            <div class="login__btn" fxLayout="column" fxLayoutAlign="center center">
                <button submit mat-flat-button color="primary" class="content-center" disableRipple fxFlex="100">
                    <div *ngIf="!disabled" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
                        <svg class="repricer-icons login-icon btn-svg-icon-16">
                            <use xlink:href="assets/icons/login-icon.svg#loginIcon" />
                        </svg>
                        <span i18n="@@button.placeholder.login">Login</span>
                    </div>
                    <div *ngIf="disabled" class="btn-spinner">
                        <div class="btn-spinner__circle"></div>
                        <div class="btn-spinner__circle"></div>
                        <div class="btn-spinner__circle"></div>
                    </div>
                </button>
            </div>
        </form>
        <div class="login__create-account" fxLayout="row" fxLayoutAlign="center ">
            <span style="padding-right:4px;" i18n="@@general.text.is.new.to.repricer">New to Repricer? </span><a routerLink="/signup" i18n="@@general.text.is.create.an.account">Create an account</a>
        </div>
    </div>
</div>