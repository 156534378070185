<mat-form-field class="form-field" [class.form-field__empty]="!value" appearance="outline" 
    fxFlex="100">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <div fxLayout="row">
        <input type="text" [ngClass]="styleClass" title=" " matInput placeholder="0" autocomplete="off"
            [disabled]="formDisabled" [required]="required" #numberInput [(ngModel)]="value" [pattern]="regex"
            (keydown.arrowup)="onUp($event)" (keydown.arrowdown)="onDown($event)" (ngModelChange)="onChange()"
            (blur)="onCheckValue()" />
        <span *ngIf="suffix" matTextSuffix class="text" [class.suffix-disabled]="formDisabled"
            style="line-height: 17px;" fxLayout="row" fxLayoutAlign="start center">{{ suffix }}</span>
    </div>
    <div matSuffix disableRipple [class.suffix-disabled]="formDisabled" (click)="onUp($event)"
        onselectstart="return false" onmousedown="return false" style="margin-right: 10px;">
        <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
            <svg-icon key="add-circle" fontSize="16px"></svg-icon>
        </div>
    </div>
    <div matSuffix disableRipple [class.suffix-disabled]="formDisabled" (click)="onDown($event)"
        onselectstart="return false" onmousedown="return false">
        <div class="minus-icon" fxLayout="row" fxLayoutAlign="center center">
            <svg-icon key="remove-circle" fontSize="16px"></svg-icon>
        </div>
    </div>
    <mat-error *ngIf="formControlValid">{{ errorMessage }}</mat-error>
</mat-form-field>