import { Model } from "../../shared/interfaces";
import { ArticleAttribute } from "../../articles/shared/article-attribute";
import { Matching } from "../../contracts/matching";
import { CompetitorTriggeredRules } from "./competitor-triggered-rules.model";

export class CompetitorModel implements Model {
    id!: string | number | null;
    productId!: string;
    listerId!: string;
    competitorId!: string; // добавлен чтобы сделать id уникальным
    articleId!: string;
    originalArticleLink!: string;
    originalImage!: string;
    competitivePrice!: number;
    maxCompetitorPrice!: number;
    minCompetitorPrice!: number;
    bestCompetitorPrice!: number;
    totalActualCompetitors!: number;
    totalCompetitorsSold!: number;
    platformId!: string;
    ebayUri!: string;
    articleTitle!: string;
    articleAccount!: string;
    articlePlatformId!: string;
    articleEbayUri!: string;
    articleImageUri!: string;
    title!: string;
    articleLink!: string;
    image!: string;
    imageUri!: string;
    matching!: Matching;
    seller!: string;
    sellerImage!: string;
    price!: number;
    rank!: number;
    deliveryPrice!: number;
    totalPrice!: number;
    inStock!: number;
    sold!: number;
    sale!: number;
    advertising!: boolean;
    topSeller!: boolean;
    variant!: boolean;
    active!: boolean;
    account!: string;
    searchQuery!: string;
    searchQueryUri!: string;
    attributes!: ArticleAttribute[];
    isCompetitor!: boolean;
    isCompetitorManual!: boolean;
    isCompetitorRules!: boolean;
    triggeredRules!: Array<CompetitorTriggeredRules>;
    confidence!: number;

    createdDate!: number;
    updatedDate!: number;
    updatedByUserId!: string;
    updatedByUserName!: string;

    visibilityRank!: number;
    marketplaceRank!: number;

    soldFor7Days!: number;
    soldFor30Days!: number;
    saleIncomeFor7Days!: number;
    saleIncomeFor30Days!: number;

    // For UI
    isShownImage: boolean | undefined = false;
    parentArticleId!: string | undefined;

    constructor(data?: any) {
        if (data) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    this[key as keyof CompetitorModel] = <never>data[key];
                }
            }
        }
    }
}
