<div class="cards-table__search-wrapper" [class.cards-table__filter-enable]="filterComponentVisible" [fxFlex]="filterComponentVisible ? '110px': '80px'" fxLayout="row" fxLayoutAlign="start start">
    <div *ngIf="checkboxVisible" class="cards-table__checkbox-tab" fxFlex="40px">
        <mat-checkbox disableRipple
            [checked]="checkboxState"
            [indeterminate]="checkboxIndeterminateState"
            [disabled]="checkboxDisabledState || disabled"
            (change)="onCheckboxChange($event)">
        </mat-checkbox>
    </div>

    <div *ngIf="downloadButtonVisible" class="cards-table__file-upload-tab" fxFlex="44px">
        <div class="btn-stroked" matTooltip="{{downloadButtonTooltip}}"
        [class.disabled]="downloadButtonDisabledState || disabled"
        #downloadButton [class.menu-opened]="downloadButton.ariaExpanded ==='true'"
        [matMenuTriggerFor]="downloadExcelMenu">
            <div style="line-height: 14px;">
                <svg-icon key="upload-excel" fontSize="16px"></svg-icon>
            </div>
        </div>
    </div>

    <mat-menu class="button-menu button-menu__sub" #downloadExcelMenu="matMenu">
        <div fxLayout="column">
            <button mat-menu-item class="button" style="padding: 0;"
                [class.button__disabled]="false"
                (click)="onDownloadArticles()">
                <div class="button-link"
                fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12">
                    <div style="line-height: 14px;">
                        <svg-icon key="manage-tag" fontSize="16px"></svg-icon>
                    </div>
                    <span class="text">{{pageText.downloadArticles}}</span>
                </div>
            </button>
            <button mat-menu-item class="button" style="padding: 0;"
                [class.button__disabled]="downloadCompetitorsButtonDisabled"
                (click)="onDownloadCompetitors()">
                <div class="button-link"
                fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12">
                    <div style="line-height: 14px;">
                        <svg-icon key="competitor" fontSize="16px"></svg-icon>
                    </div>
                    <span class="text">{{pageText.downloadCompetitors}}</span>
                </div>
            </button>
        </div>
    </mat-menu>

    <div class="cards-table__options-tab" style="max-width: calc(100% - 40px);overflow: hidden;" fxLayout="row" fxLayoutAlign="start start" fxFlex="auto">
        <div fxLayout="row" style="min-width: 45%;width: 100%;" [class.fx-flex-25]="sortComponentVisible">

            <app-search *ngIf="searchComponentVisible" fxFlex="100"
                [borderRadiusRightDisable]="filterComponentVisible"
                [search]="search"
                [placeholder]="generalTextIsSearchPlaceholder"
                [tagRecognitionModeEnable]="tagRecognitionModeEnableInSearchComponent"
                [tags]="tags"
                (searchChanged)="onSearch($event)"
                (lastTagChanged)="onSearchTags($event)">
            </app-search>

            <!-- это кнопка фильтров - клик открывает панель app-filter-panel -->
            <div class="filter-button-wrapper">
              <app-filter-button *ngIf="filterComponentVisible"
                  [displayLeftBorder]="false"
                  [buttonStateActive]="isFilterPanelOpened"
                  [disabled]="filterButtonDisabledState"
                  (buttonStateChanged)="onOpenFilterPanel($event)">
              </app-filter-button>
            </div>

            @if(searchComponentVisible && filterComponentVisible && regexTooltipVisible) {
                <div class="question-button-wrapper" matTooltip="{{pageText.regexTooltip}}"
                matTooltipPosition="above" (click)="onOpenRegexTooltip()">
                    <svg-icon class="text question-icon" key="question" fontSize="18px"></svg-icon>
                </div>
            }
        </div>

        <app-sort *ngIf="sortComponentVisible && !isFilterPanelOpened" style="min-width: 216px;width: calc(100% - 58%);max-width: 250px; max-height: 44px;"
            [orderBy]="orderBy"
            [orderDesc]="orderDesc"
            [sortFields]="sortFields"
            (sortChanged)="onSortChange($event)">
        </app-sort>

        <div *ngIf="filterComponentVisible && isFilterPanelOpened" style="min-width: 216px;width: calc(100% - 58%);max-width: 250px;">
            <button (click)="onApplyFilter()" type="button" mat-flat-button color="primary" disableRipple fxFlex="100">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                    <svg class="search-icon btn-svg-icon-16">
                        <use xlink:href="assets/icons/search.svg#searchIcon"/>
                    </svg>
                    <span>{{pageText.applyAction}}</span>
                </div>
            </button>
        </div>

        <!-- это выбранные фильтры - отображаются как теги -->
        <app-filter-selected-fields *ngIf="filterComponentVisible"
            [selectedFields]="selectedFields"
            (fieldClicked)="onResetSelectedField($event)"
            (resetButtonClicked)="onResetAllSelectedFieldsInSelects()">
        </app-filter-selected-fields>
        <!-- [disabled]="disabled" -->
    </div>
</div>

<!-- это панель открывающася по клику на app-filter-button -->
<app-filter-panel *ngIf="filterComponentVisible"
    #filterPanelComponent
    [filterSettings]="filterSettings"
    [panelStateOpen]="isFilterPanelOpened"
    [filterParams]="filterParams"
    [decisionRules]="decisionRulesForFilter"
    [matchRules]="matchRulesForFilter"
    [articleGroups]="articleGroupsForFilter"
    [strategies]="strategiesForFilter"
    (selectedFieldsChanged)="onChangeSelectedFields($event)"
    (filterPanelClosed)="onCloseFilterPanel($event)">
</app-filter-panel>
