import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fixed'
})
export class FixedPipe implements PipeTransform {
    transform(value?: number | string, args?: any): string {
      if (!value && value != 0 && value != '0') {
        return "0." + '0'.repeat(args);
      }
      let dotValue = value.toString().replace(',', '.');
      let floatValue = parseFloat(dotValue);
      return floatValue || floatValue === 0 ? floatValue.toFixed(args) : "0." + '0'.repeat(args);
    }
}
