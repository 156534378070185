<ng-container>
    <mat-form-field appearance="outline" 
        class="app-search-field hide-field-hint mat-form-field-suffix-padding"
        [class.border-radius-right-disable]="borderRadiusRightDisable"
        [class.border-radius-left-disable]="borderRadiusLeftDisable"
        [class.no-border]="borderDisable">
        <input matInput type="text" id="searchInput" #searchInput [placeholder]="placeholder" name="search" [(ngModel)]="search" autocomplete="off"
            [disabled]="disabled" (keyup.enter)="searchByEnter ? onSearch(search) : {}" [pattern]="searchPattern"
            (ngModelChange)="searchByEnter ? onSearch(search, null, false) : onSearch(search)">
            <!-- (click)="onCheckCaretPosition()" -->
        <div *ngIf="searchIconAfter && !search?.length" matSuffix disableRipple fxLayout="row"
            fxLayoutAlign="center center" (click)="onSearch(search, $event)">
            <svg class="btn-svg-icon-16 text" [class.additional-text]="disabled">
                <use xlink:href="assets/icons/search.svg#searchIcon" />
            </svg>
        </div>
        <div *ngIf="!searchIconAfter" matPrefix disableRipple style="margin-right: 16px;" fxLayout="row"
            fxLayoutAlign="center center" (click)="onSearch(search, $event)">
            <svg class="btn-svg-icon-16 text" [class.additional-text]="disabled">
                <use xlink:href="assets/icons/search.svg#searchIcon" />
            </svg>
        </div>
        <div *ngIf="search?.length" matSuffix disableRipple fxLayout="row" fxLayoutAlign="center center"
            (click)="onResetSearch($event)">
            <div class="text" [class.clear-icon-disabled]="disabled" fxLayout="row" fxLayoutAlign="center center">
                <svg-icon key="notmatch" fontSize="20px"></svg-icon>
            </div>
        </div>
        <div class="panel-target" id="searchInputBottomLine" #searchInputBottomLine></div>
    </mat-form-field>
</ng-container>