<div *ngIf="selectedFields && selectedFields.length" class="filter-selected-fields__block-wrapper" fxLayout="row"
    fxLayoutAlign="space-between stretch">
    <div #selectedFieldsContainer class="filter-selected-fields__fields-wrapper ellipsis" fxLayout="row"
        fxLayoutGap="10px" (wheel)="onScroll($event)">
        <div *ngFor="let selectedField of selectedFields; let index = index;" class="filter-selected-fields__field"
            [class.filter-selected-fields-disabled]="disabled" fxLayout="row" fxLayoutAlign="start center"
            fxLayoutGap="5px">
            <span class="filter-selected-fields__field-name">{{selectedField.collectionTitle}}:</span>
            <span class="filter-selected-fields__field-value">{{selectedField.selectedItemValue}}</span>
            <div class="filter-selected-fields__clear-icon" (click)="onResetSelectedField(index)">
                <svg-icon key="notmatch" fontSize="18px"></svg-icon>
            </div>
        </div>
    </div>
    <div class="filter-selected-fields__count" [class.filter-selected-fields-disabled]="disabled" fxLayout="row"
        fxLayoutAlign="center center">
        <span class="count-text">{{ selectedFields.length || 0 }}</span>
        <div class="filter-selected-fields__clear-icon" (click)="onResetAllSelectedFields()">
            <svg-icon key="notmatch" fontSize="18px"></svg-icon>
        </div>
    </div>
</div>

<div *ngIf="!selectedFields || selectedFields.length < 1" class="filter-selected-fields__block-wrapper" fxLayout="row"
    fxLayoutAlign="space-between stretch">
    <span class="additional-text no-filters" style="font-size: 16px; margin-top: -26px;">{{pageText.noFilters}}</span>
</div>