import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SharedModule } from 'src/app/shared/shared.module';
// Import the library
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { FileUploadModule } from 'ng2-file-upload';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';



@NgModule({
  declarations: [
    ChangePasswordDialogComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    NgScrollbarModule,
    SharedModule,
    NgxImageZoomModule,
    SvgIconComponent,
    // FileUploadModule,
    // CommonModule,
    // MaterialModule,
    // FlexLayoutModule,
    // FormsModule,
    ReactiveFormsModule,
    // NgScrollbarModule,
    // SharedModule,

  ],
  exports: [
    ChangePasswordDialogComponent,
  ]
})
export class ProfileSharedModule { }
