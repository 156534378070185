import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from 'src/app/api';
import { UserProfile } from 'src/app/contracts';
import { accessMap, routeMap } from 'src/app/route-map';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionPlanVerifyGuard {

    constructor(
        private router: Router,
        private authService: AuthorizationService,
    ) { }

    public isSubscriptionsPage(page: string | null): boolean {
        return page === `/${routeMap.subscriptions}` ? true : false;
    }

    public isConfirmAccountPage(page: string | null): boolean {
        return page === `/${routeMap.confirmAccount}` ? true : false;
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.authService.initialized) {
            if (this.authService.userProfile?.id === this.authService?.EMAIL_NOT_VERIFIED) {
                return true;
            }
            if (this.authService.userSubscriptionPlan?.name) {
                if (this.authService.isSubscriptionPlanActive) {
                    if (this.isSubscriptionsPage(state.url)) {
                        this.router.navigate([routeMap.notFound]);
                        return false;
                    }
                    return true;
                }

                if (this.isSubscriptionsPage(state.url)) {
                    return true;
                }

                this.router.navigate([routeMap.subscriptions]);
                return false;
            }
        }
        return true;
    }
}
