<div class="loader-page">
  <div class="loader-wrapper">
    <div class="loader">
      <div class="loader__ball">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>