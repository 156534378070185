import { Injectable } from '@angular/core';
import { ApiService, ApiEndpoints } from '../../../api';
import { ItemList, SimpleDataFilter } from '../../../contracts';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { IFilterSettings } from './ifilter-settings';
import { FilterSettings } from './filter-settings';
import * as _ from 'underscore';
import { ListItem } from 'src/app/contracts/list-item';
import { AppText } from 'src/app/text/app-text';
import { AppObject } from 'src/app/contracts/app-object';

/** The map of parameters for the filter. */
export class FilterParamsMap {
    public static filterByGroupBy: string[] = ['groupByFirstId', 'groupBySecondId'];
    public static filterByAccount: string[] = ['accountIds'];
    public static filterByAction: string[] = ['actions'];
    public static filterByCampaign: string[] = ['campaignIds'];
    public static filterByCategory: string[] = ['categoryId'];
    public static filterByCountry: string[] = ['countryIds'];
    public static filterByDomainSource: string[] = ['sourceText', 'sourceType', 'domainText', 'domainType'];
    public static filterByMailer: string[] = ['mailerIds'];
    public static filterBySource: string[] = ['sourceList'];
    public static filterByIncludeList: string[] = ['includeListIds'];
    public static filterByExcludeList: string[] = ['excludeListIds'];
    public static filterByCampaignStatus: string[] = ['statusId'];
    public static filterByServerStatus: string[] = ['serverStatus'];
    public static filterByAccountStatus: string[] = ['ipStatus'];
    public static filterByDate: string[] = ['period', 'dateFrom', 'dateTo'];
    public static filterByGroupTree: string[] = ['groupId'];
}

@Injectable({
    providedIn: 'root'
})
export class AppFilterService {
    previousPage!: string;

    savedParams!: any;

    resetParams: Array<AppObject> = [];

    setFilter: any = new BehaviorSubject({
        /** Страница для редиректа */
        routePage: null,
        filterVisible: false,
        backBtnVisible: false,
        backBtnPlaceholder: null,
        applyBtnVisible: false,
        resetBtnVisible: false,
        filterByDecisionRules: false
    });

    constructor(private apiService: ApiService) {
        this.resetParams = Array<AppObject>(
            new AppObject({ key: 'itemId', value: null }),
            new AppObject({ key: 'subitemId', value: null }),
            new AppObject({ key: 'loadStatistic', value: null }),
            new AppObject({ key: 'loadFullData', value: null }),
            new AppObject({ key: 'search', value: null }),
            new AppObject({ key: 'orderBy', value: null }),
            new AppObject({ key: 'page', value: 0 }),
            new AppObject({ key: 'pageSize', value: 25 })
        );
    }

    /**
     * Статусы для компаний
     * Хардкод на фроте
     * @returns {ListItem[]}
     */
    getDecisionRules(): ListItem[] {
        return [
            { id: '0', name: 'New' },
            { id: '1', name: 'Running' },
            { id: '2', name: 'Paused' },
            { id: '3', name: 'Finished' }
        ];
    }

    /**
     * Returns object of filter parameters for settings
     * @param settings Filter settings
     *
     * @returns Object of filter parameters only for settings
     */
    getSavedParams(settings: FilterSettings | null): SimpleDataFilter | null {
        if (!this.savedParams) {
            return null;
        }

        const paramForPage: any = _.clone(this.savedParams);

        // Check keys in settings by the map
        Object.entries(FilterParamsMap).forEach(([key, value]) => {
            // A key of the map is null or false in the settings i.e. the filter is absent
            if (settings != null && !settings[key as keyof FilterSettings]) {
                // If there is no filter, removes the value in saveParams
                value.forEach((param: string, index: number) => {
                    paramForPage[param] = null;
                });
            }
        });

        //  Special params for page by settings
        return paramForPage;
    }

    /**
     * Saves filter parameters in this service
     * @param settings Filter settings
     * @param params Filter parameters. Optional param
     * @param resetParams Array of filter parameters to reset. Optional param
     *
     * @returns void
     */
    setSavedParams(settings: FilterSettings | null = null, params: any = null, resetParams: any = this.resetParams): void {
        if (!settings) {
            this.savedParams = null;
            return;
        }

        if (!params) {
            this.savedParams = null;
            return;
        }

        this.savedParams = this.savedParams != null ? this.savedParams : new SimpleDataFilter();

        // Change params in this service only for page settings
        Object.entries(settings).forEach(([key, value]) => {
            if (settings[key as keyof FilterSettings] && FilterParamsMap[key as keyof FilterParamsMap]) {
                // get param names
                let param: any = FilterParamsMap[key as keyof FilterParamsMap];
                param.forEach((paramName: string) => {
                    // set param value by map key
                    this.savedParams[paramName] = params[paramName] != null ? params[paramName] : null;
                });
            }
        });

        if (!(resetParams || (resetParams && !resetParams.length))) {
            return;
        }

        // Reset non-general parameters if necessary
        for (const i in resetParams) {
            if (resetParams.hasOwnProperty(i)) {
                this.savedParams[resetParams[i].key as keyof SimpleDataFilter] = resetParams[i].value;
            }
        }
    }

    /**
     * Default filter settings
     * @returns Filter settings
     */
    setDefaultSettings(): FilterSettings {
        return new FilterSettings({
            routePage: null,
            filterVisible: false,
            backBtnVisible: false,
            backBtnPlaceholder: null,
            applyBtnVisible: false,
            resetBtnVisible: false,
            filterByGroupBy: false,
            filterByAccount: false,
            filterByAction: false,
            filterByCampaign: false,
            filterByCategory: false,
            filterByCountry: false,
            filterByDomainSource: false,
            filterByMailer: false,
            filterBySource: false,
            filterByIncludeList: false,
            filterByExcludeList: false,
            filterByCampaignStatus: false,
            filterByServerStatus: false,
            filterByAccountStatus: false,
            filterByDate: false,
            filterByGroupTree: false,
            filterBySearch: false
        });
    }

    /**
     * Запрос на получение коллекции
     * @returns {Observable<ItemList<Group>>} объект `Observable` на лист
     */
    getMocks(param?: any): Observable<ItemList<any>> {
        const ctx = this;
        return new Observable((s: Subscriber<ItemList<any>>) => {
            s.next(ctx.mocks());
            s.complete();
        });
    }

    /**
     * Запрос на получение по id
     * @returns {Observable<ItemList<Group>>} объект `Observable`
     */
    getMockById(id?: any): Observable<any> {
        const ctx = this;
        return new Observable((s: Subscriber<any>) => {
            const result = _.find(ctx.mocks().data, (i: any) => i.id === id);

            s.next(result);
            s.complete();
        });
    }

    /**
     * Универсальные моки
     * @returns {ItemList<any>} объект
     */
    private mocks(): ItemList<any> {
        return new ItemList<any>({
            page: 0,
            pageSize: 20,
            total: 3,
            data: new Array<any>(
                { id: '0', name: 'Mock 1', key: 'AU', parentId: null, subGroups: [] },
                { id: '1', name: 'Mock 2', key: 'RUS', parentId: '0', subGroups: [] },
                { id: '2', name: 'Mock 3', key: 'ENG', parentId: '1', subGroups: [] }
            )
        });
    }
}
