import { EntityModel } from 'src/app/shared/models/entity-model';

export class PlatformAccountModel extends EntityModel {
    name: string | null = null;
    isSynchronizationInProgress: boolean | null = null;
    lastSyncedAt: string | null = null;

    constructor(data?: object) {
        super(data);
        this.setData(data);
    }
}
