import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FileService } from 'src/app/shared/services/file.service';

type Timer = any;
@Component({
    selector: 'app-file-uploader-bar',
    templateUrl: './app-file-uploader-bar.component.html',
    styleUrls: ['./app-file-uploader-bar.component.scss']
})
export class AppFileUploaderBarComponent implements OnInit {
    private isCompletedUpload!: boolean;
    private currentProgress: number = 0;
    private timer: Timer;

    public title!: string;
    public subtitle!: string;
    constructor(private dialogRef: MatDialogRef<AppFileUploaderBarComponent>, private fileUploader: FileService) {
        this.fileUploader.uploadComplete.subscribe(res => {
            if (res) {
                this.isCompletedUpload = true;
                this.initIntervalChangingLoadLevel(1);
            }
        });
        this.fileUploader.uploadCompleteWithError.subscribe(res => {
            if (res) {
                clearInterval(this.timer);
                this.onClose();
            }
        });
    }

    ngOnInit() {
        this.initIntervalChangingLoadLevel(30);
    }

    private initIntervalChangingLoadLevel(interval: number) {
        const progressBar = document.getElementById('progressBar82119');
        const ctx = this;
        clearInterval(ctx.timer);
        ctx.timer = setInterval(() => {
            if (progressBar) {
                this.changeLoadLevel(ctx, progressBar);
            }
        }, interval);
    }

    private changeLoadLevel(ctx: this, progressBar: HTMLElement) {
        const fullLoadLevel = 100;
        const firstCheckPoint = 30;
        const secondCheckPoint = 60;
        const lastCheckPoint = 90;
        const spopCheckPoint = 95;

        if (ctx.currentProgress === fullLoadLevel) {
            ctx.onClose(true);
            return;
        }
        ctx.currentProgress = ctx.currentProgress + 1;
        progressBar.style.width = ctx.currentProgress + '%';
        if (this.isCompletedUpload) {
            this.initIntervalChangingLoadLevel(1);
            return;
        }
        if (ctx.currentProgress === firstCheckPoint) {
            this.initIntervalChangingLoadLevel(100);
            return;
        }
        if (ctx.currentProgress === secondCheckPoint) {
            this.initIntervalChangingLoadLevel(500);
            return;
        }
        if (ctx.currentProgress === lastCheckPoint) {
            this.initIntervalChangingLoadLevel(1000);
            return;
        }
        if (ctx.currentProgress === spopCheckPoint) {
            clearInterval(ctx.timer);
            return;
        }
    }

    onClose(complete?: boolean) {
        this.dialogRef.close(complete);
    }
}
