<div class="app-content" fxLayout="row" fxLayoutAlign="start space-between">
    <div class="app-content-wrapper" fxLayout="column" fxLayoutAlign="start stretch" fxFlex="auto" fxLayoutGap="24px">
        <app-header [labelVisible]="false" [backButtonVisible]="false"></app-header>

        <div class="table-container block" fxLayout="column" fxLayoutAlign="center center">
            <div class="email-confirmation-container"
            [class.progress]="isSubscriptionPlanActive"
            fxLayout="column" fxLayoutAlign="center center">
                @if (isSubscriptionPlanNotFound) {
                    <div class="email-confirmation__success-icon" style="line-height: 14px;">
                        <svg-icon key="attention" fontSize="90px" color="#CCC"></svg-icon>
                    </div>

                    <h3 class="email-confirmation__title" i18n="subscription.verification.page.text.is.subscription.not.found.title">Subscription required</h3>
                    <span class="email-confirmation__info" i18n="subscription.verification.page.text.is.your.trial.period.has.expired">Your trial period has expired. But we've saved all your work.</span>

                    <div class="resend-confirmation">
                        <span i18n="subscription.verification.page.text.is.to.continue.your.work">To continue your work, </span>
                        <span class="btn-link" i18n="subscription.verification.page.text.is.buy.now"
                            (click)="onSubscriptionPlanList()">buy a subscription</span>
                    </div>
                }
                @else if (!isSubscriptionPlanActive) {
                    <div class="email-confirmation__success-icon" style="line-height: 14px;">
                        <svg-icon key="attention" fontSize="90px" color="#CCC"></svg-icon>
                    </div>
                    <h3 class="email-confirmation__title" i18n="subscription.verification.page.text.is.your.subscription.has.expired.title">Your subscription has expired</h3>
                    <span class="email-confirmation__info" i18n="subscription.verification.page.text.is.renew.your.subscription">
                        What now? You can renew your subscription or subscribe to a new plan.</span>

                    <div class="resend-confirmation">
                        <span i18n="subscription.verification.page.text.is.to.get.subscription">To get subscription, </span>
                        <span class="btn-link" i18n="subscription.verification.page.text.is.manage.subscriptions"
                            (click)="onManageMySubscription()">manage subscriptions</span>
                    </div>
                }
                @else {
                    <div class="email-confirmation__success-icon" style="line-height: 14px;">
                        <svg-icon key="clock" fontSize="75px" color="#00CC66"></svg-icon>
                    </div>
                    <h3 class="email-confirmation__title" i18n="general.text.is.subscription.verification.in.progress">Subscription verification</h3>
                    <span class="email-confirmation__info" i18n="general.text.is.please.do.not.refresh.or.leave.the.page">Please do not refresh or leave the page while we are processing your request</span>
                }
            </div>
        </div>
    </div>
</div>
