// import { Model } from '../shared/interfaces';
import { Role } from '../shared/types/role';

export class UserProfile {
    id: string | null = null;
    firstName: string | null = null;
    secondName: string | null = null;
    companyName: string | null = null;
    email: string | null = null;
    role: Role | null = null;
    password: string | null = null;
    timestamp: number | null = null;

    constructor(data?: object | UserProfile) {
        if (data) {
            Object.keys(data).forEach((key: string) => {
                if (Object.prototype.hasOwnProperty.call(this, key)) {
                    this[key as keyof UserProfile] = data[key as keyof typeof data];
                }
            });
        }
    }
}
