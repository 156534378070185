import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { AppText } from '../text/app-text';
import { Router } from '@angular/router';
import { routeMap } from '../route-map';
import { getPreviousRouterUrl, resetPreviousRouterUrl } from '../shared/utils/util-functions';
import { ApiEndpoints } from '../api/api-endpoints';
import { ApiService, AuthorizationService } from '../api';
import { takeUntil } from 'rxjs/operators';
import { UserProfile } from '../contracts';
import { MessageService } from '../shared/services/message.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ChangePasswordDialogComponent } from './shared/change-password-dialog/change-password-dialog.component';
import { emailPattern } from '../shared/patterns-config';
import { UserSubscriptionPlan } from '../contracts/user-subscription-plan';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    /**
     * Переменная для подписки, к которой обязательна отписка
     * @type {Subscription}
     */
    protected subscriptions: Subscription[] = [];
    /**
     * Для отписок на запросы
     * @type {Subject<void>}
     */
    protected unsubscribe: Subject<void> = new Subject();
    /**
     * Идентификатор модели
     * @type {string}
     */
    protected userProfileId!: string | null;
    /**
     * Модель
     * @type {string}
     */
    protected userProfile: UserProfile = new UserProfile;
    /**
     * Информация по текущей подписке
     * @type {string}
     */
    protected userSubscriptionPlan: UserSubscriptionPlan = new UserSubscriptionPlan;
    /**
     * Массив для отображения иконки текущего пароля (вместо поля - кружочки)
     * @type {Array<number>}
     */
    currentPasswordIconLength: Array<number> = new Array<number>(8);
    /**
     * Загрузка страницы
     * @type {boolean}
     */
    isLoading: boolean = true;
    /**
     * Фото профиля
     * @type {string}
     */
    profileImgUrl: string = '../../assets/icons/user-account-logo.png';
    /**
     * Форма для редактирования
     * @type {FormGroup}
     */
    detailsForm: FormGroup = new FormGroup({});
    /**
     * Массив для скелетного блока
     * @type {Array<number>}
     */
    skeletonArray: Array<number> = new Array(5);
    /**
     * Флаг об изменениях
     * Передается при закрытии окна
     * @type {boolean}
     */
    isChanged!: boolean;
    /**
     * Блокировка
     * @type {boolean}
     */
    disableForm: boolean = false;

    firstName!: string | null;
    lastName!: string | null;
    /**
     * Почта
     * @type {string}
     */
    userEmail!: string | null;

    companyName!: string | null;

    emailPattern = emailPattern;

    pageText = {
        pageHeaderIsEditAccount: AppText.pageHeaderIsEditAccount,
        applyAction: AppText.applyAction,
        editAction: AppText.editAction,
        propertyPlaceholderFirstName: AppText.propertyPlaceholderFirstName,
        propertyPlaceholderLastName: AppText.propertyPlaceholderLastName,
        propertyPlaceholderUserEmail: AppText.propertyPlaceholderUserEmail,
        propertyPlaceholderUserPassword: AppText.propertyPlaceholderUserPassword,
        propertyPlaceholderUserNewPassword: AppText.propertyPlaceholderUserNewPassword,
        propertyPlaceholderUserReenterPassword: AppText.propertyPlaceholderUserReenterPassword,
        propertyPlaceholderUserPasswordStrength: AppText.propertyPlaceholderUserPasswordStrength,
        propertyPlaceholderInvalidUserEmail: AppText.hintMessageIsInvalidEmail,
        generalTextIsIsRequired: AppText.generalTextIsIsRequired,
        propertyPlaceholderCompanyName: AppText.propertyPlaceholderCompanyName,
        thePasswordIsHiddenForSecurityReason: AppText.generalTextIsThePasswordIsHiddenForSecurityReason,
        articles: AppText.mainMenuItemIsArticles,
        accounts: AppText.entityIsAccounts,
        base: AppText.profilePageTextIsBase,
        trial: AppText.profilePageTextIsTrial,
        subscriptionPlanNotFoundName: AppText.profilePageTextIsYouDoNotHaveSubs,
        from: AppText.generalTextIsFrom.toLowerCase(),
        to: AppText.generalTextIsTo.toLowerCase(),
    };

    get isModel(): boolean {
        return this.userProfileId != null;
    }

    get pageTitle(): string {
        return this.pageText.pageHeaderIsEditAccount;
    }

    get isSubscriptionPlanNotFound(): boolean {
        return this.userSubscriptionPlan && this.userSubscriptionPlan?.name === this.authService.SUBSCRIPTION_PLAN_NOT_FOUND ? true : false;
    }

    get isSubscriptionPlanActive(): boolean {
        return this.userSubscriptionPlan && this.userSubscriptionPlan.isActive ? true : false;
    }

    get isSubscriptionPlanTrial(): boolean {
        return this.userSubscriptionPlan && this.userSubscriptionPlan.trialEndDate ? true : false;
    }

    get subscriptionName(): string {
        if (!this.userSubscriptionPlan || this.isSubscriptionPlanNotFound) {
            return this.pageText.base;
        }
        if (this.userSubscriptionPlan.trialEndDate) {
            return this.pageText.trial;
        }
        return this.userSubscriptionPlan.name || '';
    }

    // тут будет наша страница-лэндинг
    get subscriptionPlansUrl(): string {
        return environment.subscriptionPlansUrl;
    }

    get customerPortalUrl(): string {
        return this.userSubscriptionPlan?.customerPortalUrl || '';
    }

    constructor(
        private router: Router,
        private authService: AuthorizationService,
        private apiService: ApiService,
        private messageService: MessageService,
        private formBuilder: FormBuilder,
        private dialog: MatDialog,
    ) {

    }

    ngOnInit(): void {
        forkJoin([this.getProfile(), this.getActualUserSubscriptionPlan()])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([profile, subscriptionPlan]: any) => {
                this.userProfile = profile;
                this.userProfileId = profile.id;
                this.userSubscriptionPlan = this.authService.getActualUserSubscriptionPlan(subscriptionPlan);
                this.firstName = this.userProfile.firstName;
                this.lastName = this.userProfile.secondName;
                this.userEmail = this.userProfile.email;
                this.companyName = this.userProfile.companyName;
                this.detailsForm = this.createForm();
                this.isLoading = false;
            }, (error: any) => {

            });
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach(s => s.unsubscribe());
        }
    }

    /**
     * Метод создания/обновления
     * @param form Редактируемая форма
     * @param inputUrl Входной урл
     */
    onSave(form: FormGroup, inputUrl: string = ApiEndpoints.profile().listUrl()): void {
        if (!form.valid || this.disableForm || !this.userProfileId) {
            return;
        }
        this.disableForm = true;
        const payload = {
            email: this.userProfile.email || '',
            id: this.userProfileId,
            firstName: form.controls['firstName']?.value || '',
            secondName: form.controls['lastName']?.value || '',
            companyName: form.controls['companyName']?.value || '',
        };
        this.updateProfile(payload)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res: UserProfile) => {
                this.userProfile = res;
                this.firstName = this.userProfile.firstName;
                this.lastName = this.userProfile.secondName;
                this.disableForm = false;
                this.messageService.success(AppText.infoPopupSuccessSaveMessage);
                this.authService.updateProfile(this.userProfile);
            }, (error: any) => {
                this.disableForm = false;
            });
    }

    onSubscriptionPlanList(): void {
        this.subscriptionPlansUrl && window.open(this.subscriptionPlansUrl, '_blank');
    }

    onManageMySubscription(): void {
        this.customerPortalUrl && window.open(this.customerPortalUrl, '_blank');
    }

    onChangePassword(): void {
        let options = new MatDialogConfig();
        options.hasBackdrop = true;
        options.disableClose = true;
        options.autoFocus = false;
        options.restoreFocus = false;
        options.backdropClass = 'tooltip-dialog__backdrop';
        options.data = { userProfileId: this.userProfileId };
        const dialogRef = this.dialog.open(ChangePasswordDialogComponent, options);
        const sub = dialogRef.afterClosed().subscribe((res: boolean) => {
            console.log(res);
            if (res) {
                // this.messageService.success(AppText.infoPopupPasswordChangedMessage);
                this.authService.logout();
            }
        });
        this.subscriptions.push(sub);
    }

    /**
     * Метод возвращения к списку
     * Это для страниц, которые не в диалоговых окнах
     * @returns {void}
     */
    onBack(): void {
        let currentPageName = this.router.url?.split('?')?.[0] || '';
        let previousUrl = getPreviousRouterUrl('/profile', '');
        resetPreviousRouterUrl(currentPageName);
        previousUrl ?
            this.router.navigateByUrl(previousUrl)
            : this.router.navigate([`/${routeMap.articles}?page=0&pageSize=100&orderBy=createdDate&orderDesc=false`]);
    }

    private createForm(): FormGroup {
        let form = this.formBuilder.group({
            firstName: [{ value: this.firstName, disabled: this.disableForm }, [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(30)
            ]],
            lastName: [{ value: this.lastName, disabled: this.disableForm }, [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(30)
            ]],
            userEmail: [{ value: this.userEmail || '', disabled: true }, [
                Validators.required,
                Validators.minLength(5),
                Validators.maxLength(128),
                Validators.pattern(this.emailPattern)
            ]],
            companyName: [{ value: this.companyName || '', disabled: this.disableForm }, [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(50)
            ]]
        });
        return form;
    }

    private getProfile(): Observable<UserProfile> {
        let url = ApiEndpoints.profile().listUrl();
        return this.apiService.get<UserProfile>(url);
    }

    private getActualUserSubscriptionPlan(): Observable<UserSubscriptionPlan | number | null> {
        let url = ApiEndpoints.userSubscriptionPlan().listUrl();
        return this.apiService.get<UserSubscriptionPlan | number | null>(url);
    }

    private updateProfile(payload: { email: string, id: string, firstName: string, secondName: string }): Observable<UserProfile> {
        let url = ApiEndpoints.profile().listUrl();
        return this.apiService.patch<{ email: string, id: string, firstName: string, secondName: string }, UserProfile>(url, payload);
    }
}