import { NgModule } from '@angular/core';

/**
 * Создан отдельный модуль для импорта сервисов - чтобы избежать:
 * 1) Цекличиской зависимости в данном lazy модуле регистрации/авторизации
 * 2) Предотвратит внедрение сервисов этого модуля в другие модули приложения
 * 3) Сервис будет добавлен в бандл, только если он внедрен в компонент или другой сервис,
 * используемый в модуле - это ускорит загрузку приложения
 *
 * Сервисы добавляются сюда непосредственно в самом сервисе
 * @example @Injectable({providedIn: LoginServicesModule})
 */
@NgModule()
export class SignUpServicesModule { }