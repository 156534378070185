import { Model } from "../../shared/interfaces";

export class ArticleGroupSettingsModel implements Model {
  id!: string | number | null;
  groupName!: string;
  confidence!: number;
  minimumRecognitionQuality!: number;
  excluded!: boolean
  synonyms!: string;

  createdDate!: number;
  updatedDate!: number;
  updatedByUserId!: string;
  updatedByUserName!: string;

  constructor(data?: any) {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this[key as keyof ArticleGroupSettingsModel] = <never>data[key];
        }
      }
    }
  }
}
