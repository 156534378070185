import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from 'src/app/api';
import { UserProfile } from 'src/app/contracts';
import { accessMap, routeMap } from 'src/app/route-map';

@Injectable({
    providedIn: 'root'
})
export class EmailVerifyGuard {

    constructor(
        private router: Router,
        private authService: AuthorizationService,
    ) { }

    public isConfirmAccountPage(page: string | null): boolean {
        return page === `/${routeMap.confirmAccount}` || page?.includes(`/${routeMap.confirmAccount}?code=`) ? true : false;
    }

    public isNotFoundPage(page: string | null): boolean {
        return page === `/${routeMap.notFound}` ? true : false;
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const token = AuthorizationService.getToken();
        const temporaryToken = AuthorizationService.getTemporaryTokenOfNotEmailVerifiedUser();
        if (token != null) {
            if (this.isConfirmAccountPage(state.url)) {
                this.router.navigate([routeMap.notFound]);
                return false;
            }
            return true;
        }
        if (temporaryToken != null) {
            if (!this.isConfirmAccountPage(state.url)) {
                this.router.navigate([routeMap.confirmAccount]);
                return false;
            }
            return true;
        }
        this.router.navigate([`/${routeMap?.loading}`]);
        return false;
    }
}
