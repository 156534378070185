import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

export class AppPreloadingStrategy implements PreloadingStrategy {
    /** Загрузка второстепенных модулей через 1.5 сек для ускорения загрузки приложения */
    preload(route: Route, loadModules: () => Observable<any>): Observable<any> {
        // return Observable.of(true).delay(1500).do(_ => loadModules());
        return of(true).pipe(
            delay(1500),
            tap(_ => loadModules())
        );
    }
}
