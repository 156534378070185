export class ErrorResponse {
    errorMessage!: string;
    errorDetails!: any;
    code!: number;
    status!: number;
    message!: string;
    title?: string;

    constructor(data?: any) {
        if (data) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    this[key as keyof ErrorResponse] = <never>data[key];
                }
            }
        }
    }
}

export class ErrorResp {
    errors!: Array<any>;
    status!: number;
    title?: string;
    traceId!: string;
    type?: string;
}
