import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiEndpoints, ApiService, AuthorizationService, ErrorResponse } from '../api';
import { Observable, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { PlatformAccountModel } from '../platform-accounts/shared/platform-account.model';
import { routeMap } from '../route-map';
import { LocalStorage } from '../api/local-storage-new';
import { LocalStorageKeys } from '../api/local-storage-keys';
import { environment } from 'src/environments/environment';
import { UserSubscriptionPlan } from '../contracts/user-subscription-plan';
import { defaultRoute } from 'src/app/route-map';

@Component({
    selector: 'app-subscription-plan-verification',
    templateUrl: './subscription-plan-verification.component.html',
    styleUrls: ['./subscription-plan-verification.component.scss',
        '../email-verification/email-verification.component.scss']
})
export class SubscriptionPlanVerificationComponent implements OnInit, OnDestroy {
    private _routeSub: Subscription = new Subscription();
    private _unsubscribe: Subject<void> = new Subject<void>();

    // тут будет наша страница-лэндинг
    get subscriptionPlansUrl(): string {
        return environment.subscriptionPlansUrl;
    }

    get customerPortalUrl(): string {
        return this.authService?.userSubscriptionPlan?.customerPortalUrl || '';
    }

    get isSubscriptionPlanNotFound(): boolean {
        return this.authService.isSubscriptionPlanNotFound;
    }

    get isSubscriptionPlanActive(): boolean {
        return this.authService.isSubscriptionPlanActive;
    }

    constructor(
        private authService: AuthorizationService,
        private router: Router,
        // private route: ActivatedRoute,
        private apiService: ApiService,
    ) {
    }

    ngOnInit(): void {
        this.updateUserSubscriptionPlan();
    }

    ngOnDestroy(): void {
        if (this._routeSub) {
            this._routeSub.unsubscribe();
        }
        if (this._unsubscribe) {
            this._unsubscribe.complete();
        }
    }

    onSubscriptionPlanList(): void {
        this.subscriptionPlansUrl && window.open(this.subscriptionPlansUrl, '_blank');
    }

    onManageMySubscription(): void {
        this.customerPortalUrl && window.open(this.customerPortalUrl, '_blank');
    }

    private updateUserSubscriptionPlan(): void {
        this.userSubscriptionPlan()
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((res: UserSubscriptionPlan) => {
                this.authService.userSubscriptionPlan = res;
                if (this.authService.isSubscriptionPlanActive) {
                    this.router.navigate([`/${defaultRoute}`]);
                }
            }, (error: any) => {
                console.log(error);
            });
    }

    private userSubscriptionPlan(): Observable<UserSubscriptionPlan> {
        const url = ApiEndpoints.userSubscriptionPlan().listUrl();
        return this.apiService.get<UserSubscriptionPlan>(url);
    }
}
