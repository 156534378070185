<div class="app-button-toggle-filter" fxLayout="column">
    <div *ngIf="enableMinimizedMode" class="app-button-toggle-filter__header" fxLayout="row" fxLayoutAlign="space-between center"
        [class.active]="value || value != ''" [class.open]="!isFilterHide" (click)="isFilterHide = !isFilterHide">

        <span>Articles states</span>
        <div matSuffix class="app-button-toggle-filter__header__icon-arrow"></div>
    </div>
    <div class="app-button-toggle-filter__container" [class.animate]="enableMinimizedMode" [class.hide]="enableMinimizedMode && isFilterHide">
        <mat-button-toggle-group class="app-button-toggle-filter__container__body" fxLayout="row"
            fxLayoutAlign="space-between center" [(ngModel)]="value" (change)="onToggle($event)">
            <mat-button-toggle *ngFor="let item of items" [value]="item.id">{{item.name}}</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>
