import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfile } from '../contracts';
import { routeMap } from '../route-map';
import { AppText } from '../text/app-text';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationMockService {
    private static tokenKey = 'api-token';

    private _user: UserProfile;

    private _userProfile: UserProfile = new UserProfile({
        id: 'userId',
        displayName: 'C. Howell',
        email: 'user@insys.io',
        password: '19WmoiAx4zUOAIzR32wi',
        role: 'admin'
    });

    private _initialized!: boolean;
    private _rememberMe: boolean = true;

    get authorizationRequired() {
        return this.requestAuthorization;
    }

    get userProfile() {
        return this.requestAuthorization ? this._user : null;
    }

    get initialized() {
        return this._initialized;
    }

    private requestAuthorization: boolean;

    constructor(private router: Router) {
        this.requestAuthorization = false;
        this._user = this._userProfile;
    }

    setRememberMe(checked: boolean) {
        this._rememberMe = checked;
    }

    setAuthorizationRequired() {
        this.requestAuthorization = true;
        this.router.navigate([`/${routeMap.signIn}`]);
    }

    appInit(): Observable<boolean> {
        const ctx = this;

        return new Observable<boolean>((observable: any) => {
            ctx.fillUserProfile().subscribe(
                () => {
                    ctx.requestAuthorization = false;
                    ctx._initialized = true;
                    observable.next(true);
                    observable.complete();
                },
                error => {
                    ctx.requestAuthorization = true;
                    ctx._initialized = true;
                    observable.next(false);
                    ctx.logout();
                    observable.complete();
                }
            );
        });
    }

    authorize(username: string, password: string): Observable<void> {
        const ctx = this;
        return new Observable<void>((observable: any) => {
            // if (username === this.userProfile?.email && password === this.userProfile?.password) {
            ctx.fillUserProfile().subscribe(
                null,
                error => {
                    this.requestAuthorization = true;
                    observable.error(null);
                    observable.complete();
                },
                () => {
                    this.requestAuthorization = false;
                    observable.next();
                    observable.complete();
                }
            );
            observable.next();
            observable.complete();
            return;
            // } else {
            //   this.requestAuthorization = false;
            //   observable.error(AppText.requestFailedWrongLoginOrPassword);
            //   observable.complete();
            // }
        });
    }

    fillUserProfile(): Observable<void> {
        const ctx = this;
        return new Observable<void>((observable: any) => {
            ctx._user = ctx._userProfile;
            observable.next();
            observable.complete();
            return;
        });
    }

    logout() {
        this.setAuthorizationRequired();
    }
}
