<div class="found-items-list {{customStyleClassForList}}" [class.overlay-list]="overlayStyleEnable">
    <div class="found-items-list__panel">
        <!-- Пустой список -->
        <div *ngIf="!isArrayValid(items)" class="found-items-list__not-found found-items-list__item disabled">
            <span class="found-items-list__title ellipsis">{{generalPageText.notFoundText}}</span>
        </div>

        <!-- Список в обортке cdk-virtual-scroll-viewport - position: relative -->
        <!-- Без обертки не работает на iphone 12 pro -->
        <ng-scrollbar *ngIf="isArrayValid(items)" class="virtual-scroll">
            <cdk-virtual-scroll-viewport scrollViewport appendOnly [itemSize]="itemSizeInPixels"
                style="width:100%;height:100%;">

                <!-- matTooltip - Блокирует скролл на iphone 12 pro -->
                <div *cdkVirtualFor="let item of items; let index = index;"
                    id="item_{{index}}_{{item?.id}}" class="found-items-list__item"
                    [tabindex]="index"
                    [class.found-items-list__item--selected]="isItemSelected(item)"
                    [class.found-items-list__item--focused]="isItemFocused(item)"
                    (click)="onSelectItem($event, item)"
                    (focus)="onFocusItem($event)">

                    <div class="found-items-list__text-wrapper ellipsis">
                        <span class="found-items-list__title ellipsis">{{item?.title || item?.id}}</span>
                        <span *ngIf="item?.subTitle" class="found-items-list__subtitle ellipsis">{{item?.subTitle || ''}}</span>
                    </div>

                    <div *ngIf="iconsEnable" class="found-items-list__icons-wrapper">
                        <div *ngIf="addIconEnable && !item?.itemIncludedInAllSelectedVariables" class="found-items-list__icon add-tag-icon" (click)="onAddItem($event, item)">
                            <svg-icon key="add-circle" fontSize="16px"></svg-icon>
                        </div>
                        <div *ngIf="(addIconEnable && item?.itemIncludedInAllSelectedVariables) || (applyIconEnable && item?.itemIncludedInAllSelectedVariables)" class="found-items-list__icon apply-icon">
                            <svg-icon key="apply-icon" fontSize="16px"></svg-icon>
                        </div>
                        <div *ngIf="removeIconEnable && !item?.notExistInDatabase" class="found-items-list__ico trash-icon" (click)="onRemoveItem($event, item)">
                            <svg-icon key="trash" fontSize="16px"></svg-icon>
                        </div>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </ng-scrollbar>
    </div>
</div>