export const environment = {
    production: false,
    apiUrl: 'https://repricer.api.devets.prixoft.de',
    webSite: 'https://repricer.devets.prixoft.de',
    clientDashboardId: 'https://dashboard.crwautoteile.de',
    realTimeUrl: '',
    clientId: 'tNB7hxTxzzHiBQQK3iPo',
    clientSecret: 'y9sNZ55wXpeDxco3MfiE',
    subscriptionPlansUrl: 'https://dashboard.stripe.com/login',
};
