import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'app-filter-button',
    templateUrl: './app-filter-button.component.html',
    styleUrls: ['./app-filter-button.component.scss']
})
export class AppFilterButtonComponent {
    /**
     * Активное состояние кнопки(для открытия панели например)
     */
    @Input() buttonStateActive!: boolean;

    /**
     * Флаг для заблокированного состояния
     */
    @Input() disabled!: boolean;

    /**
     * Флаг для отображения левой границы кнопки фильтров
     */
    @Input() displayLeftBorder: boolean = true;

    /**
     * Флаг для отображения правой границы кнопки фильтров
     */
    @Input() displayRightBorder: boolean = true;

    /**
     * Событие о том, что состояние кнопки поменялось
     * @type {EventEmitter<boolean>}
     */
    @Output() buttonStateChanged!: EventEmitter<boolean>;

    constructor() {
        this.buttonStateActive = false;
        this.disabled = false;
        this.buttonStateChanged = new EventEmitter<boolean>();
    }

    /**
     * Клип по кнопке - изменяет ее состояние(цвет)
     * Кнопка становится активной или наоборот
     */
    public onButtonStateChange(): void {
        if (this.disabled) {
            return;
        }
        this.buttonStateActive = !this.buttonStateActive;
        this.buttonStateChanged.next(this.buttonStateActive);
    }
}