import { Injectable, ViewContainerRef, Injector } from '@angular/core';
import { AppPaginatorOverlayComponent, PAGINATOR_DATA } from './app-paginator-overlay/app-paginator-overlay.component';
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';




@Injectable({
    providedIn: 'root'
})
export class AppPaginatorService {
    /**
     * Экземпляр объекта для создания портейла с
     * шаблоном  AppPaginatorOverlayComponent
     * @type {componentPortal}
     */
    private componentPortal!: ComponentPortal<AppPaginatorOverlayComponent>;
    /**
     * Объект для overlay
     * @type {OverlayRef}
     */
    overlayRef!: OverlayRef;

    constructor(
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        private injector: Injector
    ) { }

    /**
     * Создание
     * @param {any} data Данные для overlay
     * @param {any} overlayConfig Конфиги для overlay
     * 
     * @returns {void}
     */
    public createOverlay(data: any, overlayConfig: OverlayConfig): void {
        this.overlayRef = this.overlay.create(new OverlayConfig(overlayConfig));
        this.componentPortal = new ComponentPortal(
            AppPaginatorOverlayComponent,
            this.viewContainerRef,
            this.createInjector({ data })
        );
    }

    /**
     * Открытие overlay
     * @returns {void}
     */
    public openPageSizePanel(): void {
        this.overlayRef.attach(this.componentPortal);
    }

    /**
     * Закрытие overlay
     * @returns {void}
     */
    public closePageSizePanel(): void {
        if (!this.overlayRef || !this.overlayRef.hasAttached()) {
            // this.overlayRef = null;
            return;
        }
        this.overlayRef.detach();
        // this.overlayRef = null;
    }

    /**
     * Создание инжектора с данными для отображения
     * @param {any} data Данные для передачи
     */
    public createInjector(data: any): PortalInjector {
        const injectorTokens = new WeakMap();
        injectorTokens.set(PAGINATOR_DATA, data);
        return new PortalInjector(this.injector, injectorTokens);
    }
}
