import { AppText } from "src/app/text/app-text";
import { UserActionPopupData } from "./user-action-popup.service";

/*
 *  Конфигурации попапов для UserActionPopupService 
 */
const FLAT_BUTTON = 'flat';
const STROKED_BUTTON = 'stroked';
export function defaultError(userMessage: string): UserActionPopupData {
    return {
        type: 'error',
        popupHeader: AppText.infoPopupErrorTitle,
        messageHeader: null,
        message: userMessage || '',
        messageHint: null,
        buttons: [
            {
                type: FLAT_BUTTON,
                text: AppText.okAction,
                icon: 'popup-applay-icon',
                emittingValue: true,
            }
        ]
    };
}

export function resetChanges(): UserActionPopupData {
    return {
        type: 'warn',
        popupHeader: AppText.infoPopupResetChangesTitle,
        messageHeader: null,
        message: AppText.infoPopupResetChangesTitle,
        messageHint: null,
        buttons: [
            {
                type: FLAT_BUTTON,
                text: AppText.resetAction,
                icon: 'popup-applay-icon',
                emittingValue: true
            },
            {
                type: STROKED_BUTTON,
                text: AppText.cancelAction,
                icon: 'popup-cancel-icon',
                emittingValue: false
            },
        ]
    }
}

export function success(userMessage: string): UserActionPopupData {
    return {
        type: 'success',
        popupHeader: AppText.infoPopupSuccessTitle,
        messageHeader: null,
        message: userMessage,
        messageHint: null,
        buttons: [
            {
                type: FLAT_BUTTON,
                text: AppText.okAction,
                icon: 'popup-applay-icon',
                emittingValue: 'ok',
            },
        ]
    }
}


export function warning(userMessage: string): UserActionPopupData {
    return {
        type: 'warn',
        popupHeader: 'Attention',
        messageHeader: null,
        message: userMessage,
        messageHint: null,
        buttons: [
            {
                type: FLAT_BUTTON,
                text: AppText.okAction,
                icon: 'popup-applay-icon',
                emittingValue: 'ok',
            },
        ]
    }
}

export function deletion(subject: string): UserActionPopupData {
    return {
        type: 'warn',
        popupHeader: 'Confirmation',
        messageHeader: null,
        message: AppText.getDeleteWarnMessage(subject),
        messageHint: null,
        buttons: [
            {
                type: FLAT_BUTTON,
                text: AppText.deleteAction,
                icon: 'popup-applay-icon',
                emittingValue: true
            },
            {
                type: STROKED_BUTTON,
                text: AppText.cancelAction,
                icon: 'popup-cancel-icon',
                emittingValue: false
            },
        ]
    };
}

export function confirmation(userMessage: string, continuePlaceholder?: string, cancelPlaceholder?: string): UserActionPopupData {
    return {
        type: 'warn',
        popupHeader: 'Confirmation',
        messageHeader: null,
        message: userMessage,
        messageHint: null,
        buttons: [
            {
                type: FLAT_BUTTON,
                text: continuePlaceholder || AppText.yesAction,
                icon: 'popup-applay-icon',
                emittingValue: true
            },
            {
                type: STROKED_BUTTON,
                text: cancelPlaceholder || AppText.noAction,
                icon: 'popup-cancel-icon',
                emittingValue: false
            },
        ]
    };
}

export function cancellation(subject: string): UserActionPopupData {
    return {
        type: 'warn',
        popupHeader: 'Cancellation',
        messageHeader: null,
        message: AppText.getDeleteWarnMessage(subject),
        messageHint: null,
        buttons: [
            {
                type: FLAT_BUTTON,
                text: AppText.okAction,
                icon: 'popup-applay-icon',
                emittingValue: true
            },
            {
                type: STROKED_BUTTON,
                text: AppText.cancelAction,
                icon: 'popup-cancel-icon',
                emittingValue: false
            },
        ]
    };
}