import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BaseApiService } from './base-api.service';
import { ApiService } from './abstracts/api.service';
import { CompApiService } from './comp-api.service';
import { AuthorizationService } from './authorization.service';
import { InMemoryApiService } from './in-memory-api.service';
import { AuthorizationMockService } from './authorization-mock.service';
import { DataMock } from './data-mock';

@NgModule({
    declarations: [],
    imports: [CommonModule, HttpClientModule, MatSnackBarModule],
    providers: [
        DataMock,
        // { provide: BaseApiService, useClass: InMemoryApiService },
        BaseApiService,
        { provide: ApiService, useClass: CompApiService },
        CompApiService,
        // { provide: AuthorizationService, useClass: AuthorizationMockService },
        AuthorizationService
    ]
})
export class ApiModule { }
