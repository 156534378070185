<button class="button" [matMenuTriggerFor]="menu" [class.button__disabled]="isEmailNotVerified">
    <div style="line-height: 14px;">
        <svg-icon key="list" fontSize="14"></svg-icon>
    </div>
    <mat-menu class="menu-container" [overlapTrigger]="true" #menu="matMenu">
        <div class="menu" fxLayout="row">
            <div class="menu__left">
                <svg-icon class="fill-text" key="list"></svg-icon>
            </div>
            <div class="menu__right" fxLayout="column" fxLayoutGap="40">
                <div fxLayout="column" fxFlex="100">
                    @if (!isEmailNotVerified) {
                        <a *ngFor="let item of menuItems" [class.disabled]="item.name == 'dashboard'"
                            routerLink="{{ item.routerLink }}" routerLinkActive="active" (click)="onClearRouterHistory()">
                            <div class="menu__item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8">
                                <div style="width: 16px; height: 16px; line-height: 14px;"
                                    [innerHTML]='item.icon | safeHtml'></div>
                                <span class="text">{{ item.title }}</span>
                            </div>
                        </a>
                        <a *ngFor="let item of docItems" href="{{ item.routerLink }}" target="_blank">
                            <div class="menu__item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8">
                                <div style="width: 16px; height: 16px; line-height: 14px;"
                                    [innerHTML]='item.icon | safeHtml'></div>
                                <span class="text">{{ item.title }}</span>
                            </div>
                        </a>
                    }
                </div>
                <div fxLayout="column">
                    @if (!isEmailNotVerified) {
                        <div class="menu__item" fxLayout="row" fxLayoutAlign="start center"
                        fxLayoutGap="8" (click)="onProfile()">
                            <svg-icon key="profile"></svg-icon>
                            <span class="text">{{ userName }}</span>
                        </div>
                    }
                    @else {
                        <div class="menu__item not-click" fxLayout="row" fxLayoutAlign="start center">
                            <span class="text" i18n="@@general.text.is.user">User not verified</span>
                        </div>
                    }
                    <div class="menu__item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8"
                        (click)="onLogout()">
                        <svg-icon fontSize="16px"></svg-icon>
                        <span class="logout" i18n="@@logout.button.name">Logout</span>
                    </div>
                </div>
            </div>
        </div>
    </mat-menu>
</button>