import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'split'
})
export class SplitNumberPipe implements PipeTransform {
  transform(value: number | string, args?: any): string {
    if (!value || value === '' || value === '0') {
      return '0';
    }
    let intFloat = value.toString().split('.');
    let str = intFloat[0];
    let float = intFloat.length > 1 ? intFloat[1] : '';

    if (str.length > 3) {
      let splitedValue = '';

      let residue = str.length % 3;
      if (residue !== 0) {
        splitedValue = str.slice(0, residue);
        str = str.slice(residue, str.length);
      }

      let iterations = Math.trunc(str.length / 3);
      for(let i = 0; i < iterations; i++) {
        splitedValue += ' ' + str.slice(0, 3);
        str = str.slice(3, str.length);
      }

      return float ? `${splitedValue}.${float}` : splitedValue;
    } else {
      return float ? `${str}.${float}` : str;
    }
  }
}
