import { Pipe, PipeTransform } from '@angular/core';

/**
 * puts ellipsis after specified length of a string
 */
@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {

  transform(value: string, length: number): string {
    if (value && value.length > length) {
        return value.slice(0, length) + '...';
    }
    return value;
  }
}
