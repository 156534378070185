import { Injectable } from '@angular/core';
import { SignUpServicesModule } from './sign-up-services.module';
import { ApiEndpoints, ApiService, AuthorizationService, BaseApiService } from 'src/app/api';
import { Router } from '@angular/router';
import { Observable, Subject, Subscriber, throwError } from 'rxjs';
import { routeMap } from '../route-map';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MessageService } from '../shared/services';
import { catchError, takeUntil } from 'rxjs/operators';
import { _handleError } from '../shared/utils/util-functions';
import { UserProfile } from '../contracts';

@Injectable({
    providedIn: SignUpServicesModule
})
export class SignUpService {
    /**
     * Для прервывания запроса
     */
    private _cancelRequest: Subject<void> = new Subject<void>();

    authorizationInProgress = false;

    get errorMessage(): string | null {
        return this.authService.errorMessage;
    }

    constructor(
        private authService: AuthorizationService,
        private apiService: ApiService,
        private router: Router,
        private messageService: MessageService,
        private baseApiService: BaseApiService,
        private http: HttpClient,
    ) {
        // TODO
    }

    public cancelRequest(): void {
        if (this._cancelRequest) {
            this._cancelRequest.next();
            this._cancelRequest.complete();
        }
    }

    public signup(data: any): Observable<UserProfile> {
        const url = ApiEndpoints.profile().listUrl();
        // return this.apiService.post<any, any>(url, data);
        let options = AuthorizationService.createOptions();
        this.baseApiService.loadInProgress = true;
        let ctx = this;
        return new Observable<UserProfile>((sub: Subscriber<UserProfile>) => {
            ctx.http
                .post(url, data, options)
                .pipe(
                    takeUntil(ctx._cancelRequest),
                    catchError((error: any) => throwError(error))
                )
                .subscribe(
                    (res: any) => {
                        console.log(res);
                        ctx.baseApiService.loadInProgress = false;
                        sub.next(res);
                        sub.complete();
                    },
                    (error: HttpErrorResponse) => {
                        ctx.baseApiService.loadInProgress = false;
                        sub.error(_handleError(error));
                        sub.complete();
                    }
                );
        });
    }

    public login(login: string, password: string) {
        this.authorizationInProgress = true;
        // this.authService.setRememberMe(this.checked);
        this.authService.setRememberMe(true);
        this.authService.authorize(login, password)
            .subscribe(() => {
                // никогда сюда не попадет
            }, (error: any) => {
                if (error?.error_description === this.authService?.EMAIL_NOT_VERIFIED) {
                    this.router.navigate([`/${routeMap.confirmAccount}`]).then(() => {
                        this.authorizationInProgress = false;
                    });
                }
                this.authorizationInProgress = false;
            });
    }
}