import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SignUpRoutingModule } from './sign-up-routing.module';
import { SignUpComponent } from './sign-up.component';
import { SignUpServicesModule } from './sign-up-services.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material/material.module';
import { SvgIconComponent } from '@ngneat/svg-icon';



@NgModule({
    declarations: [
        SignUpComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        SignUpRoutingModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        SignUpServicesModule,
        SvgIconComponent
    ]
})
export class SignUpModule { }