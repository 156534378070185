import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, ViewChild, ElementRef } from "@angular/core";
import { FilterSelectedField } from "./filter-selected-field";
import { AppText } from "src/app/text/app-text";

@Component({
    selector: 'app-filter-selected-fields',
    templateUrl: './app-filter-selected-fields.component.html',
    styleUrls: ['./app-filter-selected-fields.component.scss']
})
export class AppFilterSelectedFieldsComponent implements OnChanges {
    /**
     * Все выбранные поля
     */
    @Input() selectedFields!: Array<FilterSelectedField>;

    /**
     * Флаг блокировки
     */
    @Input() disabled: boolean = false;

    pageText = {
        noFilters: AppText.generalTextIsNoFilters
    }

    /**
     * Событие о том, кликнули на крестик у тега
     * (чтобы удалить конкретный тег)
     * @type {EventEmitter<number>}
     */
    @Output() fieldClicked!: EventEmitter<number>;

    /**
     * Событие о том, что кликнули на кнопку сброса всех тегов
     * (крестик рядом с счетчиком)
     * @type {EventEmitter<void>}
     */
    @Output() resetButtonClicked!: EventEmitter<void>;

    constructor() {
        this.selectedFields = [];
        this.fieldClicked = new EventEmitter<number>();
        this.resetButtonClicked = new EventEmitter<void>();
    }

    @ViewChild('selectedFieldsContainer', { static: false }) selectedFieldsContainer?: ElementRef;

    /**
     * Отслеживаются изменения при поступлении
     * @param {SimpleChange} changes Изменения входных полей
     */
    public ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
        // console.log(changes);
    }

    /**
     * Сброс конкретного тега по клику на крестик
     * (крестик в самом теге находится)
     * @param {number} index Индекс выбранного элемента
     */
    public onResetSelectedField(index: number): void {
        this.fieldClicked.next(index);
    }

    /**
     * Сброс всех тегов - кликнули на кнопку сброса всех тегов
     * (крестик рядом с счетчиком)
     */
    public onResetAllSelectedFields(): void {
        this.resetButtonClicked.next();
    }

    /**
     * Горизонтальный скролл выбранных фильтров
     */
    public onScroll(event: any) {
        if (this.selectedFieldsContainer && this.selectedFieldsContainer.nativeElement) {
            let modifier;
            if (event.deltaMode == event.DOM_DELTA_PIXEL) {
                modifier = 1;
                // иные режимы возможны в Firefox
            } else if (event.deltaMode == event.DOM_DELTA_LINE) {
                modifier = parseInt(getComputedStyle(this.selectedFieldsContainer.nativeElement).lineHeight);
            } else if (event.deltaMode == event.DOM_DELTA_PAGE) {
                modifier = this.selectedFieldsContainer.nativeElement.clientHeight;
            }
            if (event.deltaY != 0) {
                // замена вертикальной прокрутки горизонтальной
                this.selectedFieldsContainer.nativeElement.scrollLeft += modifier * event.deltaY;
                event.preventDefault();
            }
        }
    }
}