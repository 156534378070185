import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ArticleGroupAnalysisModel } from '../article-groups/shared/artcile-group-analysis.model';
import { ArticleGroupSettingsModel } from '../article-groups/shared/artcile-group-settings.model';
import { ArticleGroupArticleStatus } from '../article-groups/shared/article-group-article-status';
import { ArticleGroupCompetitorStatus } from '../article-groups/shared/article-group-competitor-status';
import { ArticleGroupPotentialCompetitorStatus } from '../article-groups/shared/article-group-potential-competitor-status';
import { ArticleGroupModel } from '../article-groups/shared/article-group.model';
import { CompetitorSearchRequestModel } from '../competitors/shared/competitor-search-request-model';
import { CompetitorModel, RulesConfiguration, User, ArticleModel } from '../contracts';
import { Matching } from '../contracts/matching';
import { MatchingAction } from '../rules/shared/matching-action';
import { MatchingMode } from '../rules/shared/matching-mode';
import { RuleModel } from '../rules/shared/rule.model';
import { ItemList } from '../shared/contracts';
import { Model } from '../shared/interfaces';
import { DataType } from './types';
import { SearchRequestTemplateModel } from '../search-request-templates/shared/search-request-template.model';
import { TagAssignmentRuleModel } from '../tag-assignment-rules/shared/tag-assignment-rule-model';
import { StrategyModel } from '../strategies/shared/strategy.model';
import { ArticleHistoryModel } from '../articles/shared/article-history.model';
import { PlatformAccountModel } from '../platform-accounts/shared/platform-account.model';

@Injectable({
    providedIn: 'root'
})
export class DataMock {
    csvOriginals: ArticleModel[] = [];
    csvAngebots: CompetitorModel[] = [];
    matchingResults: Matching[] = [];
    competitorOriginalPair: { original: ArticleModel; competitor: CompetitorModel }[] = [];

    constructor(private httpClient: HttpClient) {
        // this.httpClient.get<any>("assets/competitors.csv", { responseType: 'text' as 'json' }).subscribe((data) => {
        //   let csvToRowArray = data.split("\n");

        //   let headers = csvToRowArray[0].split(";");
        //   let attriduteNames = [];
        //   for (let index = 33; index < 50; index++) {
        //     attriduteNames.push({index: index, name: headers[index]});
        //   }

        //   for (let index = 1; index < csvToRowArray.length - 1; index++) {
        //     let row = csvToRowArray[index].split(";");

        //     let price = row[17]?.replace(',','.');
        //     let deliveryPrice = row[18]?.replace(',','.');
        //     let totalPrice = parseFloat(price) + parseFloat(deliveryPrice);
        //     let article = new Competitor({
        //       id: index,
        //       productId: row[29],
        //       listerId: row[28],
        //       originalArticleLink: row[27],
        //       competitivePrice: 0,
        //       ebayId: row[0],
        //       title: row[1],
        //       articleLink: row[2],
        //       image: row[26],
        //       matching: new Matching({
        //         id: '1',
        //         isCompetitor: this.parseBoolean(row[3]),
        //         confidence: parseFloat(row[4] ? row[4]?.replace(',','.') : 0),
        //         matchingResults: [
        //           new MatchingResult({
        //             name: 'EAN Match',
        //             match: this.parseBoolean(row[6]),
        //             isDecisive: true
        //           }),
        //           new MatchingResult({
        //             name: 'Is Set',
        //             match: this.parseBoolean(row[7]),
        //             isDecisive: false
        //           }),
        //           new MatchingResult({
        //             name: 'Same Manufacturer',
        //             match: this.parseBoolean(row[8]),
        //             isDecisive: true
        //           }),
        //           new MatchingResult({
        //             name: 'Filter Design',
        //             match: this.parseBoolean(row[9]),
        //             isDecisive: false
        //           }),
        //           new MatchingResult({
        //             name: 'OEM Match',
        //             match: this.parseBoolean(row[10]),
        //             isDecisive: true
        //           }),
        //           new MatchingResult({
        //             name: 'MPN Match',
        //             match: this.parseBoolean(row[11]),
        //             isDecisive: false
        //           }),
        //           new MatchingResult({
        //             name: 'Is Set Multiplier',
        //             match: this.parseBoolean(row[12]),
        //             isDecisive: false
        //           })
        //         ]
        //       }),
        //       seller: row[10],
        //       price: parseFloat(price),
        //       deliveryPrice: parseFloat(deliveryPrice),
        //       totalPrice: totalPrice,
        //       inStock: row[19],
        //       sold: parseInt(row[20]),
        //       advertising: this.parseBoolean(row[23]),
        //       topSeller: this.parseBoolean(row[24]),
        //       variant: this.parseBoolean(row[25]),
        //       active: this.parseBoolean(row[22]),
        //       account: row[30],
        //       searchQuery: row[32],
        //       searchQueryUrl: row[31],
        //       attributes: [],
        //       createdDate: 198342353,
        //       updatedDate: 198342375,
        //       updatedByUserId: '1',
        //       updatedByUserName: 'rmedvedev',
        //     });

        //     let decisiveMatch = article.matching.matchingResults.filter(res => res.isDecisive && res.match);
        //     let decisiveNotMatch = article.matching.matchingResults.filter(res => res.isDecisive && !res.match);
        //     let notDecisiveMatch = article.matching.matchingResults.filter(res => !res.isDecisive && res.match);
        //     let notDecisiveNotMatch = article.matching.matchingResults.filter(res => !res.isDecisive && !res.match);

        //     article.matching.matchingResults = [
        //       ...decisiveMatch,
        //       ...decisiveNotMatch,
        //       ...notDecisiveMatch,
        //       ...notDecisiveNotMatch
        //     ];

        //     attriduteNames.forEach(item => {
        //       article.attributes.push(new Attribute({
        //         name: item.name,
        //         value: row[item.index]
        //       }));
        //     });

        //     this.csvAngebots.push(article);
        //   }

        //   this.httpClient.get<any>("assets/originals.csv", { responseType: 'text' as 'json' }).subscribe((data) => {
        //     let csvToRowArray = data.split("\n");
        //     for (let index = 1; index < csvToRowArray.length - 1; index++) {
        //       let row = csvToRowArray[index].split(";");

        //       let price = row[5]?.replace(',','.');

        //       let article = new Article({
        //         id: index,
        //         productId: row[1],
        //         listerId: row[0],
        //         title: row[2],
        //         articleLink: row[3],
        //         image: row[8],
        //         price: price,
        //         attributes: [],
        //         active: true,
        //         excluded: false,
        //         createdDate: 198342353,
        //         updatedDate: 198342375,
        //       });

        //       let attributesSplit = row[13].split('|');
        //       attributesSplit.forEach((value: string, index: number) => {
        //         if (index % 2 === 0) {
        //           article.attributes.push(new Attribute({
        //             name: value,
        //             value: attributesSplit[index + 1]
        //           }));
        //         }
        //       });

        //       let competitors = this.csvAngebots.filter(item => item.listerId === article.listerId);
        //       if (competitors && competitors.length > 0) {
        //         article.totalCompetitors = competitors.length;

        //         let competitorPrices: number[] = [];
        //         article.totalCompetitorsSold = 0;
        //         competitors.forEach(competitor => {
        //           competitorPrices.push(competitor.totalPrice);

        //           article.totalCompetitorsSold += competitor.sold ? competitor.sold : 0;
        //           competitor.originalImage = article ? article.image : '';
        //         });
        //         article.maxCompetitorPrice = Math.max(...competitorPrices);
        //         article.minCompetitorPrice = Math.min(...competitorPrices);
        //       }

        //       this.csvOriginals.push(article);
        //     }

        //     this.data['/competitors'] = new ItemList<Model>({
        //       page: 0,
        //       pageSize: 25,
        //       total: 3,
        //       uniqueTotal: 0,
        //       data: this.csvAngebots
        //     });

        //     this.data['/articles'] = new ItemList<Model>({
        //       page: 0,
        //       pageSize: 25,
        //       total: 3,
        //       uniqueTotal: 0,
        //       data: this.csvOriginals
        //     });

        //     this.data['/matchingResults'] = new ItemList<Model>({
        //       page: 0,
        //       pageSize: 25,
        //       total: 3,
        //       uniqueTotal: 0,
        //       data: this.csvAngebots
        //     });

        //     let competitors = this.csvAngebots.slice(1000, 1100);
        //     this.competitorOriginalPair = competitors.map(competitor => {
        //       let original = this.csvOriginals.find(item => item.listerId === competitor.listerId);
        //       return new CompetitorOriginalPair({
        //         id: '1',
        //         original: original,
        //         competitor: competitor
        //       });
        //     });

        //     this.data['/validateCompetitors'] = new ItemList<Model>({
        //       page: 0,
        //       pageSize: 25,
        //       total: 3,
        //       uniqueTotal: 0,
        //       data: this.competitorOriginalPair
        //     });
        //   });
        // });

        this.data = {
            '/users': new ItemList<Model>({
                page: 0,
                pageSize: 25,
                total: 6,
                data: new Array<User>(
                    new User({
                        id: '1',
                        name: 'Иванов А.А.',
                        email: 'user1@mail.ru',
                        role: 'admin'
                    }),
                    new User({
                        id: '2',
                        name: 'Сидоров А.А.',
                        email: 'user2@mail.ru',
                        role: 'manager'
                    }),
                    new User({
                        id: '3',
                        name: 'Петров А.А.',
                        email: 'user3@mail.ru',
                        role: 'client'
                    }),
                    new User({
                        id: '4',
                        name: 'Лебедев А.А.',
                        email: 'user4@mail.ru',
                        role: 'manager'
                    }),
                    new User({
                        id: '5',
                        name: 'Волков А.А.',
                        email: 'user5@mail.ru',
                        role: 'client'
                    }),
                    new User({
                        id: '6',
                        name: 'Зайцев А.А.',
                        email: 'user6@mail.ru',
                        role: 'admin'
                    })
                )
            }),
            '/trends': new ItemList<Model>({
                page: 0,
                pageSize: 25,
                total: 3,
                data: new Array<CompetitorModel>(
                    new CompetitorModel({
                        id: 'article 0',
                        keyword: 'keyword',
                        link: 'google.com',
                        searchVolume: 100,
                        comment: 'comment',
                        trends: [],
                        tags: []
                    }),
                    new CompetitorModel({
                        id: 'article 1',
                        keyword: 'keyword',
                        link: 'google.com',
                        searchVolume: 100,
                        comment: 'comment',
                        trends: [],
                        tags: []
                    }),
                    new CompetitorModel({
                        id: 'article 2',
                        keyword: 'keyword',
                        link: 'google.com',
                        searchVolume: 100,
                        comment: 'comment',
                        trends: [],
                        tags: []
                    })
                )
            }),
            '/originals': new ItemList<Model>({
                page: 0,
                pageSize: 25,
                total: 3,
                uniqueTotal: 0,
                data: this.csvOriginals
            }),
            '/validateCompetitors': new ItemList<Model>({
                page: 0,
                pageSize: 25,
                total: 3,
                uniqueTotal: 0,
                data: this.competitorOriginalPair
            }),
            '/articles': new ItemList<Model>({
                page: 0,
                pageSize: 25,
                total: 3,
                uniqueTotal: 0,
                data: new Array<ArticleModel>(
                    new ArticleModel({
                        active: true,
                        articleGroup: '02.12.2022-LIQUI MOLY',
                        articleId: '1',
                        competitivePrice: 0,
                        createdDate: '1675233084',
                        ebayUri: 'https://ebay.de/itm/115334093952',
                        excluded: false,
                        id: 'cc070a46-a5f4-11ed-99dc-0a0027000003',
                        imageUri: 'https://images.crw-carparts.de/lj/21604.jpg',
                        listerId: '100536657',
                        price: 44.36,
                        deliveryPrice: 0,
                        totalPrice: 44.36,
                        maxCompetitorPrice: 9.9,
                        minCompetitorPrice: 2.6,
                        productId: '28581137',
                        title: 'LIQUI MOLY Motoröl 1l 21604',
                        totalCompetitorsSold: 115268,
                        updatedDate: null
                    }),
                    new ArticleModel({
                        id: '1'
                    })
                )
            }),
            '/articles/1': new ArticleModel({
                active: true,
                articleGroup: '02.12.2022-LIQUI MOLY',
                articleId: '1',
                competitivePrice: 0,
                createdDate: '1675233084',
                ebayUri: 'https://ebay.de/itm/115334093952',
                excluded: false,
                id: 'cc070a46-a5f4-11ed-99dc-0a0027000003',
                imageUri: 'https://images.crw-carparts.de/lj/21604.jpg',
                listerId: '100536657',
                price: 44.36,
                deliveryPrice: 0,
                totalPrice: 44.36,
                maxCompetitorPrice: 9.9,
                minCompetitorPrice: 2.6,
                productId: '28581137',
                title: 'LIQUI MOLY Motoröl 1l 21604',
                totalCompetitorsSold: 115268,
                updatedDate: null
            }),
            '/competitors': new ItemList<Model>({
                page: 0,
                pageSize: 25,
                total: 3,
                uniqueTotal: 0,
                data: new Array<CompetitorModel>(
                    new CompetitorModel({
                        account: null,
                        active: true,
                        advertising: true,
                        articleEbayUri: null,
                        articleImageUri: 'https://images.crw-carparts.de/lj/21604.jpg',
                        competitivePrice: 0,
                        competitorId: '08db041e-2374-47e7-8679-35a2a7d94f63',
                        createdDate: '1675233092',
                        deliveryPrice: 0,
                        ebayId: '192582017511',
                        ebayUri: 'https://www.ebay.de/itm/192582017511',
                        id: 'd68cea2f-a5f4-11ed-99dc-0a0027000003',
                        imageUri: 'https://i.ebayimg.com/images/g/1j0AAOSwWhxdutPD/s-l500.jpg',
                        inStock: 4,
                        isCompetitor: false,
                        isCompetitorManual: null,
                        listerId: null,
                        price: 44.36,
                        productId: '28581137',
                        searchQuery: 'LIQUI MOLY 20647',
                        searchQueryUri:
                            'https://www.ebay.de/sch/i.html?_ipg=240&LH_PrefLoc=1&_fcid=77&LH_ItemCondition=1000&_sasl=auto-carparts24 crw-carparts crw-autoteile crw-carparts24 parts-online24 qrparts24&_saslop=2&_nkw=LIQUI MOLY 20647&_pgn=1',
                        seller: 'kfzparts24',
                        sold: 3011,
                        title: ' Liqui Moly Longlife III Motoröl 5W-30, 5-Liter, VW 504 00, VW 507 00 - 20647',
                        topSeller: true,
                        totalPrice: 44.36,
                        triggeredRules: [
                            { name: 'EanMatch', match: false, decisive: false },
                            { name: 'IsSet', match: false, decisive: false },
                            { name: 'SameHersteller', match: true, decisive: false },
                            { name: 'OemMatch', match: false, decisive: false },
                            { name: 'MPNMatch', match: false, decisive: false },
                            { name: 'IsSet(multiplier)', match: false, decisive: false }
                        ],
                        updatedDate: null,
                        variant: true,
                        parentArticleId: '08db041d-f0c1-412b-8885-539535143fa3'
                    }),
                    new CompetitorModel({
                        id: '2',
                        title: 'MANNOL 20L UNIVERSEL Für BMW M5',
                        isCompetitor: false,
                        parentArticleId: '1',
                        imageUri: 'https://i.ebayimg.com/images/g/EbEAAOSw785iDkKg/s-l500.jpg',
                        articleImageUri: 'https://images.crw-carparts.de/lj/21604.jpg'
                    })
                )
            }),
            '/requests': null,
            '/requests/1': new CompetitorSearchRequestModel({
                id: '1',
                query: 'Search Requst 111',
                offerCount: 253,
                minimumPrice: 1,
                maximumPrice: 10,
                validated: true,
                createdDate: 34633473473
            }),
            '/requests/2': new CompetitorSearchRequestModel({
                id: '2',
                query: 'Search Requst 222',
                offerCount: 2355,
                minimumPrice: 22,
                maximumPrice: 123,
                validated: true,
                createdDate: 34633473473
            }),
            '/matchingResults': new ItemList<Model>({
                page: 0,
                pageSize: 25,
                total: 3,
                uniqueTotal: 0,
                data: this.matchingResults
            }),
            '/article-groups': new ItemList<Model>({
                page: 0,
                pageSize: 25,
                total: 3,
                uniqueTotal: 0,
                data: new Array<ArticleGroupModel>(
                    new ArticleGroupModel({
                        id: '1',
                        name: 'BOSCH Filter',
                        articleStatus: ArticleGroupArticleStatus.UPLOAD_IN_PROGRESS,
                        potentialCompetitorStatus: ArticleGroupPotentialCompetitorStatus.NOT_FOUND,
                        competitorStatus: ArticleGroupCompetitorStatus.NOT_FOUND,
                        ruleConfigurationChanged: false,
                        monitoringInProgress: false,
                        isManual: false,
                        recognitionQuality: null,
                        totalArticleCount: 23525,
                        notValidatedArticleCount: 344,
                        totalActualCompetitorCount: 346444,
                        totalCompetitorCount: 685432,
                        confidenceThreshold: 67,
                        minimumRecognitionQuality: 45,
                        validations: 546,
                        setSize: 300,
                        itemsToVerify: 100,
                        createdDate: 1674820874,
                        updatedDate: 1674820874,
                        updatedByUserName: 'Admin'
                    }),
                    new ArticleGroupModel({
                        id: '2',
                        name: 'BOSCH Filter',
                        articleStatus: ArticleGroupArticleStatus.UPLOADED,
                        potentialCompetitorStatus: ArticleGroupPotentialCompetitorStatus.SEARCH_IN_PROGRESS,
                        competitorStatus: ArticleGroupCompetitorStatus.NOT_FOUND,
                        ruleConfigurationChanged: false,
                        monitoringInProgress: false,
                        isManual: false,
                        recognitionQuality: null,
                        totalArticleCount: 23525,
                        notValidatedArticleCount: 344,
                        totalActualCompetitorCount: 346444,
                        totalCompetitorCount: 685432,
                        confidenceThreshold: 67,
                        minimumRecognitionQuality: 45,
                        validations: 546,
                        setSize: 300,
                        itemsToVerify: 100,
                        createdDate: 1673961026,
                        updatedDate: 1673961026,
                        updatedByUserName: 'Admin'
                    }),
                    new ArticleGroupModel({
                        id: '3',
                        name: 'BOSCH Filter',
                        articleStatus: ArticleGroupArticleStatus.UPLOADED,
                        potentialCompetitorStatus: ArticleGroupPotentialCompetitorStatus.FOUND,
                        competitorStatus: ArticleGroupCompetitorStatus.SEARCH_IN_PROGRESS,
                        ruleConfigurationChanged: false,
                        monitoringInProgress: false,
                        isManual: false,
                        recognitionQuality: null,
                        totalArticleCount: 23525,
                        notValidatedArticleCount: 344,
                        totalActualCompetitorCount: 346444,
                        totalCompetitorCount: 685432,
                        confidenceThreshold: 67,
                        minimumRecognitionQuality: 45,
                        validations: 546,
                        setSize: 300,
                        itemsToVerify: 100,
                        createdDate: 1673961026,
                        updatedDate: 1673961026,
                        updatedByUserName: 'Admin'
                    }),
                    new ArticleGroupModel({
                        id: '4',
                        name: 'BOSCH Filter',
                        articleStatus: ArticleGroupArticleStatus.UPLOADED,
                        potentialCompetitorStatus: ArticleGroupPotentialCompetitorStatus.FOUND,
                        competitorStatus: ArticleGroupCompetitorStatus.FOUND,
                        ruleConfigurationChanged: false,
                        monitoringInProgress: false,
                        isManual: false,
                        recognitionQuality: null,
                        totalArticleCount: 23525,
                        notValidatedArticleCount: 344,
                        totalActualCompetitorCount: 346444,
                        totalCompetitorCount: 685432,
                        confidenceThreshold: 67,
                        minimumRecognitionQuality: 45,
                        validations: 546,
                        setSize: 300,
                        itemsToVerify: 100,
                        createdDate: 1673961026,
                        updatedDate: 1673961026,
                        updatedByUserName: 'Admin'
                    }),
                    new ArticleGroupModel({
                        id: '5',
                        name: 'BOSCH Filter',
                        articleStatus: ArticleGroupArticleStatus.UPLOADED,
                        potentialCompetitorStatus: ArticleGroupPotentialCompetitorStatus.FOUND,
                        competitorStatus: ArticleGroupCompetitorStatus.FOUND,
                        ruleConfigurationChanged: true,
                        monitoringInProgress: false,
                        isManual: false,
                        recognitionQuality: null,
                        totalArticleCount: 23525,
                        notValidatedArticleCount: 344,
                        totalActualCompetitorCount: 346444,
                        totalCompetitorCount: 685432,
                        confidenceThreshold: 67,
                        minimumRecognitionQuality: 45,
                        validations: 546,
                        setSize: 300,
                        itemsToVerify: 100,
                        createdDate: 1673961026,
                        updatedDate: 1673961026,
                        updatedByUserName: 'Admin'
                    }),
                    new ArticleGroupModel({
                        id: '6',
                        name: 'BOSCH Filter',
                        articleStatus: ArticleGroupArticleStatus.UPLOADED,
                        potentialCompetitorStatus: ArticleGroupPotentialCompetitorStatus.FOUND,
                        competitorStatus: ArticleGroupCompetitorStatus.FOUND,
                        ruleConfigurationChanged: false,
                        monitoringInProgress: true,
                        isManual: false,
                        recognitionQuality: null,
                        totalArticleCount: 23525,
                        notValidatedArticleCount: 344,
                        totalActualCompetitorCount: 346444,
                        totalCompetitorCount: 685432,
                        confidenceThreshold: 67,
                        minimumRecognitionQuality: 45,
                        validations: 546,
                        setSize: 300,
                        itemsToVerify: 100,
                        createdDate: 1673961026,
                        updatedDate: 1673961026,
                        updatedByUserName: 'Admin'
                    }),
                    new ArticleGroupModel({
                        id: '7',
                        name: 'BOSCH Filter',
                        articleStatus: ArticleGroupArticleStatus.UPLOADED,
                        potentialCompetitorStatus: ArticleGroupPotentialCompetitorStatus.FOUND,
                        competitorStatus: ArticleGroupCompetitorStatus.PURGED_FROM_REPRICING,
                        ruleConfigurationChanged: false,
                        monitoringInProgress: false,
                        isManual: false,
                        recognitionQuality: null,
                        totalArticleCount: 23525,
                        notValidatedArticleCount: 344,
                        totalActualCompetitorCount: 346444,
                        totalCompetitorCount: 685432,
                        confidenceThreshold: 67,
                        minimumRecognitionQuality: 45,
                        validations: 546,
                        setSize: 300,
                        itemsToVerify: 100,
                        createdDate: 1673961026,
                        updatedDate: 1673961026,
                        updatedByUserName: 'Admin'
                    }),
                    new ArticleGroupModel({
                        id: '8',
                        name: 'BOSCH Filter',
                        articleStatus: ArticleGroupArticleStatus.UPLOADED,
                        potentialCompetitorStatus: ArticleGroupPotentialCompetitorStatus.FOUND,
                        competitorStatus: ArticleGroupCompetitorStatus.FOUND,
                        ruleConfigurationChanged: false,
                        ruleConfigurationRequired: true,
                        monitoringInProgress: false,
                        isManual: false,
                        recognitionQuality: null,
                        totalArticleCount: 23525,
                        notValidatedArticleCount: 344,
                        totalActualCompetitorCount: 346444,
                        totalCompetitorCount: 685432,
                        confidenceThreshold: 67,
                        minimumRecognitionQuality: 45,
                        validations: 546,
                        setSize: 300,
                        itemsToVerify: 100,
                        createdDate: 1673961026,
                        updatedDate: 1673961026,
                        updatedByUserName: 'Admin'
                    })
                )
            }),
            '/rulesConfiguration': new RulesConfiguration({
                id: '1',
                name: 'BOSCH Filter',
                rules: [{ id: 1 }, { id: 2 }],
                recognitionQuality: 97,
                competitorsQuantity: 14322,
                potentialCompetitorsQuantity: 125562,
                potentialCompetitorsOnArticleAvg: 100,
                competitorsOnArticleAvg: 40,
                setSize: 300,
                itemsToVerify: 100
            }),
            '/article-groups/1': new ArticleGroupModel({
                id: '1',
                name: 'BOSCH Filter',
                articleStatus: ArticleGroupArticleStatus.UPLOADED,
                potentialCompetitorStatus: ArticleGroupPotentialCompetitorStatus.FOUND,
                competitorStatus: ArticleGroupCompetitorStatus.FOUND,
                ruleConfigurationChanged: true,
                ruleConfigurationRequired: false,
                monitoringInProgress: false,
                isManual: false,
                recognitionQuality: 56,
                totalArticleCount: 23525,
                notValidatedArticleCount: 344,
                totalActualCompetitorCount: 346444,
                totalCompetitorCount: 685432,
                confidenceThreshold: 67,
                minimumRecognitionQuality: 45,
                validatedCount: 546
            }),
            '/article-groups/1/rules': new ItemList<Model>({
                page: 0,
                pageSize: 25,
                total: 3,
                uniqueTotal: 0,
                data: new Array<RuleModel>(100).fill(
                    new RuleModel({
                        id: '1',
                        name: 'rule 1',
                        articleGroupName: 'BOSCH Filter',
                        ruleEnable: true,
                        default: false,
                        valueFromField: true,
                        originalArticleFields: 'Eans',
                        valueFromList: false,
                        values: null,
                        matchRegex: false,
                        regex: null,
                        searchInTitle: true,
                        searchInAttributes: true,
                        attributeNameRegex: '^(ean|EAN)$',
                        actionOnMatch: MatchingAction.ASSIGN_COMPETITOR,
                        actionOnMissmatch: MatchingAction.INCREASE_CONFIDENCE,
                        confidenceIncrement: 0.5,
                        outputField: 'EanMatch',
                        skipValues: true,
                        minAllowedValueLength: 5,
                        matchingMode: MatchingMode.FULL_MATCH,
                        separateValues: true,
                        separatorCharacters: ',/ %',
                        removeCharacters: true,
                        charactersToRemove: '-_',
                        createdDate: 198342353,
                        updatedDate: 198342353,
                        updatedByUserId: '1',
                        updatedByUserName: 'rmedvedev'
                    })
                )
            }),
            '/article-groups/1/settings': new ArticleGroupSettingsModel({
                groupName: 'BOSCH Filter',
                confidence: 87,
                minimumRecognitionQuality: 39,
                synonyms: 'synonym1,synonym2\nsynonym1,synonym1'
            }),
            '/article-groups/1/analysis': new ArticleGroupAnalysisModel({
                articlesQuantity: 4593493,
                competitorsQuantity: 983795087,
                potentialCompetitorsQuantity: 983795087,
                competitorsOnArticleAvg: 49,
                potentialCompetitorsOnArticleAvg: 942,
                recognitionQuality: 87,
                validations: 485,
                itemsValidated: 0.0015,
                competitorsOnRepricing: 983795087,
                potentialCompetitorsOnRepricing: 983795087
            }),
            '/rules': new ItemList<Model>({
                page: 0,
                pageSize: 100,
                total: 10,
                uniqueTotal: 0,
                data: []
            }),

            '/rules/0': new RuleModel({
                id: 'multiplierDetectionRule',
                name: 'rule 1',
                articleGroupName: 'BOSCH Filter',
                ruleEnable: true,
                default: true,
                valueFromField: true,
                originalArticleFields: 'Eans',
                valueFromList: false,
                values: null,
                matchRegex: false,
                regex: null,
                searchInTitle: true,
                searchInAttributes: true,
                attributeNameRegex: '^(ean|EAN)$',
                actionOnMatch: MatchingAction.ASSIGN_COMPETITOR,
                actionOnMissmatch: MatchingAction.INCREASE_CONFIDENCE,
                confidenceIncrement: 0,
                confidenceDecrement: 23,
                outputField: 'EanMatch',
                skipValues: true,
                minAllowedValueLength: 5,
                matchingMode: MatchingMode.FULL_MATCH,
                separateValues: true,
                separatorCharacters: ',/ %',
                removeCharacters: true,
                charactersToRemove: '-_',
                createdDate: 198342353,
                updatedDate: 198342353,
                updatedByUserId: '1',
                updatedByUserName: 'rmedvedev'
            }),
            '/rules/1': new RuleModel({
                id: '1',
                name: 'rule 1',
                articleGroupName: 'BOSCH Filter',
                ruleEnable: true,
                default: false,
                valueFromField: true,
                originalArticleFields: 'Eans',
                valueFromList: false,
                values: null,
                matchRegex: false,
                regex: null,
                searchInTitle: true,
                searchInAttributes: true,
                attributeNameRegex: '^(ean|EAN)$',
                actionOnMatch: MatchingAction.ASSIGN_COMPETITOR,
                actionOnMissmatch: MatchingAction.INCREASE_CONFIDENCE,
                confidenceIncrement: 0,
                confidenceDecrement: 23,
                outputField: 'EanMatch',
                skipValues: true,
                minAllowedValueLength: 5,
                matchingMode: MatchingMode.FULL_MATCH,
                separateValues: true,
                separatorCharacters: ',/ %',
                removeCharacters: true,
                charactersToRemove: '-_',
                createdDate: 198342353,
                updatedDate: 198342353,
                updatedByUserId: '1',
                updatedByUserName: 'rmedvedev'
            }),
            '/rules/2': new RuleModel({
                id: 'manufacturerTypeMatchRule',
                name: 'rule 1',
                articleGroupName: 'BOSCH Filter',
                ruleEnable: true,
                default: true,
                valueFromField: true,
                originalArticleFields: 'Eans',
                valueFromList: false,
                values: null,
                matchRegex: false,
                regex: null,
                searchInTitle: true,
                searchInAttributes: true,
                attributeNameRegex: '^(ean|EAN)$',
                actionOnMatch: MatchingAction.ASSIGN_COMPETITOR,
                actionOnMissmatch: MatchingAction.INCREASE_CONFIDENCE,
                confidenceIncrement: 0,
                confidenceDecrement: 23,
                outputField: 'EanMatch',
                skipValues: true,
                minAllowedValueLength: 5,
                matchingMode: MatchingMode.FULL_MATCH,
                separateValues: true,
                separatorCharacters: ',/ %',
                removeCharacters: true,
                charactersToRemove: '-_',
                createdDate: 198342353,
                updatedDate: 198342353,
                updatedByUserId: '1',
                updatedByUserName: 'rmedvedev'
            }),
            '/queries': new ItemList<Model>({
                page: 0,
                pageSize: 100,
                total: 10,
                uniqueTotal: 0,
                data: []
            }),
            '/tagAssignmentRules': new ItemList<Model>({
                page: 0,
                pageSize: 100,
                total: 10,
                uniqueTotal: 0,
                data: []
            }),
            '/strategies': new ItemList<Model>({
                page: 0,
                pageSize: 25,
                total: 3,
                data: []
            }),
            '/strategies/1': new StrategyModel({
                id: '1',
                name: 'First Strategy',
                inProcessing: true,
                totalListerCount: 50,
                adRateType: 'Fixed',
                enabledDynamicAdRateCap: true,
                dynamicAdRateCapPercent: 50,
                enabledDynamicAdRateAdjustment: true,
                dynamicAdRateAdjustmentPercent: -100,
                fixedAdRatePercent: 50,
                createdDate: 1673960000,
                updatedDate: 1673961026
            }),
            '/strategies/2': new StrategyModel({
                id: '2',
                name: 'Second Strategy',
                inProcessing: false,
                totalListerCount: 100,
                adRateType: 'Dynamic',
                enabledDynamicAdRateCap: false,
                dynamicAdRateCapPercent: 100,
                enabledDynamicAdRateAdjustment: false,
                dynamicAdRateAdjustmentPercent: 100,
                fixedAdRatePercent: 100,
                createdDate: 1673960000,
                updatedDate: 1673961026
            }),
            '/strategies/3': new StrategyModel({
                id: '3',
                name: 'Third Strategy',
                inProcessing: false,
                totalListerCount: 1_000_000,
                adRateType: 'Dynamic',
                enabledDynamicAdRateCap: false,
                dynamicAdRateCapPercent: 100,
                enabledDynamicAdRateAdjustment: true,
                dynamicAdRateAdjustmentPercent: 100,
                fixedAdRatePercent: 18,
                createdDate: 1673960000,
                updatedDate: 1673961026
            }),
            '/articleHistory/mock': new ItemList<ArticleHistoryModel>({
                page: 0,
                pageSize: 25,
                total: 3,
                data: []
            }),
            '/platform-accounts': new ItemList<PlatformAccountModel>({
                page: 0,
                pageSize: 100,
                total: 6,
                data: []
            }),
        };

        let rules = [];
        for (let i = 0; i < 100; i++) {
            rules.push(
                new RuleModel({
                    id: i,
                    name: 'rule 1',
                    articleGroupName: 'BOSCH Filter',
                    ruleEnable: i % 2 ? true : false,
                    default: false,
                    valueFromField: true,
                    originalArticleFields: 'Eans',
                    valueFromList: false,
                    values: null,
                    matchRegex: false,
                    regex: null,
                    searchInTitle: true,
                    searchInAttributes: true,
                    attributeNameRegex: '^(ean|EAN)$',
                    actionOnMatch: MatchingAction.ASSIGN_COMPETITOR,
                    actionOnMissmatch: MatchingAction.INCREASE_CONFIDENCE,
                    confidenceIncrement: 0.5,
                    outputField: 'EanMatch',
                    skipValues: true,
                    minAllowedValueLength: 5,
                    matchingMode: MatchingMode.FULL_MATCH,
                    separateValues: true,
                    separatorCharacters: ',/ %',
                    removeCharacters: true,
                    charactersToRemove: '-_',
                    createdDate: 198342353,
                    updatedDate: 198342353,
                    updatedByUserId: '1',
                    updatedByUserName: 'rmedvedev'
                })
            );
        }

        let queries = [];
        for (let i = 0; i < 10; i++) {
            queries.push(
                new SearchRequestTemplateModel({
                    id: i + '',
                    request: 'Motoröl {EAN} {MENGE}'
                })
            );
        }

        let competitors = [];
        let customRules = [];
        let articleGroups = [];
        let tagAssignmentRules = [];
        for (let i = 0; i < 10; i++) {
            competitors.push(
                new CompetitorModel({
                    account: null,
                    active: true,
                    advertising: true,
                    articleEbayUri: null,
                    articleImageUri: 'https://images.crw-carparts.de/lj/21604.jpg',
                    competitivePrice: 0,
                    competitorId: i.toString(),
                    createdDate: '1675233092',
                    deliveryPrice: 0,
                    ebayId: '192582017511',
                    ebayUri: null,
                    id: i.toString(),
                    imageUri: 'https://i.ebayimg.com/images/g/1j0AAOSwWhxdutPD/s-l500.jpg',
                    inStock: 4,
                    isCompetitor: false,
                    isCompetitorManual: null,
                    listerId: null,
                    price: 44.36,
                    productId: '28581137',
                    searchQuery: 'LIQUI MOLY 20647',
                    searchQueryUri:
                        'https://www.ebay.de/sch/i.html?_ipg=240&LH_PrefLoc=1&_fcid=77&LH_ItemCondition=1000&_sasl=auto-carparts24 crw-carparts crw-autoteile crw-carparts24 parts-online24 qrparts24&_saslop=2&_nkw=LIQUI MOLY 20647&_pgn=1',
                    seller: 'kfzparts24',
                    sold: 3011,
                    title: ' Liqui Moly Longlife III Motoröl 5W-30, 5-Liter, VW 504 00, VW 507 00 - 20647',
                    topSeller: true,
                    totalPrice: 44.36,
                    triggeredRules: [
                        { name: 'EanMatch', match: false, decisive: false },
                        { name: 'IsSet', match: false, decisive: false },
                        { name: 'SameHersteller', match: true, decisive: false },
                        { name: 'OemMatch', match: false, decisive: false },
                        { name: 'MPNMatch', match: false, decisive: false },
                        { name: 'IsSet(multiplier)', match: false, decisive: false }
                    ],
                    updatedDate: null,
                    variant: true,
                    parentArticleId: '1'
                })
            );

            customRules.push(
                new RuleModel({
                    id: i.toString(),
                    name: 'rule 1',
                    articleGroupName: 'BOSCH Filter',
                    ruleEnable: true,
                    default: false,
                    valueFromField: true,
                    originalArticleFields: 'Eans',
                    valueFromList: false,
                    values: null,
                    matchRegex: false,
                    regex: null,
                    searchInTitle: true,
                    searchInAttributes: true,
                    attributeNameRegex: '^(ean|EAN)$',
                    actionOnMatch: MatchingAction.ASSIGN_COMPETITOR,
                    actionOnMissmatch: MatchingAction.INCREASE_CONFIDENCE,
                    confidenceIncrement: 0.5,
                    outputField: 'EanMatch',
                    skipValues: true,
                    minAllowedValueLength: 5,
                    matchingMode: MatchingMode.FULL_MATCH,
                    separateValues: true,
                    separatorCharacters: ',/ %',
                    removeCharacters: true,
                    charactersToRemove: '-_',
                    createdDate: 198342353,
                    updatedDate: 198342353,
                    updatedByUserId: '1',
                    updatedByUserName: 'rmedvedev'
                })
            );

            articleGroups.push(
                new ArticleGroupModel({
                    id: i.toString() + 9,
                    name: 'BOSCH Filter',
                    articleStatus: ArticleGroupArticleStatus.UPLOADED,
                    potentialCompetitorStatus: ArticleGroupPotentialCompetitorStatus.FOUND,
                    competitorStatus: ArticleGroupCompetitorStatus.PURGED_FROM_REPRICING,
                    isManual: false,
                    ruleConfigurationChanged: true,
                    recognitionQuality: null,
                    totalArticleCount: 23525,
                    notValidatedArticleCount: 344,
                    totalActualCompetitorCount: 346444,
                    totalCompetitorCount: 685432,
                    confidenceThreshold: 67,
                    minimumRecognitionQuality: 45,
                    validations: 546,
                    setSize: 300,
                    itemsToVerify: 100,
                    createdDate: 1673961026,
                    updatedDate: 1673961026,
                    updatedByUserName: 'Admin'
                })
            );
            tagAssignmentRules.push(
                new TagAssignmentRuleModel({
                    id: i + '',
                    name: 'Assign competitiveness',
                    assignmentCondition: '(bestCompPrice - articlePrice) / articlePrice > 0.1',
                    updatedDate: 1673961026,
                    lastUpdatedUserName: 'aivanov',
                    update: '#10winn',
                    enabled: i % 2 ? true : false
                })
            );
        }
        let strategies = [];
        for (let i = 0; i < 3; i++) {
            strategies.push(
                new StrategyModel({
                    id: (i + 1).toString(),
                    name: `Strategy Name ${(i + 1).toString()}`,
                    inProcessing: i % 2 ? true : false,
                    totalListerCount: i % 2 ? 50 : 100,
                    adRateType: i % 2 ? 'Fixed' : 'Dynamic',
                    enabledDynamicAdRateCap: i % 2 ? true : false,
                    dynamicAdRateCapPercent: i % 2 ? 50 : 100,
                    enabledDynamicAdRateAdjustment: i % 2 ? true : false,
                    dynamicAdRateAdjustmentPercent: i % 2 ? -100 : 100,
                    fixedAdRatePercent: i % 2 ? 50 : 100,
                    createdDate: 1673960000,
                    updatedDate: 1673961026
                })
            );
        }
        let articleHistory = [];
        for (let i = 0; i < 3; i++) {
            articleHistory.push(
                new ArticleHistoryModel({
                    id: (i + 1).toString(),
                    updatedByUser: 'Albert',
                    comment: 'Problem with something Problem with something Problem with something Problem with something Problem with something Problem with something',
                    createdDate: 1673960000,
                    updatedDate: 1673961026
                })
            );
        }
        let platformAccounts = [];
        for (let i = 0; i < 6; i++) {
            platformAccounts.push(
                new PlatformAccountModel({
                    id: (i + 1).toString(),
                    name: `Account Name ${(i + 1).toString()}`,
                    isSynchronizationInProgress: i % 2 ? true : false,
                    lastSyncedAt: '1673961026',
                })
            );
        }
        this.data['/competitors'].data = competitors;
        this.data['/rules'].data = rules;
        this.data['/tagAssignmentRules'].data = tagAssignmentRules;
        this.data['/article-groups/1/rules'].data = customRules;
        this.data['/article-groups'].data = this.data['/article-groups'].data.concat(articleGroups);
        this.data['/queries'].data = queries;
        this.data['/strategies'].data = strategies;
        this.data['/articleHistory/mock'].data = articleHistory;
        this.data['/platform-accounts'].data = platformAccounts;
    }

    parseBoolean(str: string) {
        return str.toLowerCase() === 'true' ? true : false;
    }

    public data!: DataType;
}