<div class="app-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <div class="login-wrapper" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="32px">
        <form [formGroup]="detailsForm" (ngSubmit)="onSignUp(detailsForm)" class="login-form" fxLayout="column"
            fxLayoutAlign="center ">

            <div class="login__header-wrapper" fxLayout="column" fxLayoutAlign="start center">
                <h3 class="login__header" i18n="@@general.text.is.start.your.repricing.now">Start your repricing now!</h3>
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
                    <span class="login__subheader" i18n="@@general.text.is.free.for.14.days">Free for 14 days</span>
                    <span class="login__subheader" i18n="@@general.text.is.no.credit.card.required">No credit card required</span>
                </div>
            </div>

            <input class="hidden-input" type="hidden" name="fakeLogin">
            <input class="hidden-input" type="password" autocomplete="fakeLogin">

            <div fxLayout="column" style="margin-bottom: 20px;">
                <mat-form-field appearance="outline">
                    <input matInput type="text" formControlName="userEmail" placeholder="Email" autocomplete="off" minLength="5"
                        maxlength="128" i18n-placeholder="@@hint.message.is.email">
                        @if (isUserExist(userEmail)) {
                            <mat-hint class="mat-error" align="start">
                                <span i18n="@@general.text.is.the.user.with.the.same.email.already.exist">The user with <strong>{{userEmail}}</strong> already exist</span>
                            </mat-hint>
                        }
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-form-field appearance="outline" fxFlex="49">
                        <input matInput type="text" formControlName="firstName" [placeholder]="pageText.propertyPlaceholderFirstName"
                        autocomplete="off" minLength="3" maxlength="30">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="49">
                        <input matInput type="text" formControlName="lastName" [placeholder]="pageText.propertyPlaceholderLastName"
                        autocomplete="off" minLength="3" maxlength="30">
                    </mat-form-field>
                </div>

                <mat-form-field appearance="outline">
                    <input matInput [type]="isPasswordHidden ? 'password' : 'text'" formControlName="password"
                    [placeholder]="pageText.propertyPlaceholderUserPassword" autocomplete="off"
                        minLength="8" maxlength="128" #passwordInput>
                    <div matSuffix (click)="onShowPassword($event)"
                        onselectstart="return false" onmousedown="return false">
                        <div [class.pass-type]="isPasswordHidden" style="line-height:12px;">
                            <svg-icon key="show-password-icon" fontSize="26px" color="#00124D"></svg-icon>
                        </div>
                    </div>
                    @if (passwordInput.value && passwordInput.value.length < 8) {
                        <mat-error align="start" style="font-size: 12px !important;">{{pageText.minLengthTooltip}}</mat-error>
                    }
                    @else if (getControlError('password', 'pattern')) {
                        <mat-error align="start" fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="6px" style="font-size: 12px !important;">
                            <div style="line-height: 10px; pointer-events: all;"
                                matTooltip="{{pageText.patternPasswordTooltip}}">
                                <svg-icon key="question" fontSize="14px" style="margin-right: 6px !important;"></svg-icon>
                            </div>
                            {{pageText.passwordNotStrongEnoughTooltip}}
                        </mat-error>
                    }
                    <mat-hint align="end" style="font-size: 12px !important;">{{passwordInput.value.length}}/32</mat-hint>

                    <!-- @if (!errorMessage) {
                        <mat-hint align="start">
                            <span i18n="@@general.text.is.minimum.length.is.8.characters">Minimum length is 8 characters</span>
                        </mat-hint>
                    }
                    @else {
                        <mat-hint class="mat-error" align="start">
                            <span>{{errorMessage}}</span>
                        </mat-hint>
                    } -->
                </mat-form-field>

                <div fxLayout="column" style="padding-top: 30px;">
                    <mat-form-field appearance="outline">
                        <input matInput type="text" formControlName="companyName" [placeholder]="pageText.propertyPlaceholderCompanyName"
                        autocomplete="off" minLength="3" maxlength="50">
                    </mat-form-field>
                </div>
            </div>

            <div class="login__btn" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
                <button submit mat-flat-button color="primary" class="content-center"
                    disableRipple style="width:366px;" [disabled]="isUserExist(userEmail)">
                    @if (!disabled) {
                        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
                            <span i18n="@@button.placeholder.start.my.free.trial">Start my free trial</span>
                        </div>
                    }
                    @else {
                        <div class="btn-spinner">
                            <div class="btn-spinner__circle"></div>
                            <div class="btn-spinner__circle"></div>
                            <div class="btn-spinner__circle"></div>
                        </div>
                    }
                </button>
                @if (isUserExist(userEmail)) {
                    <button type="button" mat-stroked-button class="content-center"
                        disableRipple fxFlex="100" style="width:366px;" (click)="goToLogin()">
                        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
                            <span i18n="@@button.placeholder.use.an.existing.email">Use an existing email</span>
                        </div>
                    </button>
                }
                <div class="login__privacy-policy" fxLayout="column">
                    <span i18n="@@general.text.is.by.clicking.the.start.my.free.trial.button">By clicking the "Start my free trial" button, you agree to our <a href="#" target="_blank">Terms and Conditions</a> and <a href="#" target="_blank">Privacy Policy.</a></span>
                </div>
            </div>
        </form>
        <div class="login__go-to-login-wrapper">
            <span class="login__go-to-login"
                i18n="@@general.text.is.already.have.an.account">Already have an account? <a routerLink="/login">Sign in!</a></span>
        </div>
    </div>
</div>