import { Injectable } from '@angular/core';
import { UserActionPopupService } from '../components/user-action-popup/user-action-popup.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AppText } from 'src/app/text/app-text';

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    errorPopupOpened: boolean = false;

    constructor(private service: UserActionPopupService, private snackBar: MatSnackBar) { }

    error(error: any) {
        console.log(error);

        let message = '';

        if (error) {
            if (typeof error === 'string') {
                message = error;
            } else {
                message =
                    error.errorMessage != null ? error.errorMessage : error?.message != null ? error?.message : error?.title != null ? error?.title : null;
                message = message == null ? '' : message;
            }

            if (!message) {
                if (error.status == 404) {
                    message = AppText.requestFailedResourceNotFound;
                } else if (error.status === 0) {
                    error.message = AppText.requestFailed;
                } else {
                    message = AppText.requestFailedUnknownError;
                }
            }
        } else {
            message = AppText.requestFailedUnknownError;
        }

        if (this.errorPopupOpened) {
            return;
        }
        this.errorPopupOpened = true;
        this.service.showDefaultError(message).subscribe(res => {
            this.errorPopupOpened = false;
        });
    }

    success(message: string) {
        this.service.showSuccessPopup(message);
    }

    warning(message: string) {
        this.service.showWarningPopup(message);
    }

    actionConfirm(message: string, continuePlaceholder?: string, cancelPlaceholder?: string) {
        return this.service.showActionConfirmPopup(message, continuePlaceholder, cancelPlaceholder);
    }

    deletionWarn(subject: string, subjectType: any) {
        return this.service.showDeletionPopup(subject, subjectType);
    }

    snackbar(message: string) {
        const options = new MatSnackBarConfig();
        options.duration = 3000;
        options.panelClass = ['snackbar-success'];
        this.snackBar.open(message, '', options);
    }
}
