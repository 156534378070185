import { SelectionModel } from '@angular/cdk/collections';
import {Component, Input, OnInit, Output, EventEmitter, SimpleChange, OnChanges } from '@angular/core';



const enum State {
  TREND = 0,
  NOT_TREND = 1
}

@Component({
  selector: 'app-check-filter',
  templateUrl: './app-check-filter.component.html',
  styleUrls: ['./app-check-filter.component.scss']
})
export class AppCheckFilterComponent implements OnChanges, OnInit {

  @Input() algorithms: string[] = [];

  @Input() enableMinimizedMode: boolean = true;

  @Input() valueDetected!: string;

  @Input() valueNotDetected!: string;

  valueDetectedArray!: Array<string>;

  valueNotDetectedArray!: Array<string>;

  @Output() change: EventEmitter<{ detected: string, notDetected: string }>;

  isFilterHide: boolean = true;

  trendChecklist = new SelectionModel<string>(true);
  notArticleChecklist = new SelectionModel<string>(true);

  constructor() {

    this.valueDetected = '';
    this.valueNotDetected = '';

    this.change = new EventEmitter<{ detected: string, notDetected: string }>();
    // this.algorithms = ['1', '2', '3'];
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {

    if (changes['valueDetected']) {
      this.valueDetected = !changes['valueDetected'].currentValue || changes['valueDetected'].currentValue == '' ? '' : changes['valueDetected'].currentValue;
    }

    if (changes['valueNotDetected']) {
      this.valueNotDetected = !changes['valueNotDetected'].currentValue || changes['valueNotDetected'].currentValue == '' ? '' : changes['valueNotDetected'].currentValue;
    }

    if (changes['algorithms']) {
      this.algorithms = changes['algorithms'].currentValue ? changes['algorithms'].currentValue : [];

      this.getDetected();

      this.getNotDetected();
    }
  }

  ngOnInit(): void {
    if (this.algorithms) {
      this.getDetected();

      this.getNotDetected();
    }
  }

  onToggleItem(state: State, item: string) {
    if (state === State.TREND) {
      this.trendChecklist.toggle(item);
      if (this.trendChecklist.isSelected(item)) {
        this.notArticleChecklist.deselect(item);
      }
    }
    else {
      this.notArticleChecklist.toggle(item);
      if (this.notArticleChecklist.isSelected(item)) {
        this.trendChecklist.deselect(item);
      }
    }

    this.valueDetected = this.trendChecklist.selected?.length ? this.trendChecklist.selected.join(',') : '';
    this.valueNotDetected = this.notArticleChecklist.selected?.length ? this.notArticleChecklist.selected.join(',') : '';

    this.change.emit({ detected: this.valueDetected, notDetected: this.valueNotDetected });

    this.getDetected();

    this.getNotDetected();
  }

  private getDetected(): void {

    if (this.valueDetected == '') {
      this.valueDetectedArray = [];
      return;
    }

    this.valueDetectedArray = this.valueDetected.split(',');
    this.valueDetectedArray.forEach((algorithm: any) => this.trendChecklist.select(algorithm));
  }

  private getNotDetected(): void {

    if (this.valueNotDetected == '') {
      this.valueNotDetectedArray = [];
      return;
    }

    this.valueNotDetectedArray = this.valueNotDetected.split(',');
    this.valueNotDetectedArray.forEach((algorithm: any) => this.notArticleChecklist.select(algorithm));
  }
}
