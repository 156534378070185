import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, HostListener, OnInit, Injector, OnChanges, SimpleChange, ComponentRef } from '@angular/core';
import { OverlayRef, OverlayConfig, Overlay, ScrollStrategyOptions, PositionStrategy, ConnectionPositionPair } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { FoundItemsModel } from '../found-items-list/found-items-model';
import { TEXTAREA_AUTOCOMPLETE_OVERLAY_PANEL, TextareaAutocompleteOverlayComponent } from './textarea-autocomplete-overlay/textarea-autocomplete-overlay.component';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { AppText } from 'src/app/text/app-text';
import { findAllTagsPattern, searchLastTagPattern, tagsPattern } from '../../patterns-config';
import { StringGenerator } from '../../utils/string-generator';
import { findQuotationMarks, getTextWithQuotationMarks, getTextWithotQuotationMarks } from '../../utils/util-functions';

@Component({
    selector: 'app-textarea-autocomplete',
    templateUrl: './textarea-autocomplete.component.html',
    styleUrls: ['./textarea-autocomplete.component.scss']
})
export class TextareaAutocompleteComponent implements OnInit, OnDestroy, OnChanges {
    /**
     * Список тегов для отображения
     */
    @Input() items: Array<FoundItemsModel> = [];

    /**
     * Блокировщик для поля (в форме)
     */
    @Input() disabled: boolean = false;

    /**
     * Обязательность для поля (в форме)
     */
    @Input() required: boolean = false;

    /**
     * Минимальная длина строки
     */
    @Input() minLength: number = 3;

    /**
     * Максимальная длина строки
     */
    @Input() maxLength: number = 255;

    /**
     * Label для поля
     */
    @Input() label: string = '';

    /**
     * Плейсхолдер поля
     */
    @Input() placeholder: string = '';

    /**
     * Искамая, желаемая строка - ее паттерн
     */
    @Input() fieldPattern!: RegExp;

    /**
     * Вводимый текст в поле
     */
    @Input() displayText: string = '';

    /**
     * Подсказка для поля
     */
    @Input() displayHintMessage: string = '';

    /**
     * Подсказка с введенными символами по умолчанию отключена
     */
    @Input() enableTextLengthHintMessage: boolean = false;

    /**
     * Ошибка для поля
     */
    @Input() displayErrorMessage: string = '';

    /**
     * Минимальный размер поля textarea
     */
    @Input() cdkAutosizeMinRows: number = 4;

    /**
     * Максимальный размер поля textarea
     */
    @Input() cdkAutosizeMaxRows: number = 4;

    /**
     * Максимальная длина строки
     */
    @Input() styleClass: string = '';

    /**
     * Вставить пробел перед выбранным элементом в строку
     */
    @Input() insertSpaceInStringBeforeSelectedItemFromList: boolean = false;

    // Паттерны для работы с конечной частью строки
    /**
     * Искамая, желаемая строка в конце всего текста.
     * Это паттерн для проверки валидности этой строки
     */
    @Input() lastCombinationOfCharactersInDisplayTextPattern: RegExp = tagsPattern;

    /**
     * Искамая, желаемая строка в конце всего текста.
     * Это паттерн не для валидности, а именно для поиска, после которого должна отображаться подсказка,
     * т. е., если мы ищем теги, то посказка должна появляться как только пользователь ввел знак #
     */
    @Input() lastCombinationOfCharactersInDisplayTextForSearchingAndOpeningOverlayPanel: RegExp = searchLastTagPattern;

    /**
     * Все совпадения в строке, которые нужно проверить на совпадения
     */
    @Input() allMatchesOfCombinationOfCharactersInDisplayTextPattern: RegExp = findAllTagsPattern;

    /**
     * Ковычки, для искамой, желаемой строки в конце всего текста.
     * Т е например слово может быть закючено в {} или <>  или может иметь один символ #
     */
    @Input() quotationMarksForLastCombinationOfCharacters: Array<Array<string>> = [];

    /**
     * Событие о том, что строка поиска изменилась
     * @type {EventEmitter<string>}
     */
    @Output() displayTextChanged: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Событие о том, что последная часть строки, изменилась
     * (на данный момент это только имя тега)
     * @type {EventEmitter<string>}
     */
    @Output() lastCombinationOfCharactersChanged: EventEmitter<string> = new EventEmitter<string>();

    // генерация ID необходима когда на одной странице два таких компонента
    public textareaWrapperName: string = `textarea_wrapper__${this.generateId()}`;
    public textareaElementName: string = `textarea_${this.generateId()}`;
    public textareaAutocompleteOverlayPanelId: string = `textarea_autocomplete_overlay_panel_${this.generateId()}`;

    /**
     * Последний часть строки в всем введенном текста
     */
    private lastCombinationOfCharactersInDisplayText: string = '';

    /**
     * Для подписок
     */
    private componentSubscriptions: Subscription[] = [];

    // Для создания overlay со списком
    private itemListOverlayRef!: OverlayRef;
    private itemListOverlayComponentRef!: ComponentRef<TextareaAutocompleteOverlayComponent>;

    private clientX: number = 0;
    private clientY: number = 0;

    /**
     * Для проверки валидности при использовании в форме
     */
    public get isValid(): boolean {
        if (this.required) {
            if (this.fieldPattern) {
                return this.displayText.match(this.fieldPattern)?.[0] ? true : false;
            }
            return this.displayText?.length ? true : false;
        }
        if (this.displayText && this.fieldPattern) {
            return this.displayText.match(this.fieldPattern)?.[0] ? true : false;
        }
        return true;
    }

    /**
     * Флаг открытой/закрытой панели
     */
    public get itemListOverlayPanelOpened() {
        return this.itemListOverlayRef && this.itemListOverlayRef.hasAttached() ? true : false;
    }

    /**
     * Элемент для расчета позиции панели со списком
     */
    public get textareaWrapper(): any {
        return document.getElementById(this.textareaWrapperName);
    }

    /**
     * Элемент для расчета позиции панели со списком
     */
    public get textareaElement(): any {
        return document.getElementById(this.textareaElementName);
    }

    /**
     * Элемент для расчета позиции панели со списком
     */
    public get targetElementForiItemListOverlayPanel(): any {
        return this.textareaElement || this.textareaWrapper;
    }

    public get focused(): boolean {
        return document?.activeElement?.id === this.textareaElementName;
    }

    public get lastIndexOfLastCombinationOfCharacters(): number {
        if (this.displayText || this.lastCombinationOfCharactersInDisplayText) {
            let lastIndex = this.displayText.lastIndexOf(this.lastCombinationOfCharactersInDisplayText);
            if (this.displayText.length === this.lastCombinationOfCharactersInDisplayText.length) {
                return lastIndex;
            }
            return (lastIndex + this.lastCombinationOfCharactersInDisplayText.length) === this.displayText.length ? 0 : lastIndex;
        }
        return -1;
    }

    constructor(
        private overlay: Overlay,
        private injector: Injector,
        private scrollStrategyOptions: ScrollStrategyOptions
    ) {
        this.componentSubscriptions = [];
        this.items = [];
    }

    /**
     * Слушает изменение размеров окна и обновляет
     * расположение панели overlay отнительно элемента-создателя
     */
    @HostListener('window:resize')
    handleWindowResizeEvent(): void {
        // if (this.itemListOverlayRef) {
        //     this.itemListOverlayRef.updateSize({
        //         width: this.targetElementForiItemListOverlayPanel?.getBoundingClientRect()?.width
        //     });
        //     this.itemListOverlayRef.updatePosition();
        // }

        this.updatePositionStrategy();
    }

    /**
     * Слушает нажатие esc и закрывает окно
     * @param event событие гнажатие esc
     */
    @HostListener('document:keyup.escape', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        event.stopPropagation();
        if (this.itemListOverlayPanelOpened) {
            this.closeItemsListOverlayPanel();
        }
    }

    /**
     * Слушает нажатие esc и закрывает окно
     * @param event событие гнажатие esc
     */
    @HostListener('document:keydown.enter', ['$event'])
    handleKeyboardEnterEvent(event: KeyboardEvent): boolean {
        event.stopPropagation();
        if (this.itemListOverlayPanelOpened || this.focused) {
            event?.stopPropagation();
            return false;
        }
        return true;
    }

    public ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
        if (changes['items']) {
            if (!this.items?.length) {
                this.closeItemsListOverlayPanel();
            }
            else {
                if (this.userEnteredExistingItem()) {
                    this.closeItemsListOverlayPanel();
                }
                else {
                    if (this.itemListOverlayPanelOpened) {
                        this.itemListOverlayComponentRef?.instance?.parentItemsChange.next(this.items);
                    }
                    else if (this.lastCombinationOfCharactersInDisplayText && this.focused && !this.lastIndexOfLastCombinationOfCharacters) {
                        this.onOpenItemsListOverlayPanel();
                    }
                }
            }
        }
    }

    public ngOnInit(): void {
        this.disabled = this.disabled != null ? this.disabled : false;
        this.required = this.required != null ? this.required : false;
        this.label = this.label != null ? this.label : AppText.generalTextIsLabel;
        this.placeholder = this.placeholder != null ? this.placeholder : AppText.generalTextIsPlaceholder;
        this.styleClass = this.styleClass != null ? this.styleClass : '';
    }

    public ngOnDestroy(): void {
        this.onClosePanel();
    }

    /**
     * Изменение текста в textarea
     * @param newText измененная строка
     */
    public onDisplayTextChange(newText: string, event?: any): void {
        event?.stopPropagation();
        this.displayText = newText || this.displayText;
        this.findLastCombinationOfCharactersAtEndOfDisplayText();
        this.displayTextChanged.next(this.displayText);
    }

    public onSelectItemFromList(seletcedItem: FoundItemsModel | null): void {
        if (!seletcedItem) { // this.itemAlreadyExistInDisplayText(seletcedItem?.title + '')
            this.closeItemsListOverlayPanel();
            return;
        }
        let lastCharsCombinationIndex = this.displayText?.lastIndexOf(this.lastCombinationOfCharactersInDisplayText);
        let foundArrayQuotationMarks = findQuotationMarks(this.lastCombinationOfCharactersInDisplayText, this.quotationMarksForLastCombinationOfCharacters) || [];
        this.displayText = this.displayText?.substring(0, lastCharsCombinationIndex);
        this.displayText = this.insertSpaceInStringBeforeSelectedItemFromList ? this.displayText?.trim() : this.displayText;
        if (this.displayText?.length) {
            let spaceChar = this.displayText[this.displayText.length - 1] != '-' && this.insertSpaceInStringBeforeSelectedItemFromList ? ' ' : '';
            this.displayText += `${spaceChar}${getTextWithQuotationMarks(seletcedItem.title + '', foundArrayQuotationMarks)}`;
        }
        else {
            this.displayText += `${getTextWithQuotationMarks(seletcedItem.title + '', foundArrayQuotationMarks)}`;
        }
        this.lastCombinationOfCharactersInDisplayText = this.displayText?.match(this.lastCombinationOfCharactersInDisplayTextForSearchingAndOpeningOverlayPanel)?.[0] || '';
        this.closeItemsListOverlayPanel();
        this.lastCombinationOfCharactersChanged.next('');
        this.displayTextChanged.next(this.displayText);
    }

    /**
     * Проверяет позицию каретки, после ее установки
     * Если кликнули в конце строки и там же есть тег, то откроется окно
     */
    public onCheckCaretPosition(event: any): void {
        console.log(event);
        // this.clientX = event?.clientX || 0;
        // this.clientY = event?.clientY || 0;
        // console.log(this.textareaElement.clientHeight .clientWidth);
        // if (!this.textareaElement) {
        //     return; 
        // }
        // if (!this.textareaElement?.selectionEnd && (this.textareaElement?.selectionEnd + '') !== '0') {
        //     return; 
        // }
        // let currentCaretPosition = Number(this.textareaElement.selectionEnd) || 0;
        // let caretPositionInEndOfString = currentCaretPosition === this.displayText?.length;
        // if (this.lastCombinationOfCharactersInDisplayText && caretPositionInEndOfString &&
        //     !this.itemListOverlayPanelOpened && this.items?.length && !this.userEnteredExistingItem()
        //     && !this.lastIndexOfLastCombinationOfCharacters) {
        //     this.onOpenItemsListOverlayPanel();
        // }
    }

    public onSetCursorPosition(event: any): void {
        console.log(event);
        this.clientX = event?.clientX || 0;
        this.clientY = event?.clientY || 0;
    }

    public onResetCursorPosition(): void {
        // this.clientX =  0;
        // this.clientY = 0;
    }

    public onClosePanel(): void {
        this.closeItemsListOverlayPanel();
    }

    public generateId(): string {
        return new StringGenerator().generateId();
    }

    /**
     * Поиск комбинации сиволов в конце строки, которые отвечают паттерну
     */
    protected findLastCombinationOfCharactersAtEndOfDisplayText(): void {
        this.lastCombinationOfCharactersInDisplayText = this.displayText?.match(this.lastCombinationOfCharactersInDisplayTextForSearchingAndOpeningOverlayPanel)?.[0] || '';
        if (this.lastCombinationOfCharactersInDisplayText?.length) {
            this.lastCombinationOfCharactersChanged.next(this.lastCombinationOfCharactersInDisplayText);
        }
        else if (this.itemListOverlayPanelOpened) {
            this.closeItemsListOverlayPanel();
        }
    }

    /**
     * Проверка на присутствие подстроки(например тега) в введенной строке
     * @param itemName имя подстроки(на данный момент пока только имя тега), уникальное
     * @returns истинно, если результат отвечает условию
     */
    protected itemAlreadyExistInDisplayText(itemName: string): boolean {
        if (!this.displayText || !itemName) {
            return false;
        }
        // let foundArrayQuotationMarks = findQuotationMarks(this.lastCombinationOfCharactersInDisplayText, this.quotationMarksForLastCombinationOfCharacters) || [];
        // let searchTags: string[] = this.displayText?.match(this.allMatchesOfCombinationOfCharactersInDisplayTextPattern) || [];
        // const foundTagInSearch = searchTags?.findIndex((_itemName: string) => _itemName === getTextWithQuotationMarks(itemName || '', foundArrayQuotationMarks));
        // return foundTagInSearch > -1 ? true : false;

        let foundArrayQuotationMarks = findQuotationMarks(this.lastCombinationOfCharactersInDisplayText, this.quotationMarksForLastCombinationOfCharacters) || [];
        const foundItemInDisplayText = this.displayText.indexOf(getTextWithQuotationMarks(itemName, foundArrayQuotationMarks));
        return foundItemInDisplayText > -1 ? true : false;
    }

    /**
     * Пользователь сам ввел тег руками, который нашелся в списке,
     * т е выбирать его из списка тег нет необходимости, поэтому панель должна закрываться
     * @returns истинно, если результат отвечает условию
     */
    protected userEnteredExistingItem(): boolean {
        let foundArrayQuotationMarks = findQuotationMarks(this.lastCombinationOfCharactersInDisplayText, this.quotationMarksForLastCombinationOfCharacters) || [];
        if (this.items?.length == 1 && this.items[0] && getTextWithQuotationMarks(this.items[0].title || '', foundArrayQuotationMarks) === this.lastCombinationOfCharactersInDisplayText) {
            return this.itemAlreadyExistInDisplayText(this.items[0].title + '');
        }
        return false;
    }

    /**
     * Метод открытия панели со списком
     */
    private onOpenItemsListOverlayPanel(): void {
        if (this.itemListOverlayPanelOpened || !this.targetElementForiItemListOverlayPanel) {
            return;
        }
        this.itemListOverlayRef = this.overlay.create(this.createItemListOverlayConfig());
        let componentRef = new ComponentPortal(
            TextareaAutocompleteOverlayComponent,
            null,
            this.createInjectorForItemListOverlay({ items: this.items, customStyleClassForList: 'textarea-custom-style' })
        );
        this.itemListOverlayComponentRef = this.itemListOverlayRef.attach(componentRef);
        this.subscribeTagListOverlayEvents();
    }

    /**
     * Создание конфига для панели с элементами
     * @returns объект с конфигурацией
     */
    private createItemListOverlayConfig(): OverlayConfig {
        return new OverlayConfig({
            // Цепляемся к элементу, указываем позицию относительно элемента
            positionStrategy: this.createItemListOverlayPositionStrategy(this.targetElementForiItemListOverlayPanel),
            scrollStrategy: this.scrollStrategyOptions.block(), // блокируется скроллинг на заднем плане
            hasBackdrop: false,
            backdropClass: `textarea_autocomplete_overlay_backdrop_${this.textareaAutocompleteOverlayPanelId}`,
            width: `${this.textareaElement.clientWidth || 321}px`,
            height: '340px',
            panelClass: this.textareaAutocompleteOverlayPanelId
        });
    }

    /**
     * Создание инжектора передачи данных в панель
     */
    private createInjectorForItemListOverlay(data: any): PortalInjector {
        return new PortalInjector(this.injector, new WeakMap<any, any>([[TEXTAREA_AUTOCOMPLETE_OVERLAY_PANEL, data]]));
    }

    /**
     * Стратегия для расположения панели overlay
     * @param originElement Элемента относительно которого будет панель overlay
     * @returns объект с настройками позиции панели overlay
     */
    private createItemListOverlayPositionStrategy(originElement: any): PositionStrategy {
        const positionStrategy = this.overlay.position()
            .flexibleConnectedTo(originElement)
            .withPositions(this.createItemListOverlayConnectionPositionPair(this.clientX, this.clientY))
            .withPush(false);

        return positionStrategy;
    }

    private updatePositionStrategy(): void {
        if (this.itemListOverlayRef) {
            this.itemListOverlayRef.updatePositionStrategy(this.createItemListOverlayPositionStrategy(this.targetElementForiItemListOverlayPanel));
            this.itemListOverlayRef.updatePosition();
        }
    }

    /**
     * Точки соединения родительского элемента и элемента панели
     * @returns объект с парами - обозначения позиции
     */
    private createItemListOverlayConnectionPositionPair(offsetX?: number, offsetY?: number): ConnectionPositionPair[] {
        return [
            {
                // offsetX: offsetX,
                // offsetY: offsetY,
                originX: 'start',
                originY: 'bottom',
                overlayX: 'start',
                overlayY: 'top',
            },
            {
                // offsetX: offsetX,
                // offsetY: offsetY,
                originX: 'start',
                originY: 'bottom',
                overlayX: 'end',
                overlayY: 'top',
            },
            {
                // offsetX: offsetX,
                // offsetY: offsetY,
                originX: 'start',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'bottom',
            },
            {
                // offsetX: offsetX,
                // offsetY: offsetY,
                originX: 'start',
                originY: 'top',
                overlayX: 'end',
                overlayY: 'bottom',
            },
        ];
    }

    /**
     * Подписка на все события overlay
     */
    private subscribeTagListOverlayEvents(): void {
        if (!this.itemListOverlayComponentRef) {
            return;
        }
        const itemChangeSub = this.itemListOverlayComponentRef.instance.itemChange
            .subscribe((res: FoundItemsModel | null) => {
                this.onSelectItemFromList(res);
            });
        const insideOverlayClosedSub = this.itemListOverlayComponentRef.instance.insidePanelClose
            .subscribe(() => {
                this.closeItemsListOverlayPanel();
            });

        const outsidePointerEventsSub = this.itemListOverlayRef.outsidePointerEvents()
            .subscribe((res: any) => {
                console.log(res?.target?.id);
                if (res?.target?.id !== this.textareaElementName) {
                    this.closeItemsListOverlayPanel();
                }
            });
        this.componentSubscriptions.push(itemChangeSub);
        this.componentSubscriptions.push(insideOverlayClosedSub);
        this.componentSubscriptions.push(outsidePointerEventsSub);
    }

    /**
     * Закрытие панели со списком
     */
    private closeItemsListOverlayPanel(): void {
        this.unsubscribeFromAllTagsListOverlayEvents();
        if (!this.itemListOverlayPanelOpened) {
            return;
        }
        this.itemListOverlayRef?.detach();
    }

    /**
     * Отписки
     */
    private unsubscribeFromAllTagsListOverlayEvents(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.forEach((s: Subscription) => s.unsubscribe());
            this.componentSubscriptions = [];
        }
    }
}