export class FileContract {
  id?: string;
  creationDate?: number;
  fileName?: string;
  file!: Blob;
  url?: string;
  size?: number;

  constructor(data?: any) {
    this.id = data?.id;
    this.creationDate = data?.creationDate;
    this.fileName = data?.fileName;
    this.file = data?.file;
    this.url = data?.url;
    this.size = data?.size;
  }
}
