import { Injectable } from '@angular/core';
import { SessionStorage } from 'src/app/api';

export type RouterHistoryModel = {
    currentPage: string,
    previousPages: string,
};

@Injectable({
    providedIn: 'root'
})
export class RouterHistoryService {
    private static routerHistoryKey = 'repricer.router.history';

    constructor() {
        // TODO
    }

    public static getHistory(): RouterHistoryModel[] | null {
        return SessionStorage.get(RouterHistoryService.routerHistoryKey);
    }

    public static setHistory(data: RouterHistoryModel[]): void {
        if (!data || !data.length) {
            RouterHistoryService.removeAllHistory();
            return;
        }
        SessionStorage.set(RouterHistoryService.routerHistoryKey, data);
    }

    public static removeHistoryForPage(pageKeys: string | string[]): void {
        if (!pageKeys || !pageKeys.length) {
            return;
        }
        let removeData: string[] = typeof pageKeys === 'string' ? [pageKeys] : pageKeys;
        let existHistory: RouterHistoryModel[] = RouterHistoryService.getHistory() || [];
        let filteredHistory = existHistory.filter((pageData: RouterHistoryModel) => !removeData.includes(pageData.currentPage));
        RouterHistoryService.setHistory(filteredHistory);
    }

    public static removeAllHistory(): void {
        SessionStorage.remove(RouterHistoryService.routerHistoryKey);
    }
}