export class UserSubscriptionPlan {
    name: string | null = null;
    customerPortalUrl: string | null = null;
    currentArticleCount: number | null = null;
    currentAccountCount: number | null = null;
    maxArticleCount: number | null = null;
    maxAccountCount: number | null = null;
    subscriptionStartDate: number | null = null;
    subscriptionEndDate: number | null = null;
    trialEndDate: number | null = null; //только для триала
    isActive: boolean | null = null;

    constructor(data?: object) {
        data && Object.keys(data).forEach((key: string) => {
            if (Object.prototype.hasOwnProperty.call(this, key)) {
                this[key as keyof UserSubscriptionPlan] = data[key as keyof typeof data];
            }
        });
    }
}