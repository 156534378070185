import { Component, OnInit } from '@angular/core';
import { AppText } from '../text/app-text';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  pageText = {
    notFound: AppText.generalTextIs404NotFound,
    gotoMenu: AppText.generalTextIsTryOnePageInTheMenu,
  };

  constructor() { }

  ngOnInit(): void {
    // TODO
  }

}