import { FilterSelectedField } from "src/app/shared/components/app-filter/app-filter-selected-fields/filter-selected-field";
import { Model } from "src/app/shared/interfaces"

export class CompetitorSearchRequestModel implements Model {
    id!: string | null;
    articleId!: string | null;
    query!: string | null;
    offerCount!: number;
    filters!: { [key: string]: any };
    minimumPrice!: number | null;
    maximumPrice!: number | null;
    hsn!: string | null;
    tsn!: string | null;
    validated!: boolean;
    createdDate!: number;

    // Для фронта
    selectedFields!: FilterSelectedField[];

    constructor(data?: any) {
        if (data) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    this[key as keyof CompetitorSearchRequestModel] = <never>data[key];
                }
            }
        }
    }
}

export class CompetitorSearchRequestViewModel {
    id!: string | null;
    query!: string | null;
    offerCount!: number | null;
    filters!: { [key: string]: any } | null;
    validated!: boolean | null;
    articleId!: string | null;
    createdDate!: number | null;

    // Для фронта
    selectedFields!: FilterSelectedField[];

    constructor(data?: any) {
        if (data) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    this[key as keyof CompetitorSearchRequestViewModel] = <never>data[key];
                }
            }
        }
    }
}

export class CompetitorSearchRequestEditModel {
    query!: string | null;
    minimumPrice!: number | null;
    maximumPrice!: number | null;
    articleId!: string | null;
    model!: string | null;
    type!: string | null;
    make!: string | null;
    year!: string | null;
    platform!: string | null;
    engine!: string | null;
    hsn!: string | null;
    tsn!: string | null;

    constructor(data?: any) {
        if (data) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    this[key as keyof CompetitorSearchRequestEditModel] = <never>data[key];
                }
            }
        }
    }
}