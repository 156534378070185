import { Model } from 'src/app/shared/interfaces';

export type RateType = 'Fixed' | 'Dynamic';

export class StrategyModel implements Model {
    id: string | null = null;
    name: string | null = null;
    ebayAdsSettings: AdRateModel = new AdRateModel;
    boostEbayAdsSettings: BoostAdRateModel = new BoostAdRateModel;
    priceManagement: PriceManagementModel = new PriceManagementModel;
    inProcessing: boolean | null = null;
    isDefaultStrategy: boolean | null = null;
    totalListerCount: number | null = null;
    createdDate: number | null = null;
    updatedDate: number | null = null;
    articleAssignment: ArticleAssignmentModel = new ArticleAssignmentModel;

    constructor(data?: any) {
        if (data) {
            for (let property in data) {
                if (this.hasOwnProperty(property)) {
                    this[property as keyof StrategyModel] = <never>data[property];
                }
            }
        }
    }

    serialize() {
        return {
            id: this.id,
            name: this.name,
            ebayAdsSettings: this.ebayAdsSettings,
            boostEbayAdsSettings: this.boostEbayAdsSettings,
            priceManagement: this.priceManagement,
            inProcessing: this.inProcessing,
            totalListerCount: this.totalListerCount,
            articleAssignment: this.articleAssignment
        };
    }
}

export class AdRateModel {
    adRateType: RateType | null = null;

    enabledDynamicAdRateCap: boolean | null = null; // флаг, указывающий, будет ли использоваться ограничение на рекламу для динамической ставки
    dynamicAdRateCapPercent: number | null = null; // ограничение на рекламу для динамической ставки. Представляет собой число типа double, которое может ранжироваться от 2 до 20 включительно, причем после запятой может находиться только одна цифра

    enabledDynamicAdRateAdjustment: boolean | null = null; // флаг, указывающий, будет ли использоваться настраиваемая ставка для динамической рекламы
    dynamicAdRateAdjustmentPercent: number | null = null; // настраиваемая ставка для динамической рекламы. Такие же ограничения, что и у dynamicAdRateCapPercent, кроме того, что ранжирование происходит от -20 до 20

    fixedAdRatePercent: number | null = null; // ставка для фиксированной рекламы. Такие же ограничения, что и у dynamicAdRateCapPercent

    enableEbayAds: boolean | null = null;
    constructor(data?: any) {
        if (data) {
            for (let property in data) {
                if (this.hasOwnProperty(property)) {
                    this[property as keyof AdRateModel] = <never>data[property];
                }
            }
        }
    }
}

export class BoostAdRateModel {
    enableEbayAds: boolean | null = null;

    boostDurationInDays: number | null = null;

    adRateType: RateType | null = null;

    enabledDynamicAdRateCap: boolean | null = null; // флаг, указывающий, будет ли использоваться ограничение на рекламу для динамической ставки
    dynamicAdRateCapPercent: number | null = null; // ограничение на рекламу для динамической ставки. Представляет собой число типа double, которое может ранжироваться от 2 до 20 включительно, причем после запятой может находиться только одна цифра

    enabledDynamicAdRateAdjustment: boolean | null = null; // флаг, указывающий, будет ли использоваться настраиваемая ставка для динамической рекламы
    dynamicAdRateAdjustmentPercent: number | null = null; // настраиваемая ставка для динамической рекламы. Такие же ограничения, что и у dynamicAdRateCapPercent, кроме того, что ранжирование происходит от -20 до 20

    fixedAdRatePercent: number | null = null; // ставка для фиксированной рекламы. Такие же ограничения, что и у dynamicAdRateCapPercent
    constructor(data?: any) {
        if (data) {
            for (let property in data) {
                if (this.hasOwnProperty(property)) {
                    this[property as keyof AdRateModel] = <never>data[property];
                }
            }
        }
    }
}

export const enum PriceOffsetType {
    NONE = 'None',
    PERCENTAGE = 'Percentage',
    FIXED = 'Fixed',
}

export class PriceManagementModel {
    allowRepricing: boolean | null = null;
    enableCompetitivePriceRange: boolean | null = null;
    fromCompetitivePrice: number | null = null;
    toCompetitivePrice: number | null = null;
    recommendedPriceOffsetType!: PriceOffsetType;
    recommendedPricePercentageOffset: number | null = null;
    recommendedPriceFixedOffset: number | null = null;

    constructor(data?: any) {
        if (data) {
            for (let property in data) {
                if (this.hasOwnProperty(property)) {
                    this[property as keyof PriceManagementModel] = <never>data[property];
                }
            }
        }
    }
}

export class ArticleAssignmentModel {
    requiredTags!: string;
    forbiddenTags!: string;

    constructor(data?: any) {
        if (data) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    this[key as keyof ArticleAssignmentModel] = <never>data[key];
                }
            }
        }
    }
}