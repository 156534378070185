import { Pipe, PipeTransform } from '@angular/core';
/**
* Convert phone value which is 10 digits long to string
* @param phone - номер телефона
* @param codeDividers - разделители для кода региона
* @param bodyDivider - разделитель для основного номера
*/
@Pipe({
  name: 'phone',
  pure: true
})
export class PhonePipe implements PipeTransform {
  transform(phone: string | number, codeDividers: [string, string] = ['(', ')'], bodyDivider: string = ' '): string {

    if (!phone) {
      return '';
    }

    const stringified = phone.toString().replace(/[^0-9]/g, '');
    if (stringified.length < 11) {
      return stringified;
    }

    const arr: string[] = [];

    arr.push(

      stringified.substring(stringified.length - 2, stringified.length),
      stringified.substring(stringified.length - 4, stringified.length - 2),
      stringified.substring(stringified.length - 7, stringified.length - 4),
      stringified.substring(stringified.length - 10, stringified.length - 7),

      // Код страны
      stringified.substring(0, stringified.length - 10),
    );

    return `+${arr.pop()}${bodyDivider}${codeDividers.shift()}${arr.pop()}${codeDividers.shift()}${bodyDivider}${arr.pop()}${bodyDivider}${arr.pop()}${bodyDivider}${arr.pop()}`;
  }
}
