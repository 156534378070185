export class AnimationData {
  item!: any;
  action!: 'update' | 'create' | 'delete';
  animationStart!: boolean; // For start animation in a table
  parentId?: string | number;

  constructor(data: any) {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this[key as keyof AnimationData] = data[key];
        }
      }
    }
  }
}