export class StringGenerator {
    private chars = 'abcdefghijklmnopqrstuvwxyz';
    private numbers = '0123456789';
    private symbols = '~`!@#$%^&*()";\:?*}{[]/><-_=+|,.';
    private stringLength: number;

    constructor (length?: number) {
        this.stringLength = length ? length : 32
    }

    public generateId(): string {
        const _lowerCaseChars = this.chars;
        const _upperCaseChars = this.chars.toUpperCase();
        const _numbers = this.numbers;
        let generatedId = '';
        let character = '';
        while (generatedId.length < this.stringLength) {
          const entity1 = _lowerCaseChars.charAt(Math.floor(Math.random() * _lowerCaseChars.length));
          const entity2 = _upperCaseChars.charAt(Math.floor(Math.random() * _upperCaseChars.length));
          const entity3 = _numbers.charAt(Math.floor(Math.random() * _numbers.length));
          character = '';
          character += entity1;
          character += entity2;
          character += entity3;
          generatedId += character.charAt(Math.floor(Math.random() * character.length));
        }
        return generatedId;
    }
}