import { Component, EventEmitter, Input, Output, ViewChild, OnChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SimpleDataFilter } from 'src/app/contracts';
import { ListItem } from '../../contracts';
import { AppFilterPanelComponent } from '../app-filter/app-filter-panel/app-filter-panel.component';
import { FilterSelectedField } from '../app-filter/app-filter-selected-fields/filter-selected-field';
import { FilterSettings } from '../app-filter/shared/filter-settings';
import { AppText } from 'src/app/text/app-text';
import { FoundItemsModel } from '../found-items-list/found-items-model';
import { MatMenu } from '@angular/material/menu';

@Component({
    selector: 'app-card-table-header',
    templateUrl: './app-card-table-header.component.html',
    styleUrls: ['./app-card-table-header.component.scss']
})
// export class AppCardTableHeaderComponent implements OnInit {
export class AppCardTableHeaderComponent implements OnChanges {
    /**
     * Все выбранные поля
     */
    public selectedFields!: Array<FilterSelectedField>;

    pageText = {
        applyAction: AppText.applyAction,
        regexTooltip: AppText.regexTooltip,
        downloadArticles: AppText.mainMenuItemIsArticles,
        downloadCompetitors: AppText.mainMenuItemIsCompetitors
    };

    /**
     * Флаг для отображения фильтра
     * @type {boolean}
     */
    @Input() filterComponentVisible!: boolean;

    /**
     * Флаг для отображения подсказки
     * @type {boolean}
     */
    @Input() regexTooltipVisible: boolean = true;

    /**
     * Флаг ,блокировки
     * @type {boolean}
     */
    @Input() disabled!: boolean;

    /**
     * Флаг состояния панели
     * true - открыта
     * @type {boolean}
     */
    @Input() isFilterPanelOpened!: boolean;

    /**
     * Флаг для блокировки кнопки фильтра
     * @type {boolean}
     */
    @Input() filterButtonDisabledState!: boolean;

    /**
     * Список правил на основе которых было принято решение
     * @type {ListItem[]}
     */
    @Input() decisionRulesForFilter!: ListItem[];

    /**
     * Список правил на основе которых было принято решение
     * @type {ListItem[]}
     */
    @Input() matchRulesForFilter!: ListItem[];

    /**
     * Список групп артиклей
     * @type {ListItem[]}
     */
    @Input() articleGroupsForFilter!: ListItem[];

    /**
     * Список стратегий
     * @type {ListItem[]}
     */
    @Input() strategiesForFilter!: ListItem[];

    /**
     * Флаг для отображения чекбокса
     * @type {boolean}
     */
    @Input() checkboxVisible!: boolean;

    /**
     * Флаг состояния чекбокса
     * @type {boolean}
     */
    @Input() checkboxState!: boolean;

    /**
     * Флаг для особого состояния чекбокса
     * (горизонтальная черта вместо галочки)
     * @type {boolean}
     */
    @Input() checkboxIndeterminateState!: boolean;

    /**
     * Флаг для блокировки чекбокса
     * @type {boolean}
     */
    @Input() checkboxDisabledState!: boolean;

    /**
     * Флаг для отображения кнопки загрузки через Excel
     * @type {boolean}
     */
    @Input() downloadButtonVisible!: boolean;

    /**
     * Флаг для блокировки кнопки загрузки
     * @type {boolean}
     */
    @Input() downloadButtonDisabledState!: boolean;

    /**
     * Флаг для блокировки кнопки загрузки
     * @type {boolean}
     */
    @Input() downloadCompetitorsButtonDisabled!: boolean;

    /**
     * Подсказка скачивания
     * @type {boolean}
     */
    @Input() downloadButtonTooltip!: string;

    /**
     * Флаг для отображения компонента поиска
     * @type {boolean}
     */
    @Input() searchComponentVisible!: boolean;

    /**
     * Строка поиска
     * @type {string}
     */
    @Input() search!: string;

    /**
     * Плейсхолдер поля поиска
     * @type {string}
     */
    @Input() generalTextIsSearchPlaceholder!: string;

    /**
     * Распознование тегов в строке
     * @type {boolean}
     */
    @Input() tagRecognitionModeEnableInSearchComponent: boolean = false;

    /**
     * Список тегов для отображения
     */
    @Input() tags: Array<FoundItemsModel> = [];

    /**
     * Флаг для отображения компонента сортировки
     * @type {boolean}
     */
    @Input() sortComponentVisible!: boolean;

    /**
     * Активное поле сортировки
     * @type {string}
     */
    @Input() orderBy!: string;

    /**
     * Активное направление сортировки
     * true - сотривка с конца
     * @type {boolean}
     */
    @Input() orderDesc!: boolean;

    /**
     * Все поля для сортировки
     * Содержит ключ и значение для отображения на фронте
     * Данные нужны в таком формате потому что компонент
     * работает с другим компонентом - app-multiselect
     * @type {Array<ListItem>}
     */
    @Input() sortFields!: Array<ListItem>;

    /**
     * Параметры для изменения
     * @type {SimpleDataFilter}
     */
    @Input() filterParams!: SimpleDataFilter;

    /**
     * Настройки видимости фильтров
     * @type {FilterSettings}
     */
    @Input() filterSettings!: FilterSettings;

    /**
     * Событие о том, что панель закрылась
     * Передает флаг о том были ли изменения или нет
     * и параметры фильтра
     * @type {EventEmitter<boolean>}
     */
    @Output() filterPanelClosed!: EventEmitter<{ changes: boolean; params: SimpleDataFilter }>;

    /**
     * Событие о том, что было изменено состояние чекбокса "Выбрать всех"
     * @type {EventEmitter<MatCheckboxChange>}
     */
    @Output() checkboxChecked!: EventEmitter<MatCheckboxChange>;

    /**
     * Событие о том, что кликнули на кнопку загрузки
     * @type {EventEmitter<void>}
     */
    @Output() downloadArticlesButtonClicked!: EventEmitter<void>;

    /**
     * Событие о том, что кликнули на кнопку загрузки
     * @type {EventEmitter<void>}
     */
    @Output() downloadCompetitorsButtonClicked!: EventEmitter<void>;

    /**
     * Событие о том, что строка поиска изменилась
     * @type {EventEmitter<string>}
     */
    @Output() searchChanged!: EventEmitter<string>;

    /**
     * Событие о том, что изменилась сортировка
     * @type {EventEmitter<boolean>}
     */
    @Output() sortChanged!: EventEmitter<{ orderBy: string; orderDesc: boolean }>;

    /**
     * Событие о том, что последний тег изменился
     * @type {EventEmitter<string>}
     */
    @Output() lastTagChanged!: EventEmitter<string>;

    /**
     * Событие о том, что последний тег изменился
     * @type {EventEmitter<string>}
     */
    @Output() showRegexTooltip!: EventEmitter<void>;

    get isMenuOpened(): boolean {
        return this.downloadExcelMenu?._allItems?.length ? true : false;
    }

    constructor() {
        this.filterComponentVisible = false;
        this.decisionRulesForFilter = [];
        this.matchRulesForFilter = [];
        this.articleGroupsForFilter = [];
        this.strategiesForFilter = [];
        this.isFilterPanelOpened = false;
        this.filterButtonDisabledState = false;
        this.selectedFields = [];
        this.checkboxVisible = true;
        this.checkboxState = false;
        this.checkboxIndeterminateState = false;
        this.checkboxDisabledState = false;
        this.downloadButtonVisible = false;
        this.searchComponentVisible = true;
        this.sortComponentVisible = true;
        this.filterPanelClosed = new EventEmitter<{ changes: boolean; params: SimpleDataFilter }>();
        this.checkboxChecked = new EventEmitter<MatCheckboxChange>();
        this.downloadArticlesButtonClicked = new EventEmitter<void>();
        this.downloadCompetitorsButtonClicked = new EventEmitter<void>();
        this.searchChanged = new EventEmitter<string>();
        this.sortChanged = new EventEmitter<{ orderBy: string; orderDesc: boolean }>();
        this.lastTagChanged = new EventEmitter<string>();
        this.showRegexTooltip = new EventEmitter<void>();
    }

    @ViewChild('filterPanelComponent', { static: false }) filterPanelComponent?: AppFilterPanelComponent;
    @ViewChild('downloadExcelMenu', { static: false }) downloadExcelMenu?: MatMenu;

    public ngOnChanges(): void {
        console.log(this.filterParams);
        this.filterPanelComponent?.updateFilterParams(this.filterParams);
    }

    /**
     * Открывает панель фильтра
     * @param {boolean} panelOpened Флаг состояния панели
     */
    public onOpenFilterPanel(panelOpened: boolean): void {
        if (this.filterButtonDisabledState) {
            return;
        }
        this.isFilterPanelOpened = panelOpened;
    }

    /**
     * Закрывает панель фильтра и передает с нее событие дальше
     * @param {{changes: boolean, params: SimpleDataFilter}} event Изменения в фильтре
     */
    public onCloseFilterPanel(event: { changes: boolean; params: SimpleDataFilter }): void {
        this.isFilterPanelOpened = false;
        this.filterPanelClosed.next(event);
    }

    /**
     * Изменения выбранных полей (отображаются как теги)
     * @param fields Выбранные значения
     */
    public onChangeSelectedFields(fields: Array<FilterSelectedField>): void {
        setTimeout(() => {
            this.selectedFields = fields ? fields : [];
        }, 0);
    }

    /**
     * Переключение чекбокса
     * @param {MatCheckboxChange} checked Флаг, на который переключили чекбокс
     */
    public onCheckboxChange(checked: MatCheckboxChange): void {
        if (this.checkboxDisabledState) {
            return;
        }
        this.checkboxChecked.next(checked);
    }

    /**
     * Сброс фильтра по общей кнопке reset
     */
    public onDownloadArticles(): void {
        if (this.downloadButtonDisabledState) {
            return;
        }

        this.downloadArticlesButtonClicked.next();
    }

    /**
     * Сброс фильтра по общей кнопке reset
     */
    public onDownloadCompetitors(): void {
        if (this.downloadButtonDisabledState) {
            return;
        }

        this.downloadCompetitorsButtonClicked.next();
    }

    /**
     * Метод поиска - изменяет строку поиска
     * @param {string} search Строка поиска
     */
    public onSearch(search: string): void {
        this.search = search;
        this.searchChanged.next(this.search);
    }

    /**
     * Поиск тегов
     * @param search Строка поиска
     */
    public onSearchTags(search: string): void {
        this.lastTagChanged.next(search);
    }

    /**
     * Метод отправляет событие о том, что сортировка изменилась
     * Сделано так чтобы не переделывать компонент app-list и работу с таблицами
     * @param {{ orderBy: string, orderDesc: boolean }} sortFields Посля сортировки
     */
    public onSortChange(sortFields: { orderBy: string; orderDesc: boolean }): void {
        this.sortChanged.next({ orderBy: sortFields.orderBy, orderDesc: sortFields.orderDesc });
    }

    public onOpenRegexTooltip(): void {
        this.showRegexTooltip.next();
    }

    /**
     * Сброс конкретного тега по клику на крестик
     * (крестик в самом теге находится)
     * @param {number} index Индекс выбранного элемента
     */
    public onResetSelectedField(index: number): void {
        if (this.filterPanelComponent) {
            this.filterPanelComponent.onResetSelectItemBySelectedFieldIndex(this.selectedFields[index]);
        }
    }

    /**
     * Сброс всех тегов - кликнули на кнопку сброса всех тегов
     * (крестик рядом с счетчиком)
     */
    public onResetAllSelectedFieldsInSelects(): void {
        if (this.filterPanelComponent) {
            this.filterPanelComponent.onResetFilter();
        }
    }

    /**
     * Применение фильтра по общей кнопке apply
     */
    public onApplyFilter(): void {
        if (this.filterPanelComponent) {
            this.filterPanelComponent.onApplyFilter();
        }
    }

    /**
     * Сброс фильтра
     */
    public onResetFilter(): void {
        if (this.filterPanelComponent) {
            this.filterPanelComponent.onResetFilter();
        }
    }

    /**
     * Закрытие фильтра без изменений
     */
    public onCloseFilterWithoutChanges(): void {
        if (this.filterPanelComponent) {
            this.filterPanelComponent.onClose();
        }
    }
}
