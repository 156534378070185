import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';

import { UserActionPopupComponent } from './components/user-action-popup/user-action-popup.component';
import {
    AbbreviationPipe,
    BooleanToWordPipe,
    EllipsisPipe,
    FixedPipe,
    MapingStatusPipe,
    PhonePipe,
    SafeHtmlPipe,
    SafePipe,
    SplitNumberPipe,
    SumPipe,
    ThousandsPipe,
    TimeAgoPipe,
    TimePipe,
    ToPercentPipe,
    UnixDatePipe
} from './pipes';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { AppPaginatorComponent } from './components/app-paginator/app-paginator.component';
import { AppPaginatorOverlayComponent } from './components/app-paginator/app-paginator-overlay/app-paginator-overlay.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMenuComponent } from './components/app-menu/app-menu.component';
import { AppDatepickerComponent } from './components/app-datepicker/app-datepicker.component';
import { FileUploadModule } from 'ng2-file-upload';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppCheckFilterComponent } from './components/app-check-filter/app-check-filter.component';
import { AppButtonToggleComponent } from './components/app-button-toggle/app-button-toggle.component';
import { AppMultiselectComponent } from './components/app-multiselect/app-multiselect.component';
import { AppMultiselectOverlayComponent } from './components/app-multiselect/app-multiselect-overlay/app-multiselect-overlay.component';
import { AppFilterComponent } from './components/app-filter/app-filter.component';
import { AppFileUploaderComponent } from './components/app-file-uploader/app-file-uploader.component';
import { AppFileUploaderBarComponent } from './components/app-file-uploader/app-file-uploader-bar/app-file-uploader-bar.component';
import { AppFileListComponent } from './components/app-file-list/app-file-list.component';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { AppSearchComponent } from './components/app-search/app-search.component';
import { AppSortComponent } from './components/app-sort/app-sort.component';
import { AppFilterButtonComponent } from './components/app-filter/app-filter-button/app-filter-button.component';
import { AppFilterPanelComponent } from './components/app-filter/app-filter-panel/app-filter-panel.component';
import { AppFilterDialogComponent } from './components/app-filter/app-filter-dialog/app-filter-dialog.component';
import { AppFilterSelectedFieldsComponent } from './components/app-filter/app-filter-selected-fields/app-filter-selected-fields.component';
import { AppCardTableHeaderComponent } from './components/app-card-table-header/app-card-table-header.component';
import { AppNumberInputComponent } from './components/app-number-input/app-number-input.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FoundItemsListComponent } from './components/found-items-list/found-items-list.component';
import { TextareaAutocompleteOverlayComponent } from './components/textarea-autocomplete/textarea-autocomplete-overlay/textarea-autocomplete-overlay.component';
import { TextareaAutocompleteComponent } from './components/textarea-autocomplete/textarea-autocomplete.component';

@NgModule({
    declarations: [
        // components
        UserActionPopupComponent,
        AppHeaderComponent,
        AppPaginatorComponent,
        // AppFilterComponent,
        AppPaginatorOverlayComponent,
        AppMenuComponent,
        AppDatepickerComponent,
        AppCheckFilterComponent,
        AppMultiselectComponent,
        AppMultiselectOverlayComponent,
        AppFilterComponent,
        AppFilterButtonComponent,
        AppFilterPanelComponent,
        AppFilterDialogComponent,
        AppFilterSelectedFieldsComponent,
        AppFileListComponent,
        AppFileUploaderComponent,
        AppFileUploaderBarComponent,
        AppSearchComponent,
        AppSortComponent,
        AppCheckFilterComponent,
        AppButtonToggleComponent,
        AppCardTableHeaderComponent,
        AppNumberInputComponent,
        FoundItemsListComponent,
        TextareaAutocompleteComponent,
        TextareaAutocompleteOverlayComponent,
        // pipes
        SafePipe,
        PhonePipe,
        SumPipe,
        ThousandsPipe,
        TimePipe,
        MapingStatusPipe,
        SplitNumberPipe,
        BooleanToWordPipe,
        UnixDatePipe,
        FixedPipe,
        TimeAgoPipe,
        SafeHtmlPipe,
        AbbreviationPipe,
        EllipsisPipe,
        ToPercentPipe
    ],
    imports: [
        SvgIconComponent,
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MaterialModule,
        OverlayModule,
        NgbModule,
        NgScrollbarModule,
        ScrollingModule,
        // PerfectScrollbarModule,
        // NgModule,
        // NgbDatepickerModule,
        FileUploadModule
    ],
    exports: [
        MaterialModule,

        // components
        UserActionPopupComponent,
        AppHeaderComponent,
        AppPaginatorComponent,
        // AppFilterComponent,
        AppPaginatorOverlayComponent,
        AppMenuComponent,
        AppDatepickerComponent,
        AppCheckFilterComponent,
        AppButtonToggleComponent,
        AppMultiselectComponent,
        AppMultiselectOverlayComponent,
        AppFilterComponent,
        AppFilterButtonComponent,
        AppFilterPanelComponent,
        AppFilterDialogComponent,
        AppFilterSelectedFieldsComponent,
        AppFileListComponent,
        AppFileUploaderComponent,
        AppFileUploaderBarComponent,
        AppSearchComponent,
        AppSortComponent,
        AppCardTableHeaderComponent,
        AppNumberInputComponent,
        FoundItemsListComponent,
        TextareaAutocompleteComponent,
        TextareaAutocompleteOverlayComponent,
        // pipes
        SafePipe,
        PhonePipe,
        SumPipe,
        ThousandsPipe,
        TimePipe,
        MapingStatusPipe,
        SplitNumberPipe,
        BooleanToWordPipe,
        UnixDatePipe,
        FixedPipe,
        TimeAgoPipe,
        SafeHtmlPipe,
        AbbreviationPipe,
        EllipsisPipe,
        ToPercentPipe
    ],
    providers: [SplitNumberPipe, ThousandsPipe]
})
export class SharedModule { }
