<div class="app-content" fxLayout="row" fxLayoutAlign="start space-between">
    <div class="app-content-wrapper" fxLayout="column" fxLayoutAlign="start stretch" fxFlex="auto" fxLayoutGap="24px">
        <app-header [labelVisible]="false" [backButtonVisible]="false">
            <div fxLayout="row" fxLayoutGap="24" buttons>
                <div class="buttons-container" fxLayout="row">
                    <button class="button" disableRipple (click)="onLogout()">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                            <svg class="repricer-icons login-icon btn-svg-icon-16">
                                <use xlink:href="assets/icons/login-icon.svg#loginIcon" />
                            </svg>
                            <span i18n="@@logout.button.name">Logout</span>
                        </div>
                    </button>
                </div>
            </div>
        </app-header>

        <div class="table-container block" fxLayout="column" fxLayoutAlign="center center">
            <div class="email-confirmation-container"
            [class.progress]="isEmailVerificationInProgress || isEmailVerificationFailed"
            fxLayout="column" fxLayoutAlign="center center">
                @if (isEmailVerificationInProgress) {
                    <div class="email-confirmation__success-icon" style="line-height: 14px;">
                        <svg-icon key="clock" fontSize="90px" color="#00CC66"></svg-icon>
                    </div>
                    <h3 class="email-confirmation__title" i18n="general.text.is.email.verification.in.progress">Verification in progress</h3>
                    <span class="email-confirmation__info" i18n="general.text.is.please.wait.until.the.status.changes">Please wait until the status changes</span>
                }
                @else if (isEmailAddressVerified) {
                    <div class="email-confirmation__success-icon" style="line-height: 14px;">
                        <svg-icon key="check" fontSize="75px" color="#00CC66"></svg-icon>
                    </div>
                    <h3 class="email-confirmation__title" i18n="general.text.is.email.confirmation">Email Confirmation</h3>
                    <span class="email-confirmation__info" i18n="general.text.is.your.email.was.verified">
                        Your email was verified. You can continue using the repricer web-application.</span>
                }
                @else if (isEmailVerificationFailed) {
                    <div class="email-confirmation__success-icon" style="line-height: 14px;">
                        <svg-icon key="error" fontSize="90px" color="#F55826"></svg-icon>
                    </div>
                    <h3 class="email-confirmation__title" i18n="general.text.is.email.confirmation.failed.title">Error</h3>
                    <span class="email-confirmation__info" i18n="general.text.is.your.email.address.could.not.be.verified">Your email address could not be verified.</span>
                    <div class="resend-confirmation">
                        <span i18n="general.text.is.your.link.is.probably.out.of.date">Your link is probably out of date. </span>
                        <span class="btn-link" i18n="general.text.is.resend.confirmation.mail" (click)="onSendMail()">Resend confirmation mail</span>
                    </div>
                }
                @else {
                    <h3 class="email-confirmation__title" i18n="general.text.is.email.confirmation">Email Confirmation</h3>
                    <span class="email-confirmation__info" i18n="general.text.is.we.have.sent.email.to">We have sent email to <strong>{{userEmailFromLocalStorage}}</strong> to confirm the validity of our email address. After receiving the email follow the link provided to complete you registration.</span>
                    <div class="resend-confirmation">
                        <span i18n="general.text.is.if.you.not.got.any.mail">If you not got any mail </span>
                        <span class="btn-link" i18n="general.text.is.resend.confirmation.mail" (click)="onSendMail()">Resend confirmation mail</span>
                    </div>
                }
            </div>
        </div>
    </div>
</div>