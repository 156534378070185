import { Model } from "../shared/interfaces";

export class User implements Model {
  id!: string | number | null;
  name!: string;
  email!: string;
  role!: string;
  password!: string;

  constructor(data?: any) {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this[key as keyof User] = data[key];
        }
      }
    }
  }
}
