import { IListModel } from "../interfaces/IListModel";

export class ItemList<T> implements IListModel<T> {
  /**Page items */
  data!: Array<T>;
  /**Total nubmer of items */
  total!: number;
  /**Total nubmer of items */
  uniqueTotal!: number;
  /**Requested page nubmer */
  page!: number;
  /**Requested page size */
  pageSize!: number;

  constructor(data: any) {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this[key as keyof ItemList<T>] = data[key];
        }
      }
    }
  }
}
