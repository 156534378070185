import { activityHistoryIcon } from './activity-history';
import { addCircleIcon } from './add-circle';
import { amazonLogoIconIcon } from './amazon-logo-icon';
import { analysisIcon } from './analysis';
import { applyIconIcon } from './apply-icon';
import { arrowDownIcon } from './arrow-down';
import { arrowNextIcon } from './arrow-next';
import { articlesIcon } from './articles';
import { attentionIcon } from './attention';
import { backIcon } from './back';
import { basicmainShortdefaultIcon } from './basicmain-shortdefault';
import { basicmainShortdisabledIcon } from './basicmain-shortdisabled';
import { boostIconIcon } from './boost-icon';
import { boxIcon } from './box';
import { buttonrightIcon } from './buttonright';
import { calcIcon } from './calc';
import { calculatorIconIcon } from './calculator-icon';
import { checkPopupIconDarkIcon } from './check-popup-icon-dark';
import { checkIcon } from './check';
import { checkrulesIcon } from './checkrules';
import { circleFullIconIcon } from './circle-full-icon';
import { clockIcon } from './clock';
import { closeIcon } from './close';
import { cogsIcon } from './cogs';
import { competitorIcon } from './competitor';
import { competitorsSearchQueriesIconIcon } from './competitors-search-queries-icon';
import { copyDocumentIcon } from './copy-document';
import { dashboardIcon } from './dashboard';
import { deliveryIcon } from './delivery';
import { diamondIconIcon } from './diamond-icon';
import { dropdownarrowIcon } from './dropdownarrow';
import { ebayLogoIconIcon } from './ebay-logo-icon';
import { editCopyIcon } from './edit-copy';
import { editTagsIcon } from './edit-tags';
import { editIcon } from './edit';
import { errorIcon } from './error';
import { executeIcon } from './execute';
import { expandLinkIcon } from './expand-link';
import { expandIcon } from './expand';
import { feedIcon } from './feed';
import { fileIcon } from './file';
import { frameIcon } from './frame';
import { groupIcon } from './group';
import { infoIconIcon } from './info-icon';
import { likeIconIcon } from './like-icon';
import { listIcon } from './list';
import { logoIcon } from './logo';
import { manageTagIcon } from './manage-tag';
import { menuArrowRightIcon } from './menu-arrow-right';
import { menuIcon } from './menu';
import { moveFileIcon } from './move-file';
import { moveToGroupIcon } from './move-to-group';
import { notCompetitorIcon } from './not-competitor';
import { notificationIcon } from './notification';
import { notmatchIcon } from './notmatch';
import { pickproductIcon } from './pickproduct';
import { postfixIcon } from './postfix';
import { profileIcon } from './profile';
import { questionIcon } from './question';
import { refreshIcon } from './refresh';
import { removeCircleIcon } from './remove-circle';
import { searchIcon } from './search';
import { selectArrowIcon } from './select-arrow';
import { settingsIcon } from './settings';
import { settings1Icon } from './settings1';
import { showPasswordIconIcon } from './show-password-icon';
import { sortDescIcon } from './sort-desc';
import { switchOffIcon } from './switch-off';
import { tagIcon } from './tag';
import { targetIcon } from './target';
import { testIcon } from './test';
import { trashIcon } from './trash';
import { uploadExcelIcon } from './upload-excel';
import { uploadIcon } from './upload';
import { userAccountLogoIcon } from './user-account-logo';
import { warningIcon } from './warning';
export const iconsIcons = [activityHistoryIcon, addCircleIcon, amazonLogoIconIcon, analysisIcon, applyIconIcon, arrowDownIcon, arrowNextIcon, articlesIcon, attentionIcon, backIcon, basicmainShortdefaultIcon, basicmainShortdisabledIcon, boostIconIcon, boxIcon, buttonrightIcon, calcIcon, calculatorIconIcon, checkPopupIconDarkIcon, checkIcon, checkrulesIcon, circleFullIconIcon, clockIcon, closeIcon, cogsIcon, competitorIcon, competitorsSearchQueriesIconIcon, copyDocumentIcon, dashboardIcon, deliveryIcon, diamondIconIcon, dropdownarrowIcon, ebayLogoIconIcon, editCopyIcon, editTagsIcon, editIcon, errorIcon, executeIcon, expandLinkIcon, expandIcon, feedIcon, fileIcon, frameIcon, groupIcon, infoIconIcon, likeIconIcon, listIcon, logoIcon, manageTagIcon, menuArrowRightIcon, menuIcon, moveFileIcon, moveToGroupIcon, notCompetitorIcon, notificationIcon, notmatchIcon, pickproductIcon, postfixIcon, profileIcon, questionIcon, refreshIcon, removeCircleIcon, searchIcon, selectArrowIcon, settingsIcon, settings1Icon, showPasswordIconIcon, sortDescIcon, switchOffIcon, tagIcon, targetIcon, testIcon, trashIcon, uploadExcelIcon, uploadIcon, userAccountLogoIcon, warningIcon];
