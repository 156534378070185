import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagDetailsDialogComponent } from './tag-details-dialog/tag-details-dialog.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SharedModule } from 'src/app/shared/shared.module';
// Import the library
import { SvgIconComponent } from '@ngneat/svg-icon';


@NgModule({
    declarations: [
        TagDetailsDialogComponent,
    ],
    imports: [
        SvgIconComponent,
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        NgScrollbarModule,
        SharedModule,
    ],
    exports: [
        TagDetailsDialogComponent,
    ]
})
export class TagSharedModule { }