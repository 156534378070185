import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { AppHeaderService } from './app-header.service';
import * as _ from 'underscore';
import { Subscription } from 'rxjs';
import { AuthorizationService, BaseApiService } from 'src/app/api';
import { ActivatedRoute, Router } from '@angular/router';
import { accessMap } from 'src/app/route-map';
import { AppText } from 'src/app/text/app-text';
import { IHeaderSettings } from '../../interfaces';
import { Breadcrumbs } from 'src/app/contracts';



@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit, OnDestroy {
    /**
     * Subscriptions
     */
    private subscriptions!: Subscription[] | undefined;
    /**
     * Текущая страница
     */
    private currentPage!: string;
    /**
     * Страница по умолчанию для юзера
     */
    private userDefaultPage!: string;
    /**
     * Настройки для хэдэра
     * @type {string}
     */
    public settings!: Partial<IHeaderSettings>;

    breadcrumbs: Breadcrumbs[] = [];
    /**
     * Id
     * @type {string}
     */
    @Input() id!: string | number | null;
    /**
     * Id
     * @type {string}
     */
    @Input() label!: string | number | null;
    /**
     * Лейбл
     * @type {boolean}
     */
    @Input() labelVisible: boolean = true;
    /**
     * Загрузка Лейбла
     * @type {boolean}
     */
    @Input() isLabelLoading: boolean = false;
    /**
     * Текст после хедера
     * @type {string}
     */
    @Input() postLabel!: string;
    /**
     * Количество записей
     * @type {number}
     */
    @Input() totalRecords!: number;
    /**
     * Текущая запись
     * @type {number}
     */
    @Input() currentRecord!: number;
    /**
     * Блокирование кнопок
     * @type {boolean}
     */
    @Input() disabled: boolean;
    /**
     * Копка назад
     * @type {boolean}
     */
    @Input() backButtonVisible: boolean = false;

    @Output() onBack = new EventEmitter();

    /**
     * Флаг ожидания ответа на запрос
     */
    public get inProgressState(): boolean {
        return this.baseApiService?.loadInProgress;
    }

    /**
     * The user name
     */
    public get userName(): string {
        return this.authService?.userProfile?.firstName || 'null';
    }

    /**
     * The user role
     */
    public get userRole(): string {
        return this.authService?.userProfile?.role ? this.authService?.userProfile?.role : 'null';
    }

    /**
     * Пользователь находится на собственной странице по умолчанию
     */
    public get currentPageIsDefaultPage(): boolean {
        return this.currentPage == this.userDefaultPage ? true : false;
    }

    constructor(
        private service: AppHeaderService,
        private authService: AuthorizationService,
        private baseApiService: BaseApiService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        this.settings = {};
        this.disabled = false;
        this.userDefaultPage = accessMap.find(map => map?.role === this.authService.userProfile?.role)?.defaultRoute || '';
        this.currentPage = this.router?.url?.split('?')?.[0] || '';
        this.currentPage = this.currentPage?.substring(1, this.currentPage?.length) || '';
    }

    public ngOnInit(): void {
        let url = this.router.url.split('?');
        let segments = url[0].split('/');

        segments.forEach(segment => {
            let breadcrumb = AppText.breadcrumbs.find(b => b.route === segment);
            if (breadcrumb) {
                this.breadcrumbs.push(breadcrumb);
            }
        });

        this.initSubscription();
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe();
        this.settings = this.service.setDefaultSettings();
    }

    public onDefaultPage(): void {
        if (this.currentPageIsDefaultPage) {
            return;
        }
        this.router.navigate([this.userDefaultPage]);
    }

    onBackPage() {
        this.onBack.emit();
    }

    goToBreadcrumb(breadcrumb: Breadcrumbs) {
        if (!this.id) {
            this.router.navigate([breadcrumb.url]);
        } else {
            this.router.navigate([`${breadcrumb.url}/${this.id}`]);
        }
    }

    /**
     * The logout method
     */
    public onLogout(): void {
        this.authService.logout();
    }

    private initSubscription(): void {
        const sub = this.service.setSettings
            .subscribe((params: Partial<IHeaderSettings>) => {

                this.settings = this.settings
                    ? this.settings
                    : {};

                Object.entries(params).forEach(([flagName, value]) => {
                    this.settings[flagName as keyof Partial<IHeaderSettings>] = <boolean>value;
                });

            });

        this.subscriptions = this.subscriptions ? this.subscriptions : [];
        this.subscriptions.push(sub);
    }

    private ngUnsubscribe(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
        }
        this.subscriptions = undefined;
    }
}
