import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingRoutingModule } from './loading-routing.module';
import { LoadingComponent } from './loading.component';



@NgModule({
  declarations: [
    LoadingComponent
  ],
  imports: [
    RouterModule,
    LoadingRoutingModule
  ]
})
export class LoadingModule { }
