import { Token } from '.';
import { ColumnView, TableSettings } from '../contracts';
import { ArticleCustomField } from '../contracts/article-custom-field';

export class LocalStorage {
    private static tokenKey = 'repricer.token';
    private static tokenLifeTime = 'repricer.token.life.time';
    private static isMenuCompact = 'menu-compact-flag';
    private static isFilterCompact = 'filter-compact-flag';
    private static isTegsHintDoNotShow = 'do-not-show-tag-hint';

    private static columnSettings = 'table-column-settings';
    private static customFieldColumnSettings = 'table-custom-field-column-settings';

    private static pageSize = 'page-size';

    private static filterWidth = 'filter-width';

    private static isTableCompact = 'table-compact';

    private static temporaryTokenOfNotEmailVerifiedUser = 'repricer.temporary.token';
    private static returnToListAfterDetails = 'repricer.return.to.list.after.details';

    // token
    public static getToken(): Token | null {
        const tokenString = localStorage[LocalStorage.tokenKey];
        return tokenString ? <Token>JSON.parse(tokenString) : null;
    }

    public static getTemporaryTokenOfNotEmailVerifiedUser(): string | null {
        const tokenString = localStorage[LocalStorage.temporaryTokenOfNotEmailVerifiedUser];
        return tokenString ? <string>JSON.parse(tokenString) : null;
    }

    // token life time
    public static getTokenLifeTime(): number {
        const lifeTime = localStorage[LocalStorage.tokenLifeTime];
        return lifeTime ? JSON.parse(lifeTime) : null;
    }

    // menu compact
    public static getMenuCompactFlag(): boolean | null {
        const flag = localStorage[LocalStorage.isMenuCompact];
        return flag ? JSON.parse(flag) : null;
    }

    // filter compact
    public static getFilterCompactFlag(): boolean | null {
        const flag = localStorage[LocalStorage.isFilterCompact];
        return flag ? JSON.parse(flag) : null;
    }

    public static getTagsHint(): boolean | null {
        const flag = localStorage[LocalStorage.isTegsHintDoNotShow];
        return flag ? JSON.parse(flag) : null;
    }

    // column settings
    public static getColumnSettings(): ColumnView[] | null {
        const value = localStorage[LocalStorage.columnSettings];
        return value ? JSON.parse(value) : null;
    }

    // column settings
    public static getCustomFieldColumnSettings(): ArticleCustomField[] | null {
        const value = localStorage[LocalStorage.customFieldColumnSettings];
        return value ? JSON.parse(value) : null;
    }

    // token life time
    public static getPageSize(): number | null {
        const pageSize = localStorage[LocalStorage.pageSize];
        return pageSize ? JSON.parse(pageSize) : null;
    }

    // token life time
    public static getFilterWidth(): number | null {
        const filterWidth = localStorage[LocalStorage.filterWidth];
        return filterWidth ? JSON.parse(filterWidth) : null;
    }

    // tableCompactFlag
    public static getTableCompactFlag(): boolean | null {
        const flag = localStorage[LocalStorage.isTableCompact];
        return flag ? JSON.parse(flag) : null;
    }

    public static setToken(token: Token | null) {
        if (token === null) {
            localStorage.removeItem(LocalStorage.tokenKey);
            return;
        }
        localStorage[LocalStorage.tokenKey] = JSON.stringify(token);
    }

    public static setTemporaryTokenOfNotEmailVerifiedUser(flag: string | null) {
        if (flag === null) {
            localStorage.removeItem(LocalStorage.temporaryTokenOfNotEmailVerifiedUser);
            return;
        }
        localStorage[LocalStorage.temporaryTokenOfNotEmailVerifiedUser] = JSON.stringify(flag);
    }

    public static setTokenLifeTime(lifeTime: number | null) {
        if (lifeTime === null) {
            localStorage.removeItem(LocalStorage.tokenLifeTime);
            return;
        }
        localStorage[LocalStorage.tokenLifeTime] = JSON.stringify(lifeTime);
    }

    public static setMenuCompactFlag(flag: boolean | null) {
        if (flag === null) {
            localStorage.removeItem(LocalStorage.isMenuCompact);
            return;
        }
        localStorage[LocalStorage.isMenuCompact] = JSON.stringify(flag);
    }

    public static setFiltersCompactFlag(flag: boolean | null) {
        if (flag === null) {
            localStorage.removeItem(LocalStorage.isFilterCompact);
            return;
        }
        localStorage[LocalStorage.isFilterCompact] = JSON.stringify(flag);
    }

    public static setTagsHint(flag: boolean | null) {
        if (flag === null) {
            localStorage.removeItem(LocalStorage.isTegsHintDoNotShow);
            return;
        }
        localStorage[LocalStorage.isTegsHintDoNotShow] = JSON.stringify(flag);
    }

    public static setColumnSettings(settings: ColumnView[] | null) {
        if (settings === null) {
            localStorage.removeItem(LocalStorage.columnSettings);
            return;
        }
        localStorage[LocalStorage.columnSettings] = JSON.stringify(settings);
    }

    public static setCustomFieldColumnSettings(settings: ArticleCustomField[] | null) {
        if (settings === null) {
            localStorage.removeItem(LocalStorage.customFieldColumnSettings);
            return;
        }
        localStorage[LocalStorage.customFieldColumnSettings] = JSON.stringify(settings);
    }

    public static setPageSize(pageSize: number | null) {
        if (pageSize === null) {
            localStorage.removeItem(LocalStorage.pageSize);
            return;
        }
        localStorage[LocalStorage.pageSize] = JSON.stringify(pageSize);
    }

    public static setFilterWidth(filterWidth: number | null) {
        if (filterWidth === null) {
            localStorage.removeItem(LocalStorage.filterWidth);
            return;
        }
        localStorage[LocalStorage.filterWidth] = JSON.stringify(filterWidth);
    }

    public static setTableCompactFlag(flag: boolean | null) {
        if (flag === null) {
            localStorage.removeItem(LocalStorage.isTableCompact);
            return;
        }
        localStorage[LocalStorage.isTableCompact] = JSON.stringify(flag);
    }

    public static getDetailsId(): string | null {
        const id = localStorage[LocalStorage.returnToListAfterDetails];
        return id ? JSON.parse(id) : null;
    }

    public static setDetailsId(id: string | null) {
        if (id === null) {
            localStorage.removeItem(LocalStorage.returnToListAfterDetails);
            return;
        }
        localStorage[LocalStorage.returnToListAfterDetails] = JSON.stringify(id);
    }
}
