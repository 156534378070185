import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatRadioModule } from '@angular/material/radio';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
    declarations: [],
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatTableModule,
        MatDialogModule,
        MatTabsModule,
        MatMenuModule,
        MatTooltipModule,
        ScrollingModule,
        MatProgressBarModule,
        MatTreeModule,
        MatCheckboxModule,
        MatIconModule,
        DragDropModule,
        MatListModule,
        MatChipsModule,
        MatIconModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatRadioModule,
        OverlayModule,
        TextFieldModule,
        MatSnackBarModule
    ],
    exports: [
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatTableModule,
        MatDialogModule,
        MatTabsModule,
        MatMenuModule,
        MatTooltipModule,
        ScrollingModule,
        MatProgressBarModule,
        MatTreeModule,
        MatCheckboxModule,
        MatIconModule,
        DragDropModule,
        MatListModule,
        MatChipsModule,
        MatIconModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatRadioModule,
        OverlayModule,
        TextFieldModule,
        MatSnackBarModule
    ]
})
export class MaterialModule { }
