import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subject } from 'rxjs';
import { SimpleDataFilter } from 'src/app/contracts';
import { ListItem } from 'src/app/shared/contracts';
import { AppText } from 'src/app/text/app-text';
import * as _ from 'underscore';
import { FilterSelectedField } from '../app-filter-selected-fields/filter-selected-field';
import { FilterSettings } from '../shared/filter-settings';
import { ListerPlatformTypes } from 'src/app/articles/shared/article.model';

@Component({
    selector: 'app-filter-panel',
    templateUrl: './app-filter-panel.component.html',
    styleUrls: ['./app-filter-panel.component.scss']
})
export class AppFilterPanelComponent implements OnChanges, OnInit {
    /**
     * Измененные параметры, которые еще не отправлены в родителю
     * @type {SimpleDataFilter}
     */
    private changedFilterParams!: SimpleDataFilter;
    /**
     * Минимальное значение уверенности
     * @type {string}
     */
    private confidenceMin: number = 0.01;

    /**
     * Максимаьное значение уверенности
     * @type {string}
     */
    private confidenceMax: number = 1;

    /**
     * Минимальная уверенность
     * Для увлечения/уменьшения числа по клику
     * @type {string}
     */
    protected confidenceFrom!: string | null;

    /**
     * Максимаьная уверенность
     * Для увлечения/уменьшения числа по клику
     * @type {string}
     */
    protected confidenceTo!: string | null;

    private rankMin: number = 1;
    private rankMax: number = 100;
    protected fromMarketplaceRank!: string | null;
    protected toMarketplaceRank!: string | null;
    protected fromVisibilityRank!: string | null;
    protected toVisibilityRank!: string | null;

    private priceDifferenceStep: number = 0.05;
    private priceDifferenceMin: number = -9999999;
    private priceDifferenceMax: number = 9999999;
    protected fromPrice!: string | null;
    protected toPrice!: string | null;
    protected fromCompetitorPrice!: string | null;
    protected toCompetitorPrice!: string | null;
    protected fromPriceDifferencePercent!: string | null;
    protected toPriceDifferencePercent!: string | null;

    /**
     * Список выбранных правил на основе которых было принято решение
     * @type {ListItem[]}
     */
    protected selectedDecisionRules!: ListItem[];

    /**
     * Список выбранных правил на основе которых было принято решение
     * @type {ListItem[]}
     */
    protected selectedMatchRules!: ListItem[];

    /**
     * Список выбранных групп артиклей
     * @type {ListItem[]}
     */
    protected selectedArticleGroups!: ListItem[];

    /**
     * Список выбранных стратегий
     * @type {ListItem[]}
     */
    protected selectedStrategies!: ListItem[];

    /**
     * Список выбранных машин
     * @type {ListItem[]}
     */
    protected selectedCars!: ListItem[];

    /**
     * Список выбранных полей (включает только мультиселекты)
     * @type {FilterSelectedField[]}
     */
    protected selectedFields!: Array<FilterSelectedField>;

    /**
     * Флаг нахождения артиклей на репрайсинге
     * @type {boolean}
     */
    public articlesExcluded!: boolean | null;

    /**
     * Флаг нахождения артиклей на бустинге
     * @type {boolean}
     */
    public articlesBoosted!: boolean | null;

    /**
     * Флаг нахождения артиклей на бустинге
     * @type {boolean}
     */
    public listerPlatformType!: ListerPlatformTypes | null;

    /**
     * Чекбокс для артиклей
     * @type {boolean}
     */
    public articlesWithoutCompetitors!: boolean | null;

    /**
     * Флаг для артиклей с/без мануальных запросов
     * @type {boolean}
     */
    public manuallyCreatedSearchRequests!: boolean | null;

    /**
     * Чекбокс для артиклей для конкурентов
     * Потенциальные конкуренты
     * @type {boolean}
     */
    public potentialCompetitors!: boolean | null;

    public competitorsWithoutSales!: boolean | null;

    public competitorsWithLongShipping!: boolean | null;

    /**
     * Чекбокс для артиклей для конкурентов
     * PotentialСompetitors не равно Сompetitors(!)
     * @type {boolean}
     */
    public isCompetitor!: boolean | null;

    public inactiveCompetitors!: boolean | null;

    /**
     * Флаг валидации конкурентов
     * @type {boolean}
     */
    public validated!: boolean | null;

    /**
     * Ручная валидация совпала с валидацией по правилам:
     * null - all, все
     * true - right, совпали
     * false - wrong, не совпали
     * @type {boolean}
     */
    public validationMatchesRules!: boolean | null;

    /**
     * Флаг для конкурентов: является ли конкурент вариантом
     * @type {boolean}
     */
    public markedAsVariant!: boolean | null;

    /**
     * Значение цены "от"
     * @type {number}
     */
    public priceFrom!: number | null;

    /**
     * Значение цены "до"
     * @type {number}
     */
    public priceTo!: number | null;

    /**
     * Регулярное выражение для цен
     * @type {number}
     */
    public priceRegex: RegExp = /^(([1-9]{1})([0-9]{1,6})?((\.|\,)[0-9]{1,2})?)$/;

    /**
     * Значение HSN
     * @type {number}
     */
    public hsn!: string | null;

    /**
     * Значение TSN
     * @type {number}
     */
    public tsn!: string | null;

    /**
     * Регулярное выражение для HSN
     * @type {number}
     */
    public hsnRegex: RegExp = /^[0-9]{1,4}$/;

    /**
     * Регулярное выражение для TSN
     * @type {number}
     */
    public tsnRegex: RegExp = /^[0-9A-Za-z]{1,3}$/;

    /**
     * Регулярное выражение для HSN-TSN
     * @type {number}
     */
    public hsnTsnRegex: RegExp = /^[0-9A-Za-z]{4}(-|\s|\/)[0-9A-Za-z]{3}$/;

    public carsChange: Subject<ListItem[]> = new Subject<ListItem[]>();

    pageText = {
        excludedFilterOptions: AppText.excludedFilterOptions,
        boostedFilterOptions: AppText.boostedFilterOptions,
        platformFilterOptions: AppText.platformFilterOptions,
        manuallyCreatedSearchRequestsFilterOptions: AppText.manuallyCreatedSearchRequestsFilterOptions,
        competitorFilterOptions: AppText.competitorFilterOptions,
        validationFilterOptions: AppText.validationFilterOptions,
        variationFilterOptions: AppText.variationFilterOptions,
        validationMatchesRulesFilterOptions: AppText.validationMatchesRulesFilterOptions,
        yes: AppText.yesAction,
        no: AppText.noAction,
        articles: AppText.mainMenuItemIsArticles,
        articleGroups: AppText.mainMenuItemIsArticleGroups,
        strategies: AppText.mainMenuItemIsStrategies,
        showAction: AppText.showAction,
        displayOptions: AppText.generalTextIsDisplayOptions,
        competitors: AppText.mainMenuItemIsCompetitors,
        rules: AppText.pageHeaderIsRules,
        confidence: AppText.generalTextIsConfidence,
        potentialCompetitors: AppText.entityIsPotentialCompetitors,
        competition: AppText.propertyPlaceholderCompetition,
        validation: AppText.propertyPlaceholderValidation,
        variation: AppText.propertyPlaceholderVariation,
        productPrice: AppText.propertyPlaceholderProductPrice,
        platformFiltersPlaceholder: AppText.generalTextIsFilters,
        entityIsOneCar: AppText.entityIsOneCar,
        generalTextIsSelectCar: AppText.generalTextIsSelectCar,
        generalTextIsHsn: AppText.generalTextIsHsn,
        generalTextIsTsn: AppText.generalTextIsTsn,
        HSNTSNPlaceholder: `${AppText.generalTextIsHsn}-${AppText.generalTextIsTsn} (0000-000)`,
        generalTextIsArticlesOnRepricing: AppText.generalTextIsArticlesOnRepricing,
        generalTextIsValidationMatchesRules: AppText.generalTextIsValidationMatchesRules,
        marketplaceRank: AppText.sortFieldIsMarketplaceRank,
        visibilityRank: AppText.sortFieldIsVisibilityRank,
        priceDifference: AppText.propertyPlaceholderPrice,
        priceDifferencePercent: AppText.sortFieldIsPriceDifference,
        tooltipMessageShowOutOfStockCompetitors: AppText.tooltipMessageShowOutOfStockCompetitors,
        showPotentialCompetitors: AppText.propertyPlaceholderShowPotentialCompetitors,
        showCompetitorsWithoutSales: AppText.propertyPlaceholderShowCompetitorsWithoutSales,
        showCompetitorsWithLongShippingTimes: AppText.propertyPlaceholderShowCompetitorsWithLongShippingTimes,
        articlesBoostedLabel: AppText.generalTextIsBoosting,
        articlesPlatformLabel: AppText.generalTextIsPlatform,
    };

    /**
     * Параметры для изменения
     * @type {boolean}
     */
    @Input() panelStateOpen!: boolean;

    /**
     * Параметры для изменения
     * @type {SimpleDataFilter}
     */
    @Input() filterParams!: SimpleDataFilter;

    /**
     * Настройки видимости фильтров
     * @type {FilterSettings}
     */
    @Input() filterSettings: FilterSettings = new FilterSettings();

    /**
     * Список правил на основе которых было принято решение
     * @type {ListItem[]}
     */
    @Input() decisionRules!: ListItem[];

    /**
     * Список правил на основе которых было принято решение
     * @type {ListItem[]}
     */
    @Input() matchRules!: ListItem[];

    /**
     * Список групп артиклей
     * @type {ListItem[]}
     */
    @Input() articleGroups!: ListItem[];

    /**
     * Список стратегий
     * @type {ListItem[]}
     */
    @Input() strategies!: ListItem[];

    /**
     * Список машин
     * @type {ListItem[]}
     */
    @Input() cars!: ListItem[];

    /**
     * Событие о том, что панель закрылась
     * Передает флаг о том были ли изменения или нет
     * и параметры фильтра
     * @type {EventEmitter<boolean>}
     */
    @Output() filterPanelClosed!: EventEmitter<{ changes: boolean; params: SimpleDataFilter }>;

    /**
     * Событие о том, что параметры фильтра изменились
     * @type {EventEmitter<SimpleDataFilter>}
     */
    @Output() selectedFieldsChanged!: EventEmitter<Array<FilterSelectedField>>;

    /**
     * Событие о том, что значение поиска в селектах было изменено
     * @type {EventEmitter<boolean>}
     */
    @Output() searchChange!: EventEmitter<{ search: string | null; arrayName: string }>;

    constructor() {
        this.changedFilterParams = new SimpleDataFilter({});
        this.filterParams = new SimpleDataFilter({});
        this.decisionRules = [];
        this.selectedDecisionRules = [];
        this.matchRules = [];
        this.selectedMatchRules = [];
        this.articleGroups = [];
        this.selectedArticleGroups = [];
        this.strategies = [];
        this.selectedStrategies = [];
        this.cars = [];
        this.selectedCars = [];
        this.articlesExcluded = null;
        this.articlesBoosted = null;
        this.listerPlatformType = null;
        this.articlesWithoutCompetitors = null;
        this.manuallyCreatedSearchRequests = null;
        this.confidenceFrom = null;
        this.confidenceTo = null;
        this.fromMarketplaceRank = null;
        this.toMarketplaceRank = null;
        this.fromVisibilityRank = null;
        this.toVisibilityRank = null;
        this.priceFrom = null;
        this.priceTo = null;
        this.hsn = null;
        this.tsn = null;
        this.fromPrice = null;
        this.toPrice = null;
        this.fromCompetitorPrice = null;
        this.toCompetitorPrice = null;
        this.fromPriceDifferencePercent = null;
        this.toPriceDifferencePercent = null;
        this.potentialCompetitors = null;
        this.competitorsWithoutSales = null;
        this.competitorsWithLongShipping = null;
        this.isCompetitor = null;
        this.inactiveCompetitors = null;
        this.validated = null;
        this.validationMatchesRules = null;
        this.markedAsVariant = null;
        this.filterPanelClosed = new EventEmitter<{ changes: boolean; params: SimpleDataFilter }>();
        this.selectedFieldsChanged = new EventEmitter<Array<FilterSelectedField>>();
        this.searchChange = new EventEmitter<{ search: string | null; arrayName: string }>();
    }

    @HostListener('document:keyup.escape', ['$event'])
    public onKeydownHandler(event: KeyboardEvent): void {
        event?.stopPropagation();
        this.onClose();
    }

    @ViewChild('confidenceFromInput', { static: false }) confidenceFromInput?: any;
    @ViewChild('confidenceToInput', { static: false }) confidenceToInput?: any;
    @ViewChild('rankFromInput', { static: false }) rankFromInput?: any;
    @ViewChild('rankToInput', { static: false }) rankToInput?: any;
    @ViewChild('visibilityRankFromInput', { static: false }) visibilityRankFromInput?: any;
    @ViewChild('visibilityRankToInput', { static: false }) visibilityRankToInput?: any;
    @ViewChild('articlesOptions', { static: false }) articlesOptions?: ElementRef;
    @ViewChild('competitorsOptions', { static: false }) competitorsOptions?: ElementRef;
    @ViewChild('hsnInput', { static: false }) hsnInput?: any;
    @ViewChild('tsnInput', { static: false }) tsnInput?: any;
    @ViewChild('priceFromInput', { static: false }) priceFromInput?: any;
    @ViewChild('priceToInput', { static: false }) priceToInput?: any;
    @ViewChild('priceDifferencePercentFromInput', { static: false }) priceDifferencePercentFromInput?: any;
    @ViewChild('priceDifferencePercentToInput', { static: false }) priceDifferencePercentToInput?: any;
    @ViewChild('fromCompetitorPriceInput', { static: false }) fromCompetitorPriceInput?: any;
    @ViewChild('toCompetitorPriceInput', { static: false }) toCompetitorPriceInput?: any;

    /**
     * Отслеживаются изменения при поступлении
     * @param {SimpleChange} changes Изменения входных полей
     */
    public ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
        if (changes['decisionRules'] || changes['matchRules'] || changes['articleGroups'] || changes['strategies'] || changes['cars']) {
            this.updateSelects();
        }
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit(): void {
        // Запоминаем для дальнейшего обнаружения изменений
        this.changedFilterParams = _.clone(this.filterParams);
        this.setArticleCompetitorsCheckboxes(this.filterParams);
        this.setConfidence(this.filterParams);
        this.setRank(this.filterParams);
        this.setPriceDifference(this.filterParams);
        this.setPriceFrom(this.filterParams);
        this.setPriceTo(this.filterParams);
        this.setHsn(this.filterParams);
        this.setTsn(this.filterParams);
        this.updateSelectedFields(true);
    }

    /**
     * Скроллит к пункту артикля
     */
    public onScrollToArticle(): void {
        if (this.articlesOptions) {
            this.articlesOptions.nativeElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    /**
     * Скроллит к пункту конкурентам
     */
    public onScrollToCompetitor(): void {
        if (this.competitorsOptions) {
            this.competitorsOptions.nativeElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    /**
     * Применение фильтра по общей кнопке apply
     */
    public onApplyFilter(): void {
        this.onCloseWithChanges();
    }

    /**
     * Сброс фильтра по общей кнопке reset
     */
    public onResetFilter(): void {
        this.onResetSelects();
        if (this.filterSettings?.confidenceFilters) {
            this.confidenceFrom = null;
            this.confidenceTo = null;
            this.changedFilterParams.confidenceFrom = null;
            this.changedFilterParams.confidenceTo = null;
        }
        if (this.filterSettings?.rankFilters) {
            this.fromMarketplaceRank = null;
            this.toMarketplaceRank = null;
            this.changedFilterParams.fromMarketplaceRank = null;
            this.changedFilterParams.toMarketplaceRank = null;
            this.fromVisibilityRank = null;
            this.toVisibilityRank = null;
            this.changedFilterParams.fromVisibilityRank = null;
            this.changedFilterParams.toVisibilityRank = null;
        }
        if (this.filterSettings?.priceDifferenceFilters) {
            this.fromPrice = null;
            this.toPrice = null;
            this.changedFilterParams.fromPrice = null;
            this.changedFilterParams.toPrice = null;
            this.fromPriceDifferencePercent = null;
            this.toPriceDifferencePercent = null;
            this.changedFilterParams.fromPriceDifferencePercent = null;
            this.changedFilterParams.toPriceDifferencePercent = null;
        }
        if (this.filterSettings?.priceDifferenceCompetitorFilters) {
            this.fromCompetitorPrice = null;
            this.toCompetitorPrice = null;
            this.changedFilterParams.fromCompetitorPrice = null;
            this.changedFilterParams.toCompetitorPrice = null;
        }
        if (this.filterSettings?.priceFilters) {
            this.priceFrom = null;
            this.priceTo = null;
            this.changedFilterParams.priceFrom = null;
            this.changedFilterParams.priceTo = null;
        }
        if (this.filterSettings?.hsnTsnFilters) {
            this.hsn = null;
            this.tsn = null;
            this.changedFilterParams.hsn = null;
            this.changedFilterParams.tsn = null;
        }
        if (this.filterSettings?.articlesExcludedOption) {
            this.articlesExcluded = null;
            this.changedFilterParams.excluded = null;
        }
        if (this.filterSettings?.articlesBoostedFilter) {
            this.articlesBoosted = null;
            this.changedFilterParams.boosted = null;
        }
        if (this.filterSettings?.articlesPlatformFilter) {
            this.listerPlatformType = null;
            this.changedFilterParams.listerPlatformType = null;
        }
        if (this.filterSettings?.articlesWithoutCompetitorsOption) {
            this.articlesWithoutCompetitors = null;
            this.changedFilterParams.noCompetitors = null;
        }
        if (this.filterSettings?.manuallyCreatedSearchesFilter) {
            this.manuallyCreatedSearchRequests = null;
            this.changedFilterParams.noManualSearchRequests = null;
        }
        if (this.filterSettings?.competitorFilters || this.filterSettings?.competitorsOption) {
            this.potentialCompetitors = null;
            this.changedFilterParams.potentialCompetitors = null;
            this.competitorsWithoutSales = null;
            this.changedFilterParams.competitorsWithoutSales = null;
            this.competitorsWithLongShipping = null;
            this.changedFilterParams.competitorsWithLongShipping = null;
            this.isCompetitor = null;
            this.changedFilterParams.isCompetitor = null;
            this.inactiveCompetitors = null;
            this.changedFilterParams.inactiveCompetitors = null;
        }
        if (this.filterSettings?.validationCompetitorsFilter) {
            this.validated = null;
            this.changedFilterParams.validated = null;
        }
        if (this.filterSettings?.validationMatchesRulesCompetitorsFilter) {
            this.validationMatchesRules = null;
            this.changedFilterParams.validationMatchesRules = null;
        }
        if (this.filterSettings?.variationCompetitorsFilter) {
            this.markedAsVariant = null;
            this.changedFilterParams.markedAsVariant = null;
        }
        this.onCloseWithChanges();
    }

    /**
     * Сброс селектов
     * @param {boolean} closeFilter Флаг для закрытия фильтра
     */
    public onResetSelects(closeFilter: boolean = false): void {
        if (this.filterSettings?.decisionRuleFilter) {
            this.selectedDecisionRules = [];
            this.changedFilterParams.decisionRuleIds = null;
        }
        if (this.filterSettings?.rulesMatchFilter) {
            this.selectedMatchRules = [];
            this.changedFilterParams.matchRuleIds = null;
        }
        if (this.filterSettings?.articleGroupFilter) {
            this.selectedArticleGroups = [];
            this.changedFilterParams.articleGroupIds = null;
        }
        if (this.filterSettings?.strategiesFilter) {
            this.selectedStrategies = [];
            this.changedFilterParams.strategyIds = null;
        }
        if (this.filterSettings?.carsFilter) {
            this.selectedCars = [];
            this.changedFilterParams.carIds = null;
        }
        if (closeFilter) {
            this.onCloseWithChanges();
        }
    }

    /**
     * Сброс конкретного тега по клику на крестик
     * (крестик в самом теге находится)
     * @param {FilterSelectedField} selectedField Выбранный элемент в тегах
     */
    public onResetSelectItemBySelectedFieldIndex(selectedField: FilterSelectedField): void {
        if (!selectedField) {
            return;
        }

        let arrayKeys = ['selectedDecisionRules', 'selectedMatchRules', 'selectedArticleGroups', 'selectedStrategies', 'selectedCars'];

        if (arrayKeys.includes(selectedField.collectionKey)) {
            let selectedCollection: [] = this[selectedField.collectionKey as keyof AppFilterPanelComponent];
            let foundItem = _.find(selectedCollection, (item: any) => {
                return selectedField.selectedItemUniqueFieldValue == item[selectedField.selectedItemUniqueFieldKey];
            });
            if (!foundItem) {
                this.changedFilterParams.decisionRuleIds = this.getKeysStringFromArray(this.selectedDecisionRules, 'key') || null;
                this.changedFilterParams.matchRuleIds = this.getKeysStringFromArray(this.selectedMatchRules, 'key') || null;
                this.changedFilterParams.articleGroupIds = this.getKeysStringFromArray(this.selectedArticleGroups, 'key') || null;
                this.changedFilterParams.strategyIds = this.getKeysStringFromArray(this.selectedStrategies, 'key') || null;
                this.changedFilterParams.carIds = this.getKeysStringFromArray(this.selectedCars, 'key') || null;
                this.updateSelectedFields();
                this.onApplyFilter();
                return;
            }
            /** Поиск в массиве */
            const index = selectedCollection.indexOf(foundItem);
            if (index > -1) {
                try {
                    this[selectedField.collectionKey as keyof AppFilterPanelComponent].splice(index, 1);
                    this.changedFilterParams.decisionRuleIds = this.getKeysStringFromArray(this.selectedDecisionRules, 'key') || null;
                    this.changedFilterParams.matchRuleIds = this.getKeysStringFromArray(this.selectedMatchRules, 'key') || null;
                    this.changedFilterParams.articleGroupIds = this.getKeysStringFromArray(this.selectedArticleGroups, 'key') || null;
                    this.changedFilterParams.strategyIds = this.getKeysStringFromArray(this.selectedStrategies, 'key') || null;
                    this.changedFilterParams.carIds = this.getKeysStringFromArray(this.selectedCars, 'key') || null;
                    this.updateSelectedFields();
                    this.onApplyFilter();
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            if (selectedField.selectedItemUniqueFieldKey == 'articlesExcluded') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.excluded = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'articlesBoosted') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.boosted = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'listerPlatformType') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.listerPlatformType = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'articlesWithoutCompetitors') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.noCompetitors = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'noManualSearchRequests') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.noManualSearchRequests = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'potentialCompetitors') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.potentialCompetitors = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'competitorsWithoutSales') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.competitorsWithoutSales = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'competitorsWithLongShipping') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.competitorsWithLongShipping = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'isCompetitor') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.isCompetitor = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'inactiveCompetitors') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.inactiveCompetitors = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'validated') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.validated = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'validationMatchesRules') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.validationMatchesRules = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'markedAsVariant') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.markedAsVariant = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'confidenceFrom') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.confidenceFrom = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'confidenceTo') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.confidenceTo = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'fromMarketplaceRank') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.fromMarketplaceRank = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'toMarketplaceRank') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.toMarketplaceRank = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'fromVisibilityRank') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.fromVisibilityRank = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'toVisibilityRank') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.toVisibilityRank = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'fromPrice') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.fromPrice = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'toPrice') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.toPrice = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'fromPriceDifferencePercent') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.fromPriceDifferencePercent = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'toPriceDifferencePercent') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.toPriceDifferencePercent = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'fromCompetitorPrice') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.fromCompetitorPrice = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'toCompetitorPrice') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.toCompetitorPrice = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'priceFrom') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.priceFrom = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'priceTo') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.priceTo = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'hsn') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.hsn = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            if (selectedField.selectedItemUniqueFieldKey == 'tsn') {
                this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent] = null;
                this.changedFilterParams.tsn = this[selectedField.selectedItemUniqueFieldKey as keyof AppFilterPanelComponent];
            }
            this.updateSelectedFields();
            this.onApplyFilter();
        }
    }

    /**
     * Сброс фильтра по общей кнопке reset
     */
    public onCloseWithChanges(): void {
        this.updateSelectedFields(true);
        this.filterPanelClosed.next({ changes: true, params: this.changedFilterParams });
    }

    /**
     * Сброс фильтра по общей кнопке reset
     */
    public onClose(): void {
        this.panelStateOpen = false;
        this.filterPanelClosed.next({ changes: false, params: this.filterParams });
    }

    /**
     * Метод обновляет списки выбранных элементов
     * @param {string} selectedItems Выбранные элементы
     * (если селект не с мультивыбором, то в массиве всегда один элемент)
     * @param {any[]} arrayName Имя массива выбранных элементов
     *
     * @returns {void}
     */
    public onUpdateSelectedItems(selectedItems: any[], arrayName: string): void {
        selectedItems = selectedItems ? selectedItems : [];
        this[arrayName as keyof AppFilterPanelComponent] = <never>selectedItems;
    }

    public onSearchChange(search: string | null, arrayName: string): void {
        let isValid = search?.match(this.hsnTsnRegex) ? true : false;
        if (isValid) {
            const tranformedSearch = search ? search.replace(/(-|\s|\/)/, '-') : '';
            this.searchChange.emit({ search: tranformedSearch, arrayName: arrayName });
        }
    }

    /**
     * Изменение ключей правил
     * @param {ListItem} item Выбранное правило
     */
    public onSelectDecisionRules(item: ListItem): void {
        this.changedFilterParams.decisionRuleIds = null;
        if (item) {
            if (!this.selectedDecisionRules) {
                return;
            }
            this.changedFilterParams.decisionRuleIds = this.getKeysStringFromArray(this.selectedDecisionRules, 'key');
        }
        this.updateSelectedFields();
    }

    /**
     * Изменение идентификаторов
     * @param {boolean} flag Флаг "Выбрать все"
     */
    public onSelectAllDecisionRules(flag: boolean): void {
        this.changedFilterParams.decisionRuleIds = null;

        if (flag) {
            this.changedFilterParams.decisionRuleIds = this.getKeysStringFromArray(this.selectedDecisionRules, 'key');
        }

        this.updateSelectedFields();
    }

    /**
     * Изменение ключей правил
     * @param {ListItem} item Выбранное правило
     */
    public onSelectMatchRules(item: ListItem): void {
        this.changedFilterParams.matchRuleIds = null;
        if (item) {
            if (!this.selectedMatchRules) {
                return;
            }
            this.changedFilterParams.matchRuleIds = this.getKeysStringFromArray(this.selectedMatchRules, 'key');
        }
        this.updateSelectedFields();
    }

    /**
     * Изменение идентификаторов
     * @param {boolean} flag Флаг "Выбрать все"
     */
    public onSelectAllMatchRules(flag: boolean): void {
        this.changedFilterParams.matchRuleIds = null;

        if (flag) {
            this.changedFilterParams.matchRuleIds = this.getKeysStringFromArray(this.selectedMatchRules, 'key');
        }

        this.updateSelectedFields();
    }

    /**
     * Обновление параметров фильтров
     * @param {boolean} filterPrams параметры
     */
    public updateFilterParams(filterPrams: SimpleDataFilter) {
        this.filterParams = filterPrams;
        this.changedFilterParams = _.clone(filterPrams);
        this.updateSelects();
        this.setArticleCompetitorsCheckboxes(this.filterParams);
        this.setConfidence(this.filterParams);
        this.setRank(this.filterParams);
        this.setPriceDifference(this.filterParams);
        this.setPriceFrom(this.filterParams);
        this.setPriceTo(this.filterParams);
        this.setHsn(this.filterParams);
        this.setTsn(this.filterParams);
        this.updateSelectedFields(true);
    }

    /**
     * Изменение ключей групп артиклей
     * @param {ListItem} item Выбранная группа артиклей
     */
    public onSelectArticleGroups(item: ListItem): void {
        this.changedFilterParams.articleGroupIds = null;
        if (item) {
            if (!this.selectedArticleGroups) {
                return;
            }
            this.changedFilterParams.articleGroupIds = this.getKeysStringFromArray(this.selectedArticleGroups, 'key');
        }
        this.updateSelectedFields();
    }

    public onSelectStrategies(item: ListItem): void {
        this.changedFilterParams.strategyIds = null;
        if (item) {
            if (!this.selectedStrategies) {
                return;
            }
            this.changedFilterParams.strategyIds = this.getKeysStringFromArray(this.selectedStrategies, 'key');
        }
        this.updateSelectedFields();
    }

    /**
     * Изменение ключей машин
     * @param {ListItem} item Выбранная машина
     */
    public onSelectCars(item: ListItem): void {
        this.changedFilterParams.carIds = null;
        if (item) {
            if (!this.selectedCars) {
                return;
            }
            this.changedFilterParams.carIds = this.getKeysStringFromArray(this.selectedCars, 'key');
        }
        this.updateSelectedFields();
    }

    onChangeArticlesExcluded(checked: boolean | null): void {
        this.articlesExcluded = checked;
        this.changeArticleCompetitors();
    }

    onChangeArticlesBoosted(checked: boolean | null): void {
        this.articlesBoosted = checked;
        this.changeArticleCompetitors();
    }

    onChangeArticlesPlatform(checked: ListerPlatformTypes | null): void {
        this.listerPlatformType = checked;
        this.changeArticleCompetitors();
    }

    onChangeManuallyCreatedSearchRequests(checked: boolean | null): void {
        this.manuallyCreatedSearchRequests = checked;
        this.changeArticleCompetitors();
    }

    /**
     * Переключение чекбокса для артиклей
     * @param {MatCheckboxChange} checked Состояние чекбокса
     */
    public onChangeArticlesWithoutCompetitors(checked: MatCheckboxChange): void {
        this.articlesWithoutCompetitors = checked.checked;
        this.changeArticleCompetitors();
    }

    /**
     * Переключение чекбокса для конкурентов - потенциальные
     * @param {MatCheckboxChange} checked Состояние чекбокса
     */
    public onChangePotentialCompetitors(checked: MatCheckboxChange): void {
        this.potentialCompetitors = checked.checked;
        this.changeArticleCompetitors();
    }

    /**
     * Переключение чекбокса для конкурентов
     * @param {boolean} checked Состояние чекбокса
     */
    public onChangeCompetitors(checked: boolean | null, flagName: string): void {
        this[flagName as keyof AppFilterPanelComponent] = checked;
        this.changeArticleCompetitors();
    }

    /**
     * Изменение значений цен
     * @param {any} value введеное значение
     * @param {string} fieldName имя поля, которое было изменено
     */
    public onChangePriceFrom(value: any) {
        this.priceFrom = value;

        this.changePriceFrom();
    }

    public onChangePriceTo(value: any) {
        this.priceTo = value;

        this.changePriceTo();
    }

    /**
     * Изменение значений HSN/TSN
     * @param {any} value введеное значение
     * @param {string} fieldName имя поля, которое было изменено
     */
    public onChangeHsn(value: any) {
        let isValid = value?.match(this.hsnRegex) ? true : false;
        value = isValid ? value : null;

        if (this.hsnInput) {
            this.hsn = value;
            this.hsnInput.nativeElement.value = value;
        }

        this.changeHsn();
    }

    public onChangeTsn(value: any) {
        let isValid = value?.match(this.tsnRegex) ? true : false;
        value = isValid ? value : null;

        if (this.tsnInput) {
            this.tsn = value;
            this.tsnInput.nativeElement.value = <never>value;
        }

        this.changeTsn();
    }

    /**
     * Проверяет строку на соответствие паттерну
     * Если нет - принудительно изменяет строку на 1
     * @param value Строка
     * @param fieldName Имя поля в компоненте
     * @param inputName Имя инпута в разметке
     */
    public onChangeConfidence(value: string, fieldName: string, inputName: string): void {
        if (value?.length) {
            // Позволяем ввести точку или запятую
            let separator = value.substring(value?.length - 1, value?.length);
            if (separator == '.' || separator == ',') {
                return;
            }
        }
        let isValid = value?.match(/^(([0-9]*)((\.|\,)[0-9]{1,2})?)$/) ? true : false;
        value = isValid && value != '0.00' && value != '0,00' && value != '0' ? value : this.confidenceMin + '';

        if (this[inputName as keyof AppFilterPanelComponent]) {
            this[fieldName as keyof AppFilterPanelComponent] = <never>value;
            this[inputName as keyof AppFilterPanelComponent].nativeElement.value = <never>value;
        }

        this.changeArticleConfidence();
    }

    /**
     * Проверка на валидность двух полей Confidence
     */
    public onCheckConfidence(): void {
        if (!this.confidenceFrom?.length || !this.confidenceTo?.length) {
            return;
        }

        let confidenceFromAsNumber = Number(this.confidenceFrom);
        let confidenceToAsNumber = Number(this.confidenceTo);

        if (confidenceFromAsNumber < confidenceToAsNumber) {
            return;
        }

        if (confidenceFromAsNumber == this.confidenceMin) {
            confidenceToAsNumber += this.confidenceMin;
        } else {
            let _confidenceToAsNumber = confidenceToAsNumber;
            confidenceToAsNumber = confidenceFromAsNumber;
            confidenceFromAsNumber = _confidenceToAsNumber;

            if (confidenceFromAsNumber == confidenceToAsNumber) {
                confidenceFromAsNumber -= this.confidenceMin;
            }
        }

        this.confidenceFrom = confidenceFromAsNumber.toFixed(2);
        this.confidenceTo = confidenceToAsNumber.toFixed(2);

        if (this.confidenceFromInput) {
            this.confidenceFromInput.nativeElement.value = this.confidenceFrom;
        }
        if (this.confidenceToInput) {
            this.confidenceToInput.nativeElement.value = this.confidenceTo;
        }
        this.changeArticleConfidence();
    }

    /**
     * Поднимает значение имитируя работу инпута с типом Number
     * @param value Строка
     * @param fieldName Имя поля в компоненте
     * @param inputName Имя инпута в разметке
     */
    public onUpConfidence(value: string | null, fieldName: string, inputName: string): void {
        value = !value ? '0' : value;
        let confidenceAsNumber = Number(value);
        if (confidenceAsNumber >= this.confidenceMax) {
            confidenceAsNumber = this.confidenceMax;
        } else {
            confidenceAsNumber += this.confidenceMin;
        }

        if (this[inputName as keyof AppFilterPanelComponent]) {
            this[fieldName as keyof AppFilterPanelComponent] = <never>confidenceAsNumber.toFixed(2);
            this[inputName as keyof AppFilterPanelComponent].nativeElement.value = <never>confidenceAsNumber.toFixed(2);
        }
        this.changeArticleConfidence();
    }

    /**
     * Опускает значение имитируя работу инпута с типом Number
     * @param value Строка
     * @param fieldName Имя поля в компоненте
     * @param inputName Имя инпута в разметке
     */
    public onDownConfidence(value: string | null, fieldName: string, inputName: string): void {
        value = !value ? '0' : value;
        let confidenceAsNumber = Number(value);
        if (confidenceAsNumber <= this.confidenceMin) {
            confidenceAsNumber = this.confidenceMin;
        } else {
            confidenceAsNumber -= this.confidenceMin;
        }
        if (this[inputName as keyof AppFilterPanelComponent]) {
            this[fieldName as keyof AppFilterPanelComponent] = <never>confidenceAsNumber.toFixed(2);
            this[inputName as keyof AppFilterPanelComponent].nativeElement.value = <never>confidenceAsNumber.toFixed(2);
        }
        this.changeArticleConfidence();
    }

    /**
     * Проверяет строку на соответствие паттерну
     * Если нет - принудительно изменяет строку на 1
     * @param value Строка
     * @param fieldName Имя поля в компоненте
     * @param inputName Имя инпута в разметке
     */
    public onChangeRank(value: string, fieldName: string, inputName: string): void {
        let isValid = value?.match(/^([0-9]*)$/) ? true : false;
        value = isValid ? value : this.rankMin.toString();
        if (this[inputName as keyof AppFilterPanelComponent]) {
            this[fieldName as keyof AppFilterPanelComponent] = <never>value;
            this[inputName as keyof AppFilterPanelComponent].nativeElement.value = <never>value;
        }
        this.changeArticleRank();
    }

    /**
     * Проверка на валидность двух полей rank
     */
    public onCheckRank(fromFieldName: string, toFieldName: string, fromInputName: string, toInputName: string): void {
        if (!this[fromFieldName as keyof AppFilterPanelComponent]?.length || !this[toFieldName as keyof AppFilterPanelComponent]?.length) {
            return;
        }

        let rankFromAsNumber = Number(this[fromFieldName as keyof AppFilterPanelComponent]);
        let rankToAsNumber = Number(this[toFieldName as keyof AppFilterPanelComponent]);

        if (rankFromAsNumber < rankToAsNumber) {
            return;
        }

        if (rankFromAsNumber == this.rankMin) {
            rankToAsNumber += this.rankMin;
        } else {
            let _rankToAsNumber = rankToAsNumber;
            rankToAsNumber = rankFromAsNumber;
            rankFromAsNumber = _rankToAsNumber;

            if (rankFromAsNumber == rankToAsNumber) {
                rankFromAsNumber -= this.rankMin;
            }
        }

        this[fromFieldName as keyof AppFilterPanelComponent] = rankFromAsNumber.toFixed(0);
        this[toFieldName as keyof AppFilterPanelComponent] = rankToAsNumber.toFixed(0);

        try {
            if (this[fromInputName as keyof AppFilterPanelComponent]) {
                this[fromInputName as keyof AppFilterPanelComponent].nativeElement.value = this[fromFieldName as keyof AppFilterPanelComponent];
            }
            if (this[toInputName as keyof AppFilterPanelComponent]) {
                this[toInputName as keyof AppFilterPanelComponent].nativeElement.value = this[toFieldName as keyof AppFilterPanelComponent];
            }
        } catch (error) {
            console.log(error);
        }
        this.changeArticleRank();
    }

    /**
     * Поднимает значение имитируя работу инпута с типом Number
     * @param value Строка
     * @param fieldName Имя поля в компоненте
     * @param inputName Имя инпута в разметке
     */
    public onUpRank(value: string | null, fieldName: string, inputName: string): void {
        value = !value ? this.rankMin.toString() : value;
        let rankAsNumber = Number(value);
        if (rankAsNumber >= this.rankMax) {
            rankAsNumber = this.rankMax;
        } else {
            rankAsNumber += this.rankMin;
        }
        if (this[inputName as keyof AppFilterPanelComponent]) {
            this[fieldName as keyof AppFilterPanelComponent] = <never>rankAsNumber.toFixed(0);
            this[inputName as keyof AppFilterPanelComponent].nativeElement.value = <never>rankAsNumber.toFixed(0);
        }
        this.changeArticleRank();
    }

    /**
     * Опускает значение имитируя работу инпута с типом Number
     * @param value Строка
     * @param fieldName Имя поля в компоненте
     * @param inputName Имя инпута в разметке
     */
    public onDownRank(value: string | null, fieldName: string, inputName: string): void {
        value = !value ? this.rankMin.toString() : value;
        let rankAsNumber = Number(value);
        if (rankAsNumber <= this.rankMin) {
            rankAsNumber = this.rankMin;
        } else {
            rankAsNumber -= this.rankMin;
        }
        if (this[inputName as keyof AppFilterPanelComponent]) {
            this[fieldName as keyof AppFilterPanelComponent] = <never>rankAsNumber.toFixed(0);
            this[inputName as keyof AppFilterPanelComponent].nativeElement.value = <never>rankAsNumber.toFixed(0);
        }
        this.changeArticleRank();
    }

    public onChangePriceDifference(value: string, fieldName: string, inputName: string, isDifferencePercent: boolean = false): void {
        let regexp = isDifferencePercent ? /^([-+]?[0-9]*([.,]{1}[0-9]{2})?)$/ : /^([0-9]*([.,]{1}[0-9]{2})?)$/;
        let isValid = value?.match(regexp) ? true : false;
        value = isValid ? value : '0.00';
        if (this[inputName as keyof AppFilterPanelComponent]) {
            this[fieldName as keyof AppFilterPanelComponent] = <never>value;
            this[inputName as keyof AppFilterPanelComponent].nativeElement.value = <never>value;
        }
        this.changePriceDifference();
    }

    public onCheckPriceDifference(
        fromFieldName: string,
        toFieldName: string,
        fromInputName: string,
        toInputName: string,
        isDifferencePercent: boolean = false
    ): void {
        if (!this[fromFieldName as keyof AppFilterPanelComponent]?.length || !this[toFieldName as keyof AppFilterPanelComponent]?.length) {
            return;
        }

        let fromPriceDifferenceAsNumber = Number(this[fromFieldName as keyof AppFilterPanelComponent]);
        let toPriceDifferenceAsNumber = Number(this[toFieldName as keyof AppFilterPanelComponent]);

        if (fromPriceDifferenceAsNumber < toPriceDifferenceAsNumber) {
            return;
        }

        let minValue = isDifferencePercent ? this.priceDifferenceMin : 0;

        if (fromPriceDifferenceAsNumber == minValue) {
            toPriceDifferenceAsNumber += this.priceDifferenceStep;
        } else {
            let _toPriceDifferenceAsNumber = toPriceDifferenceAsNumber;
            toPriceDifferenceAsNumber = fromPriceDifferenceAsNumber;
            fromPriceDifferenceAsNumber = _toPriceDifferenceAsNumber;

            if (fromPriceDifferenceAsNumber == toPriceDifferenceAsNumber) {
                fromPriceDifferenceAsNumber -= this.priceDifferenceStep;
            }
        }

        this[fromFieldName as keyof AppFilterPanelComponent] = fromPriceDifferenceAsNumber.toFixed(2);
        this[toFieldName as keyof AppFilterPanelComponent] = toPriceDifferenceAsNumber.toFixed(2);

        try {
            if (this[fromInputName as keyof AppFilterPanelComponent]) {
                this[fromInputName as keyof AppFilterPanelComponent].nativeElement.value = this[fromFieldName as keyof AppFilterPanelComponent];
            }
            if (this[toInputName as keyof AppFilterPanelComponent]) {
                this[toInputName as keyof AppFilterPanelComponent].nativeElement.value = this[toFieldName as keyof AppFilterPanelComponent];
            }
        } catch (error) {
            console.log(error);
        }
        this.changePriceDifference();
    }

    public onUpPriceDifference(value: string | null, fieldName: string, inputName: string, isDifferencePercent: boolean = false): void {
        value = !value ? '0.00' : value;
        let priceAsNumber = Number(value);
        if (priceAsNumber >= this.priceDifferenceMax) {
            priceAsNumber = this.priceDifferenceMax;
        } else {
            priceAsNumber += this.priceDifferenceStep;
        }
        if (this[inputName as keyof AppFilterPanelComponent]) {
            this[fieldName as keyof AppFilterPanelComponent] = <never>priceAsNumber.toFixed(2);
            this[inputName as keyof AppFilterPanelComponent].nativeElement.value = <never>priceAsNumber.toFixed(2);
        }
        this.changePriceDifference();
    }

    public onDownPriceDifference(value: string | null, fieldName: string, inputName: string, isDifferencePercent: boolean = false): void {
        value = !value ? '0.00' : value;
        let priceAsNumber = Number(value);
        let minValue = isDifferencePercent ? this.priceDifferenceMin : 0;
        if (priceAsNumber <= minValue) {
            priceAsNumber = minValue;
        } else {
            priceAsNumber -= this.priceDifferenceStep;
        }
        if (this[inputName as keyof AppFilterPanelComponent]) {
            this[fieldName as keyof AppFilterPanelComponent] = <never>priceAsNumber.toFixed(2);
            this[inputName as keyof AppFilterPanelComponent].nativeElement.value = <never>priceAsNumber.toFixed(2);
        }
        this.changePriceDifference();
    }

    /**
     * Изменения в параметрах - Competitors
     */
    private changeArticleCompetitors(): void {
        this.changedFilterParams.excluded = this.articlesExcluded;
        this.changedFilterParams.boosted = this.articlesBoosted;
        this.changedFilterParams.listerPlatformType = this.listerPlatformType;
        this.changedFilterParams.noCompetitors = this.articlesWithoutCompetitors;
        this.changedFilterParams.noManualSearchRequests = this.manuallyCreatedSearchRequests;
        this.changedFilterParams.potentialCompetitors = this.potentialCompetitors;
        this.changedFilterParams.competitorsWithoutSales = this.competitorsWithoutSales;
        this.changedFilterParams.competitorsWithLongShipping = this.competitorsWithLongShipping;
        this.changedFilterParams.isCompetitor = this.isCompetitor;
        this.changedFilterParams.inactiveCompetitors = this.inactiveCompetitors;
        this.changedFilterParams.validated = this.validated;
        this.changedFilterParams.validationMatchesRules = this.validationMatchesRules;
        this.changedFilterParams.markedAsVariant = this.markedAsVariant;
    }

    /**
     * Изменения в параметрах - Confidence
     */
    private changeArticleConfidence(): void {
        this.changedFilterParams.confidenceFrom = this.confidenceFrom || null;
        this.changedFilterParams.confidenceTo = this.confidenceTo || null;
    }

    /**
     * Изменения в параметрах - rank
     */
    private changeArticleRank(): void {
        this.changedFilterParams.fromMarketplaceRank = this.fromMarketplaceRank || null;
        this.changedFilterParams.toMarketplaceRank = this.toMarketplaceRank || null;
        this.changedFilterParams.fromVisibilityRank = this.fromVisibilityRank || null;
        this.changedFilterParams.toVisibilityRank = this.toVisibilityRank || null;
    }

    /**
     * Изменения в параметрах - rank
     */
    private changePriceDifference(): void {
        this.changedFilterParams.fromPrice = this.fromPrice || null;
        this.changedFilterParams.toPrice = this.toPrice || null;
        this.changedFilterParams.fromPriceDifferencePercent = this.fromPriceDifferencePercent || null;
        this.changedFilterParams.toPriceDifferencePercent = this.toPriceDifferencePercent || null;
        if (this.filterSettings?.priceDifferenceCompetitorFilters) {
            this.changedFilterParams.fromCompetitorPrice = this.fromCompetitorPrice || null;
            this.changedFilterParams.toCompetitorPrice = this.toCompetitorPrice || null;
        }
    }

    /**
     * Изменения в параметрах - Price
     */
    private changePriceFrom(): void {
        this.changedFilterParams.priceFrom = this.priceFrom || null;
    }
    private changePriceTo(): void {
        this.changedFilterParams.priceTo = this.priceTo || null;
    }

    /**
     * Изменения в параметрах - Hsn/Tsn
     */
    private changeHsn(): void {
        this.changedFilterParams.hsn = this.hsn || null;
    }
    private changeTsn(): void {
        this.changedFilterParams.tsn = this.tsn || null;
    }

    /**
     * Обновления всех выбранных полей
     */
    private updateSelectedFields(emitEvent: boolean = false): void {
        this.selectedFields = this.changeSelectedFields(this.selectedFields);
        if (emitEvent) {
            this.selectedFieldsChanged.next(this.selectedFields);
        }
    }

    /**
     * Собирает из разных коллекций - одну selectedFields
     * @param {Array<FilterSelectedField>} selectedFields коллекция выбранных элементов со всех селектов
     * @returns {Array<FilterSelectedField>} Возвращает весь список полей
     */
    private changeSelectedFields(selectedFields: Array<FilterSelectedField>): Array<FilterSelectedField> {
        selectedFields = [];

        if (this.filterSettings?.decisionRuleFilter) {
            this.selectedDecisionRules.forEach((item: ListItem) => {
                selectedFields.push(this.createField('selectedDecisionRules', 'Decision Rules', 'key', <never>item.key, <never>item.value));
            });
        }

        if (this.filterSettings?.rulesMatchFilter) {
            this.selectedMatchRules.forEach((item: ListItem) => {
                selectedFields.push(this.createField('selectedMatchRules', 'Rules Match', 'key', <never>item.key, <never>item.value));
            });
        }

        if (this.filterSettings?.articleGroupFilter) {
            this.selectedArticleGroups.forEach((item: ListItem) => {
                selectedFields.push(this.createField('selectedArticleGroups', AppText.mainMenuItemIsArticleGroups, 'key', <never>item.key, <never>item.value));
            });
        }

        if (this.filterSettings?.strategiesFilter) {
            this.selectedStrategies.forEach((item: ListItem) => {
                selectedFields.push(this.createField('selectedStrategies', AppText.mainMenuItemIsStrategies, 'key', <never>item.key, <never>item.value));
            });
        }

        if (this.filterSettings?.carsFilter) {
            this.selectedCars.forEach((item: ListItem) => {
                selectedFields.push(this.createField('selectedCars', AppText.entityIsOneCar, 'key', <never>item.key, <never>item.value));
            });
        }

        if (this.filterSettings?.articlesExcludedOption && this.articlesExcluded != null) {
            selectedFields.push(
                this.createField(
                    'articlesExcluded',
                    AppText.generalTextIsStatusOnRepricing,
                    'articlesExcluded',
                    'articlesExcluded',
                    this.articlesExcluded ? AppText.generalTextIsDisabled : AppText.generalTextIsEnabled
                )
            );
        }

        if (this.filterSettings?.articlesBoostedFilter && this.articlesBoosted != null) {
            selectedFields.push(
                this.createField(
                    'articlesBoosted',
                    AppText.generalTextIsBoosting,
                    'articlesBoosted',
                    'articlesBoosted',
                    this.articlesBoosted ? AppText.generalTextIsEnabled : AppText.generalTextIsDisabled
                )
            );
        }

        if (this.filterSettings?.articlesPlatformFilter && this.listerPlatformType != null) {
            selectedFields.push(
                this.createField(
                    'listerPlatformType',
                    AppText.generalTextIsPlatform,
                    'listerPlatformType',
                    'listerPlatformType',
                    this.listerPlatformType === AppText.propertyNameIsEbayLister ? AppText.generalTextIsEbay : AppText.generalTextIsAmazon
                )
            );
        }

        if (this.filterSettings?.articlesWithoutCompetitorsOption && this.articlesWithoutCompetitors) {
            selectedFields.push(
                this.createField(
                    'articlesWithoutCompetitors',
                    AppText.mainMenuItemIsCompetitors,
                    'articlesWithoutCompetitors',
                    'articlesWithoutCompetitors',
                    <never>AppText.generalTextIsWithout
                )
            );
        }

        if (this.filterSettings?.manuallyCreatedSearchesFilter && this.manuallyCreatedSearchRequests != null) {
            selectedFields.push(
                this.createField(
                    'noManualSearchRequests',
                    AppText.generalTextIsManuallyCreatedRequests,
                    'noManualSearchRequests',
                    'noManualSearchRequests',
                    this.manuallyCreatedSearchRequests === true ? AppText.generalTextIsExcluded : AppText.generalTextIsIncluded
                ) // Пишется в noManualSearchRequests, поэтому Excluded противоположно true
            );
        }

        if (this.filterSettings?.fromConfidenceFilter && this.confidenceFrom) {
            selectedFields.push(
                this.createField('confidenceFrom', AppText.generalTextIsConfidenceFrom, 'confidenceFrom', 'confidenceFrom', <never>this.confidenceFrom)
            );
        }

        if (this.filterSettings?.toConfidenceFilter && this.confidenceTo) {
            selectedFields.push(this.createField('confidenceTo', AppText.generalTextIsConfidenceTo, 'confidenceTo', 'confidenceTo', <never>this.confidenceTo));
        }

        if (this.filterSettings?.rankFilters) {
            if (this.fromMarketplaceRank) {
                selectedFields.push(
                    this.createField(
                        'fromMarketplaceRank',
                        AppText.generalTextIsMarketplaceRankFrom,
                        'fromMarketplaceRank',
                        'fromMarketplaceRank',
                        <never>this.fromMarketplaceRank
                    )
                );
            }
            if (this.toMarketplaceRank) {
                selectedFields.push(
                    this.createField(
                        'toMarketplaceRank',
                        AppText.generalTextIsMarketplaceRankTo,
                        'toMarketplaceRank',
                        'toMarketplaceRank',
                        <never>this.toMarketplaceRank
                    )
                );
            }
            if (this.fromVisibilityRank) {
                selectedFields.push(
                    this.createField(
                        'fromVisibilityRank',
                        AppText.generalTextIsVisibilityRankFrom,
                        'fromVisibilityRank',
                        'fromVisibilityRank',
                        <never>this.fromVisibilityRank
                    )
                );
            }
            if (this.toVisibilityRank) {
                selectedFields.push(
                    this.createField(
                        'toVisibilityRank',
                        AppText.generalTextIsVisibilityRankTo,
                        'toVisibilityRank',
                        'toVisibilityRank',
                        <never>this.toVisibilityRank
                    )
                );
            }
        }
        if (this.filterSettings?.priceDifferenceFilters) {
            if (this.fromPrice) {
                selectedFields.push(this.createField('fromPrice', AppText.generalTextIsPriceFrom, 'fromPrice', 'fromPrice', <never>this.fromPrice));
            }
            if (this.toPrice) {
                selectedFields.push(this.createField('toPrice', AppText.generalTextIsPriceTo, 'toPrice', 'toPrice', <never>this.toPrice));
            }
            if (this.fromPriceDifferencePercent) {
                selectedFields.push(
                    this.createField(
                        'fromPriceDifferencePercent',
                        AppText.generalTextIsPriceDifferencePercentFrom,
                        'fromPriceDifferencePercent',
                        'fromPriceDifferencePercent',
                        <never>this.fromPriceDifferencePercent
                    )
                );
            }
            if (this.toPriceDifferencePercent) {
                selectedFields.push(
                    this.createField(
                        'toPriceDifferencePercent',
                        AppText.generalTextIsPriceDifferencePercentTo,
                        'toPriceDifferencePercent',
                        'toPriceDifferencePercent',
                        <never>this.toPriceDifferencePercent
                    )
                );
            }
        }
        if (this.filterSettings?.priceDifferenceCompetitorFilters) {
            if (this.fromCompetitorPrice) {
                selectedFields.push(
                    this.createField(
                        'fromCompetitorPrice',
                        AppText.generalTextIsPriceFrom,
                        'fromCompetitorPrice',
                        'fromCompetitorPrice',
                        <never>this.fromCompetitorPrice
                    )
                );
            }
            if (this.toCompetitorPrice) {
                selectedFields.push(
                    this.createField('toCompetitorPrice', AppText.generalTextIsPriceTo, 'toCompetitorPrice', 'toCompetitorPrice', <never>this.toCompetitorPrice)
                );
            }
        }
        if (this.filterSettings?.markedAsCompetitorsFilter) {
            if (this.isCompetitor != null) {
                selectedFields.push(
                    this.createField(
                        'isCompetitor',
                        AppText.sortFieldIsIsCompetitor,
                        'isCompetitor',
                        'isCompetitor',
                        this.isCompetitor ? this.pageText.yes : this.pageText.no
                    )
                );
            }
        }
        if (this.filterSettings?.competitorsOption) {
            if (this.potentialCompetitors != null) {
                selectedFields.push(
                    this.createField(
                        'potentialCompetitors',
                        AppText.propertyPlaceholderShowPotentialCompetitors,
                        'potentialCompetitors',
                        'potentialCompetitors',
                        this.potentialCompetitors ? this.pageText.yes : this.pageText.no
                    )
                );
            }
            if (this.competitorsWithoutSales != null) {
                selectedFields.push(
                    this.createField(
                        'competitorsWithoutSales',
                        AppText.propertyPlaceholderShowCompetitorsWithoutSales,
                        'competitorsWithoutSales',
                        'competitorsWithoutSales',
                        this.competitorsWithoutSales ? this.pageText.yes : this.pageText.no
                    )
                );
            }
            if (this.competitorsWithLongShipping != null) {
                selectedFields.push(
                    this.createField(
                        'competitorsWithLongShipping',
                        AppText.propertyPlaceholderShowCompetitorsWithLongShippingTimes,
                        'competitorsWithLongShipping',
                        'competitorsWithLongShipping',
                        this.competitorsWithLongShipping ? this.pageText.yes : this.pageText.no
                    )
                );
            }
            if (this.inactiveCompetitors != null) {
                selectedFields.push(
                    this.createField(
                        'inactiveCompetitors',
                        AppText.propertyPlaceholderInactiveCompetitors,
                        'inactiveCompetitors',
                        'inactiveCompetitors',
                        this.inactiveCompetitors ? this.pageText.yes : this.pageText.no
                    )
                );
            }
        }
        if (this.filterSettings?.validationCompetitorsFilter && this.validated != null) {
            selectedFields.push(
                this.createField(
                    'validated',
                    AppText.generalTextIsValidated,
                    'validated',
                    'validated',
                    this.validated ? this.pageText.yes : this.pageText.no
                )
            );
        }

        if (this.filterSettings?.validationMatchesRulesCompetitorsFilter && this.validationMatchesRules != null) {
            selectedFields.push(
                this.createField(
                    'validationMatchesRules',
                    this.pageText.generalTextIsValidationMatchesRules,
                    'validationMatchesRules',
                    'validationMatchesRules',
                    this.validationMatchesRules ? this.pageText.yes : this.pageText.no
                )
            );
        }

        if (this.filterSettings?.variationCompetitorsFilter && this.markedAsVariant != null) {
            selectedFields.push(
                this.createField(
                    'markedAsVariant',
                    AppText.generalTextIsIsVariant,
                    'markedAsVariant',
                    'markedAsVariant',
                    this.markedAsVariant ? this.pageText.yes : this.pageText.no
                )
            );
        }

        if (this.filterSettings?.priceFilters && this.priceFrom) {
            selectedFields.push(this.createField('priceFrom', AppText.generalTextIsPriceFrom, 'priceFrom', 'priceFrom', <never>this.priceFrom));
        }

        if (this.filterSettings?.priceFilters && this.priceTo) {
            selectedFields.push(this.createField('priceTo', AppText.generalTextIsPriceTo, 'priceTo', 'priceTo', <never>this.priceTo));
        }

        if (this.filterSettings?.hsnTsnFilters && this.hsn) {
            selectedFields.push(this.createField('hsn', AppText.generalTextIsHsn, 'hsn', 'hsn', <never>this.hsn));
        }

        if (this.filterSettings?.hsnTsnFilters && this.tsn) {
            selectedFields.push(this.createField('tsn', AppText.generalTextIsTsn, 'tsn', 'tsn', <never>this.tsn));
        }

        return selectedFields;
    }

    /**
     * Установка элементов как выбранных по ключам(идентификаторам)
     * @param {string} inputIds Идентификаторы, разделенные запятой
     */
    private setSelectedDecisionRules(inputIds?: string): void {
        this.selectedDecisionRules = [];

        if (!this.decisionRules?.length || !inputIds?.length) {
            return;
        }

        // Поиск выбранных
        let ids = inputIds.split(',');

        if (ids?.length) {
            ids.forEach((id: string) => {
                const foundItem = _.find(this.decisionRules, (item: ListItem) => {
                    return String(id) === String(item?.key);
                });
                if (foundItem) {
                    this.selectedDecisionRules.push(foundItem);
                }
            });
        }
    }

    /**
     * Установка элементов как выбранных по ключам(идентификаторам)
     * @param {string} inputIds Идентификаторы, разделенные запятой
     */
    private setSelectedMatchRules(inputIds?: string): void {
        this.selectedMatchRules = [];

        if (!this.matchRules?.length || !inputIds?.length) {
            return;
        }

        // Поиск выбранных
        let ids = inputIds.split(',');

        if (ids?.length) {
            ids.forEach((id: string) => {
                const foundItem = _.find(this.matchRules, (item: ListItem) => {
                    return String(id) === String(item?.key);
                });
                if (foundItem) {
                    this.selectedMatchRules.push(foundItem);
                }
            });
        }
    }

    /**
     * Установка элементов как выбранных по ключам(идентификаторам)
     * @param {string} inputIds Идентификаторы, разделенные запятой
     */
    private setSelectedArticleGroups(inputIds?: string): void {
        this.selectedArticleGroups = [];

        if (!this.articleGroups?.length || !inputIds?.length) {
            return;
        }

        // Поиск выбранных
        let ids = inputIds.split(',');

        if (ids?.length) {
            ids.forEach((id: string) => {
                const foundItem = _.find(this.articleGroups, (item: ListItem) => {
                    return String(id) === String(item?.key);
                });
                if (foundItem) {
                    this.selectedArticleGroups.push(foundItem);
                }
            });
        }
    }

    private setSelectedStrategies(inputIds?: string): void {
        this.selectedStrategies = [];

        if (!this.strategies?.length || !inputIds?.length) {
            return;
        }

        // Поиск выбранных
        let ids = inputIds.split(',');

        if (ids?.length) {
            ids.forEach((id: string) => {
                const foundItem = _.find(this.strategies, (item: ListItem) => {
                    return String(id) === String(item?.key);
                });
                if (foundItem) {
                    this.selectedStrategies.push(foundItem);
                }
            });
        }
    }

    /**
     * Установка элементов как выбранных по ключам(идентификаторам)
     * @param {string} inputIds Идентификаторы, разделенные запятой
     */
    private setSelectedCars(inputIds?: string): void {
        if (!this.cars?.length || !inputIds?.length) {
            return;
        }

        if (inputIds) {
            const foundItem = _.find(this.cars, (item: ListItem) => {
                return String(inputIds) === String(item?.key);
            });
            if (foundItem && (!this.selectedCars || this.selectedCars.length == 0 || this.selectedCars[0].key === inputIds)) {
                this.selectedCars = [];
                this.selectedCars.push(foundItem);
            }
        }
    }

    /**
     * Обновление всех селектов
     */
    private updateSelects(): void {
        this.setSelectedDecisionRules(this.filterParams?.decisionRuleIds || '');
        this.setSelectedMatchRules(this.filterParams.matchRuleIds || '');
        this.setSelectedArticleGroups(this.filterParams.articleGroupIds || '');
        this.setSelectedStrategies(this.filterParams.strategyIds || '');
        this.setSelectedCars(this.filterParams.carIds || '');
        this.updateSelectedFields(true);
    }

    /**
     * Установка чекбоксов исходя из фильтра
     * @param params Параметры фильтра
     */
    private setArticleCompetitorsCheckboxes(params: SimpleDataFilter): void {
        if (this.filterSettings?.articlesExcludedOption) {
            this.articlesExcluded = params?.excluded + '' === 'true' ? true : params?.excluded + '' === 'false' ? false : null;
        }
        if (this.filterSettings?.articlesBoostedFilter) {
            this.articlesBoosted = params?.boosted + '' === 'true' ? true : params?.boosted + '' === 'false' ? false : null;
        }
        if (this.filterSettings?.articlesPlatformFilter) {
            this.listerPlatformType = params?.listerPlatformType != null ? params?.listerPlatformType : null;
        }
        if (this.filterSettings?.articlesWithoutCompetitorsOption) {
            this.articlesWithoutCompetitors = params?.noCompetitors + '' === 'true' ? true : null;
        }
        // Пишется в noManualSearchRequests, поэтому противоположное значение
        if (this.filterSettings?.manuallyCreatedSearchesFilter) {
            this.manuallyCreatedSearchRequests = params?.noManualSearchRequests + '' === 'true' ?
                true : params?.noManualSearchRequests + '' === 'false' ?
                    false : null;
        }
        if (this.filterSettings?.competitorsOption) {
            this.potentialCompetitors = params?.potentialCompetitors + '' === 'true' ? true : null;
            this.competitorsWithoutSales = params?.competitorsWithoutSales + '' === 'true' ? true : null;
            this.competitorsWithLongShipping = params?.competitorsWithLongShipping + '' === 'true' ? true : null;
            this.inactiveCompetitors = params?.inactiveCompetitors + '' === 'true' ? true : null;
        }
        if (this.filterSettings?.markedAsCompetitorsFilter) {
            this.isCompetitor = params?.isCompetitor + '' === 'true' ? true : params?.isCompetitor + '' === 'false' ? false : null;
        }
        if (this.filterSettings?.validationCompetitorsFilter) {
            this.validated = params?.validated + '' === 'true' ? true : params?.validated + '' === 'false' ? false : null;
        }
        if (this.filterSettings?.validationMatchesRulesCompetitorsFilter) {
            this.validationMatchesRules =
                params?.validationMatchesRules + '' === 'true' ? true : params?.validationMatchesRules + '' === 'false' ? false : null;
        }
        if (this.filterSettings?.variationCompetitorsFilter) {
            this.markedAsVariant = params?.markedAsVariant + '' === 'true' ? true : params?.markedAsVariant + '' === 'false' ? false : null;
        }
    }

    /**
     * Установка Confidence исходя из фильтра
     * @param params Параметры фильтра
     */
    private setConfidence(params: SimpleDataFilter): void {
        if (this.filterSettings?.confidenceFilters) {
            this.confidenceFrom = params?.confidenceFrom ? params?.confidenceFrom : null;
            this.confidenceTo = params?.confidenceTo ? params?.confidenceTo : null;
        }
    }

    /**
     * Установка rank исходя из фильтра
     * @param params Параметры фильтра
     */
    private setRank(params: SimpleDataFilter): void {
        if (this.filterSettings?.rankFilters) {
            this.fromMarketplaceRank = params?.fromMarketplaceRank ? params?.fromMarketplaceRank : null;
            this.toMarketplaceRank = params?.toMarketplaceRank ? params?.toMarketplaceRank : null;
            this.fromVisibilityRank = params?.fromVisibilityRank ? params?.fromVisibilityRank : null;
            this.toVisibilityRank = params?.toVisibilityRank ? params?.toVisibilityRank : null;
        }
    }

    private setPriceDifference(params: SimpleDataFilter): void {
        if (this.filterSettings?.priceDifferenceFilters) {
            this.fromPrice = params?.fromPrice ? params?.fromPrice : null;
            this.toPrice = params?.toPrice ? params?.toPrice : null;
            this.fromPriceDifferencePercent = params?.fromPriceDifferencePercent ? params?.fromPriceDifferencePercent : null;
            this.toPriceDifferencePercent = params?.toPriceDifferencePercent ? params?.toPriceDifferencePercent : null;
        }

        if (this.filterSettings?.priceDifferenceCompetitorFilters) {
            this.fromCompetitorPrice = params?.fromCompetitorPrice ? params?.fromCompetitorPrice : null;
            this.toCompetitorPrice = params?.toCompetitorPrice ? params?.toCompetitorPrice : null;
        }
    }

    /**
     * Установка Price исходя из фильтра
     * @param params Параметры фильтра
     */
    private setPriceFrom(params: SimpleDataFilter): void {
        if (this.filterSettings?.priceFilters) {
            this.priceFrom = params?.priceFrom ? params?.priceFrom : null;
        }
    }

    private setPriceTo(params: SimpleDataFilter): void {
        if (this.filterSettings?.priceFilters) {
            this.priceTo = params?.priceTo ? params?.priceTo : null;
        }
    }

    /**
     * Установка Price исходя из фильтра
     * @param params Параметры фильтра
     */
    private setHsn(params: SimpleDataFilter): void {
        if (this.filterSettings?.hsnTsnFilters) {
            this.hsn = params?.hsn ? params?.hsn : null;
        }
    }

    private setTsn(params: SimpleDataFilter): void {
        if (this.filterSettings?.hsnTsnFilters) {
            this.tsn = params?.tsn ? params?.tsn : null;
        }
    }

    /**
     * Метод проверяет равенство значений (старого и нового)
     * @param {string} newValue Новое значение
     * @param {string} formerValue Прежнее значение
     *
     * @returns {boolean} Истинно, если значения одинаковые
     */
    private isValueTheSame(newValue: string, formerValue: string): boolean {
        return newValue === formerValue ? true : false;
    }

    /**
     * Получает строку из ключей, разделенные сепаратором (`,` по умолчанию)
     * @param {any[]} array Выбранные элементы
     * @param {string} key Ключ который нужно вписывать в строку
     * @param {string} separator Разделитель ключей в строке
     * @returns {string} Строка ключей через запятыми
     */
    private getKeysStringFromArray(array: any[], key: string = '', separator: string = ','): string | null {
        let str = '';
        if (!array?.length) {
            return str;
        }
        if (typeof array === 'string') {
            str = str + array;
            return str;
        }

        array.forEach((item: any, index: number) => {
            // Для массива с ключами
            if (key && item[key]) {
                str = index === array.length - 1 ? str + item[key] : str + item[key] + separator;
            }
            // Для массива строк, например
            else if (!key && item) {
                str = index === array.length - 1 ? str + item : str + item + separator;
            }
        });
        return str ? str : null;
    }

    /**
     * Создание модели поля применного фильтра
     * @param arrayKey общий ключ коллекции фильров
     * @param arrayTitle отображаемый заголовок примененного фильтра
     * @param uniqueFieldKey уникальный ключ поля для фильтрации
     * @param uniqueFieldValue уникальное значение поля для фильтрации
     * @param value отображаемое значение примененного фильтра
     * @returns
     */
    private createField(arrayKey: string, arrayTitle: string, uniqueFieldKey: string, uniqueFieldValue: string, value: string): FilterSelectedField {
        let field = new FilterSelectedField({});
        field.collectionKey = arrayKey;
        field.collectionTitle = arrayTitle;
        field.selectedItemUniqueFieldKey = uniqueFieldKey;
        field.selectedItemUniqueFieldValue = uniqueFieldValue;
        field.selectedItemValue = value;
        return field;
    }
}
