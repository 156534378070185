<div class="app-content" fxLayout="row" fxLayoutAlign="start space-between">
    <div class="app-content-wrapper" fxLayout="column" fxLayoutAlign="start stretch" fxFlex="auto" fxLayoutGap="24px">
        <app-header [isLabelLoading]="isLoading" [label]="pageTitle" [backButtonVisible]="true" (onBack)="onBack()">
            <div fxLayout="row" fxLayoutAlign=" center" buttons>
                <button class="border-right-radius-none btn-primary-min-width" type="button" mat-flat-button color="primary"
                    disableRipple (click)="onSave(detailsForm)">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                        <svg class="btn-svg-icon-16">
                          <use xlink:href="assets/icons/apply-icon.svg#applyIcon"/>
                        </svg>
                        <span>{{pageText.applyAction}}</span>
                    </div>
                </button>
                <button class="btn-stroked border-left-radius-none" type="button" mat-button disableRipple (click)="onBack()">
                    <div class="close-icon" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                        <svg class="btn-svg-icon-16">
                          <use xlink:href="assets/icons/clear-icon.svg#clearIcon"/>
                        </svg>
                    </div>
                </button>
            </div>
        </app-header>

        <div *ngIf="isLoading" class="table-container block" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="24px">
            <div class="profile-photo-skeleton skeleton-block"></div>
            <div class="app-dialog__form profile-form" fxLayout="column" fxLayoutGap="16px" fxFlex="100%">
                <div *ngFor="let item of skeletonArray" class="skeleton-block" style="width: 100% !important; height: 45px !important;"></div>
            </div>
        </div>

        <div *ngIf="!isLoading" class="table-container block" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="24px">
            <div class="profile-photo" fxLayout="column">
                <img src="../../assets/icons/user-account-logo.png" alt="Profile photo" />
            </div>
            <form class="app-dialog__form profile-form" fxLayout="column" fxLayoutGap="16px" fxFlex="100%"
            [formGroup]="detailsForm" (ngSubmit)="onSave(detailsForm)">
                <div class="app-dialog__form-control" fxLayout="column" fxLayoutGap="5px" fxFlex="auto">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>{{pageText.propertyPlaceholderFirstName}}</mat-label>
                        <input matInput type="text" #firstNameInput formControlName="firstName" placeholder="Enter name"
                        autocomplete="off" maxlength="30" i18n-placeholder="@@hint.message.is.enter.name" />
                        <mat-error align="start">{{pageText.propertyPlaceholderFirstName}} {{pageText.generalTextIsIsRequired}}</mat-error>
                        <mat-hint align="end">{{firstNameInput.value.length}}/30</mat-hint>
                    </mat-form-field>
                </div>
                <div class="app-dialog__form-control" fxLayout="column" fxLayoutGap="5px" fxFlex="auto">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>{{pageText.propertyPlaceholderLastName}}</mat-label>
                        <input matInput type="text" #lastNameInput formControlName="lastName" placeholder="Enter name"
                        autocomplete="off" maxlength="30" i18n-placeholder="@@hint.message.is.enter.name" />
                        <mat-error align="start">{{pageText.propertyPlaceholderLastName}} {{pageText.generalTextIsIsRequired}}</mat-error>
                        <mat-hint align="end">{{lastNameInput.value.length}}/30</mat-hint>
                    </mat-form-field>
                </div>
                <div class="app-dialog__form-control" fxLayout="column" fxLayoutGap="5px" fxFlex="auto">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>{{pageText.propertyPlaceholderUserEmail}}</mat-label>
                        <input matInput type="text" #userEmailInput formControlName="userEmail" placeholder="Enter email"
                        autocomplete="off" maxlength="128" i18n-placeholder="@@hint.message.is.enter.email" [pattern]="emailPattern" />
                        @if (userEmailInput.validity.valueMissing) {
                            <mat-error align="start">{{pageText.propertyPlaceholderUserEmail}} {{pageText.generalTextIsIsRequired}}</mat-error>
                        }
                        @else {
                            <mat-error align="start">{{pageText.propertyPlaceholderInvalidUserEmail}}</mat-error>
                        }
                        <mat-hint align="end">{{userEmailInput.value.length}}/128</mat-hint>
                    </mat-form-field>
                </div>
                <div class="app-dialog__form-control" fxLayout="column" fxLayoutGap="5px" fxFlex="auto">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>{{pageText.propertyPlaceholderCompanyName}}</mat-label>
                        <input matInput type="text" #companyInput formControlName="companyName" placeholder="Enter company name"
                        autocomplete="off" maxlength="50" i18n-placeholder="@@hint.message.is.enter.company.name" />
                        <mat-error align="start">{{pageText.propertyPlaceholderCompanyName}} {{pageText.generalTextIsIsRequired}}</mat-error>
                        <mat-hint align="end">{{companyInput.value.length}}/50</mat-hint>
                    </mat-form-field>
                </div>

                <div class="app-dialog__form-control" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100%">
                    <div fxLayout="column" fxLayoutGap="15px" style="width:100%;">
                        <span class="text" style="font-size:16px;">{{pageText.propertyPlaceholderUserPassword}}</span>
                        <span class="text" style="font-size:14px;">{{pageText.thePasswordIsHiddenForSecurityReason}}</span>
                        <!-- <div fxLayout="row" fxLayoutAlign="start " fxLayoutGap="5px">
                            <svg-icon *ngFor="let item of currentPasswordIconLength;" key="circle-full-icon" fontSize="14px"></svg-icon>
                        </div> -->
                    </div>
                    <div class="buttons-container" fxLayout="row">
                        <button type="button" class="button" fxLayoutAlign="center center" style="min-width: 110px;"
                            fxLayoutGap="12" disableRipple (click)="onChangePassword()">
                            <span>{{pageText.editAction}}</span>
                        </button>
                    </div>
                </div>
                <button type="submit" class="hide-btn"></button>
            </form>
        </div>
    </div>
</div>