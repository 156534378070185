import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EmailVerificationRoutingModule } from './email-verification-routing.module';
import { EmailVerificationComponent } from './email-verification.component';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SvgIconComponent } from '@ngneat/svg-icon';



@NgModule({
    declarations: [
        EmailVerificationComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        RouterModule,
        SharedModule,
        SvgIconComponent,
        EmailVerificationRoutingModule
    ]
})
export class EmailVerificationModule { }
